


<!--========================================-->



<app-page-heade></app-page-heade>
<!--<app-page-heade></app-page-heade>-->
<br />
<br />
<br />
<br />
<br />

<br />
<br />


<div class="container">
  <div>
    <!--<div style="font-size: 17px;letter-spacing: 0.5px;font-weight: bold;">Pending Orders  : </div>-->
    <h1 class="heading" style="margin-left: 0px;">
      Sales and Collections 
    </h1>
    <br />



    <form *ngIf="ShowDropDown">
      <fieldset>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label class="col-md-4 control-label">Sales-Exec.</label>
              <div class="col-md-4">
                <div class="input-group">
                  <select name="SalesExecutive" class="form-control selectpicker" [(ngModel)]="SalesPerson" (change)="AvraSyn_GetDispatchDetailsOnSelect(SalesPerson)">
                    <option [ngValue]="undefined" disabled selected> Please select Sales Exective </option>
                    <option *ngFor="let item of DropDownData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </div>
      </fieldset>
    </form>


    <p-table #dt [columns]="cols" [value]="Achievements" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">

      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
            <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
            <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
               aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;">Export to Excel</i>
            <p class="CountGridRecords" style="margin-left:30%">{{dt.totalRecords}} out of {{AchievementLength}}</p>
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>

          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span>{{col.header}}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="cursor:pointer">
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
            <div *ngSwitchCase="'CatalogValue'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'BulkAmt'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'GrossAmt'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'TotalAmt'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'CollectedAmount'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>

          </td>
        </tr>
      </ng-template>


    </p-table>

  </div>
</div>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>












































<style>

  .heading {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px dotted #808080;
    display: block;
    font-size: 14px;
    width: 99.7%;
    font-weight: bold;
    margin-left: -12px;
    margin-bottom: 0;
  }

  .CountGridRecords {
    color: #3e50b4;
    margin-top: -24px !important;
    text-align: left;
  }
</style>
