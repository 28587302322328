

<!--==========================================-->

<app-page-heade></app-page-heade>
<br />
<br />
<br />
<br />
<br />

<br />
<br />


<div class="container">
  <div>
    <h1 class="heading">
      Invoice
    </h1>


    <form *ngIf="ShowDropDown">
      <fieldset>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label class="col-md-4 control-label">Sales-Exec.</label>
              <div class="col-md-4">
                <div class="input-group">
                  <select name="SalesExecutive" class="form-control selectpicker" (change)="AvraSyn_GetInvoiceDetailsOnSelect(SalesPerson)" [(ngModel)]="SalesPerson">
                    <option [ngValue]="undefined" disabled selected> Please select Sales Exective </option>
                    <option *ngFor="let item of DropDownData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </div>
      </fieldset>
    </form>



    <form class="row form-inline" style="padding-left:1rem;">
      <div class="row" style="padding-top:2rem;padding-bottom:1rem;">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <label for="input-12">Inv Start Date</label>
          <input type="date" class="form-control" [max]="maxdate" style="height: calc(2.5em + 0.75rem + -10px);" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="    padding-left: 7px;">
          <label for="input-13">Inv End Date</label>
          <input type="date" style="height: calc(2.5em + 0.75rem + -10px);" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
        </div>
        <div class="col-lg-2 col-md-1 col-sm-12 col-xs-12" style="padding-left: 77px;">
          <button type="submit" class="btn btn-primary" style="    border-radius: 0px !important;
    margin-top: 26px;width: 70px;" (click)="Filter(startdate,enddate)">
            Get
          </button>
        </div>
      </div>








    </form>


    <div class="row" style="padding-left:1rem;">
      <p-table #dt [columns]="cols" [value]="InvoiceByCoordinator" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'98%'}" [scrollable]="true" scrollHeight="285px">

        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
              <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
              <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                 aria-label="" (click)="dt.exportCSV()"
                 style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"> Export to Excel</i>
              <p class="CountGridRecords" style="margin-left:30%">{{dt.totalRecords}} out of {{InvoiceByCoordinatorlength}}</p>
            </div>
            <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
            </div>
          </div>

        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>

            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              <span>{{col.header}}</span>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr style="cursor:pointer">
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
              <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
              <div *ngSwitchCase="'Quantity'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
              <div *ngSwitchCase="'NetAmt'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">

          <tr style="text-align:right" *ngIf="InvoiceByCoordinatorlength!=0">
            <td colspan="6" style="text-align: right;">Grand Total</td>
            <td><b>{{Quantity | number:'1.2-2'}}</b></td>
            <td><b>{{NetAmt | number:'1.2-2'}}</b></td>
          </tr>
          <tr style="text-align:center" *ngIf="InvoiceByCoordinatorlength==0">
            <td colspan="11" style="text-align: center;">No Invoice details availabale,Contact administrator</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>






































































