<app-page-heade></app-page-heade>
<!--<app-page-heade></app-page-heade>-->
<br />
<br />
<br />
<br />
<br />


<div class="container">
  <div>
    <div class="container">
      <h1 class="heading">
        Orders
      </h1>
           <div class="row">

             <form class="row form-inline" style="padding-left:1rem;">
               <div class="row" style="padding-top:2rem;padding-bottom:1rem;">

                 <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                 </div>
                 <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                   <label for="input-12">Orders Start Date</label>
                   <input type="date" class="form-control" [max]="maxdate" style="height: calc(2.5em + 0.75rem + -10px);" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
                 </div>
                 <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="    padding-left: 7px;">
                   <label for="input-13">Orders End Date</label>
                   <input type="date" style="height: calc(2.5em + 0.75rem + -10px);" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
                 </div>
                 <div class="col-lg-2 col-md-1 col-sm-12 col-xs-12" style="padding-left: 77px;">
                   <button type="submit" class="btn btn-primary" style="    border-radius: 0px !important;
    margin-top: 26px;width: 64px;" (click)="Filter(startdate,enddate)">
                     Get
                   </button>
                 </div>
               </div>

             </form>








             <p-table #dt [columns]="cols" [value]="Orders" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'97.5%'}">
               <ng-template pTemplate="caption">
                 <div class="row">

                   <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                     <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                     <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                        aria-label="" (click)="dt.exportCSV()"
                        style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"> Export to Excel </i>
                     <p class="CountGridRecords" style="margin-left:30%"> &nbsp;&nbsp;{{dt.totalRecords}} Records</p>
                   </div>
                     <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                       <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                     </div>
                   </div>

</ng-template>
               <ng-template pTemplate="colgroup" let-columns>
                 <colgroup>
                   <col style="width:70px">
                   <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                 </colgroup>
               </ng-template>
               <ng-template pTemplate="header" let-columns>
                 <tr>
                   <th>Mail</th>
                   <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                     <span>{{col.header}}</span>
                     <p-sortIcon [field]="col.field"></p-sortIcon>
                   </th>
                 </tr>
               </ng-template>
               <ng-template pTemplate="body" let-rowData let-columns="columns">

                 <tr style="cursor:pointer">
                   <!--<td style="text-align:center">
          <input type="submit" value="Mail" class="button" (click)="ResendMail(rowData)">
        </td>-->
                   <td style="text-align:center">
                     <div class="summary" style="width:87%;">
                       <div class="total" (click)="ResendMail(rowData)">
                         <div><b>Mail</b></div>
                       </div>
                     </div>
                   </td>
                   <td *ngFor="let col of columns" [ngSwitch]="col.field">
                     <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                     <!--<span *ngSwitchCase="'catalog_no'"><a style="cursor:pointer" pTooltip="Click to Order" tooltipPosition="top" (click)="ProductDetails(rowData)">{{rowData[col.field]}}</a></span>-->




                   </td>




                 </tr>
               </ng-template>
             </p-table>
           </div>
    </div>

  </div>
</div>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>
<style>

  .heading {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px dotted #808080;
    display: block;
    font-size: 25px !important;
    width: 99.7%;
    font-weight: bold;
    margin-left: -12px;
    margin-bottom: 0;
    letter-spacing: 1px !important;
    padding-top: 2rem !important;
  }

  .CountGridRecords {
    color: #3e50b4;
    margin-top: -24px !important;
    text-align: left;
  }
</style>
