import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-bio-group-prods',
  templateUrl: './bio-group-prods.component.html',
  styleUrls: ['./bio-group-prods.component.scss']
})
export class BioGroupProdsComponent implements OnInit {
  spinner: boolean;
  AGroupDesc: string;
  FromSubFrps: boolean;
  AgroupCode: string;
  grps: string;
  igmcode: string;
  SubGroupProductsLength: any;
  igmDesc: any;
  isgmDesc: any;
  SubGroupProducts: any;
  SubGroupName: any;

  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient,
    public Location: Location) {
    debugger
    this.SubGroupName = localStorage.getItem('SubGroupName');
    this.isgmDesc = localStorage.getItem('isgmDesc')
    this.igmDesc = localStorage.getItem('igmDesc');
    this.AgroupCode = localStorage.getItem('AGroupCode'); 
    this.AGroupDesc = localStorage.getItem('AGroupDesc'); 


    localStorage.removeItem('home'); 

    this.grps = localStorage.getItem('Grps');

  }

  ngOnInit(): void {
    this.OnClickOfBioGrpSubGrp();
  }

  //Getting Special Chemical Group's Products on click of Group//
  OnClickOfBioGrpSubGrp() {
    debugger
    this.spinner = true;
    this.isgmDesc = localStorage.getItem('isgmDesc')
    this.igmDesc = localStorage.getItem('igmDesc')
    this.igmcode = localStorage.getItem('igmCode')
    if (this.grps == 'FromSubFrps') {
      this.FromSubFrps = true;
      var GetUrl = 'api/Values/GetGroupInfoBasedOnGroupID?GroupName=' + encodeURIComponent(this.igmDesc) + '&SubGroupName=' + encodeURIComponent(this.isgmDesc) + '&igmcode=' + encodeURIComponent(this.igmcode) + '&flag=2';
    }
    else if (this.grps == 'FromAgroups') {
      this.FromSubFrps = false;

      //here igmcode is used as Agropcode
      var GetUrl = 'api/Values/GetGroupInfoBasedOnGroupID?GroupName=' + encodeURIComponent(this.igmDesc) + '&SubGroupName=' + encodeURIComponent(this.isgmDesc) + '&igmcode=' + encodeURIComponent(this.AgroupCode) + '&flag=3';
    }
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.SubGroupProducts = data;
      this.SubGroupProductsLength = this.SubGroupProducts.length;
      this.spinner = false;

    });
  }

  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnBiologics() {
    debugger
    this.router.navigateByUrl('/Biologics');
  }
  ClickOnHeadGroup() {
    debugger
    this.router.navigateByUrl('/BioSubGrps');
  }
  ClickOnBioGrpProd(ItemCode) {
    debugger
    localStorage.removeItem('ItemCode')
    localStorage.setItem('ItemCode', ItemCode)
    this.router.navigateByUrl('/BioGrpProdDet');
  }


  ClickOnHeadSubGrp() {
    this.Location.back();

  }
}
