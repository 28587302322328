
<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>

<section *ngIf="!ShowProductDetails">
  <div class="container" style="margin-top:8rem;">
    <h3 class="ClaHead">
      Product Classification
    </h3>
    <div *ngIf="ShowGroups">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div class="Head1">
            <span class="hoverr" (click)="CickOnAvraCaralogue()">Hyma Catalogue</span><i style="padding: 8px;" class="fa fa-angle-double-right"></i>
            <b>Speciality Chemicals</b>
          </div>
        </div><br />
      </div>
      <div class="row">
        <div class="col-md-6">
          <ul class="sub_head">
            <li class="hoverr" style="margin-bottom: 3px;" *ngFor="let item of SplGroups" (click)="ClickOnSplGroupProds(item.GroupName)">
              <i [ngStyle]="{'color' : '#8eb629'}" class="fa fa-book" aria-hidden="true"></i> {{item.GroupName}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-indicator" *ngIf="spinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>

</section>













