import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;


@Component({
  selector: 'app-bio-sub-grps',
  templateUrl: './bio-sub-grps.component.html',
  styleUrls: ['./bio-sub-grps.component.scss']
})
export class BioSubGrpsComponent implements OnInit {
  spinner: boolean;
  Items: any;
  BioGrpProds: any;
  SubGroups: any;
  HomeUrl: any;
  Item: any;
  IgmCode: any;
  igmDesc:any;
  Icon   :any;
  Color  :any;
  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {

    this.Item = JSON.parse(localStorage.getItem('Item'));
    this.Items = JSON.parse(localStorage.getItem('Items'));

    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    })
    localStorage.removeItem('home');

  }

  ngOnInit(): void {
    this.Avra2020_ALS_GetSubGroupsBasedOnGroupId();
  }



  //Getting  Group's SubGroups on click of Group//
  Avra2020_ALS_GetSubGroupsBasedOnGroupId() {
    debugger
    this.spinner = true;
    this.IgmCode = this.Item.igmCode;
    this.igmDesc = this.Item.igmDesc;
    this.Icon    = this.Item.Icon;
    this.Color   = this.Item.Color;
    //var GetUrl = 'api/Values/Avra2020_ALS_GetSubGroupsBasedOnGroupId?IgmCode=' + this.IgmCode;
    //this.generalService.GetData(GetUrl).then((data: any) => {
    var GetUrl = 'api/Values/GetGroupSPL';
    var UploadFile = new FormData();
    UploadFile.append('Flag', "3");
    UploadFile.append('ItmCode', this.IgmCode);
    UploadFile.append('ItmDesc', "");
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.SubGroups = data;
      this.spinner = false;

    });
  }

  getColor(igmDesc): string {
    
    igmDesc = this.igmDesc;
    if (igmDesc == 'Microbiology') {
      return '#991d62';
    }
    else if (igmDesc == 'Immunology') {
      return '#e6a025';
    }
    else if (igmDesc == 'Proteomics') {
      return '#8eb629';
    }
    else if (igmDesc == 'Biochemicals') {
      return '#3592c1';
    }
    else if (igmDesc == 'Molecular Biology') {
      return '#672862';
    }

  }

  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnBiologics() {
    debugger
    this.router.navigateByUrl('/Biologics');
  }

  //Getting Special Chemical Group's Products on click of Group//
  OnClickofSubGrp(SubGroupName, isgmDesc) {
    debugger
    var GetUrl = 'api/Admin/CCheck_AGRP_code?desc=' + encodeURIComponent(SubGroupName);
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.BioGrpProds = data;

      localStorage.removeItem('Grps')
      localStorage.setItem('Grps', 'FromSubFrps')

      localStorage.removeItem('SubGroupName')
      localStorage.setItem('SubGroupName', SubGroupName);

      localStorage.removeItem('isgmDesc')
      localStorage.setItem('isgmDesc', isgmDesc);


      localStorage.removeItem('igmDesc')
      localStorage.setItem('igmDesc', this.igmDesc);
      localStorage.removeItem('igmCode')
      localStorage.setItem('igmCode', SubGroupName);

      if (this.BioGrpProds.length != 0) {

    

        localStorage.removeItem('AGroupData')
        localStorage.setItem('AGroupData', JSON.stringify(this.BioGrpProds));
        this.router.navigate(['/AGroupData']);
       // this.router.navigateByUrl('/AGroupData')
      }
      if (this.BioGrpProds.length == 0) {
        this.router.navigate(['/BioGrpProds']);
      }
    });
  }

}
