<app-page-heade></app-page-heade>

<br />
<br />
<br />
<br />
<br />
<br />
<div class="container">
  <div *ngIf="!noProductsFound">
    <div class="container">
      <h1 class="heading">Search Results</h1><br />
      <div class="row">
        <p-table #dt [columns]="cols" [scrollable]="true" [value]="AllProducts" [rows]="12" [paginator]="true" [style]="{width:'97.5%'}">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top" aria-label="" (click)="dt.exportCSV()"
                   style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"> Export to Excel ({{dt.totalRecords}} Records)</i>
              </div>
              <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr style="cursor:pointer" (click)="ClickOnProd(rowData)">
              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <span *ngSwitchDefault style="width: 100%; overflow: hidden; display: table-row; text-overflow: ellipsis; cursor: pointer">{{rowData[col.field]}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div *ngIf="noProductsFound">
    <!--<h2>No products found. Please fill out the enquiry form:</h2>
    <button (click)="openEnquiryModal()" class="btn btn-primary">click here</button>-->
    <!-- Modal HTML -->
    <div class="modal" id="myModal2">
      <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <h2 class="enquriy_h1">Enquiry Form</h2>
        <h2 style="font-size:15px">Thank you for reaching us, the product you are looking for is not  listed in our range. Kindly fill the below details to respond ASAP!</h2>
        <form (ngSubmit)="submitEnquiry()">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="name">Name<span style="color:red">*</span></label>
                <input type="text" id="name" [(ngModel)]="UserObj.name" name="name" class="form-control" required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="mobile">Mobile Number<span style="color:red">*</span></label>
                <input type="text" (keypress)="keyPress($event)" id="mobile" [(ngModel)]="UserObj.mobile" name="mobile" class="form-control" minlength="10" maxlength="10" required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="email">Email ID<span style="color:red">*</span></label>
                <input type="email" id="email" [(ngModel)]="UserObj.email" name="email" class="form-control" required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="product">Product Name<span style="color:red">*</span></label>
                <input type="text" id="product" [(ngModel)]="UserObj.product" name="product" class="form-control" required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="CasNumber">CAS Number</label>
                <input type="text" id="CasNumber" [(ngModel)]="UserObj.CasNumber" name="CasNumber" class="form-control" required>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="Quantity">Quantity<span style="color:red">*</span></label>
                <input type="text" id="Quantity" [(ngModel)]="UserObj.Quantity" name="Quantity" class="form-control" required>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="Description">Description</label>
                <input type="text" id="Description" [(ngModel)]="UserObj.Description" name="Description" class="form-control" style="width:460px">
              </div>
            </div>
           


          </div>


          <div class="row">
            
          
            <div class="col-lg-4">
              <button type="submit" class="btn btn-primary">Submit Enquiry</button>
            </div>
          </div>




        </form>
      </div>
    </div>
  </div>

</div>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>

