import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [DatePipe]

})


export class OrdersComponent implements OnInit {
  TotRecords: any;
  Orders1: any;
  CartData: any[];
  spinner: boolean;
  LoginDet: any;
  
  cols: any;
  Orders: any;

  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';
  constructor(public generalService: GeneralService, public router: Router, public datePipe: DatePipe) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [

      { field: 'OrderedDate', header: 'Order Datee', width: '90px' },
      { field: 'OrderID', header: 'Order ID', width: '70px' },
      { field: 'ChemicalName', header: 'Chemical Name', width: '150px' },
      { field: 'ItemCode', header: 'Item Code', width: '100px' },
      { field: 'CASNo', header: 'CAS', width: '80px' },
      { field: 'Packsize', header: 'Pack Size', width: '60px' },
      { field: 'Packcode', header: 'Pack Code', width: '80px' },
      { field: 'Quantity', header: 'Qty.', width: '80px' },
      //{ field: 'UnitPrice', header: 'Unit Price', width: '100px' },
      { field: 'UnitPrice1', header: 'Unit Price', width: '100px' },

      { field: 'price1', header: 'Total Price', width: '80px' },


      { field: 'Plateform', header: 'Platform', width: '80px' },
      { field: 'Location', header: 'Location', width: '80px' },

      
    ]

   
  }
 



  ngOnInit() {
    var date = new Date();
    this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.mindate = this.enddate;
    this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.GetOrders();
  }

  GetOrders() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/AvraSyn_GettingMyOrders?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' +"4" ;
//+ this.LoginDet[0].OrderTo
 this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.Orders = data;
      this.Orders1 = data;
      

      this.TotRecords = data.length;
    this.spinner = false;

    });

  }
 
  Filter(startdate: any, enddate: any) {
    debugger
    this.spinner = true;

    this.Orders = '';

    // Adjust start date to beginning of the day (midnight)
    startdate = new Date(new Date(startdate).setHours(0, 0, 0, 0));

    // Adjust end date to the last millisecond of the day
    enddate = new Date(new Date(enddate).setHours(23, 59, 59, 999));

    // Filter orders by the adjusted start and end dates
    this.Orders = this.Orders1.filter(
      m => new Date(m.OrderDate) >= startdate && new Date(m.OrderDate) <= enddate
    );

    this.TotRecords = this.Orders.length;

    this.spinner = false;
  }

  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
  /*end code*/
  
  ResendMail(rowdata) {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetDataToResendMail?OrderID=' + rowdata.OrderID + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.CartData = [];
      for (var i = 0; i < data.length; i++) {
        this.CartData.push({
          ItemCode: data[i].ItemCode
          , HSNCode: data[i].HSNCode
          , GSTTAX: data[i].GSTTAX
          , CASNO: data[i].CASNO
          , ChemicalName: data[i].ChemicalName
          , PackSize: data[i].PackSize
          , Quantity: data[i].Quantity
          , Price: data[i].Price
          , IndiVidualPrice: data[i].Price
          , Currency: data[i].Currency
          
        })
      }
      //if (this.LoginDet[0].OrderTo == 1) //Commented on 24 oct
      //{
      //  this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
      //}
      //else if (this.LoginDet[0].OrderTo == 2) {
      //  this.LoginDet[0].FromAddress = "bangaloreinfo@avrasynthesis.com";

      //}
      //else {
      //  this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
      //}
      if (rowdata.Location == 'Hyderabad')
      {
        this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
      }
      else if (rowdata.Location == 'Bangalore') {
        this.LoginDet[0].FromAddress = "bangaloreinfo@avrasynthesis.com";

      }
      else {
        this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
      }
      debugger
      var Url = '/api/Admin/SendOrderMail';
      var UploadFile = new FormData();
      UploadFile.append('ProdDet', JSON.stringify(this.CartData));
      UploadFile.append('UserDet', JSON.stringify(this.LoginDet));
      UploadFile.append('OrderId', rowdata.OrderID);
      this.generalService.PostData(Url, UploadFile).then((data: any) => {
        debugger
      
        if (data == 'Sucess') {
          
          this.spinner = false;
          this.generalService.ShowAlert('SUCCESS', 'Re-Send mail successful, Please check mail .....', 'success');

          //this.generalService.ShowAlert('SUCCESS', 'Re-Send mail successful, Please check mail .....', 'success');
        }
        else {
          this.spinner = false;
          this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');

        }
      }, err => {
        this.spinner = false;
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });


    });
   


  }


}

