<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="d-flex justify-content-center align-items-center w-100 h-100 login-container banner">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="card border-0 box-shadow rounded-0">
      <div class="card-body text-center pb-1 row">
        <!--<a class="navbar-brand" routerLink="/Home">
          <img src="../../assets/Avra_Logo_new.png" class="mobile_logo">
        </a>-->
        <br />
      </div>
      <div class="card-body text-center pb-1" style="margin-top:0.5rem;">
        <h2>Member Sign In</h2>

        <div style="margin-bottom: 1rem; cursor: pointer" routerLink="/register" class="transition">
          <u>Don't have an account? Sign up now!</u>
        </div>

        <br />
        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="text-left mt-4">
          <div class="form-group">
            <input [formControl]="email" class="form-control validation-field" placeholder="Email" type="text">
            <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').hasError('required')">Email is required</small>
            <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').hasError('email')">Invalid email address</small>
          </div>
          <div class="form-group">
            <input [formControl]="password" class="form-control validation-field" placeholder="Password" type="password" id="myInput">
            <i class="fa fa-eye" *ngIf="passwordshow" style="color:green; font-size: 18px;margin-left: 21.5rem;position: absolute;margin-top: -33px;" (click)="ShowPassword(1)"></i><span class="icon-name"></span>
            <i class="fa fa-eye-slash" *ngIf="!passwordshow" style="color:green; font-size: 18px;margin-left: 21.5rem;position: absolute;margin-top: -33px;" (click)="ShowPassword(1)"></i><span class="icon-name"></span>
            <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').hasError('required')">Password is required</small>
            <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</small>
          </div>
          <div class="form-group d-flex justify-content-between">
            <!--<div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="login-checkbox">
        <label class="custom-control-label" for="login-checkbox">Keep me signed in.</label>
      </div>-->
            <a class="transition pull-right" href="javascript:void(0);" (click)="ForgotPassword()" style="color:#000">Forgot password?</a>
          </div>
          <div class="form-group">
            <button [disabled]="!form.valid" class="login-form" type="submit">Sign in</button>
          </div>
        </form>
      </div>
     
    </div>
  </div>

</div>
<!-- #region check verify mobile number popup -->
<div class="modal" id="myModal2">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile/Email</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Mobile/Email<span style="color:red">*</span></label>
          <input [(ngModel)]="Mobile" class="form-control Caps" placeholder="Enter Mobile/Email" type="text">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="CheckMobile(Mobile)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
<!-- #region check otp popup for forgot password-->
<div class="modal" id="myModal3">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter otp received on your mail</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <label>OTP<span style="color:red">*</span></label>
            <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter Otp" type="text">
          </div>
          <div class="row" style="float:right">
            <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
          </div>
        </div>
        </div>
        <!--<div class="row">
            <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>
            <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>-->
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>
          <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
  </div>
</div>
<!-- #endregion -->
<!-- #region update customer and vendor password-->
<div class="modal" id="myModal4">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Password<span style="color:red">*</span></label>
          <input [(ngModel)]="Password" class="form-control Caps" placeholder="Enter password" type="password">
          <br>
          <label>Confirm Password<span style="color:red">*</span></label>
          <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirmpassword" type="password">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="ChangePassword(Password,Confirmpassword)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->


<style>
  .banner {
    background-image: url(../../assets/ALSBgIMG.jpg);
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .modal-dialog {
    width: 400px !important;
    margin: 30px auto;
  }

  .btn:not(.btn-link):not(.btn-circle) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
    border: 0;
    font-size: 13px;
    border-radius: 50px;
    width: 43%;
    height: 3rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border: 1px solid #c1c1c1;
  }
</style>
