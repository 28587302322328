import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  totalvalue: any;

  private user = new BehaviorSubject<string>('0');

  cast = this.user.asObservable();

  constructor() {
    debugger
   var cart = JSON.parse(localStorage.getItem("totalcartitems"))
if (cart != undefined || cart != "" || cart !== null) {
  this.user.next(cart)
}
else {
  this.user.next("0");
    }
  }

edituser(newuser) {
  debugger
  this.user.next(newuser);
}
}
