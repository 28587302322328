import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss'],
  providers: [DatePipe]

})
export class LedgerComponent implements OnInit {
  LedgerData1: any;
  Debit: number;
  LedgerDataLength: any;
  LedgerData: any;
  LoginUserTypeId: string;

  spinner: boolean;
  cols: any;
  LoginDet: any;


  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';
  constructor(public generalService: GeneralService, public router: Router, public datePipe: DatePipe) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'Vocherdate', header: 'Voucher Date', width: '130px' },
      { field: 'VoucherNo', header: 'Voucher No', width: '130px' },
      { field: 'Narration', header: 'Narration', width: '130px' },
      {
        field: 'Debit', header: 'Debit(in Rs.)', width: '130px' },
      {
        field: 'Credit', header: 'Credit(in Rs.)', width: '130px' },
      {
        field: 'Balance', header: 'Balance(in Rs.)', width: '130px' },
      { field: 'DrCr', header: 'Company', width: '130px' },
      { field: 'CheckNo', header: 'Ref.Doc', width: '130px' },
      { field: 'CheckDate', header: 'Ref.Doc Date', width: '130px' },

                      

                                        


    ]

    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }
  }

  ngOnInit(): void {

    if (this.LoginUserTypeId == '4') {
      var date = new Date();
      this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.mindate = this.enddate;
      this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.LedgerDetails();
    }
   


  }




  LedgerDetails() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetLedgerInfo?CustCode=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      //this.LedgerData = data;
      this.Debit = 0;
      this.LedgerData = data.ledger;
      this.LedgerData1 = data.ledger;

      this.LedgerDataLength = this.LedgerData.length;
      for (var i = 0; i < this.LedgerDataLength; i++) {
        this.Debit = this.Debit + this.LedgerData[i].Debit;
      }
      debugger
      this.spinner = false;
    });

  }


  Filter(startdate, enddate) {
    debugger
    this.spinner = true;

    this.LedgerData = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.LedgerData = this.LedgerData1.filter(
      m => new Date(m.Vocherdate1) >= new Date(startdate) && new Date(m.Vocherdate1) <= new Date(enddate)
    );
    this.spinner = false;
    this.LedgerDataLength = this.LedgerData.length;

  }
  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
  /*end code*/
}
