import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  AuditReportLength: any;
  spinner: boolean;
  ReportData: any;
  LoginUserTypeId: any;
  LoginDet: any;
  cols: { field: string; header: string; width: string; }[];


  constructor(public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      //{ field: 'AuditID', header: 'AuditID', width: '30px' },
      { field: 'UserID', header: 'UserID', width: '80px' },
      { field: 'SearchItemName', header: 'Search Item Name', width: '150px' },
      { field: 'ClientName', header: 'Client Name', width: '150px' },
      { field: 'CompanyName', header: 'Company Name', width: '150px' },
      { field: 'Mobile', header: 'Mobile', width: '90px' },
      { field: 'EmailId', header: 'Email Id', width: '150px' },
      { field: 'SearchDate1', header: 'Search Date', width: '110px' },
      { field: 'IPAddress', header: 'IP Address', width: '100px' },
      { field: 'Latitude', header: 'Latitude', width: '120px' },
      { field: 'Longitute', header: 'Longitude', width: '120px' },
      { field: 'CatalogNo', header: 'Catalogue No', width: '120px' },
      { field: 'OrderNumber', header: 'Order Number', width: '120px' },






     // { field: 'City', header: 'City', width: '30px' },
    ]
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
      this.router.navigate(['/Home']);
    }
  }


  ngOnInit(): void {
    this.GetAuditReports();
  }

  /*GetAuditReports*/
  GetAuditReports() {
    debugger;
    this.spinner = true;
    var UploadFile = new FormData();
    var url = "/api/Admin/GetAuditReport"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      this.ReportData = data;
      this.AuditReportLength = this.ReportData.length;
    this.spinner = false;
    }, err => {
      this.spinner = false;
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*end code*/
}
