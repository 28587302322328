<app-page-heade></app-page-heade>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>




<div class="container" style="margin-top:8rem;">


  <h3 class="ClaHead">

    Purchase Order

  </h3>








  <br />
  <div class="row">

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border">
      <div style="padding: 0px 314px 8px 0px;">
        Note : Document Name must be in .XLSX or .XLS
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="margin-left: 21px;">
        Upload  Documents Here  :
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="file">
          <input (change)="detectFilesMSDS($event)" type="file" id="file" aria-label="File browser example">
          <span class="file-custom"></span>
        </label>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
        <input (click)="Upload1()" type="submit" style="padding: 0px 4px 0px 4px;" value="Upload" />
      </div>


    </div>

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

  </div>






  <div class="row">
    <div class="col-lg-12">
      <p-tabView>
        <p-tabPanel header="Cart">
          <div>
            <p-table #dt [columns]="cols" [value]="DataFromExcel" [scrollable]="true" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'98.7%'}">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                       aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:-1px;"></i>
                    <p class="CountGridRecords" style="margin-left:10%">{{dt.totalRecords}} Records </p>

                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>

                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;">{{rowData[col.field]}}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>




      </p-tabView>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-10">
    </div>
    <div class="col-lg-2">
      <div class="summary" style="width:100%;padding: 0px 0px 0px 51px;">
        <dl class="total" (click)="AddToCart()">
          <dt><b>Add To Cart</b></dt>
        </dl>
      </div>
    </div>
  </div>



</div>






  <style>
    .border {
      border: 1px solid rgba(112, 112, 108, 0.42);
      padding: 6px 0px 0px 0px;
      text-align: right
    }
  </style>

