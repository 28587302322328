import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;

@Component({
  selector: 'app-product-tree',
  templateUrl: './product-tree.component.html',
  styleUrls: ['./product-tree.component.scss']
})
export class ProductTreeComponent implements OnInit {
  BioIcon: string;
  SplIcon: string;
  Groups: any=[];
  SplGroups: any;

  constructor(public generalService: GeneralService, public router: Router) {
    localStorage.removeItem('home');
  }

  ngOnInit(): void {
    this.GetSplGroups();
    this.Groups = [];
    this.Groups.push({ ID: 1, Name: 'Speciality Chemicals', Icon: '../../../assets/007.png' }
      , { ID: 2, Name: 'Biologics', Icon: '../../../assets/008.png' }
     ,{ ID: 3, Name: 'Aqua Culture', Icon: '../../../assets/aqua.jpg' })
  }
  GetSplGroups() {
    debugger
    var GetUrl = 'api/Values/GetSplGroups';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.SplGroups = data;
    });

  }



  getColor(Name): string {

    if (Name == 'Speciality Chemicals') {
      return '#991d62';
    }
    else if (Name == 'Biologics') {
      return '#e6a025';
    }
 else if (Name == 'Aqua Culture') {
      return '#100b43d1';
    }

  }

  GoToGroups(ID) {
    debugger
    if (ID == 1) {
      this.router.navigateByUrl('/SplChem');
    }
    else if (ID == 2) {
      this.router.navigateByUrl('/Biologics');
    }
else if (ID == 3) {
      this.router.navigateByUrl('/Aquaproducts');
    }
  }
}



  




