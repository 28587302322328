
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DatePipe } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as tslib_1 from 'tslib';
import * as date_fns_2 from 'date-fns'; 
function adapterFactory() {
  return tslib_1.__assign(tslib_1.__assign({}),date_fns_2);
}


import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';

import { HttpClientModule } from '@angular/common/http';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HttpModule } from '@angular/http';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';

import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductTreeComponent } from './pages/product-tree/product-tree.component';
import { CoaMsdsComponent } from './pages/coa-msds/coa-msds.component';
import { CoaDetailsComponent } from './pages/coa-details/coa-details.component';
import { SpecialChemicalsComponent } from './pages/special-chemicals/special-chemicals.component';
import { BiologicsComponent } from './pages/biologics/biologics.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component';
import { PageHeadeComponent } from './pages/page-heade/page-heade.component';
import { PageFooterComponent } from './pages/page-footer/page-footer.component';
import { SplGrpProdsComponent } from './pages/spl-grp-prods/spl-grp-prods.component';
import { ProdsdetailsComponent } from './pages/prodsdetails/prodsdetails.component';
import { BioSubGrpsComponent } from './pages/bio-sub-grps/bio-sub-grps.component';
import { CartitemsComponent } from './pages/cartitems/cartitems.component';
import { AgroupDataComponent } from './pages/agroup-data/agroup-data.component';
import { BioGroupProdsComponent } from './pages/bio-group-prods/bio-group-prods.component';
import { BioGrpProdDetailsComponent } from './pages/bio-grp-prod-details/bio-grp-prod-details.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { DealersInfoComponent } from './pages/dealers-info/dealers-info.component';
//import { ProgressSpinnerModule } from 'primeng/primeng';
import { LoginComponent } from './pages/login/login.component';
//import {   } from '@angular/forms';
import { RegisterComponent } from './pages/register/register.component';
import { PendingPaymentsComponent } from './pages/pending-payments/pending-payments.component';

import { PendingOrdersComponent } from './pages/pending-orders/pending-orders.component';
import { DispatchDetailsComponent } from './pages/dispatch-details/dispatch-details.component';

import {BulkOrderEnquiryComponent } from './pages/bulk-order-enquiry/bulk-order-enquiry.component';
import { AchievementComponent } from './pages/achievement/achievement.component';
import { CustComplaintsComponent } from './pages/cust-complaints/cust-complaints.component';
import { FeedBackComponent } from './pages/feed-back/feed-back.component';
import { SolventsComponent } from './pages/solvents/solvents.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { LedgerComponent } from './pages/ledger/ledger.component';
import { CustomerRegComponent } from './pages/customer-reg/customer-reg.component';
import { DealersMapComponent } from './pages/dealers-map/dealers-map.component'
import { AuditComponent } from './pages/audit/audit.component'
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component'
import { GMapModule } from 'primeng/gmap';
import { TestimonialComponent } from './pages/testimonial/testimonial.component'
import {DealerslistComponent} from './pages/dealerslist/dealerslist.component';
import { AquaproductsComponent } from './pages/aquaproducts/aquaproducts.component';
import { AquagrpprodsComponent } from './pages/aquagrpprods/aquagrpprods.component';
import { AquagrpproddetailsComponent } from './pages/aquagrpproddetails/aquagrpproddetails.component';
import { NewprodComponent } from './pages/newprod/newprod.component';

 @NgModule({  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    PerfectScrollbarModule,
    NgbModule,
    MultiselectDropdownModule, ProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_sXZEFwDf7juDc3yV4P7qXKpvCIv1RFA'

    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), 
    PipesModule, ToastModule,
    AppRoutingModule, HttpClientModule, AutoCompleteModule, HttpModule, TableModule, TabViewModule, GMapModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent, LoginComponent, RegisterComponent,
    PagesComponent,
    HeaderComponent, BulkOrderEnquiryComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,

    NotFoundComponent,

    ContactUsComponent,

    DownloadsComponent, AuditComponent,

    HomeComponent,

    ProductsComponent,

    ProductTreeComponent,

    CoaMsdsComponent,

    CoaDetailsComponent,

    SpecialChemicalsComponent,

    BiologicsComponent,

    PagelayoutComponent,

    PageHeadeComponent,

    PageFooterComponent, SplGrpProdsComponent, ProdsdetailsComponent, BioSubGrpsComponent, CartitemsComponent, AgroupDataComponent, BioGroupProdsComponent, BioGrpProdDetailsComponent,
    OrdersComponent, DealersInfoComponent,


    PendingOrdersComponent, DispatchDetailsComponent, PendingPaymentsComponent,


    PendingPaymentsComponent,


    AchievementComponent,


    CustComplaintsComponent,


    FeedBackComponent,


    SolventsComponent,


    InvoiceComponent,


    LedgerComponent,


    CustomerRegComponent,


    AuditComponent, PurchaseOrderComponent,
    DealersMapComponent,
  TestimonialComponent,
DealerslistComponent,
AquaproductsComponent,
AquagrpprodsComponent,AquagrpproddetailsComponent,NewprodComponent

  ],
  providers: [ 
    AppSettings, DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { } 
