<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style>
    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: #ffffff;
      color: #000;
      text-align: center;
      border-top: ridge;
      z-index: 99;
    }
  </style>
</head>
<body>

  <h2></h2><br /><br />


  <div class="footer container">
    <div class="row">
      <div class="col-lg-6">
        <p class="copy" style="padding-left: 6.7rem;margin-top: 6px;text-align: left;">Copyright 2024 © by Hyma Synthesis Private Limited. All Rights Reserved. </p>
      </div>
      <div class="col-lg-2">
        <p style="margin-top: 6px;">
          <!--<a href="#">Privacy Policy</a>-->
        </p>
      </div>
      <div class="col-lg-4">
        <p (click)="Go()" style="margin-top: 6px;margin-left: -91px;" class="power">
          Powered by :  <span  style="color:deepskyblue"><u style="cursor:pointer;">Gagri Global IT Services Pvt. Ltd.</u>
           <img src="../../../assets/ggfooter_logo.png"  style="cursor: pointer; width: 4%;"/>
          </span>
        </p>
      </div>
    </div>
  </div>
 
</body>
</html>


<!--///New ///-->
