<app-page-heade></app-page-heade>
 <br />  
 <div class="container mt-5">
   <!--<div class="row">
    <div class="col-lg-2 ">
      <button type="button" (click)="Back()" *ngIf="!gridview" class="btn btn-danger btn-border-radius waves-effect"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>

    </div>

  </div>-->
   <br /> <br /> <br />
   <div class="card_1 mt-5" *ngIf="!gridview">
     <fieldset class="scheduler-border" [formGroup]="Form">
       <legend class="ctmr_heading scheduler-border">{{ButtonText}} Testimonial </legend>


       <!----------------------- insert and update form fields in Product Master------------------>

       <div class="row">        
         <!-- Company -->
         <div class="col-lg-5" style="margin-top: 14px;">
           <div class="form-group">
             <label><b>Company<span style="color:red">*</span></b></label>
             <input class="form-control" formControlName="CompanyName" type="text" placeholder="Company">
             <small style="color:red" class="text-danger" *ngIf="Form.controls.CompanyName.touched && Form.controls.CompanyName.errors?.required">Company is required</small>
           </div>
         </div>

         <!-- Contact Person -->
         <div class="col-lg-4" style="margin-top: 14px;">
           <div class="form-group">
             <label><b>Contact Person<span style="color:red">*</span></b></label>
             <input class="form-control" formControlName="Contactperson" type="text" placeholder="Contact person">
             <small style="color:red" class="text-danger" *ngIf="Form.controls.Contactperson.touched && Form.controls.Contactperson.errors?.required">Contact person is required</small>
           </div>
         </div>
         <!--Sequence-->
         <div class="col-lg-3" style="margin-top: 14px;">
           <div class="form-group">
             <label><b>Sequence<span style="color:red">*</span></b></label>
             <input class="form-control" formControlName="Sequence" type="text" placeholder="Sequence number">
             <small style="color:red" class="text-danger" *ngIf="Form.controls.Sequence.touched && Form.controls.Sequence.hasError('required')">Sequence is required</small>
             <small style="color:red; font-size:10px; font-style: italic; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="text-danger" *ngIf="Form.controls.Sequence.hasError('numericOnly')">
               Note: Sequence must be unique and numeric only
             </small>
           </div>
         </div>
       </div>
         
       <div class="row">

         <!-- Comment -->
         <div class="col-lg-6" style="margin-top: 14px;">
           <div class="form-group">
             <label><b>Comment<span style="color: red">*</span></b></label>
             <!-- Add maxlength attribute to limit the number of characters -->
             <textarea class="form-control" formControlName="Comment" placeholder="Comment" maxlength="225" style="width: 100%; min-height: 100px;"></textarea>
             <small style="color: red; font-size: 10px; font-style: italic; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="text-danger">Note: Max characters limit is 225.</small>
             <small style="color: red" class="text-danger" *ngIf="Form.controls.Comment.touched && Form.controls.Comment.errors?.required">
               Comment is required
             </small>
             <small style="color: red" class="text-danger" *ngIf="Form.controls.Comment.touched && Form.controls.Comment.errors?.maxlength">
               Maximum 225 characters allowed
             </small>
           </div>
         </div>
       </div>

       <br />
       <div class="form-group" style="text-align:right">
         <button type="button" style="margin-top:0px;" (click)="cancel()" class="btn btn-danger m-t-15 waves-effect">Cancel</button>&nbsp;
         <button *ngIf="flag==1" type="submit" (click)="AddSource(Form.value,1)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Submit</button>
         <button *ngIf="flag==2" type="submit" (click)="AddSource(Form.value,2)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Update</button>
       </div>
     </fieldset>
   </div><br />
   <br /> <br />   <div class="container">
     <div class="row mt-5 mb-4">
       <div class="col cols">
         <div *ngIf="gridview">
           <div id="p1">

             <p-table #dt [columns]="cols" [value]="dataResult"  [scrollable]="true"  [resizableColumns]="true" styleClass="p-datatable-gridlines" [responsive]="true" [rows]="12" [paginator]="true">

               <ng-template pTemplate="caption">
                 <div class="row" style="margin-top: 13px; margin-bottom: 0px;">
                   <div class="col-lg-4" style="text-align: left;margin-bottom: 0;">
                     <i (click)=" openAddForm()" class="fa fa-plus addIcon" pTooltip="ADD" tooltipPosition="top" style="font-size:20px;"></i>
                     <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                        aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
                   </div>



                   <div class="col-lg-8" style="text-align: right;margin-bottom: 0;">
                     {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                     <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                     <input type="text" class="global_search searchs" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')">
                   </div>
                 </div>
               </ng-template>

               <ng-template pTemplate="header" let-columns>
                 <tr style="line-height: 1.5px;">
                   <th style="width: 21px; padding-left:14px !important;" pResizableColumn>Edit</th>
                   <th style="width: 30px; " pResizableColumn>Delete</th>
                   <th style="width: 50px;" pResizableColumn>
                     <!-- Set the width for Created Date -->
                     <span>Date</span>
                     <!--<p-sortIcon [field]="'CreatedDate'" style="font-size: 12px;"></p-sortIcon>-->

                   </th>
                   <th style="width: 110px;" pResizableColumn>
                     <!-- Set the width for Company Name -->
                     <span>Company</span>
                     <!--<p-sortIcon [field]="'CompanyName'"></p-sortIcon>-->
                   </th>
                   <th style="width: 110px;" pResizableColumn>
                     <!-- Set the width for Contact Person -->
                     <span>Contact Person</span>
                     <!--<p-sortIcon [field]="'Contactperson'"></p-sortIcon>-->
                   </th>
                   <th style="width: 255px;" pResizableColumn>
                     <!-- Set the width for Comment -->
                     <span>Comment</span>
                   </th>
                   <th style="width: 40px;" pResizableColumn>
                     <!-- Set the width for Comment -->
                     <span>Sequence</span>
                   </th>
                 </tr>
               </ng-template>

               <ng-template pTemplate="body" let-rowData let-columns="columns">
                 <tr style="font-size: 13px;">
                   <td style="width:21px;"class="border-right border-left ">
                     <i (click)="edit(rowData)" pTooltip="Edit" style="cursor: pointer; font-size: 16px; padding: 7px;" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                     <p-inputSwitch [(ngModel)]="rowData.Status" (onChange)="ChangeStatus($event, rowData)"
                                    [style]="{'cursor':'pointer !important'}" style="margin-left: 6px;"></p-inputSwitch>
                   </td>
                   <td style="width:30px;"class="border-right border-left ">
                     <i (click)="Delete(rowData)" class="fa fa-trash" style="float: initial; color: red;  cursor: pointer; font-size: 16px;"></i>
                     <p-inputSwitch [(ngModel)]="rowData.Status" (onChange)="ChangeStatus($event, rowData)"
                                    [style]="{'cursor':'pointer !important'}" style="margin-left: 6px;"></p-inputSwitch>
                   </td>
                   <td style="font-size: 13px; width: 50px;" class="border-right">{{ formatCreatedDate(rowData.CreatedDate) }}</td>
                   <td style="font-size: 13px; width: 110px;" class="border-right">{{ rowData.CompanyName }}</td>
                   <td style="font-size: 13px; width: 110px;" class="border-right">{{ rowData.Contactperson }}</td>
                   <td style="font-size: 13px; width: 255px;" class="border-right">{{ rowData.Comment }}</td>
                   <td style="font-size:13px; width:40px;" class="border-right">{{rowData.Sequence}}</td>
                 </tr>
               </ng-template>

             </p-table>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

<style>
  .card_1 {
    padding: 2rem;
    padding-top: 1rem;
    border-radius: 5px;
    margin-left: 4px;
    box-shadow: 2px 2px 12px 0 #625a5a;
    background-color: white;
  }

  .modal-dialog1 {
    max-width: 31% !important;
    margin: 1.75rem auto;
  }

  td.border-right {
    border-right: 1px solid black;
  }

  tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2; /* Replace with your desired color for even rows */
  }

  .app.green .btn-success {
    color: #fff;
    background-color: #4BAE4F;
    border-color: #4BAE4F;
    width: 39% !important;
    letter-spacing: 1px !important;
    margin-top: 1% !important;
  }

  .modal-dialog {
    max-width: 60% !important;
    margin: 1.75rem auto !important;
  }

  .card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
  }

  .custom.tabs-primary {
    background-color: rgba(62, 80, 180, 0) !important;
    border: 1px solid #ffffff !important;
    COLOR: #000 !important;
  }

  .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
  }

    .nav-item .nav-link.active {
      background-color: #ffffff !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

    .nav-item .nav-link:hover {
      background-color: #9ed9f7 !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

  .HideDescription {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .strg_c {
    font-family: poppins;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    line-height: 1.8;
    text-align: center;
    padding-top: 0;
  }


  .strg {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 0.5rem
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  /*td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }*/

  #plugin {
    height: 30% !important;
    position: absolute;
    width: 100%;
  }

  .a_g99 {
    width: 97%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
