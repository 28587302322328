

<br />
<br />
<div class="container" id="contentToConvert">
  <br />
  <br />
  <div class="row" style="width: 80%;margin-left: 10%;" id="contentToConvert">

    <div class="row" style="width:100%;">


      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="width:50%;">
        <a class="navbar-brand" href="#">
          <img src="../../assets/Avra_Logo_new.png">
        </a>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding: 0px 0px 0px 168px; float:right;width:50%;">
        <div>Avra Synthesis Pvt. Ltd </div>
        <div> A/28/1/19, Road No.15,</div>
        <div>IDA Nacharam, Hyderabad-500076</div>
        <div> Tel::80008572390,91,92</div>
        <div>Website:www.avrasynthesis.com</div>

      </div>

    </div>
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="border-top: 2px solid #9a9594;">
      </div>
    </div><br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center">
        <b><u style="letter-spacing: 0.5px;font-size: 22px;font-family: Century Gothic;color:#000;">CERTIFICATE OF ANALYSIS</u></b>
      </div>
    </div><br />

    <!--<div class="row">

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="padding-right:0px;">


      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="padding-right:0px;">


      </div>

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="letter-spacing:0.5px;color:#000;font-weight: bold;font-family: Century Gothic;">
        <div style="text-align:left">PRODUCT NAME</div>
        <div style="text-align:left">CAS NO</div>
        <div style="text-align:left">CATALOGUE NO</div>
        <div style="text-align:left">BATCH NO</div>

      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12" style="padding-right:0px;">
        <div>:</div>
        <div>:</div>
        <div>:</div>
        <div>:</div>

      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" style="text-align:left;padding-left:0px;">
        <div style="text-align:left;margin-left: -50px;">{{productNAme}}</div>
        <div style="text-align:left;margin-left: -50px;">{{Cas}}</div>
        <div style="text-align:left;margin-left: -50px;">{{CatalogueNo}}</div>
        <div style="text-align:left;margin-left: -50px;">{{BatchNo}}</div>


      </div>
    </div>-->
    <!--<div class="row" style="text-align:center;">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">

      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">

        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            Product Name
          </div>
          <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
            :
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {{productNAme}}
          </div>
        </div>

        <table style="width:100%;">
          <tr>
            <td style="width:25%" class="td_heading">
              Product Name
            </td>
            <td style="width:5%">
              :
            </td>
            <td style="width:70%" class="td_text">{{productNAme}}</td>
          </tr>

          <tr>
            <td style="width:25%" class="td_heading">
              Cat Number
            </td>
            <td style="width:5%">
              :
            </td>
            <td style="width:70%" class="td_text">{{CatalogueNo}}</td>
          </tr>

          <tr>
            <td style="width:25%" class="td_heading">
              CAS Number
            </td>
            <td style="width:5%">
              :
            </td>
            <td style="width:70%" class="td_text">{{Cas}}</td>
          </tr>

          <tr>
            <td style="width:25%" class="td_heading">
              Batch Number<
            </td>
            <td style="width:5%">
              :
            </td>
            <td style="width:70%" class="td_text">{{BatchNo}}</td>
          </tr>
        </table>
      </div>

    </div>-->


    <div class="row">


      <table align="center" id="gvCatlogInfo" style="width:100%;">
        <tbody>
          <tr align="center">

            <td align="left" style="font-weight:bold;width:30%;color:white"></td>
            <td align="left" style="font-weight:bold;width:20%;">Product Name</td>
            <td align="left" style="font-weight:bold;width:2%;">:</td>
            <td align="left" style="width:48%;">{{productNAme}}</td>



          </tr>
          <tr align="center">
            <td align="left" style="font-weight:bold;width:110px;color:white"></td>
            <td align="left" style="font-weight:bold;width:50px;">Cat Number</td>
            <td align="left" style="font-weight:bold;width:5px;">:</td>
            <td align="left" style="width:150px;">{{CatalogueNo}}</td>

          </tr>
          <tr align="center">


            <td align="left" style="font-weight:bold;width:110px;color:white"></td>
            <td align="left" style="font-weight:bold;width:50px;">CAS Number</td>
            <td align="left" style="font-weight:bold;width:5px;">:</td>
            <td align="left" style="width:150px;">{{Cas}}</td>

          </tr>
          <tr align="center">


            <td align="left" style="font-weight:bold;width:110px;color:white"></td>
            <td align="left" style="font-weight:bold;width:50px;">Batch Number</td>
            <td align="left" style="font-weight:bold;width:5px;">:</td>
            <td align="left" style="width:150px;">{{BatchNo}}</td>


          </tr>
        </tbody>
      </table>

    </div>


    <br />

  </div>
  <div class="row" style="width: 80%;margin-left: 10%;" id="contentToConvert">
    <!--<p-table #dt [columns]="cols" [value]="CoaDetails3" [style]="{width:'100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>

          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span style="font-size: 12px;font-family:  Century Gothic;">{{col.header}}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;font-size:12px;font-family:  Century Gothic;">{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>-->









    <p-table #dt [columns]="cols" [value]="CoaDetails3" [responsive]="true" [style]="{width:'100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>

          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span>{{col.header}}</span>

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="cursor:pointer">
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <span *ngSwitchDefault style="width :100%;overflow:hidden;display:table-row;text-overflow: ellipsis;cursor:pointer">{{rowData[col.field]}}</span>
            <!--<span *ngSwitchCase="'Price'=='1.00'">POR</span>-->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <div class="row" style="width: 80%;margin-left: 10%;" id="contentToConvert">
    <table id="test" align="center" height="100%;" style="width:100%;">
      <tbody>
        <tr>
          <td colspan="1" style="height: 20px">
            <br>
            <b>MFG Date :</b> <span id="Mfgdate">{{COADate}}</span>
          </td>
          <td colspan="1" style="height: 20px;text-align:right">
            <br>
            <b>Retest Date :</b> <span id="RetestDate"> {{RetestDate}}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="height: 20px"></td>
        </tr>
        <tr>
          <td colspan="2" style="border-top: 2px solid #9a9594;padding-top: 12px;;height: 40%" align="right" valign="bottom">
            <!-- <img id="imgPdfLine2" src="https://www.avrasynthesis.com/oldwebsite/images/ImgPDFLine.jpg">-->
            <!--<img id="imgPdfLine" src="../../../assets/ImgPDFLine.jpg" />-->

          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:center">
            <br>
            <!--<input type="submit" (click)="CoaPdf()" name="btton5" value="DownLoad PDF.." id="btton5">-->
            <div>
              <input type="submit" class="d_b" style="border-radius:0px" (click)="CoaPdf()" name="btton5" value="DownLoad PDF" id="btton5">

              <button onclick="window.print()" class="d_b" style="border-radius:0px">Save and Print</button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="height: 20px"></td>
        </tr>
        <tr>
          <td colspan="2" align="center" style="height: 40px;">
            The results comply with the above specifications.
          </td>
        </tr>
        <tr>
          <td colspan="2" style="height: 20px"></td>
        </tr>
        <tr>
          <td colspan="2" align="center" style="display: none;">
            <div>











            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" align="center" style="height: 20px;" valign="bottom">
            <b>"This being a computer generated doc, no signature required".</b>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="height: 40%" align="right" valign="bottom">
            <img id="imgPdfLine1" src="https://www.avrasynthesis.com/oldwebsite/images/ImgPDFLine.jpg">
            <br>
            Copyright 2014 © by Avra Synthesis Pvt. Ltd. All Rights Reserved.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>






















<!--<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

        <a class="navbar-brand" href="#">
          <img src="../../assets/Avra_Logo_new.png">
        </a>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div>Avra Synthesis Private Limited </div>
        <div> A/28/1/19, Road No.15,</div>
        <div>IDA Nacharam, Hyderabad-500076</div>
        <div> Tel::80008572390,91,92</div>
        <div>Website:www.avrasynthesis.com</div>

      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      </div>
    </div><br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center">
        <b><u style="letter-spacing: 0.5px;font-size: 22px;font-family: Century Gothic;color:#000;">CERTIFICATE OF ANALYSIS</u></b>
      </div>
    </div><br /><br />

    <div class="row">

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="letter-spacing:0.5px;;color:#000;font-weight: bold;font-family: Century Gothic;">
        <div style="text-align:left">PRODUCT NAME</div>
        <div style="text-align:left">CAS NUMBER</div>
        <div style="text-align:left">CATALOG NUMBER</div>
        <div style="text-align:left">BATCH NUMBER</div>

      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12" style="padding-right:0px;">
        <div>:</div>
        <div>:</div>
        <div>:</div>
        <div>:</div>

      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" style="text-align:left;padding-left:0px;">
        <div style="text-align:left">{{productNAme}}</div>
        <div style="text-align:left">{{Cas}}</div>
        <div style="text-align:left">{{CatalogueNo}}</div>
        <div style="text-align:left">{{BatchNo}}</div>


      </div>
    </div>
    <br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center">
        <b>PRODUCT SPECIFICATIONS</b>
      </div>
    </div><br />


    <div class="row" style="width: 80%;margin-left: 10%;">
      <p-table #dt [columns]="cols" [value]="CoaDetails3" [style]="{width:'100%'}">

        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>

            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              <span style="font-size: 12px;font-family:  Century Gothic;">{{col.header}}</span>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
              <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;font-size:12px;font-family:  Century Gothic;">{{rowData[col.field]}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center">
        <b>The results comply with the above specifications.</b>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="text-align:left">
        <b>MFG Date :{{COADate}}  </b>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="text-align:right">
        <b> Retest Date : {{RetestDate}}</b>






      </div>
    </div>
    <br />


    <br /><br /><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align:center">
        <input type="submit" value=" DownLoad PDF.." class="button" (click)="CoaPdf()">

      </div>
    </div>


  </div>

</section>-->
