import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';

@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss']
})
export class AchievementComponent implements OnInit {
  AchievementLength: any;
  LoginUserTypeId: any;
  ShowDropDown: boolean = false;
  DropDownData: any;
  spinner: boolean;
  LoginDet: any;
  cols: any;
  Achievements: any;
  constructor(public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'Mon', header: 'Month', width: '90px' },
      { field: 'CompanyName', header: 'CompanyName    ', width: '90px' },
      {field: 'CatalogValue', header: 'Catalog Value(in Rs.)', width: '90px' },
      {field: 'BulkAmt', header: 'BulkBulk Value*(in Rs.)', width: '90px' },
      { field: 'GrossAmt', header: 'Gross Value*(in Rs.)', width: '90px' },
      { field: 'TotalAmt', header: 'Total*(in Rs.)', width: '90px' },
      { field: 'CollectedAmount', header: 'Collected Amount(in Rs.)', width: '90px' },
    ]

    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }
  }


  ngOnInit(): void {
    if (this.LoginUserTypeId == '5' || this.LoginUserTypeId == '7' || this.LoginUserTypeId == '8' || this.LoginUserTypeId == '9') {
      this.ShowDropDown = true;
      this.DropDown();
    }
    else if (this.LoginUserTypeId == '4') {
      this.GetAchievementsUser();
    }
  }
  GetAchievementsUser() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetAchievementsUser?PersonId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId; 
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.Achievements = data;
      this.AchievementLength = data.length;
      this.spinner = false;
    });

  }
  DropDown() {
    debugger
    //let personid = this.SalesPerson;
    this.spinner = true;
    var GetUrl = '/api/Admin/GetSalesExecutiveDDLBasedonId?PersonId=' + this.LoginDet[0].ID + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.DropDownData = data;
      this.spinner = false;
    });

  }
}
