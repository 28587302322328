<app-page-heade></app-page-heade>
<div class="container" style="height: 0%;">
  <div class="row">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="loading-indicator" *ngIf="spinner">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </div>
    <div class="container"><b>Complaints : </b></div>




    <div class="col-lg-12" *ngIf="LoginUserTypeId=='4'">
      <p-tabView (onChange)="handleChange($event)">
        <p-tabPanel header="Customer Complaints">


          <form class="well form-horizontal" action=" " method="post" id="contact_form" *ngIf="!Msg">
            <fieldset style="padding-top: 0px;">

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Name of the Customer</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="first_name" placeholder="CustomerName" disabled [(ngModel)]="UserObj.CustName" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Name of the Lab</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="last_name" placeholder="Name of the Lab" disabled [(ngModel)]="UserObj.LabName" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>


              </div>


              <div class="row">

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Complaint type<span class="star"> *</span></label>
                    <div class="col-md-4 selectContainer">
                      <div class="input-group">
                        <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                        <select name="UserTypeId" class="form-control selectpicker" [(ngModel)]="UserObj.ComplaintType">
                          <option [ngValue]="undefined" disabled selected> Please select Complaint type </option>
                          <option *ngFor="let item of ComplaintType" [ngValue]="item.ComplaintTypes"> {{ item.ComplaintText }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Invoice No.<span class="star"> *</span></label>
                    <div class="col-md-4 selectContainer">
                      <div class="input-group">
                        <select name="UserTypeId" class="form-control selectpicker" (change)="GetCatalogNum()" [(ngModel)]="UserObj.InvoiceNo">
                          <option [ngValue]="undefined" disabled selected> Please select Invoice No. </option>
                          <option *ngFor="let item of InvoiceNumber1"> {{ item.INVNo }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>


              </div>



              <div class="row">

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Catalog No.<span class="star"> *</span></label>
                    <div class="col-md-4 selectContainer">
                      <div class="input-group">
                        <select name="UserTypeId" class="form-control selectpicker" (change)="BindData()" [(ngModel)]="UserObj.CatalogNo">
                          <option [ngValue]="undefined" disabled selected> Please select Catalog No. </option>
                          <option *ngFor="let item of Catalogs"> {{ item.CatalogNo }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Chemical Name</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="Chemical_name" disabled placeholder="Chemical Name" [(ngModel)]="UserObj.ChemicalName" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Batch No.</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="Batch" disabled placeholder="Batch No" [(ngModel)]="UserObj.BatchNo" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Date of Purchase</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="Date" disabled placeholder="Date of Purchase" [(ngModel)]="UserObj.DateofPurchase" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">PO ref</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <input name="Date" placeholder="POref" disabled [(ngModel)]="UserObj.POref" class="form-control" type="text">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-md-4 control-label">Message</label>
                    <div class="col-md-4 inputGroupContainer">
                      <div class="input-group">
                        <textarea name="Message" cols="50" [(ngModel)]="UserObj.Message" rows="2" class="form-control"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-lg-6">

                </div>

                <div class="col-lg-6">
                  <button type="submit" class="btn btn-primary" data-dismiss="modal" style="margin-right: 1%; float: right;" (click)="SubmitCompliant()">Submit</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal" style="margin-right: 2%; float: right;" (click)="Cancel()">Cancel</button>
                </div>

              </div>


            </fieldset>
          </form>




        </p-tabPanel>
        <p-tabPanel header="Complaints Info">

          <p-table #dt *ngIf="LoginUserTypeId=='4'" [columns]="cols" [value]="ComplaintData" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">

            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                     aria-label="" (click)="dt.exportCSV()"
                     style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;">Export to Excel</i>
                  <p class="CountGridRecords" style="margin-left:30%">{{dt.totalRecords}} out of {{ComplaintDataLength}}</p>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
                </div>
              </div>

            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>

                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="cursor:pointer">
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                  <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                  <div *ngSwitchCase="'GrossWeight'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>

                </td>
              </tr>
            </ng-template>

          </p-table>

          <p-table #dt *ngIf="LoginUserTypeId=='5'||LoginUserTypeId=='6'" [columns]="cols" [value]="CustomerComplaints" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">

            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
                </div>
              </div>

            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                <col style="width:10px">

              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>

                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th>Edit</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="cursor:pointer">
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                  <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                  <div *ngSwitchCase="'ComplaintRemarks'">
                    <input *ngIf="EditText" pInputText type="text" [(ngModel)]="Remarks">
                    <div *ngIf="!EditText">{{rowData[col.field]}}</div>
                  </div>
                  <div *ngSwitchCase="'StatusName'">

                    <select *ngIf="EditText" [style]="{'width':'100%'}" (change)="StatusChange(Status)" [(ngModel)]="Status">
                      <option *ngFor="let item of StatusAry">{{item.label}}</option>
                    </select>

                    <div *ngIf="!EditText" [(ngModel)]="status">{{rowData[col.field]}}</div>
                  </div>
                </td>
                <td class="col-btn">
                  <i *ngIf="!EditText" class="fa fa-edit" pTooltip="Edit" style="color:green;font-size:16px;cursor:pointer !important" (click)="Edit(rowData)"></i> <span class="icon-name"></span>
                  <i *ngIf="EditText" class="fa fa-check-circle-o" pTooltip="Edit" style="color:green;font-size:16px;cursor:pointer !important" (click)="Update(rowData)"></i> <span class="icon-name"></span>
                  <i *ngIf="EditText" class="fa fa-times-circle-o" pTooltip="Edit" style="color:green;font-size:16px;cursor:pointer !important" (click)="Canceled(rowData)"></i> <span class="icon-name"></span>
                </td>
              </tr>
            </ng-template>


          </p-table>

        </p-tabPanel>


      </p-tabView>

    </div>
  </div>
</div>

