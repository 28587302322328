import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import * as XLSX from 'xlsx';
import { UserserviceService } from '../../Services/userservice.service';






@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
  CartLength: any;
  CartData: any;
  ItemCode: any;
  Url: string;
  GstPrice: any;
  PackSize: any;
  LoginUserTypeId: any;
  DataFromExcel: any;
  LoginDet: any;
  spinner: any;
  cols: any;
 
  ValidateAry: any = [];
  POary: any = [];
  SOary1: any = [];

  
  arrayBuffer: any;
  file: File;
  constructor(public generalService: GeneralService, public router: Router,
    public userice: UserserviceService) {
    localStorage.removeItem('home');
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
      this.GetCartItems();

    }

    this.cols = [

      { field: 'PONO', header: 'PONO', width: '120px' },
      { field: 'PO Date', header: 'PO Date', width: '100px' },
      { field: 'SupplierCode', header: 'SupplierCode', width: '110px' },
      { field: 'SupplierName', header: 'SupplierName', width: '200px' },
      { field: 'ItemCode', header: 'ItemCode', width: '100px' },
      { field: 'ItemName', header: 'ItemName', width: '170px' },
      { field: 'PackDesc', header: 'PackDesc', width: '100px' },
      { field: 'Quantity', header: 'Qty', width: '40px' },
      { field: 'Units', header: 'Units', width: '100px' },
      { field: 'Rate', header: 'Rate', width: '100px' },
      { field: 'GrossAmt', header: 'GrossAmt', width: '100px' },
      { field: 'DiscountAmt', header: 'DiscountAmt', width: '100px' },
      //{ field: 'CGST', header: 'CGST', width: '100px' },
      //{ field: 'SGST', header: 'SGST', width: '100px' },
      //{ field: 'IGST', header: 'IGST', width: '100px' },
      //{ field: 'OtherAmt', header: 'OtherAmt', width: '100px' },
      //{ field: 'NetAmt', header: 'NetAmt', width: '100px' },
      //{ field: 'QuotNo', header: 'QuotNo', width: '100px' },
      //{ field: 'QuotDate', header: 'QuotDate', width: '100px' },

      

    ]

    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
     
    }
    else {
     
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;
      
    }
  }

  ngOnInit(): void {
    this.GetDueAmountInfo();
  }


  detectFilesMSDS(event) {
    debugger
    //this.urls = [];
    this.file = event.target.files[0]; 
    let files = event.target.files;

    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        var selectedFile = file;


        let FileName = selectedFile.name;
        let FFF = FileName.split(' ');
        let F1 = FFF[0].split('-', ' ');
        var count1 = F1.length;
        var count = FFF.length
        var uploadfilename = FFF[count - 1].split('.')[0];

        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
        var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();

        if (extFile != "xlsx" && extFile != "xls") {
          this.generalService.ShowAlert('error', 'Document name must be in mentioned format', 'error');
        }

      }
    }

  }

  Upload() {
    debugger
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      debugger
      this.arrayBuffer = fileReader.result;
      var data1 = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data1.length; ++i) arr[i] = String.fromCharCode(data1[i]);
      var bstr = arr.join("");
      debugger
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      var arr1 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.DataFromExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      debugger
      for (var i = 0; i < this.DataFromExcel.length; i++) {
        this.ValidateAry.push({
          Sno: i, ItemCode: this.DataFromExcel[i].ItemCode,
          PackSize: this.DataFromExcel[i].PackDesc,
          Quantity: this.DataFromExcel[i].Quantity,
          WareHouseID: this.LoginDet[0].WareHouseID
        })
      }

      //for (var i = 0; i < this.DataFromExcel.length; i++) {
      
      //  this.POary.push({
      //    PONO: this.DataFromExcel[i].PONO,
      //    PODATE: this.DataFromExcel[i].PO Date
          
      //  })
      //}

      var UploadFile = new FormData();
      UploadFile.append("JSON", JSON.stringify(this.ValidateAry));
      UploadFile.append("WareHouse", this.LoginDet[0].WareHouseID);
      UploadFile.append("OrderTo", this.LoginDet[0].OrderTo);
      var PostUrl = 'api/Values/GetPurchaseOrders';
      this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
        debugger
        var Details = data;
        this.SOary1 = [];
        for (var i = 0; i < data.Details.length; i++) {
          this.PackSize = data.Details1[i].PackSize;
          this.ItemCode = data.Details1[i].ItemCode;
          this.GstPrice = data.Details1[i].GSTTAX * data.Details1[i].Price / 100;
          if (this.ItemCode != "" && this.ItemCode != null && this.ItemCode != undefined) {
            if (this.PackSize == "Bulk in ( kg )" || this.PackSize == "Bulk in ( lt )" || this.PackSize == "Bulk") {
              this.SOary1.push({
                Sno: i
                , ItemName: data.Details[i].ItemName
                , CASNO: data.Details[i].CAS
                , ItemCodeDis: data.Details[i].ItemCode
                , PackCode: data.Details1[i].PackCode
                , PackSize: data.Details1[i].PackSize
                , Quantity: data.Details1[i].Qty
                , Price: data.Details1[i].Price
                , CurrentQty: data.Details1[i].Quantity
                , HSNCode: data.Details1[i].HSNCODE
                , GSTtax: data.Details1[i].GSTTAX
                , GstPrice: this.GstPrice
                , Plateform: "WEB"
                , CompanyCode: this.LoginDet[0].CompanyCode
                , PersonID: this.LoginDet[0].ID
              })
            }
            else {
              this.SOary1.push({
                Sno: i
                , ItemName: data.Details[i].ItemName
                , CASNO: data.Details[i].CAS
                , ItemCodeDis: data.Details[i].ItemCode
                , PackCode: data.Details1[i].PackCode
                , PackSize: data.Details1[i].PackSize
                , Quantity: data.Details1[i].Qty
                , Price: data.Details1[i].Price
                , CurrentQty: data.Details1[i].Quantity
                , HSNCode: data.Details1[i].HSNCODE
                , GSTtax: data.Details1[i].GSTTAX
                , GstPrice: this.GstPrice
                , Plateform: "WEB"
                , CompanyCode: this.LoginDet[0].CompanyCode
                , PersonID: this.LoginDet[0].ID
              })
            }
            debugger
          }
        }
      })
      debugger
      



    }
    debugger
    fileReader.readAsArrayBuffer(this.file);
  }
  AddToCart() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("JSON", JSON.stringify(this.SOary1));
    UploadFile.append("PersonID", this.LoginDet[0].ID); /*this.LoginDet[0].ID*/
    UploadFile.append("OrderTo", this.LoginDet[0].OrderTo);
    var PostUrl = 'api/Values/SavePurchaseOrder';
    this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
      this.SOary1 = [];
      this.DataFromExcel = "";
      var details = data;
      this.generalService.ShowAlert('SUCCESS', 'Item added to cart successfully', 'success');

    });

  }


  GetDueAmountInfo() {
    debugger
    if (this.LoginUserTypeId == '11' || this.LoginUserTypeId == '2' || this.LoginUserTypeId == '3' || this.LoginUserTypeId == '4') {
      debugger
      var GetUrl = 'api/Values/GetDueAmountInfo?PersonId=' + this.LoginDet[0].ID;
      this.generalService.GetData(GetUrl).then((data: any) => {
        debugger;
        var Details = data;
        this.generalService.ShowAlert('Info', "Your Overdue Amount is : Rs. " + data[0].Amount, 'info');

      });
    }

  }





  GetCartItems() {
    debugger
    if (this.LoginDet[0].OrderTo == 1) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=1' + '&Param2=' + this.LoginDet[0].ID;
    }
    if (this.LoginDet[0].OrderTo == 2) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=3' + '&Param2=' + this.LoginDet[0].ID;

    }
    if (this.LoginDet[0].OrderTo == 3) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=2' + '&Param2=' + this.LoginDet[0].ID;

    }
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.CartData = '';
      this.CartData = data;
      this.CartLength = this.CartData.length;
      this.userice.edituser(this.CartLength);


    });

  }


  Upload1() {
    debugger
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      debugger
      this.arrayBuffer = fileReader.result;
      var data1 = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data1.length; ++i) arr[i] = String.fromCharCode(data1[i]);
      var bstr = arr.join("");
      debugger
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      var arr1 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.DataFromExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      debugger
      for (var i = 0; i < this.DataFromExcel.length; i++) {
        this.ValidateAry.push({
          Sno: i, ItemCode: this.DataFromExcel[i].ItemCode,
          PackSize: this.DataFromExcel[i].PackDesc,
          Quantity: this.DataFromExcel[i].Quantity,
          WareHouseID: this.LoginDet[0].WareHouseID
        })
      }
      debugger




    }
    debugger
    fileReader.readAsArrayBuffer(this.file);
  }


}
