import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
declare var window: any;
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { UserserviceService } from '../../Services/userservice.service';
import { Router, NavigationEnd } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-prodsdetails',
  templateUrl: './prodsdetails.component.html',
  styleUrls: ['./prodsdetails.component.scss']
})
export class ProdsdetailsComponent implements OnInit {
  CrrentRoute: any;
  Currency: any;
  USDButton: boolean = true;
  USD: any;
  ConvertedPrices: any;
  USDPrice: any;
  GSTPER: any;
  HSNCODE: any;
  FormulaTxt: any;
  spinner: boolean;
  SSDVAL: any ;
  PackSize: any;
  withoutlogin: boolean;
  CartLength: any;
  GstPrice: any;
  WareHouseId: string; Url: any;
  HaveInCart: boolean = false;
  CartData: any;
  LoginDet: any;
  HomeUrl: any;
  GroupName: string;
  BatchNo: any;
  WebStock: any;
  GroupProductsSpecDetails: any;
  ItemCode: string;
  Storage: any;
  GroupProductsDetails: any;
  CatalogNo: any;
  Cas: any;
  ChemicalName: any;
  molecular_structure_path: any;
  molecular_formula_path: any;
  molecular_weight: any;
  PhysicalState: any;
  boiling_point: any;
  melting_point: any;
  Rdata: any;
  Sdata: any;
  Density: any;
  FlashPoint: any;
  RefractiveIndex: any;
  Showmaintab: any;
  MainTabName: any;
  checkavail: any;
  selectedState: number;
  showStateSelection: boolean = false;
    itemToAdd: any;

  constructor(public generalService: GeneralService, public router: Router,
    public userice: UserserviceService,
    public http: HttpClient, public Location: Location) {
    this.ItemCode = localStorage.getItem('ItemCode');
    this.GroupName = localStorage.getItem('GroupName');
    localStorage.removeItem('home');
    this.SSDVAL = 'SDS';
    


    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.MainTabName = localStorage.getItem('SPl');
    if (this.MainTabName != '' || this.MainTabName != null || this.MainTabName != undefined) {
      this.Showmaintab = true;
    }
    if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
      this.GetCartItems();

    }
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.WareHouseId = '';
    }
    else {
      this.WareHouseId = this.LoginDet[0].WareHouseID;
    }
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    })




    //router.events.filter(event => event instanceof NavigationEnd)
    //  .subscribe(event => {
    //    this.CrrentRoute = event.url;
    //    console.log(event);
    //  });
  




    
  }


  ngOnInit(): void {
    debugger
    this.Currency = localStorage.getItem('Currency') || 'Rupees'; // Default to Rupees if not set

    this.GetWebStockItemMstBasedOnId();

    this.GetGroupSubNamesBasedOnCatalogNo();
    this.GetProductSpecificationOnCatalogNo();
    //this.Currency = (localStorage.getItem('Currency'));
    if (this.Currency == null || this.Currency == '' || this.Currency == undefined) {
      this.Currency = 'Rupees';
      this.Getcheckavailability();
    }

    debugger
    //alert(this.Currency)


   
    
        
  }

  //USDPrice//
  Avra2020_GetFormula() {
    

    var GetUrl = 'api/Values/Avra2020_GetFormula';
    this.generalService.GetData(GetUrl).then((data: any) => {
      

      this.USDPrice = data[0].Formula;
      localStorage.removeItem('USDPrice')
      localStorage.setItem('USDPrice', this.USDPrice)
      //if (this.Currency == 'Rupees') {
      //  this.PriceConversion(1);
      //}
      //else {
      //  this.PriceConversion(2);
      //}
     
    });
  }
  GotoBulk() {
    this.router.navigateByUrl('/BulkOrderEnquiry')
  }

  GetGroupSubNamesBasedOnCatalogNo() {
    
    this.spinner = true;
    var GetUrl = 'api/Values/GetGroupSubNamesBasedOnCatalogNo?ItemCode=' + this.ItemCode;
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
     
      this.GroupProductsDetails = data.ProdDet;
      if (this.GroupProductsDetails.length != 0) {
        this.CatalogNo = this.GroupProductsDetails[0].CatalogNo
        this.Cas = this.GroupProductsDetails[0].Cas
        this.ChemicalName = this.GroupProductsDetails[0].ChemicalName
        
        //this.molecular_structure_path = "Content/images/Structures/ASA1001.jpg" /*+this.GroupProductsDetails[0].molecular_formula_path*/      //in old project column name will be molecular_structure_path

        this.molecular_structure_path = this.HomeUrl+"/Content/images/Structures/"+this.GroupProductsDetails[0].molecular_formula_path   //in old project column name will be molecular_structure_path



        this.molecular_formula_path = this.GroupProductsDetails[0].molecular_formula_path

        this.FormulaTxt = this.GroupProductsDetails[0].FormulaTxt

        this.HSNCODE = this.GroupProductsDetails[0].HSNCODE
        this.GSTPER = this.GroupProductsDetails[0].GSTPER

        


        this.molecular_weight = this.GroupProductsDetails[0].molecular_weight
        this.PhysicalState = this.GroupProductsDetails[0].PhysicalState
        this.boiling_point = this.GroupProductsDetails[0].boiling_point
        this.melting_point = this.GroupProductsDetails[0].melting_point
        this.Rdata = this.GroupProductsDetails[0].Rdata
        this.Sdata = this.GroupProductsDetails[0].Sdata
        this.Density = this.GroupProductsDetails[0].Density
        this.FlashPoint = this.GroupProductsDetails[0].FlashPoint
        this.RefractiveIndex = this.GroupProductsDetails[0].RefractiveIndex
        this.Storage = this.GroupProductsDetails[0].Storage
        this.spinner = false;

      }

    });
  }

  //Getting Product Specifications on click of Group's Product//
  GetProductSpecificationOnCatalogNo() {
    
    this.spinner = true;

    var GetUrl = 'api/Values/GetProductSpecificationOnCatalogNo?ItemCode=' + this.ItemCode;
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
       
      this.GroupProductsSpecDetails = data.SpecDetails;
      this.spinner = false;

    });
  }

  Getcheckavailability() {
    debugger
    var UploadFile = new FormData();
  //  this.spinner = true;
    UploadFile.append('Param1', this.ItemCode);
    var GetUrl = 'api/Values/CheckAvailability';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      

      this.checkavail = data;
    //  this.spinner = false;

    });
  }
  //Getting Product Specifications on click of Group's Product//
  GetWebStockItemMstBasedOnId() {
    debugger
    if (this.LoginDet != null) {
      


      if (this.LoginDet[0].WareHouseID == 1) {
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
    

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();
          for (var i = 0; i < this.WebStock.length; i++) {
          let bulk = this.WebStock[i].PackSize;
          this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
         }


         
          this.Avra2020_GetFormula();

          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 2) {
        this.spinner = true;

        var GetUrl = 'api/Values/GetWebStockBangItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
    

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;

          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
               // this.ConvertedPrices.splice(0, 1);
              }
            }
          }

          this.Avra2020_GetFormula();
          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 4) {
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockAhmadabadItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;
          

          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
//this.WebStock.splice(0, 1);
               // this.ConvertedPrices.splice(0, 1);
              }
            }
          }

          this.Avra2020_GetFormula();
          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 3) {
        debugger
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockBangHydItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;
          //for (var i = 0; i < this.ConvertedPrices.length; i++) {
          //  this.ConvertedPrices[i].Bulk = 'Bulk'
          //}

          
          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
          }
          debugger
          this.Avra2020_GetFormula();
          this.spinner = false;
        });
      }
      else {
        debugger

        this.spinner = true;
        var GetUrl = '/api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          
          debugger

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;
          this.withoutlogin = true;
          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
          }

          this.Avra2020_GetFormula();

          this.spinner = false;
        });
      }
    }
    else {
      debugger
      this.spinner = true;
      var GetUrl = '/api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
      this.generalService.GetData(GetUrl).then((data: any) => {
        this.WebStock = data.ProdDet;
        let arr = data.ProdDet;
      //  this.ConvertedPrices = data.ProdDet.slice();
      debugger


        this.ConvertedPrices = arr;
        this.withoutlogin = true;
        for (var i = 0; i < this.WebStock.length; i++) {
          let bulk = this.WebStock[i].PackSize;
          this.PackSize = bulk.substring(0, 4);
          if (this.PackSize == 'Bulk') {
            this.ConvertedPrices[i].Bulk = 'Bulk';
            this.WebStock.splice(0, 1);

           
          }
          else {
            this.ConvertedPrices[i].Bulk = ''

          }
        }

        

        this.spinner = false;
      });
    }
    


  }


  GetWebStockItemMstBasedOnId1() {
    debugger
    if (this.LoginDet != null) {
      


      if (this.LoginDet[0].WareHouseID == 1) {
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();

          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
          }



          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 2) {
        this.spinner = true;

        var GetUrl = 'api/Values/GetWebStockBangItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;

          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
               // this.WebStock.splice(0, 1);
               // this.ConvertedPrices.splice(0, 1);
              }
            }
          }



          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 4) {
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockAhmadabadItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;
          
          for (var i = 0; i < this.WebStock.length; i++) {
          let bulk = this.WebStock[i].PackSize;
          this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
          }



          this.spinner = false;
        });
      }
      else if (this.LoginDet[0].WareHouseID == 3) {
        debugger
        this.spinner = true;
        var GetUrl = 'api/Values/GetWebStockBangHydItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          debugger

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();
          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
               // this.ConvertedPrices.splice(0, 1);
              }
            }
          }


          this.spinner = false;
        });
      }
      else {
        this.spinner = true;
        var GetUrl = '/api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
        this.generalService.GetData(GetUrl).then((data: any) => {
          

          this.WebStock = data.ProdDet;
          this.ConvertedPrices = data.ProdDet.slice();;
          this.withoutlogin = true;
          for (var i = 0; i < this.WebStock.length; i++) {
            let bulk = this.WebStock[i].PackSize;
            this.PackSize = bulk.substring(0, 4);
            if (this.PackSize == 'Bulk') {
              this.ConvertedPrices[i].Bulk = 'Bulk'
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                this.WebStock.splice(0, 1);
                this.ConvertedPrices.splice(0, 1);
              }
            }
            else {
              this.ConvertedPrices[i].Bulk = ''
              if (this.LoginDet[0].UserTypeId == 3 || this.LoginDet[0].UserTypeId == 4) {
                //this.WebStock.splice(0, 1);
                //this.ConvertedPrices.splice(0, 1);
              }
            }
          }



          this.spinner = false;
        });
      }
    }
    else {
      this.spinner = true;
      var GetUrl = '/api/Values/GetWebStockItemMstBasedOnId?ItemCode=' + this.ItemCode;
      this.generalService.GetData(GetUrl).then((data: any) => {
        this.WebStock = data.ProdDet;
        let arr = data.ProdDet;
        //  this.ConvertedPrices = data.ProdDet.slice();
        

        this.ConvertedPrices = arr;
        this.withoutlogin = true;
        for (var i = 0; i < this.WebStock.length; i++) {
        let bulk = this.WebStock[i].PackSize;
        this.PackSize = bulk.substring(0, 4);
          if (this.PackSize == 'Bulk') {
            this.ConvertedPrices[i].Bulk = 'Bulk'
            this.WebStock.splice(0, 1);
            //  this.ConvertedPrices.splice(0, 1);

          }
          else {
            this.ConvertedPrices[i].Bulk = '';
            //this.WebStock.splice(0, 1);

          }
        }




        this.spinner = false;
      });
    }



  }




  //GetCoaDetails() {
  //  
  //  localStorage.removeItem('BatchNo');
  //  localStorage.setItem('BatchNo', this.BatchNo)
  //  if (this.BatchNo != '' && this.BatchNo != null && this.BatchNo != undefined) {
  //    this.router.navigateByUrl('/CoaDetails');
  //  }
  //  else {
  //    this.generalService.ShowAlert('ERROR', 'Please enter Batch No', 'error');
  //  }
  //}













  Validations() {
    
    if (this.BatchNo == "" || this.BatchNo == undefined || this.BatchNo == null) {
      //this.messageService.add({ summary: 'Please enter Batch Number', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter Batch Number..!', 'error');

    }
    else {
      return true;
    }
  }
  PopUp1() {
    
    Swal.fire({
      title: 'Alert',
      text: 'Please login to continue',
      type: 'warning',
      //showCancelButton: true,
      //icon: 'warning',
      confirmButtonText: 'OK',
      // cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        
        this.router.navigate(['/login']);
      }
    })
  }

  GetCoaDetails() {
    
    if (this.Validations()) {
      
      if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
        this.PopUp1();
      }
      //else {
      //  
      //  localStorage.removeItem('BatchNo');
      //  localStorage.removeItem('CoaDetails');
      //  localStorage.setItem('CoaDetails', JSON.stringify(data));
      //  this.BatchNo = '';
      //  window.open('https://www.avrasynthesis.com/#/CoaDetails', '_blank');
      //  localStorage.setItem('BatchNo', this.BatchNo)
      //  //this.router.navigateByUrl('/CoaDetails');
      //}
            else {
        
        this.spinner = true;
        var GetUrl = 'api/Values/GetCoaDetails?BatchNo=' + this.BatchNo;
        this.generalService.GetData(GetUrl).then((data: any) => {
          
          var CoaDetails = data;
          debugger
          //this.user.savebatch(this.BatchNo)
          localStorage.removeItem('BatchNo');
          localStorage.setItem('BatchNo', this.BatchNo)
          localStorage.removeItem('CoaDetails');
          localStorage.setItem('CoaDetails', JSON.stringify(data));
         
         // this.BatchNo = '';
          if (CoaDetails.CoaDetails5[0].DaysLeft < 180) {
            this.generalService.ShowAlert('Info', 'COA has found..!', 'info');
          }
          else {
            window.open('http://avrasynthesis.com/CoaDetails/' + this.BatchNo, '_blank');
            //window.open('http://localhost:4200/#/CoaDetails/' + this.BatchNo, '_blank');

          }
          
        })
        this.spinner = false;
      }

    }


  }











  CickOnAvraCaralogue() {
    
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnSplProds() {
    
    this.router.navigateByUrl('/SplChem');
  }
  ClickOnGroupName() {
    this.Location.back();
  }


//  CheckLogin(item) {
    

//debugger

//    this.HaveInCart = false;
//    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
//      this.PopUp(item);
//    }

//    if (this.LoginDet[0].NoOrder == '1') {
//      if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
        
//        if (this.CartData != undefined && this.CartData != '') {
//          this.HaveInCart = false;
//          for (var i = 0; i < this.CartData.length; i++) {
//            let a = this.CartData[i].ItemCode;
//            let b = this.CartData[i].Packsize
//            if (item.ItemCode == a && item.PackSize == b) {
//              this.generalService.ShowAlert('ERROR', 'Item already in the cart', 'error');
//              this.HaveInCart = true;
//              break;
//            }

//          }

//        }

//      }
//      if (this.HaveInCart != true) {
        

//        if (this.CartData != undefined && this.CartData != '') {
//          if (this.CartData[0].Currency == this.Currency) {
//            this.AddtoCart(item);
//            this.GetCartItems();
//          }
//          else {
//            this.generalService.ShowAlert('ERROR', 'Please change currency, as your cart contains currency in ' + '' + this.CartData[0].Currency, 'error');

//          }
//        }
//        else {
//          this.selectedState = this.LoginDet[0].OrderTo; // Single state
//          this.proceedWithAddToCart(item);
//         // this.AddtoCart(item);
//          this.GetCartItems();
//        }

        
        

//      }
//    }

//    else {
//      this.generalService.ShowAlert('ERROR', 'You are not authorised to order, Please contact admin', 'error');

//    }

//  }

  SendMailToCustomer(item) {
    
    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
      this.PopUp(item);
    }
    else {
      let arr = [];
      arr.push({
        "ItemCode": item.ItemCode, "HSNCODE": item.HSNCODE, "GSTTAX": item.GSTTAX, "PackCode": item.PackCode, "PackSize": item.PackSize,
        "Quantity": parseInt(item.Qty), "Price": item.Price, CASNO: this.Cas, ChemicalName: this.ChemicalName
      });
      let arr1 = [];
      arr1.push({

        "ContactPerson": this.LoginDet[0].ContPerson, "Email": this.LoginDet[0].EMailID,
        "Companyname": this.LoginDet[0].CompanyName, "Phone1": this.LoginDet[0].Phone1,
        "PWD": null
      })
      var url = 'api/Admin/MailPlaceOrder?CartData=' + JSON.stringify(arr) + '&RegDet=' + JSON.stringify(arr1);
      this.generalService.GetData(url).then(data => {
        
        data
        this.generalService.ShowAlert('SUCCESS', 'Quotation sent to your mail, Please check mail .....', 'success');
      })
    }
  }

  AddtoCart(item) {
    debugger
    if (this.LoginDet[0].OrderTo == 1) {
      this.Url = '/api/Values/Saveclientorder';        
    }
    else if (this.LoginDet[0].OrderTo == 2) {
      this.Url = '/api/Values/BangaloreSaveclientorder';
    }
    else if (this.LoginDet[0].OrderTo == 3) {
     this.Url = '/api/Values/AhemdabadSaveclientorder';
    }

    if (item.Price == 'POR' || item.Price == '') {
      item.Price = '0';
    }

    if (item.Qty != '' && item.Qty != null && item.Qty != undefined) {
      item.Qty=item.Qty.substring(0, item.Qty.indexOf("."));
    }
    if (this.Currency == 'Rupees') {
      this.GstPrice = item.GSTTAX * item.Price / 100;
    }
    else {
      this.GstPrice = 0;
      item.GSTTAX = 0;
    }
    this.GstPrice = item.GSTTAX * item.Price / 100;
    var UploadFile = new FormData();
    UploadFile.append('PersonID', this.LoginDet[0].ID); 
    UploadFile.append('PackCode', item.PackCode);
    UploadFile.append('PackSize', item.PackSize );
    UploadFile.append('Quantity', '1');
    UploadFile.append('Price', item.Price);
    UploadFile.append('CurrentQty', item.Qty);
    UploadFile.append('ItemCodeDis', item.ItemCode);
    UploadFile.append('HsnCode', item.HSNCODE);
    UploadFile.append('GSTtax', item.GSTTAX);
    UploadFile.append('Platform', 'Web');
    UploadFile.append('GstPrice', this.GstPrice);
    UploadFile.append('Currency', this.Currency); 


    this.generalService.PostData(this.Url, UploadFile).then((data: any) => {
      this.GetCartItems();

          //this.router.navigateByUrl('/PageHead', { skipLocationChange: true }).then(() => {
          //  this.router.navigate(['/ProdDet']);
          //});

     // window.location.reload();
      this.generalService.ShowAlert('SUCCESS', 'Item added to cart successfully', 'success');
    });


  }

  PopUp(item) {
    
    Swal.fire({
      title: 'Alert',
      text: 'Please login to continue',
      type: 'warning',
      //showCancelButton: true,
      //icon: 'warning',
      confirmButtonText: 'OK',
      // cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        
        this.router.navigate(['/login']);
      }
    })
  }



  GetBackColor(i) {

    if (i == 0) {
      return '#efefef'
    }
    else if (i > 0) {

      if (i % 2 == 0) {

        return '#efefef'
      }
      else if (i % 2 == 1) {

        return;
      }
    }
  }

  ProductDataSheet() {

  }

  MsdsPdf() {
    window.open('../../../assets/MSDS_ASA1001 MSDS.pdf"', '_blank');
  }

  SDS() {
    //window.open("../../../assets/PDF/ALA1002.pdf", '_blank');
    
    var Url = '/api/Admin/DownloadSDS?CatalogNo=' + this.CatalogNo;
    //var Url = '/api/Admin/DownloadSDS?CatalogNo=' + 'ALA1003';

      this.generalService.GetData(Url).then((data: any) => {
        ;

        if (data == 'There is no MSDS document for this product' || data == '' || data == undefined || data == null) {
          this.generalService.ShowAlert('ERROR', 'There is no MSDS document for this product', 'error');
        }
        else {

          var filepath = this.HomeUrl + "/Content/MSDS/" + data;
          window.open(filepath, '_blank');
        }

    });

  }


  TypicalCOA() {
    
    
    var Url = '/api/Admin/DownloadTypicalCOA?CatalogNo=' + this.CatalogNo;
    this.generalService.GetData(Url).then((data: any) => {
      ;

      if (data == 'There is no COA document for this product' || data == '' || data == undefined || data == null) {
        this.generalService.ShowAlert('ERROR', 'There is no COA document for this product', 'error');
      }
      else {
        var filepath = this.HomeUrl + "/Content/TypicalCOA/" + data;
        window.open(filepath, '_blank');
      }

    });

  }


  openModal() {
    debugger
    $('#myModal2').modal('show');
  }

  CheckLogin(item) {
    debugger
    this.itemToAdd = item; // Store the item temporarily
    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
      this.PopUp(this.itemToAdd);
      return;
    }

    // Ensure CartData is defined and check if the cart is empty or if a state has already been selected
    if ( this.LoginDet[0].OrderTo==4
||this.LoginDet[0].OrderTo==5 || this.LoginDet[0].OrderTo==6 || this.LoginDet[0].OrderTo==7
     

    ) {
      this.openModal(); // Show the modal to select a state
    }
    if (!this.CartData || this.CartData.length === 0 || this.LoginDet[0].OrderTo == 1
      || this.LoginDet[0].OrderTo == 2
      || this.LoginDet[0].OrderTo == 3
      ) {
      this.continueCartProcess();
    }

    else {
      // If the cart is not empty and a state is already selected, continue the cart process
      this.continueCartProcess();
    }
  }
  // Method to set the selected state
  setState(stateId: number) {
    this.selectedState = stateId;
  }

  // Method to confirm state selection
  confirmState() {
    debugger
    if (this.selectedState) {
      this.LoginDet[0].OrderTo = this.selectedState;// Update OrderTo with selected state
      localStorage.setItem('LoginDetails', JSON.stringify(this.LoginDet));
      $('#myModal2').modal('hide'); // Hide the modal
      this.continueCartProcess(); // Continue the cart process
    } else {
      this.generalService.ShowAlert('ERROR', 'Please select a state', 'error');
    }
  }

  continueCartProcess() {
    debugger
    this.HaveInCart = false;
    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
      this.PopUp(this.itemToAdd);
      return;
    }

    if (this.LoginDet[0].NoOrder == '1') {
      if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
        if (this.CartData != undefined && this.CartData != '') {
          this.HaveInCart = false;
          for (var i = 0; i < this.CartData.length; i++) {
            let a = this.CartData[i].ItemCode;
            let b = this.CartData[i].Packsize;
            if (this.itemToAdd.ItemCode == a && this.itemToAdd.PackSize == b) {
              this.generalService.ShowAlert('ERROR', 'Item already in the cart', 'error');
              this.HaveInCart = true;
              break;
            }
          }
        }
      }

      if (this.HaveInCart != true) {
        // Continue with the rest of your logic
        this.proceedWithAddToCart(this.itemToAdd);
      }
    } else {
      this.generalService.ShowAlert('ERROR', 'You are not authorised to order, Please contact admin', 'error');
    }
  }

  proceedWithAddToCart(item) {
    debugger
    // Continue with the existing Add to Cart logic
    if (this.CartData != undefined && this.CartData != '') {
      if (this.CartData[0].Currency == this.Currency) {
        this.AddtoCart(item);
        this.GetCartItems();
      } else {
        this.generalService.ShowAlert('ERROR', 'Please change currency, as your cart contains currency in ' + this.CartData[0].Currency, 'error');
      }
    } else {
      this.AddtoCart(item);
      this.GetCartItems();
    }
  }

  GetCartItems() {
    debugger
    //this.spinner = true;
    if (this.LoginDet[0].OrderTo == 1 || this.LoginDet[0].OrderTo == 4 || this.LoginDet[0].OrderTo == 7) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=1' + '&Param2=' + this.LoginDet[0].ID;
    }
    if (this.LoginDet[0].OrderTo == 2 || this.LoginDet[0].OrderTo == 6) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=3' + '&Param2=' + this.LoginDet[0].ID;

    }
    if (this.LoginDet[0].OrderTo == 3 || this.LoginDet[0].OrderTo == 5) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=2' + '&Param2=' + this.LoginDet[0].ID;

    }
    this.generalService.GetData(Url).then((data: any) => {
      ;
      this.CartData = '';
      this.CartData = data;
      this.CartLength = this.CartData.length;
      this.userice.edituser(this.CartLength);
      this.Currency = this.CartData[0].Currency;

    });
    
  }



  //Avra2020_GetFormula() {
  //  var GetUrl = 'api/Values/Avra2020_GetFormula';
  //  this.generalService.GetData(GetUrl).then((data: any) => {
  //    
  //    this.USDPrice = data[0].Formula;
  //    this.USDPrice = data[0].Formula / 100;
  //    this.ConvertedPrices = this.WebStock;
  //  });
  //}

  //PriceConversion(val) {
  //  
  //  let arr = [];
  //  arr = this.WebStock;
  //   this.USD = localStorage.getItem('USDPrice');
  //  this.USDPrice = this.USD / 100;
  
  //  if (val == '2') {
     
  //    for (var i in arr) {
  //      if (this.WebStock[i].Price != '') {
  //        this.WebStock[i].Price1 = arr[i].Price * 0.17;
  //      }

  //    }
  //    //this.USDButton = false;
  //    //for (var i in arr) {
  //    //  if (arr[i].Price != '') {
  //    //    arr[i].Price = arr[i].Price * this.USDPrice
  //    //  }
       
  //    //}
  //    //for (var i = 0; i < this.WebStock.length; i++) {
  //    //  if (arr[i].Price != '') {
  //    //    var price = (arr[i].Price * this.USDPrice) / 10;
       
  //    //    arr[i].Price = price;
         
  //    //  }
      
  //  }
  //  else {
  //    this.USDButton = true;
  //    this.GetWebStockItemMstBasedOnId();

  //  }

  //}


  PriceConversion(val) {
    debugger
    // this.WebStock = this.ConvertedPrices;
    // this.ConvertedPrices = this.WebStock;
    localStorage.removeItem('Currency');
    this.USD = localStorage.getItem('USDPrice');
    this.USDPrice = this.USD / 100;

    if (val == '2') {
      debugger
      if (this.USDButton) {
        this.USDButton = false;
        for (var i = 0; i < this.WebStock.length; i++) {
          if (this.WebStock[i].Price != '') {
            var price = (this.WebStock[i].Price * this.USDPrice) / 10;

            this.ConvertedPrices[i].Price = price;

          }
        }
      }
    }
    else {
      if (!this.USDButton) {
        this.USDButton = true;
        this.GetWebStockItemMstBasedOnId1();
      }
      

    }

  }


 
  Currencyy(currency: string) {
    this.Currency = currency;
    localStorage.setItem('Currency', currency); // Store the selected currency in localStorage
    this.setPriceConversion(); // Update the price conversion based on the selected currency
  }

  setPriceConversion() {
    if (this.Currency === 'Rupees') {
      this.PriceConversion(1); // Perform price conversion for Rupees
      // Call other methods related to Rupees if needed
    } else {
      this.PriceConversion(2); // Perform price conversion for other currencies
      // Call other methods related to other currencies if needed
    }
  }

 

  // Method to determine whether a currency span should be highlighted
  isCurrencyActive(currency: string): boolean {
    return currency === this.Currency;
  }

}
