import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dealers-info',
  templateUrl: './dealers-info.component.html',
  styleUrls: ['./dealers-info.component.scss']
})
export class DealersInfoComponent implements OnInit {
  RegData: any;
  RegDataLength: any;
  cols: any;
  RegData1: any;
  Filter: number = 10;
  searchText: any;
  constructor(public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.cols = [     
      { field: 'Name', header: 'Name', width: '180px' },
      { field: 'PhoneNumber', header: 'PhoneNumber', width: '180px' },
      { field: 'EmailId', header: 'Email', width: '180px' },
      { field: 'Location', header: 'Location', width: '180px' },
      { field: 'Address', header: 'Address', width: '180px' },
    ]
  }

  ngOnInit(): void {
    this.GetDealersDetails();
  }
  GetDealersDetails() {
    debugger
    var GetUrl = 'api/Values/GetDealersDetails';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.RegData = data;
      //this.RegData1 = this.RegData.filter(t => t.GroupId ==10);
      this.RegDataLength = this.RegData.length;
    })
  }
  searchDealers() {
    debugger
    if (this.searchText == "") {

    }
    else {
      var Url = 'api/Values/GetDealersdetailsSearch?Person=' + this.searchText;
      this.generalService.GetData(Url).then((data: any) => {
        debugger


      })
    }
  }
}
