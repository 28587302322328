import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CosserviceService {
  totalvalue: any;

  private batchno = new BehaviorSubject<string>('0');

  cast = this.batchno.asObservable();

  constructor() {
    debugger
    var batch = localStorage.getItem("BatchNo")
    if (batch != undefined || batch != "" || batch !== null) {
      this.batchno.next(batch)
    }
    else {
      this.batchno.next("0");
    }
  }

  savebatch(newuser) {
    debugger
    this.batchno.next(newuser);
  }
}
