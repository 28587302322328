import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-pending-orders',
  templateUrl: './pending-orders.component.html',
  styleUrls: ['./pending-orders.component.scss'],
  providers: [DatePipe]
})
export class PendingOrdersComponent implements OnInit {
  PendingOrderLength: any;
  PendingOrders1: any;
  PendingOrders: any;
  spinner: boolean;
  cols: any;
  LoginDet: any;
  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';
  constructor(public datePipe: DatePipe,public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'SONo', header: 'Sales Order No', width: '120px' },
      { field: 'SODate', header: 'Order Date', width: '100px' },
      { field: 'CUSTCODE', header: 'Customer Code', width: '200px' },
      { field: 'PONo', header: 'Cust. PO No', width: '180px' },
      { field: 'ItemCode', header: 'Item Code', width: '100px' },
      { field: 'ChemicalName', header: 'Chemical Name', width: '250px' },
      { field: 'PackDesc', header: 'Pack Desc', width: '100px' },
      { field: 'OrderQty', header: 'SO Qty', width: '100px' },
      { field: 'DispatchedQty', header: 'Disp Qty', width: '100px' },
      { field: 'PendingQuantity', header: 'Pending Qty', width: '100px' },
      { field: 'StockStatus', header: 'Stock Status', width: '100px' },
    ]


  }



  ngOnInit(): void {
    var date = new Date();
    this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.mindate = this.enddate;
    this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.AvraSyn_GetPendingOrderDetails();
  }

  AvraSyn_GetPendingOrderDetails() {
    debugger
    this.spinner = true;

    if (this.LoginDet[0].UserTypeId != '5' && this.LoginDet[0].UserTypeId != '6' &&
      this.LoginDet[0].UserTypeId != '7' && this.LoginDet[0].UserTypeId != '8' &&
      this.LoginDet[0].UserTypeId != '9'
    ) {
      var GetUrl = '/api/Admin/AvraSyn_GetPendingOrderDetails?PersonId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    }
    else {
      var GetUrl = '/api/Admin/AvraSyn_GetPendingOrderDetails?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    }



    
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.PendingOrders = data;
      this.PendingOrders1 = data;
      this.spinner = false;
      this.PendingOrderLength = this.PendingOrders.length;
    });

  }
  Filter(startdate, enddate) {
    debugger
    this.PendingOrders = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.PendingOrders = this.PendingOrders1.filter(
      m => new Date(m.SODate) >= new Date(startdate) && new Date(m.SODate) <= new Date(enddate)
    );
    this.PendingOrderLength = this.PendingOrders.length;
  }
  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
  /*end code*/
//  let.selectedMembers = this.members.filter(
//  m => new Date(m.date) >= new Date(startDate) && new Date(m.date) <= new Date(endDate)
//);
}
