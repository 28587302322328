import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';

import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductTreeComponent } from './pages/product-tree/product-tree.component';
import { CoaMsdsComponent } from './pages/coa-msds/coa-msds.component';
import { CoaDetailsComponent } from './pages/coa-details/coa-details.component';
import { SpecialChemicalsComponent } from './pages/special-chemicals/special-chemicals.component';
import { BiologicsComponent } from './pages/biologics/biologics.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component'
import { SplGrpProdsComponent } from './pages/spl-grp-prods/spl-grp-prods.component';
import { ProdsdetailsComponent } from './pages/prodsdetails/prodsdetails.component';
import { BioSubGrpsComponent } from './pages/bio-sub-grps/bio-sub-grps.component';
import { CartitemsComponent } from './pages/cartitems/cartitems.component';
import { AgroupDataComponent } from './pages/agroup-data/agroup-data.component';
import { BioGroupProdsComponent } from './pages/bio-group-prods/bio-group-prods.component';
import { BioGrpProdDetailsComponent } from './pages/bio-grp-prod-details/bio-grp-prod-details.component';
import { OrdersComponent } from './pages/orders/orders.component'
import { PageHeadeComponent } from './pages/page-heade/page-heade.component';
import { DealersInfoComponent } from './pages/dealers-info/dealers-info.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';

import { PendingOrdersComponent } from './pages/pending-orders/pending-orders.component';
import { PendingPaymentsComponent } from './pages/pending-payments/pending-payments.component';
import { DispatchDetailsComponent } from './pages/dispatch-details/dispatch-details.component';
import { BulkOrderEnquiryComponent } from './pages/bulk-order-enquiry/bulk-order-enquiry.component'
import { AchievementComponent } from './pages/achievement/achievement.component'
import { CustComplaintsComponent } from './pages/cust-complaints/cust-complaints.component'
import { FeedBackComponent } from './pages/feed-back/feed-back.component'
import { SolventsComponent } from './pages/solvents/solvents.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { LedgerComponent } from './pages/ledger/ledger.component';
import { CustomerRegComponent } from './pages/customer-reg/customer-reg.component';
import { AuditComponent } from './pages/audit/audit.component'
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component'
import { DealersMapComponent } from './pages/dealers-map/dealers-map.component'
import { TestimonialComponent } from './pages/testimonial/testimonial.component'
import {DealerslistComponent} from './pages/dealerslist/dealerslist.component'
import { AquaproductsComponent } from './pages/aquaproducts/aquaproducts.component';
import { AquagrpprodsComponent } from './pages/aquagrpprods/aquagrpprods.component';
import { AquagrpproddetailsComponent } from './pages/aquagrpproddetails/aquagrpproddetails.component';
import { NewprodComponent } from './pages/newprod/newprod.component';

export const routes: Routes = [
  //{ path: '**', component: NotFoundComponent },
  { path: '', component: HomeComponent },
  {
    path: '',
    component: PagelayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'Dealers', component: DealersInfoComponent},
      { path: 'ContactUs', component: ContactUsComponent, data: { breadcrumb: 'Contact Us' } },
      { path: 'Downloads', component: DownloadsComponent, data: { breadcrumb: 'Downloads' } },
      { path: 'Home', component: HomeComponent, data: { breadcrumb: 'Home' } },
      { path: 'Products', component: ProductsComponent, data: { breadcrumb: 'Products' } },
      { path: 'ProductsTree', component: ProductTreeComponent, data: { breadcrumb: 'Products Tree' } },
     { path: 'coamsds', component: CoaMsdsComponent, data: { breadcrumb: 'Coa & Msds' } },
    //  { path: 'CoaDetails', component: CoaDetailsComponent, data: { breadcrumb: 'Coa Details' } },
      { path: 'SplChem', component: SpecialChemicalsComponent, data: { breadcrumb: 'Special Chemicals' } },
      { path: 'Biologics', component: BiologicsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'SplGrpProds', component: SplGrpProdsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'ProdDet', component: ProdsdetailsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'BioSubGrps', component: BioSubGrpsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'Cart', component: CartitemsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'AGroupData', component: AgroupDataComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'BioGrpProds', component: BioGroupProdsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'BioGrpProdDet', component: BioGrpProdDetailsComponent, data: { breadcrumb: 'Biologics' } },
      { path: 'orders', component:  OrdersComponent, data: { breadcrumb: 'Orders' } },
      { path: 'PageHead', component: PageHeadeComponent, data: { breadcrumb: 'Orders' } },
      { path: 'PageLayout', component: PagelayoutComponent, data: { breadcrumb: 'PageLayout' } },
      { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
      { path: 'register', component: RegisterComponent, data: { breadcrumb: 'Register' } },
      { path: 'PendingOrders', component: PendingOrdersComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'PendingPayments', component: PendingPaymentsComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'BulkOrderEnquiry', component: BulkOrderEnquiryComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'DispatchDetails', component: DispatchDetailsComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'Achievements', component: AchievementComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'CustComplaints', component: CustComplaintsComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'FeedBack', component: FeedBackComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'Solvents', component: SolventsComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'Invoice', component: InvoiceComponent , data: { breadcrumb: 'PendingOrders' } },
      { path: 'Ledger', component: LedgerComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'Customers', component: CustomerRegComponent, data: { breadcrumb: 'PendingOrders' } },
      { path: 'DealersMap', component: DealersMapComponent, data: { breadcrumb: 'DealersMap' } },
      { path: 'Aquaproducts', component: AquaproductsComponent, data: { breadcrumb: 'Aqua Products' } },
      { path: 'Aquagrpprods', component: AquagrpprodsComponent, data: { breadcrumb: 'Aqua grp prods' } },
      { path: 'Aquagrpproddetails', component: AquagrpproddetailsComponent, data: { breadcrumb: 'Aqua grp prod details' } },
      { path: 'Newprod', component: NewprodComponent, data: { breadcrumb: 'New Products' } },
       
      { path: 'Audit', component: AuditComponent, data: { breadcrumb: 'Audit' } },
      { path: 'PurchaseOrder', component: PurchaseOrderComponent, data: { breadcrumb: 'PurchaseOrder' } },
      { path: 'Testimonial', component: TestimonialComponent, data: { breadcrumb: 'Testimonial' } },


      { path: 'Dealerslist',  component: DealerslistComponent, data: { breadcrumb: 'Dealerslist' } },
     // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },

//{ path:'testimonial',component:testimonialComponent,data:{ breadcrumb: 'PurchaseOrder' } },

      ]
  },
  {
    path: '', 
    component: PagesComponent,
    children:[
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },          
       { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' }  },         
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), data: { breadcrumb: 'Admin' } },          

    ]
  },
  { path: 'CoaDetails/:BatchNo', component: CoaDetailsComponent, data: { breadcrumb: 'Coa Details' } },
 { path: '**', component: NotFoundComponent }
]; 


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
     useHash: false
    })
  ],
  exports: [
    RouterModule

  ]
})
export class AppRoutingModule { }
