

<!--<app-page-heade></app-page-heade>-->
<div class="banner">
  <div class="container">
    <div class="row">
      <h1 style="color:#000;font-weight: bold;letter-spacing: 1px;">  Dealers Information</h1>
      <br />
      <div>
      </div>
      <div class="arrow-circle-down bounce">
        <p>
          <a class="scroll" href="#booya">

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="50" height="50"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;margin-top: 30vh;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#fff"><path d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg>


          </a>
        </p>
      </div>
    </div>
  </div>
</div>








<p-table #dt [columns]="cols" [value]="RegData" [responsive]="true" [rows]="12"
         [paginator]="true" [style]="{'width':'84.2%','margin-left':'7.8%','margin-top':'2%'}">
  <ng-template pTemplate="caption">
    <div class="row">
      <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
        <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
        <p class="CountGridRecords" style="margin-left:-5px !important">{{dt.totalRecords}}out of {{RegDataLength}}</p>

      </div>
      <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
        <input type="text" class="global_search" pInputText size="50" placeholder="Name/PhoneNo/Location" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        <!--<input class="input_search" type="text" [(ngModel)]="searchText" placeholder="Name/PhoneNo/Location" (input)="searchDealers()" style="width:65% !important;margin-left: 3%;height:33px !important" />

        <button class="serch_button" style="height:33px;width:30px;">
          <i class="pi pi-search" (click)="searchDealers()"></i>
        </button>-->
      </div>
    </div>

  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>

      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <span>{{col.header}}</span>
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr style="cursor:pointer">
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
        <!--<span *ngSwitchCase="'catalog_no'"><a style="cursor:pointer" pTooltip="Click to Order" tooltipPosition="top" (click)="ProductDetails(rowData)">{{rowData[col.field]}}</a></span>-->
         
      </td>
      
    </tr>
     
  </ng-template>
</p-table>
