import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  Param2: string;
  SplBio: string;
  cols: any;
  AllProducts: any[] = [];
  SearchKeyWord: string = '';
  spinner: boolean = false;
  UserObj: any = [];
  RegAry: any[];
  loginDet: any;
  noProductsFound: boolean = false;
  enquiry = {
    name: '',
    mobile: '',
    email: '',
    product: '',
    CasNumber: '',
    Quantity: '',
    Description: ''
    

  };

  constructor(public generalService: GeneralService, public router: Router) {
    this.cols = [
      { field: 'CatalogNo', header: 'Catalog No', width: '70px' },
      { field: 'ItemName', header: 'Chemical Name', width: '250px' },
      { field: 'CAS', header: 'CAS', width: '70px' },
      { field: 'HSNCode', header: 'HSN Code', width: '70px' },
      { field: 'MainGroupName', header: 'Main Group Name', width: '70px' },
      { field: 'GroupName', header: 'Group Name', width: '70px' },
    ];
    localStorage.removeItem('home');
    this.SearchKeyWord = localStorage.getItem('Value');
    this.SplBio = localStorage.getItem('SplBio');
    this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
  }

  ngOnInit() {
    if (this.SearchKeyWord) {
      this.GetAllSearchProducts(this.SearchKeyWord);
    } else {
      this.GetAllProducts();
    }
this.openEnquiryModal();
  }

  GetAllProducts() {
    debugger
    this.spinner = true;
    var GetUrl = 'api/Values/GetAllProducts';
    this.generalService.GetData(GetUrl).then((data: any) => {
      this.spinner = false;
      this.AllProducts = this.filterProducts(data);
      localStorage.removeItem('Value');
    });
  }

  GetAllSearchProducts(SearchKeyWord) {
    debugger
    this.spinner = true;
    let query = SearchKeyWord.substring(0, SearchKeyWord.indexOf("["));
    let UploadFile = new FormData();
    let GetUrl = query ? 'api/Values/Avra2020_GetProoduccts_BasedOn_ChemicalName_Without_LikeOperator' : 'api/Values/GetProductsBasedOnChemicalName';

    UploadFile.append('ChemicalName', SearchKeyWord);
    debugger
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      this.spinner = false;
      this.AllProducts = data && data.length ? this.filterProducts(data) : [];
      this.noProductsFound = !data || data.length === 0;
      if (this.noProductsFound) {
        setTimeout(() => {
          this.openEnquiryModal();
        }, 0);
      }
      localStorage.removeItem('Value');
    });
  }

  filterProducts(data: any[]) {
    if (!this.SplBio) return data;

    switch (this.SplBio) {
      case '1': return data.filter(a => a.MainGroupName == 'Speciality Chemicals');
      case '2': return data.filter(a => a.MainGroupName == 'Biologics');
      case '3': return data.filter(a => a.MainGroupName == 'Aqua Culture');
      default: return data;
    }
  }

  openEnquiryModal() {
    debugger
    if (this.loginDet) {
      this.UserObj.name = this.loginDet[0].ContPerson;
      this.UserObj.email = this.loginDet[0].EMailID;
      this.UserObj.mobile = this.loginDet[0].Phone1;
     
    }
  this.UserObj.product = this.SearchKeyWord;
    $('#myModal2').modal('show');
  }

  closeModal() {
    $('#myModal2').modal('hide');
    this.router.navigate(['/Home']);
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  Validations() {

    debugger

    if (this.UserObj.name == "" || this.UserObj.name == undefined || this.UserObj.name == null) {
      alert("Please Enter Name")

    }
    else if (this.UserObj.mobile == "" || this.UserObj.mobile == undefined || this.UserObj.mobile == null) {
      alert("Please Enter Mobile Number")

    }
    else if (this.UserObj.email == "" || this.UserObj.email == undefined || this.UserObj.email == null) {
      alert("Please Enter Email")

    }
    else if (this.UserObj.product == "" || this.UserObj.product == undefined || this.UserObj.product == null) {
     
      alert("Please Enter Product Name")

    }
  //  else if (this.UserObj.CasNumber == "" || this.UserObj.CasNumber == undefined || this.UserObj.CasNumber == null) {
   //   alert("Please Enter CasNumber")

//    }
    else if (this.UserObj.Quantity == "" || this.UserObj.Quantity == undefined || this.UserObj.Quantity == null) {
      alert("Please Enter Qunatity")

    }
    
    else {
      return true;
    }

  }
  submitEnquiry() {
    debugger
    if (this.Validations()) {

     
      let RegDetail = [];
      RegDetail.push({

        Name: this.UserObj.name,
        Mobilenumber: this.UserObj.mobile,
        EmailId: this.UserObj.email,
        Productname: this.UserObj.product,
        CasNumber: this.UserObj.CasNumber,
        Quantity: this.UserObj.Quantity,
        Description: this.UserObj.Description,

      })

      this.RegAry = [];
      this.RegAry = RegDetail;
      var UploadFile = new FormData();
      UploadFile.append("xmlParam", JSON.stringify(RegDetail));
      UploadFile.append("flag", '1');
      var PostUrl = 'api/Values/Avra_Searchenquiry_CRUD';
      this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
        debugger
        if (data == "SUCCESS") {

          this.generalService.ShowAlert('SUCCESS', 'Thank you for your Enquiry.Our team will contact you soon...!', 'success');

          this.UserObj = [];
          this.closeModal();          
          this.SendMail(RegDetail);
          this.router.navigate(['/Home']);

        }
        else {

          this.generalService.ShowAlert('error', 'Something Went Wrong..!', 'error');


        }
      })
    }

  }
  SendMail(RegDetail) {
    debugger
    let emailDetails = {
      Name: RegDetail[0].Name,
      Mobilenumber: RegDetail[0].Mobilenumber,
      EmailId: RegDetail[0].EmailId,
      Productname: RegDetail[0].Productname,
      CasNumber: RegDetail[0].CasNumber,
      Quantity: RegDetail[0].Quantity,
      Description: RegDetail[0].Description
    };

    let formData = new FormData();
    formData.append('Details', JSON.stringify([emailDetails])); // Ensure it's an array of objects

    this.generalService.PostData('api/Values/EnquiryMailToAdminforSearch', formData).then((response: any) => {
      //if (response == "SUCCESS") {
      //  this.generalService.ShowAlert('SUCCESS', 'Enquiry email sent successfully!', 'success');
      //} else {
      //  this.generalService.ShowAlert('error', 'Failed to send enquiry email.', 'error');
      //}
    });
  }



  ClickOnProd(ItemCode) {
    const { MainGroupName, GroupName, CatalogNo, IsgmDesc } = ItemCode;

    localStorage.setItem('SPl', MainGroupName == 'Speciality Chemicals' ? 'Speciality Products' : GroupName);
    localStorage.setItem('ItemCode', CatalogNo);
    localStorage.setItem('GroupName', GroupName);
    localStorage.setItem('isgmDesc', IsgmDesc);

    const route = MainGroupName == 'Biologics' ? '/BioGrpProdDet' :
      MainGroupName == 'Speciality Chemicals' ? '/ProdDet' :
        '/Aquagrpproddetails';
    this.router.navigateByUrl(route);
  }
}

