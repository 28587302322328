import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators} from '@angular/forms'; 
import { EmailValidators } from 'ngx-validators'
import { GeneralService } from '../../Services/general.service';
declare var $;
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  EmailId: {};
  LoginData: any;
  passwordshow: boolean;
  Mobilenumber: string;
  randomnumber: any;
  Confirmpassword: string;
  Password: string;
  otp: string;
  Mobile: string;
  logindata: any;
  logindetails: any;
  HomeURL: any;
  SendOtp: any;
  public router: Router;
  public form:FormGroup;
  public email:AbstractControl;
  public password:AbstractControl;

  constructor(router: Router, fb: FormBuilder, public generalService: GeneralService, public _location: Location) {
      this.router = router;
      this.form = fb.group({
          'email': ['', Validators.compose([Validators.required, EmailValidators.normal])],
          'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
      });

      this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
    localStorage.removeItem('home');

  }

  public onSubmit(values:Object):void {
    if (this.form.valid) {
        debugger
      var UploadFile = new FormData();
      UploadFile.append("Email", this.form.controls['email'].value);
      UploadFile.append("Password", this.form.controls['password'].value );
      var GetUrl = '/api/Values/Login';
      this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        debugger
        var a = data;
        sessionStorage.removeItem('gotologin');
        sessionStorage.setItem('gotologin', 'gotologin');
        var log = sessionStorage.getItem('gotologin');
        if (data.length != 0) {
          debugger
          this.LoginData = data;
          localStorage.removeItem('LoginDetails');
          localStorage.setItem('LoginDetails', JSON.stringify(data))
          debugger
          //window.location.reload();
          this._location.back();
          localStorage.setItem('num','1')
          
         //// this._location.reload();
         //// this.router.navigate(['/PageLayout']);
         // this.router.navigateByUrl('/PageHead', { skipLocationChange: true }).then(() => {
         //   this.router.navigate(['/Home']);

         // });


        }
        else if (data == "NOT EXIST") {
          debugger
          // alert("UserName or Password is wrong");
          this.generalService.ShowAlert('warning', 'Invalid credentials..!(or)Your account is not Activated please contact Admin', 'warning');
        }
        else {
          this.generalService.ShowAlert('warning', 'Invalid credentials..!(or)Your account is not Activated please contact Admin', 'warning');
        }
      });
    }
  }
  /*forgot password*/
  ForgotPassword() {
    this.Mobile = "";
    this.otp = "";
    this.Password = "";
    this.Confirmpassword = "";
    $('#myModal2').modal('show');
  }
  /*end code*/


  /*Check mobile there or not*/
  CheckMobile(Mobile) {
    debugger;
    if (Mobile == undefined || Mobile == "" || Mobile == null) {
      debugger;
      alert("Please Enter Mobile Number")
      // this.openToast();
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("Mobile", Mobile);
      UploadFile.append("Flag", "3");
      var url = "api/Admin/CheckMobileandEmail"
      debugger;
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data == 'NOT EXIST') {
          alert("Please Enter Valid Mobile Number")
        }
        else {
          this.EmailId = data;
          this.otp = ""
          this.Mobilenumber = Mobile;
          this.SendOtpForMobile();
          $('#myModal2').modal('hide');
          $('#myModal3').modal('show');
        }
      }, err => {
        //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    }

  }
  /*end code*/

  /* send otp for registration */
  SendOtpForMobile() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    var url = 'api/Admin/SendOtp?EmailId=' + this.EmailId  + '&OTP=' + this.randomnumber;
    this.generalService.GetData(url).then(data => {
      if (data == "SUCCESS") {

      }
      else {
        //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
      }
    });
  }
  /*end code*/


  /*verifyotp for forgotpassword*/
  VerifyOtp(otp) {
    debugger
    if (this.randomnumber = otp) {
      $('#myModal3').modal('hide');
      $('#myModal4').modal('show');
    }
    else {
      alert("Invalid OTP");
    }
  }
  /*end region*/

  /*Change password*/
  ChangePassword(password, confirmpassword) {
    if (password == undefined || password == null || password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
      alert("Please enter password");
    }
    else {
      if (password != confirmpassword) {
        alert("password and confirmpassword must be same");
      }
      else {
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobilenumber);
        UploadFile.append("Password", password);
        var url = "api/Admin/Change_Password"
        debugger;
        this.generalService.PostData(url, UploadFile).then(data => {
          debugger;
          if (data == 'SUCCESS') {
            this.generalService.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
            $('#myModal4').modal('hide');
          }
        }, err => {
          $('#myModal4').modal('hide');
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
      }
    }

  }
  /*endregion*/

  /*Show passwords*/
  ShowPassword(id) {
    debugger;
    var x;
    x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      this.passwordshow = false;
    } else {
      x.type = "password";
      this.passwordshow = true;
    }



  }
  /*end code*/

  ngAfterViewInit(){
      document.getElementById('preloader').classList.add('hide');                 
  }


}
export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}

