



<br />
<br />

<div class="container" id="contentToConvert">
  <br />
  <br />
  <div class="row" style="width: 80%;margin-left: 10%;" id="contentToConvert">


    <div class="row">


      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <a class="navbar-brand" href="#">
          <img src="../../assets/Avra_Logo_new.png" class="mobile_logo" alt="hyma logo">
          <sub class="formaerl_text" style="">(Formerly known as Avra Synthesis Private Limited)</sub>
        </a>
      </div><br />

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="    padding: 0px 0px 0px 168px;">
        <div>Hyma Synthesis Private Limited </div>
        <div> A/28/1/19, Road No.15,</div>
        <div>IDA Nacharam, Hyderabad-500076</div>
        <div> Tel::80008572390,91,92</div>
        <div>Website:www.Hymasynthesis.com</div>

      </div>

    </div>
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="border-top: 2px solid #9a9594;width:98%">
      </div>
    </div><br />

    <!--<div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="    padding: 0px 0px 0px 168px;">
        Format no: F-06-03/MS-02
      </div>

    </div>-->
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="    padding: 0px 0px 0px 168px;">
        Effective Date: {{ddMMyyyy}}
      </div>

    </div>



    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        Dear {{ContPerson}} ,
      </div>


    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        Hyma Synthesis Pvt. Ltd is fully committed to maintaining the highest quality standards for the products and services we provide to our customers. We would like to solicit your opinion as to how well we responded to your inquiry and whether or not we fulfilled your overall expectations.
        We would like to thank you in advance for your timely feedback as it will assist us in making on-going improvements in the quality of our products / services. Please feel free to make any additional comments as needed
      </div>


    </div><br />




    <br />







    <div class="row">


      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="width: 135px;padding: 5px 0px 0px 16px;">
        Financial Year
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="    margin-left: -25px;">
        <select name="UserTypeId" class="form-control" [(ngModel)]="FinYear" style="width:50% !important;height: 0% !important;">
          <!--<option [ngValue]="undefined" disabled selected>Financial Year </option>-->
          <option *ngFor="let item of FY" [ngValue]="item.ID"> {{ item.Quart }}</option>
        </select>
      </div>
      <!--<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="    margin-left: -25px;">
        <input name="first_name1" [(ngModel)]="FinYear" placeholder="YYYY" class="form-control" style="width:50% !important;    height: 0% !important;" type="text">
      </div>-->

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="width: 92px;padding: 5px 0px 0px 16px;">
        For Period
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <!--<input [(ngModel)]="ForPeriod" name="first_name2" placeholder="Ex: Apr-jly" class="form-control" style="width:50% !important;    height: 0% !important;" type="text">-->

        <select name="UserTypeId1" class="form-control" [(ngModel)]="ForPeriod" style="width:50% !important;height: 0% !important;">
          <!--<option [ngValue]="undefined" disabled selected>For Period </option>-->
          <option *ngFor="let item of Quarters" [ngValue]="item.ID"> {{ item.Quart }}</option>
        </select>


      </div>



    </div><br />


    <div class="row">

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        01. Average
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        02. Good
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        03. Excellent
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        04. Outstanding
      </div>
    </div><br />


    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <table id="data-table" class="table table-striped table-bordered nowrap">
          <thead>
            <tr>
              <th style="text-align:center">Area</th>
              <th style="text-align:center">Hyma’s Enquiry</th>
              <th style="text-align:center">1</th>
              <th style="text-align:center">2</th>
              <th style="text-align:center">3</th>
              <th style="text-align:center">4</th>

              <!--<th><input name="select_all" value="1" type="checkbox"></th>-->
            </tr>
          </thead>
          <tbody>
            <tr class="odd gradeX">
              <td rowspan="2">Communication</td>
              <td>How easy was it to get in touch with the appropriate persons?</td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm1" (change)="onChange(1, $event.target.checked,1)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm2" (change)="onChange(2, $event.target.checked,1)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm3" (change)="onChange(3, $event.target.checked,1)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm4" (change)="onChange(4, $event.target.checked,1)" type="checkbox" name="name1" /></td>

            </tr>
            <tr class="odd gradeX">
              <td>How effectively did we communicate with you?</td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm5" (change)="onChange(1, $event.target.checked,2)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm6" (change)="onChange(2, $event.target.checked,2)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm7" (change)="onChange(3, $event.target.checked,2)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Comm8" (change)="onChange(4, $event.target.checked,2)" type="checkbox" name="name1" /></td>

            </tr>


            <tr class="odd gradeX">
              <td>Professionalism</td>
              <td>What rating would you give us on professionalism in handling your inquiry (promptness and completeness of response) was your inquiry processed?</td>
              <td><input style="height:17px" [(ngModel)]="Obj.Prof1" (change)="onChange(1, $event.target.checked,3)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Prof2" (change)="onChange(2, $event.target.checked,3)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Prof3" (change)="onChange(3, $event.target.checked,3)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Prof4" (change)="onChange(4, $event.target.checked,3)" type="checkbox" name="name1" /></td>

            </tr>

            <tr class="odd gradeX">
              <td rowspan="2">Responsiveness</td>
              <td>
                Please rate the turnaround time for<br />
                A) Technical queries
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp1" (change)="onChange(1, $event.target.checked,4)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp2" (change)="onChange(2, $event.target.checked,4)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp3" (change)="onChange(3, $event.target.checked,4)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp4" (change)="onChange(4, $event.target.checked,4)" type="checkbox" name="name1" /></td>
            </tr>
            <tr class="odd gradeX">
              <td>B) Commercial queries</td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp5" (change)="onChange(1, $event.target.checked,5)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp6" (change)="onChange(2, $event.target.checked,5)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp7" (change)="onChange(3, $event.target.checked,5)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Resp8" (change)="onChange(4, $event.target.checked,5)" type="checkbox" name="name1" /></td>

            </tr>

            <tr class="odd gradeX">
              <td>Expertise</td>
              <td>
                How would you rate the level of our technical expertise?
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Exper1" (change)="onChange(1, $event.target.checked,6)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Exper2" (change)="onChange(2, $event.target.checked,6)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Exper3" (change)="onChange(3, $event.target.checked,6)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Exper4" (change)="onChange(4, $event.target.checked,6)" type="checkbox" name="name1" /></td>
            </tr>
            <tr class="odd gradeX">
              <td>Delivery</td>
              <td>
                How do you rate us “timely delivery” of our supplies?
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Deli1" (change)="onChange(1, $event.target.checked,7)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Deli2" (change)="onChange(2, $event.target.checked,7)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Deli3" (change)="onChange(3, $event.target.checked,7)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Deli4" (change)="onChange(4, $event.target.checked,7)" type="checkbox" name="name1" /></td>
            </tr>

            <tr class="odd gradeX">
              <td rowspan="2">Quality</td>
              <td>
                How do you rate us on “quality of our products”?
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual1" (change)="onChange(1, $event.target.checked,8)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual2" (change)="onChange(2, $event.target.checked,8)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual3" (change)="onChange(3, $event.target.checked,8)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual4" (change)="onChange(4, $event.target.checked,8)" type="checkbox" name="name1" /></td>
            </tr>
            <tr class="odd gradeX">
              <td>How do you rate our “packing quality”?</td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual5" (change)="onChange(1, $event.target.checked,9)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual6" (change)="onChange(2, $event.target.checked,9)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual7" (change)="onChange(3, $event.target.checked,9)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Qual8" (change)="onChange(4, $event.target.checked,9)" type="checkbox" name="name1" /></td>

            </tr>
            <tr class="odd gradeX">
              <td>Documentation</td>
              <td>
                Did the supplied documentation meets your requirements (e.g., Certificate of analysis, MSDS, MOA, etc.)?
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Doc1" (change)="onChange(1, $event.target.checked,10)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Doc2" (change)="onChange(2, $event.target.checked,10)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Doc3" (change)="onChange(3, $event.target.checked,10)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Doc4" (change)="onChange(4, $event.target.checked,10)" type="checkbox" name="name1" /></td>
            </tr>
            <tr class="odd gradeX">
              <td>Website</td>
              <td>
                How would you rate our stock, specifications, other account related information in our website
              </td>
              <td><input style="height:17px" [(ngModel)]="Obj.Web1" (change)="onChange(1, $event.target.checked,11)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Web2" (change)="onChange(2, $event.target.checked,11)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Web3" (change)="onChange(3, $event.target.checked,11)" type="checkbox" name="name1" /></td>
              <td><input style="height:17px" [(ngModel)]="Obj.Web4" (change)="onChange(4, $event.target.checked,11)" type="checkbox" name="name1" /></td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>

    <br />

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        Kindly give your valuable comments / Suggestions especially if your evaluation is not satisfactory or Average on any of the area.
      </div>


    </div><br />

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="form-group" style="text-align: left;padding: 0.5rem;">
          <label for="comment">Suggestion:</label>
          <textarea [(ngModel)]="Suggestion" class="form-control" rows="5" id="comment"></textarea>
        </div>
      </div>


    </div><br />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        Contact Person : {{ContPerson}}

      </div>


    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        Company Name : {{CompanyName}}

      </div>


    </div>


    <br />
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <button (click)="FeedBackPdf()" class="d_b" style="border-radius:0px;text-align:center">Submit</button>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      </div>
    </div><br />




  </div>

  <div class="loading-indicator" *ngIf="spinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>


</div>





<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>





<!--================================================================================-->
<style>

  @media (max-width: 768px) {
    .formaerl_text {
      font-size: 5px !important;
      color: black !important;
      margin: 3% 0.2% !important;
    }
  }
  .mobile_logo  {
     
    margin-top: 2.6rem !important;
  }
</style>
