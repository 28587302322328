import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  htmlFilePath: string = 'assets/appoinment.html';
  safeHtmlFilePath: SafeResourceUrl;
  ContactDetails: any;
  SearchQuery: string;
  DealerDetails: any;

  EnquiryAry: any = [];
  spinner: boolean;
  Name: any;
  Mobile: any;
  Email: any;
  Desc: any;
  cols: any;

  lat: number = 20.5937; 
  lng: number = 78.9629; 
  zoom: number = 16;


  options: any; overlays: any[];

  constructor(public generalService: GeneralService, public router: Router, private sanitizer: DomSanitizer) {
    this.safeHtmlFilePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.htmlFilePath);
    debugger
    localStorage.removeItem('home');

    this.options = {
      center: { lat: 20.5937, lng: 78.9629 },
      zoom: 4.4
    };
    this.cols = [
      { field: 'Name', header: 'Name', width: '40px' },
      { field: 'PhoneNumber', header: 'Phone Number', width: '40px' },
      { field: 'Address', header: 'Address', width: '40px' },
      { field: 'EmailId', header: 'Email Id', width: '40px' },

    ]

  }

  ngOnInit() {
    this.GetDealersDetails();
    this.GetContacts();


    //this.overlays.push({ lat: "17.3850", lng: "78.4867", title: "HYDERABAD", label: 'H', icon:'https://tsldalis.nic.co.in/images/Slider_Images/Slider_Image_f2.jpg' })


    this.overlays = [
      new google.maps.Marker({ position: { lat: 17.3850, lng: 78.4867 }, title: "HYDERABAD", label: 'H'}),
      new google.maps.Marker({ position: { lat: 30.7046, lng: 80.9462 }, title: "SCF. 37,1ST FLOOR, PHASE-5, MOHALI" ,label: 'M' }),
      new google.maps.Marker({ position: { lat: 26.8467, lng: 80.9462 }, title: " Lucknow ", label: 'L' }),
      new google.maps.Marker({ position: { lat: 26.4499, lng: 80.3319 }, title: "466/5, SHASTRI NAGAR KANPUR-208005 ", label: 'K'}),
      new google.maps.Marker({ position: { lat: 25.3176, lng: 82.9739 }, title: "D 14/31 Tedhi Neem Dashaswmedh Varanasi-221001", label: 'V'}),
      new google.maps.Marker({ position: { lat: 28.7041, lng: 77.1025 }, title: "35 U.B.TANDON HOUSE JAWAHAR NAGAR DELHI 110007 ", label: 'D' }),
      new google.maps.Marker({ position: { lat: 18.5204, lng: 73.8567 }, title: "544 Sadashiv Peth, Kumthekar Road, Bh. Kulkarni Petrol pump, Pune - 411030", label: 'P'}),
    ]
  }

  validatepri(e: any) {
    let input;
    input = String.fromCharCode(e.which);
    return !!/[0-9]/.test(input);
  }

  Sendmail(Name, Email, Mobile, Desc) {
    debugger;
    this.spinner = true;
    if (Name == undefined || Email == undefined || Mobile == undefined
      || Name == "" || Email == "" || Mobile == "" ||
      Name == null || Email == null || Mobile == null) {
      debugger
      this.spinner = false;
      this.generalService.ShowAlert('error', 'Please fill required fields.!', 'error');

    }
    else {
      this.EnquiryAry = []; this.EnquiryAry.push({ ContPerson: Name, Phone1: Mobile, EMailID: Email, Description: Desc })
      var UploadFile = new FormData();
      UploadFile.append("Details", JSON.stringify(this.EnquiryAry));
      var url = "/api/Values/EnquiryMailToAdmin"
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
        if (data == 'SUCCESS') {
          debugger
          this.spinner = false;
          this.generalService.ShowAlert('SUCCESS', 'Enquiry Submitted successfully.......!', 'success');
        
          //this.Name = "";
          //this.Mobile="";
          //this.Email ="";
          //this.Desc  ="";

        }
        this.spinner = false;
      });
    }
  }




  GetContacts() {
    debugger
    var Url = '/api/Values/GetContacts';
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.ContactDetails = data;
    });

  }

  GetDealersDetails() {
    debugger
    var Url = '/api/Values/GetDealersDetails';
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.DealerDetails = data;
    });

  }
  GetDealersdetailsSearch() {
    debugger
    var Url = '/api/Values/GetDealersDetails?Person='+this.SearchQuery;
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.DealerDetails = data;
    });

  }

  handleChange(id) {
    debugger;
    if (id.index == 0) {
    }
    else if (id.index == 1) {
      this.GetDealersDetails();

    }
  }
}
