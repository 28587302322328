


<app-page-heade></app-page-heade>
<br />
<br />
<br />
<br />
<br />


<div class="container">
  <div>
    <div class="container">
      <h1 class="heading">
        Audit Reports
      </h1><br />
           <div class="row">

             <p-table #dt [columns]="cols" [value]="ReportData" [scrollable]="true" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'98.7%'}">
               <ng-template pTemplate="caption">



                 


                 <div class="row">
                   <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                     <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                     <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                        aria-label="" (click)="dt.exportCSV()"
                        style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"> Export to Excel {{dt.totalRecords}} Records</i>
                     <!--<p class="CountGridRecords" style="margin-left:10%">{{dt.totalRecords}} Records </p>-->

                   </div>
                   <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                     <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
                   </div>
                 </div>

               </ng-template>
               <ng-template pTemplate="colgroup" let-columns>
                 <colgroup>

                   <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                 </colgroup>
               </ng-template>
               <ng-template pTemplate="header" let-columns>
                 <tr>

                   <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                     <span>{{col.header}}</span>
                     <p-sortIcon [field]="col.field"></p-sortIcon>
                   </th>
                 </tr>
               </ng-template>
               <ng-template pTemplate="body" let-rowData let-columns="columns">
                 <tr>

                   <td *ngFor="let col of columns" [ngSwitch]="col.field">
                     <span *ngSwitchDefault style="width : 100px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;">{{rowData[col.field]}}</span>
                   </td>
                 </tr>
               </ng-template>
               <ng-template pTemplate="footer">


                 <tr style="text-align:center" *ngIf="AuditReportLength==0">
                   <td colspan="8" style="text-align: center;">No Data Found</td>
                 </tr>
               </ng-template>
             </p-table>

             




           </div>
    </div>

  </div>
</div>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>
<style>

  .heading {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px dotted #808080;
    display: block;
    font-size: 25px !important;
    width: 99.7%;
    font-weight: bold;
    margin-left: -12px;
    margin-bottom: 0;
    letter-spacing: 1px !important;
    padding-top: 2rem !important;
  }

  .CountGridRecords {
    color: #3e50b4;
    margin-top: -24px !important;
    text-align: left;
  }
</style>


