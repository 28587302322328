<!--<app-header></app-header>

<p-toast position="top-right" [baseZIndex]="5000"></p-toast>-->
<!--<div *ngFor="let i of RegData ">
  {{i.Companyname}}
</div>-->
<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>


<div class="container" style="margin-top:10%;">
  <div class="row">



    <div class="container" style="height: 0%;" *ngIf="table">

      <div class="col-lg-12">
        <p-tabView (onChange)="handleChange($event)">
          <p-tabPanel header="Active">
            <div *ngIf="ShowForm">
              <p-table #dt *ngIf="table" [columns]="cols" [value]="RegData1" [scrollable]="true" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'98.7%'}">
                <ng-template pTemplate="caption">
                  <div class="row">
                    <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                      <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                         aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:-1px;"></i>
                      <i (click)="ShowUserForm()" class="fa fa-plus addIcon" pTooltip="New Registeration" tooltipPosition="top" style="margin-left:12px;"></i>
                      <p class="CountGridRecords" style="margin-left:10%">{{dt.totalRecords}} Records </p>
                    </div>
                    <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                      <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                  <colgroup>
                    <col style="width:30px">
                    <col style="width:50px">
                    <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width:30%;">Edit</th>
                    <th style="width:50%;">Delete</th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                      <span>{{col.header}}</span>
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td class="col-btn">
                      <i pTooltip="Edit" (click)="OpenEditForm(rowData)" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </td>
                    <td class="col-btn">
                      <i pTooltip="Delete" (click)="confirmDelete(rowData)" class="fa fa-trash" style="color: red;" aria-hidden="true"></i>
                    </td>
                    <td *ngFor="let col of columns" [ngSwitch]="col.field">
                      <ng-container *ngIf="col.field !== 'IsActive'">
                        <span style="width: 150px; overflow:hidden; display:inline-block; text-overflow: ellipsis; white-space: nowrap;">
                          {{rowData[col.field]}}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="col.field === 'IsActive'">
                        <span style="width: 150px; overflow:hidden; display:inline-block; text-overflow: ellipsis; white-space: nowrap;">
                          {{rowData.IsActive ? 'Active' : 'Inactive'}}
                        </span>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel header="InActive">
            <div *ngIf="ShowForm1">
              <p-table #dt *ngIf="table" [columns]="cols" [value]="RegData2" [scrollable]="true" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'98.7%'}">
                <ng-template pTemplate="caption">
                  <div class="row">
                    <div class="col-lg-3" style="text-align: left;margin-bottom: 0;">
                      <i class="fa fa-plus addIcon" (click)="openAddForm()" pTooltip="ADD" tooltipPosition="top" style="font-size:20px;"></i>
                      <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                         aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
                    </div>
                    <div class="col-lg-9" style="text-align: right;margin-bottom: 0;">
                      {{dt.filteredValue ? dt.filteredValue.length : dt.totalRecords}} of {{dt.totalRecords}}
                      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                      <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter"
                             (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                  <colgroup>
                    <col style="width:30px">
                    <col style="width:50px">
                    <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width:30%;">Edit</th>
                    <th style="width:50%;">Delete</th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                      <span>{{col.header}}</span>
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td class="col-btn">
                      <i pTooltip="Edit" (click)="OpenEditForm(rowData)" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </td>
                    <td class="col-btn">
                      <i pTooltip="Delete" (click)="confirmDelete(rowData)" class="fa fa-trash" style="color: red;" aria-hidden="true"></i>
                    </td>
                    <td *ngFor="let col of columns" [ngSwitch]="col.field">
                      <ng-container *ngIf="col.field !== 'IsActive'">
                        <span style="width: 150px; overflow:hidden; display:inline-block; text-overflow: ellipsis; white-space: nowrap;">
                          {{rowData[col.field]}}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="col.field === 'IsActive'">
                        <span style="width: 150px; overflow:hidden; display:inline-block; text-overflow: ellipsis; white-space: nowrap;">
                          {{rowData.IsActive ? 'Active' : 'Inactive'}}
                        </span>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>

    </div>
  </div>



  <form *ngIf="!table" class="well form-horizontal" action=" " method="post" id="contact_form">
    <fieldset style="padding-top: 0px;">

      <!-- Form Name -->
      <!-- <legend><center><h2><b>Registration Form</b></h2></center></legend><br>-->
      <!-- Text input-->

      <div class="ctmr_heading"> Customer Registration Details</div>

      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Comp Code</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!--  <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <input name="first_name" placeholder="Company Code" [(ngModel)]="UserObj.CompanyCode" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Comp Name</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-home"></i></span>-->
                <input name="last_name" placeholder="Company Name" [(ngModel)]="UserObj.CompanyName" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Cont Person</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!--<span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="Cont" placeholder="Contact Person" [(ngModel)]="UserObj.ContactPerson" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">City<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-globe"></i></span>-->
                <input name="City" placeholder="City" class="form-control" [(ngModel)]="UserObj.City" type="text">
              </div>
            </div>
          </div>
        </div>

        <!--<div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Warehouse<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <input name="Warehouse" placeholder="Warehouse" [(ngModel)]="UserObj.Warehouse" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>-->


        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Warehouse<span class="star"> *</span></label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="WareHouse" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.Warehouse">
                  <option [ngValue]="undefined" disabled selected> Please select Warehouse </option>
                  <option *ngFor="let item of WarehouseDDL" [ngValue]="item.ID"> {{ item.WareHouse }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Phone1</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="Phone1" placeholder="Phone1" (keypress)="validatepri($event)" [(ngModel)]="UserObj.Phone1" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- Text input-->

      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Phone2<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="Phone2" placeholder="Phone2" (keypress)="validatepri($event)" [(ngModel)]="UserObj.Phone2" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">EmailId<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input  name="Email" placeholder="EmailId" [(ngModel)]="UserObj.Email" class="form-control" type="text">

              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">EmailCC<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="EmailCC" placeholder="EmailCC" [(ngModel)]="UserObj.EmailCC" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

      </div>









      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Password<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="Password" placeholder="Password" [(ngModel)]="UserObj.Password" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">UserType<span class="star"> *</span></label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="UserTypeId" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.UserTypeId">
                  <option [ngValue]="undefined" disabled selected> Please select UserType </option>
                  <option *ngFor="let item of UserTypeData" [ngValue]="item.ID"> {{ item.RoleName }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Zone Head</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="ZoneHead" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.ZoneHead">
                  <option [ngValue]="undefined" disabled selected> Please select Zone Head </option>
                  <option *ngFor="let item of ZoneHeadData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Sales Mngr</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="SalesManager" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.SalesManager">
                  <option [ngValue]="undefined" disabled selected> Please select Sales Manager </option>
                  <option *ngFor="let item of SalesManagerData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Sr.Sales-Exe.</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="SalesCoordinator" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.SalesCoordinator">
                  <option [ngValue]="undefined" disabled selected> Please select Sr.Sales Executive </option>
                  <option *ngFor="let item of SalesCoordinatorData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Sales-Exec.</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-list"></i></span>-->
                <select name="SalesExecutive" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.SalesExecutive">
                  <option [ngValue]="undefined" disabled selected> Please select Sales Exective </option>
                  <option *ngFor="let item of SalesExecutiveData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Credit Days</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="CreditDays" placeholder="Credit Days" [(ngModel)]="UserObj.CreditDays" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Credit Limit</label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <input name="CreditLimit" placeholder="Credit Limit" [(ngModel)]="UserObj.CreditLimit" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Order To<span class="star"> *</span></label>
            <div class="col-md-8 inputGroupContainer">
              <div class="input-group">
                <!--  <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>-->
                <!--<input name="OrderTo" placeholder="Order To" [(ngModel)]="UserObj.OrderTo" class="form-control" type="text">-->
                <select name="OrderTo" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.OrderTo">
                  <option [ngValue]="undefined" disabled selected> Please select order </option>
                  <option *ngFor="let item of Orders" [ngValue]="item.ID"> {{ item.WareHouse }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

      </div>





      <!-- Text input-->





      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Is Active</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">

                <!--<select name="department" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.IsActive">
    <option [ngValue]="undefined" disabled selected> Is Active </option>
    <option *ngFor="let item of IsActiveData" [ngValue]="item.ID"> {{ item.Active }}</option>
  </select>-->
                <select name="isActive" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.IsActive">
                  <option [ngValue]="undefined" disabled selected> Is Active </option>
                  <option [ngValue]="1"> Yes </option>
                  <option [ngValue]="0"> No </option>
                </select>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-md-4 control-label">Place Order</label>
            <div class="col-md-8 selectContainer">
              <div class="input-group">
                <select name="department" class="form-control selectpicker dwn_input" [(ngModel)]="UserObj.NoOrder">
                  <option [ngValue]="undefined" disabled selected> Place Order </option>
                  <option *ngFor="let item of IsActiveData" [ngValue]="item.ID"> {{ item.Active }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <br /><br />

        <div class="col-lg-4">
          <button *ngIf="showIndex == undefined" type="submit" class="btn btn-primary" data-dismiss="modal" style="margin-right: 3%; float: right;padding: 0.6rem !important; width: 31% !important;box-shadow: 0 2px 5px rgb(0 0 0 / 0%), 0 2px 10px rgb(0 0 0 / 0%);" (click)="Register()">Save</button>
          <button *ngIf="showIndex != undefined" type="submit" class="btn btn-primary" data-dismiss="modal" style="margin-right: 3%; float: right;padding: 0.6rem !important; width: 31% !important;box-shadow: 0 2px 5px rgb(0 0 0 / 0%), 0 2px 10px rgb(0 0 0 / 0%);" (click)="Register()">Update</button>

          <button type="button" class="btn btn-danger" data-dismiss="modal" style="margin-right: 2%; padding: 0.6rem !important; width: 31% !important;float: right;box-shadow: 0 2px 5px rgb(0 0 0 / 0%), 0 2px 10px rgb(0 0 0 / 0%);" (click)="Back()">Back</button>
        </div>

      </div>


    </fieldset>
    <!--<!-- <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateUsers()">Update</button>-->
  </form>

</div>





<style>
 
</style>
