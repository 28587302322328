import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { map, throttle } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
@Component({
  selector: 'app-solvents',
  templateUrl: './solvents.component.html',
  styleUrls: ['./solvents.component.scss']
})
export class SolventsComponent implements OnInit {
  LoginUserTypeId: string;
  Details: any;
  image: any;
  imagefile: any;
  LoginDet: any;
  spinner: boolean;
  HomeURL: any;

  constructor(public http: HttpClient, public generalService: GeneralService, public router: Router) {

    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
    });
  
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }


}


  ngOnInit(): void {
  }




  detectFiles(event) {
    debugger
    //this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        var selectedFile = file;
        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
        var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
        if (extFile == "xls" || extFile == "xlsx") {
          this.Details = event;
        }
        else {
          alert('only xlx/xlsx')
        }
      }
     // this.fileChange(event);
    }

  }


  UploadSolventsExcel() {
    debugger
    let fileList = this.Details.target.files;
    if (fileList.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        frmData.append("fileUpload", fileList[i]);
      }
      var url = '/api/Admin/Solvents';
      this.generalService.PostData(url, frmData).then((data: any) => {
        let a = data;
        if (data == 'SUCCESS') {
          this.generalService.ShowAlert('SUCCESS', 'Excel sheet uploaded successfully...!', 'success');

        }
        else {
          this.generalService.ShowAlert('error', 'Something went wrong..!', 'error');

        }
      });
    }

  }





  DownLoadSolvent() {
    debugger
    this.spinner = true;
    var GetUrl = 'api/Admin/DownLoadSolvent';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      var path = "\Content/Solvents/";
     window.open(this.HomeURL + path   + data.url1, "")
      //this.DownLoadPdf(data.url1, this.HomeURL + path + data.url1);
    });
    this.spinner = false
  }


  detectFilesMSDS(event) {
    debugger
    //this.urls = [];
    let files = event.target.files;

    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        var selectedFile = file;


        let FileName = selectedFile.name;
        let FFF = FileName.split(' ');
        let F1 = FFF[0].split('-', ' ');
        var count1 = F1.length;
        var count = FFF.length
        var uploadfilename = FFF[count - 1].split('.')[0];

        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
        var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();

        if (extFile != "pdf") {
          this.generalService.ShowAlert('error', 'MSDS Document name must be in mentioned format', 'error');
        }
        else if (uploadfilename != 'MSDS') {
          this.generalService.ShowAlert('error', 'MSDS Document name must be in mentioned format', 'error');

        }
       
        else {
          this.Details = event;

        }
      }
      // this.fileChange(event);
    }

  }



  UploadMSDS() {
    debugger
    this.spinner = true;
    let fileList = this.Details.target.files;
    if (fileList.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        frmData.append("fileUpload", fileList[i]);
      }
      var url = '/api/Admin/MSDS';
      this.generalService.PostData(url, frmData).then((data: any) => {
        let a = data;
        if (data == 'SUCCESS') {
          this.generalService.ShowAlert('SUCCESS', 'MSDS uploaded successfully...!', 'success');

        }
        else {
          this.generalService.ShowAlert('error', 'Something went wrong..!', 'error');

        }
      });
      this.spinner = false;

    }

  }



}


