import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dealerslist',
  templateUrl: './dealerslist.component.html',
  styleUrls: ['./dealerslist.component.scss']
})
export class DealerslistComponent implements OnInit {
  arr: any = [];
  arr1: any[];
  loginDet: any;
  cols: any;
  dataResult: any = [];
  Form: FormGroup;
  gridview: boolean = true;
  flag: any;
  TTMID: any;
  Companydata: any;
  persondata: any;
  deeptList: any[] = [];
  CompanyName123: any;
  selectedCompanyName: any;
  name: any;
  Contact: any;
  Sequence: any;
    ButtonText: string;
    Status: boolean;
    ckeditorContent: any;
    HomeUrl: any;
  states: any;
  StateCode: any;
  DealerId: any; table: boolean;
    Location: any;
    Address: any;
    EmailId: any;
    PhoneNumber: any;
  constructor(
    public generalService: GeneralService,
    public fb: FormBuilder,
    public http: HttpClient,
    private datePipe: DatePipe,
    private router: Router
  ) {
this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    //this.GetTestimonial();
   
    this.Form = this.fb.group({
      Name: ['', Validators.required],
      PhoneNumber: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      Address: ['', [Validators.required, Validators.maxLength(225)]],
      Location: ['', [Validators.required,]],
      EmailId: ['', [Validators.required,]],
      StateCode: ['', [Validators.required,]],
    });
    this.states = [
      { name: 'Andhra Pradesh', code: 'IN-AP' },
      { name: 'Arunachal Pradesh', code: 'IN-AR' },
      { name: 'Assam', code: 'IN-AS' },
      { name: 'Bihar', code: 'IN-BR' },
      { name: 'Chhattisgarh', code: 'IN-CT' },
      { name: 'Goa', code: 'IN-GA' },
      { name: 'Gujarat', code: 'IN-GJ' },
      { name: 'Haryana', code: 'IN-HR' },
      { name: 'Himachal Pradesh', code: 'IN-HP' },
      { name: 'Jharkhand', code: 'IN-JH' },
      { name: 'Karnataka', code: 'IN-KA' },
      { name: 'Kerala', code: 'IN-KL' },
      { name: 'Madhya Pradesh', code: 'IN-MP' },
      { name: 'Maharashtra', code: 'IN-MH' },
      { name: 'Manipur', code: 'IN-MN' },
      { name: 'Meghalaya', code: 'IN-ML' },
      { name: 'Mizoram', code: 'IN-MZ' },
      { name: 'Nagaland', code: 'IN-NL' },
      { name: 'Odisha', code: 'IN-OR' },
      { name: 'Punjab', code: 'IN-PB' },
      { name: 'Rajasthan', code: 'IN-RJ' },
      { name: 'Sikkim', code: 'IN-SK' },
      { name: 'Tamil Nadu', code: 'IN-TN' },
      { name: 'Telangana', code: 'IN-TG' },
      { name: 'Tripura', code: 'IN-TR' },
      { name: 'Uttar Pradesh', code: 'IN-UP' },
      { name: 'Uttarakhand', code: 'IN-UT' },
      { name: 'West Bengal', code: 'IN-WB' },
      { name: 'Jammu and Kashmir', code: 'IN-JK' },

    ];

    this.cols = [

      { field: 'CreatedDate', header: 'Created Date', width: '0%' },
      { field: 'Name', header: 'Name', width: '55px%' },
      { field: 'PhoneNumber', header: 'PhoneNumber', width: '90%' },
      { field: 'Address', header: 'Address', width: '90%' },
      { field: 'Location', header: 'Location', width: '90%' },
      { field: 'EmailId', header: 'EmailId', width: '90%' },
      { field: 'StateCode', header: 'StateCode', width: '90%' },


    ];

    debugger
     
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });

}

  ngOnInit(): void {
    this.table = true;

    this.Getdelearlist();
    // this.GetCompany();
  }

  Getdelearlist() {
    debugger
    this.arr = []
    this.arr.push({
      // ID: this.loginDet.ID,
      /*  TokenID: this.loginDet.TokenID,*/
    });
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(this.arr));
    UploadFile.append("Flag", '4');
    var url = "api/Values/Dealerlist_CRUD";
    this.generalService.PostData(url, UploadFile).then(data => {
      this.dataResult = data;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
    });
  }

  AddSource(value, flag) {
    debugger
    var arr = [];
    arr.push({
      
      EmailId: value.EmailId,
      Name: value.Name,
      PhoneNumber: value.PhoneNumber,
      Address: value.Address,
      Location: value.Location,
      StateCode: value.StateCode,
    });

    var UploadFile = new FormData();
    UploadFile.append("Param", JSON.stringify(arr));
    UploadFile.append("Flag", flag);
    var url = "api/Values/Dealerlist_CRUD";

    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {
        if (this.flag == 1) {
          this.generalService.ShowAlert('SUCCESS', 'Dealer Added successfully ', 'success');
        } else {
          this.generalService.ShowAlert('SUCCESS', 'Dealer Updated successfully', 'success');
        }
        this.Form.reset();
        this.gridview = true;
        this.Getdelearlist();
      }
      if (data == "Exist") {
        this.generalService.ShowAlert('Exist', 'Data Already Exist ', 'success');
      }
    }, error => ({
      title: 'ERROR',
      text: 'Something went wrong. Please try again later.',
      type: 'error',
      confirmButtonText: 'Ok'
    }));
  }


  update(value, flag) {
    debugger
    var arr = [];
    arr.push({
      DealerId: this.DealerId,
      EmailId: value.EmailId,
      Name: value.Name,
      PhoneNumber: value.PhoneNumber,
      Address: value.Address,
      Location: value.Location,
      StateCode: value.StateCode,
    });

    var UploadFile = new FormData();
    UploadFile.append("Param", JSON.stringify(arr));
    UploadFile.append("Flag", flag);
    var url = "api/Values/Dealerlist_CRUD";

    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {
        if (this.flag == 1) {
          this.generalService.ShowAlert('SUCCESS', 'Dealer Added successfully ', 'success');
        } else {
          this.generalService.ShowAlert('SUCCESS', 'Dealer Updated successfully', 'success');
        }
        this.Form.reset();
        this.gridview = true;
        this.Getdelearlist();
      }
      if (data == "Exist") {
        this.generalService.ShowAlert('Exist', 'Data Already Exist ', 'success');
      }
    }, error => ({
      title: 'ERROR',
      text: 'Something went wrong. Please try again later.',
      type: 'error',
      confirmButtonText: 'Ok'
    }));
  }

  openAddForm1() {
    this.Status = true;
    this.Form.reset();
    this.gridview = false;
    this.flag = 1;
    this.ButtonText = "Add";

  } 
  edit(val) {
    debugger
    this.gridview = false;
    this.flag = 2;
    this.ButtonText = "Update";
    this.DealerId = val.DealerId;
    this.Form.controls.Name.setValue(val.Name);
    this.name = val.Name;
    this.Form.controls.PhoneNumber.setValue(val.PhoneNumber);
    this.Form.controls.PhoneNumber.setValue(val.PhoneNumber);
    this.PhoneNumber = val.PhoneNumber;

    this.Form.controls.Location.setValue(val.Location);
    this.Form.controls.Location.setValue(val.Location);
    this.Location = val.Location;

    this.Form.controls.StateCode.setValue(val.StateCode);
    this.Form.controls.StateCode.setValue(val.StateCode);
    this.StateCode = val.StateCode;

    this.Form.controls.Address.setValue(val.Address);
    this.Form.controls.Address.setValue(val.Address);
    this.Address = val.Address;
    this.Form.controls.EmailId.setValue(val.EmailId);
    this.Form.controls.EmailId.setValue(val.EmailId);
    this.EmailId = val.EmailId;
  }

  ChangeStatus(e, val) {
    var UploadFile = new FormData();
    UploadFile.append("TTMID", val.DealerId);
    UploadFile.append("Status", e.checked);
    UploadFile.append("Flag", "1");
    var url = "api/Values/Dealerlist_CRUD"
    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == 'SUCCESS') {
        this.generalService.ShowAlert('SUCCESS', 'Status Updated successfully.', 'success');
      } else {
        this.generalService.ShowAlert('ERROR', data, 'error');
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
    });
  }

  cancel() {
    this.Getdelearlist();
    this.gridview = true;
  }

  onChange(event: any) {
    setTimeout(() => {
      this.ckeditorContent = event;
    });
  }

  onReady(event: any) {
    event.editor.focus();
  }

  onFocus(event: any) {
    console.log("editor is focused");
  }

  onBlur(event: any) {}

  openAddForm() {
    this.Status = true;
    this.Form.reset();
    this.gridview = false;
    this.flag = 1;
    this.ButtonText = "Add";
  }

  Delete(val) {
    debugger
    Swal.fire({
      title: 'Alert',
      text: 'Are you sure to delete this Dealer ?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        this.arr1 = []
        this.arr1.push({
          DealerId : val.DealerId
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr1));
        UploadFile.append("Flag", '3');
        var url = "api/Values/Dealerlist_CRUD";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          if (data == "SUCCESS") {
            this.generalService.ShowAlert('SUCCESS', 'Dealer deleted successfully', 'success');
            this.Getdelearlist();
          }
        }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        })
      }
    })
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  SearchHistory(event: any) {
    debugger
    let searchCont = [];
    //this.Doctorlist = this.DocotrsList.length;
    const query = event.target.value;
    this.dataResult = this.dataResult.filter((BH: any) => {
      debugger
      return (
        BH.Name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        BH.PhoneNumber.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        BH.Address.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        BH.EmailId.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        BH.Address.toLowerCase().indexOf(query.toLowerCase()) > -1)
    });
  }

  ClearSearch() {
    this.Form.removeControl;
    this.router.navigate(['/Dealerslist']).then(() => { window.location.reload(); });
   this.Getdelearlist();
  }
 
  searchCont(event) {
    let filtered: any = [];
    let query = event.query;
    this.deeptList = [];
    for (var i = 0; i < this.dataResult.length; i++) {
      let dataResult = this.dataResult[i];
      if (dataResult.Name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(dataResult);
      }
    }
    this.deeptList = filtered;
  }

  }
