

<!--<app-page-heade></app-page-heade>-->

<app-page-heade></app-page-heade>
<!--<p-toast position="top-right" [baseZIndex]="5000"></p-toast>-->
<div class="content" style="margin-top:7rem;">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td align="center" valign="top" bgcolor="#ffffff">
              <!--HEADER ENDS--><!--HEADER ENDS-->
              <table width="83%">
                <tbody>
                  <tr>
                    <td width="100%" height="46" valign="top" align="right"></td>
                  </tr>
                  <tr>
                    <td class="bodyTextTitle">Search COA</td>
                  </tr>

                  <tr>
                    <td height="20"></td>
                  </tr>
                  <tr>
             
                    <td class="bodyText">
                      <form name="COAMSDSForm">
                        Enter Batch No. for Certificate of Analysis (COA) <br>
                        (Example:N2000338, N1810025)<br><br>

                        <input [(ngModel)]="BatchNo" name="filename" type="text" id="filename" size="50" class="textInput" style="background-color: rgb(248, 248, 248); color: rgb(51, 51, 51);padding-left:0.3rem;">
                        &nbsp;&nbsp;<input type="submit" value=" Go.." class="button" (click)="GetCoaDetails()">
                        <!--<br> <br>
      <br><br>
      <span class="bodyLink">Search for Material Safety Data Sheet (MSDS) ... </span>-->
                        <br>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table><!--FOOTER STARTS-->
            </td>
          </tr>
        </tbody>
      </table>

      <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td align="center" valign="top" bgcolor="#ffffff">
              <!--HEADER ENDS--><!--HEADER ENDS-->
              <table width="83%" style="margin-top: 0px">
                <tbody>
                  <tr>
                    <td width="100%" height="46" valign="top" align="right"></td>
                  </tr>
                  <tr>
                    <td class="bodyTextTitle" style="border:none;">Search  MSDS</td>

                  </tr>
                  <div class="bodyLink bodyTextTitle" style="font-weight:normal;font-size:12px;">Search for Material Safety Data Sheet (MSDS) ... </div>
                  <tr>
                    <td height="20"></td>
                  </tr>
                  <tr>
                    <td class="bodyText">
                      <form name="COAMSDSForm">
                        Please provide keywords related to the product you are looking for -
                        <br /> Keywords can also include Catalogue or CAS numbers or Product Name<br><br>
                        <input name="filename" [(ngModel)]="MSDNparam"  type="text" id="filename" size="50" class="textInput" style="background-color: rgb(248, 248, 248); color: rgb(51, 51, 51);padding-left:0.3rem;">
                        &nbsp;&nbsp;


                        <a (click)="view()" href="../../assets/AvraLifeSciencesCatalogReport_V1.pdf" target="_blank" style="text-decoration:none;bottom: 73px;right: 67.5%;">

                          <span class="button" type="text" id="filename" size="45" style="background-color: rgb(248, 248, 248); color: rgb(51, 51, 51);">
                            &nbsp;&nbsp;&nbsp;&nbsp;<input type="submit" value=" Go.." class="button">
                          </span>

                        </a>
                        <br> <br>
                        <br><br>
                        <br>
                      </form>


                    </td>
                  </tr>
                </tbody>
              </table><!--FOOTER STARTS-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<div *ngIf="spinner" class="loader"></div>


<style>
  .bodyTextTitle {
    font-family: 'Poppins', Verdana, Helvetica, sans-serif;
    font-size: 14px;
    color: #000000;
    text-align: justify;
    line-height: 20px;
    font-weight: bold;
    border-bottom: 1px dotted #808080;
  }

  .bodyText {
    font-family: 'Poppins', Verdana, Helvetica, sans-serif;
    font-size: 8pt;
    color: #000000;
    text-align: justify;
    line-height: 18px;
  }
</style>
