import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dispatch-details',
  templateUrl: './dispatch-details.component.html',
  styleUrls: ['./dispatch-details.component.scss'],
  providers: [DatePipe]

})
export class DispatchDetailsComponent implements OnInit {
  DispatchDetailsWithOutReceipt1: any;
  DispatchDetailsWithReceipt1: any;
  DispatchDetails1: any;
  DispDetails: any=[];
  DateOfRct: any;
  DispatchDetailsWithOutReceiptLength: any;
  DispatchDetailsWithReceiptLength: any;
  DispatchDetailsWithOutReceipt: any;
  LoginUserTypeId: any;
  DispatchDetailsWithReceipt: any;
  DispatchDetailsLength: any;
  ShowDropDown: boolean=false;
  DropDownData: any;
  GrandTotal: any;
  SalesPerson: any;

  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';


  DispatchDetails: any;
  spinner: boolean;
  cols: any;
  LoginDet: any;
  constructor(public datePipe: DatePipe,public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'CustCode', header: 'Company Name', width: '130px' },
      { field: 'DispNo', header: 'Dispatch No', width: '120px' },
      { field: 'DispDate', header: 'Dispatch Date', width: '120px' },
      { field: 'LRNo', header: 'LR No', width: '120px' },
      { field: 'LRDate', header: 'LR Date', width: '80px' },
      { field: 'GrossWeight', header: 'Gross Weight', width: '100px' },
      { field: 'TrptCode', header: 'Trpt Code', width: '80px' },
      { field: 'Invoices', header: 'Bill', width: '120px' },

      //{ field: 'CustCode', header: 'Bill', width: '120px' },

  

    ]

    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }
  }

  ngOnInit(): void {
    this.ShowDropDown = false;
    var date = new Date();
    this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.mindate = this.enddate;
    this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.AvraSyn_GetDispatchDetails();
    if (this.LoginDet[0].UserTypeId == '5' || this.LoginDet[0].UserTypeId == '7' || this.LoginDet[0].UserTypeId == '8' || this.LoginDet[0].UserTypeId == '9') {
      this.ShowDropDown = true;
      this.DropDown();
    }
    else if (this.LoginUserTypeId == '4') {
 
      this.GetDispatchDetailsWithReceipt();
      this.GetDispatchDetailsWithOutReceipt();

    }
  }
  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
  /*end code*/

  handleChange(id) {
    debugger;
    if (id.index == 0) {
      this.GetDispatchDetailsWithOutReceipt();
    }
    else if (id.index == 1) {
      this.GetDispatchDetailsWithReceipt();
    }
  }


  AvraSyn_GetDispatchDetails() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/AvraSyn_GetDispatchDetails?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.GrandTotal = 0;
      this.DispatchDetails = data;
      this.DispatchDetails1 = data;

      for (var i = 0; i < data.length; i++) {
        this.GrandTotal = this.GrandTotal + data[i].GrossWeight;
      }
      this.spinner = false;
      this.DispatchDetailsLength = data.length;
    });

  }
  DropDown() {
    debugger
    let personid = this.SalesPerson;
    this.spinner = true;
    var GetUrl = '/api/Admin/GetSalesExecutiveDDLBasedonId?PersonId=' + this.LoginDet[0].ID + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.DropDownData = data;
      this.spinner = false;
    });

  }
  AvraSyn_GetDispatchDetailsOnSelect(Value) {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/AvraSyn_GetDispatchDetails?PersonId=' + Value + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.GrandTotal = 0;
      this.DispatchDetails = data;
      this.DispatchDetails1 = data;

      for (var i = 0; i < data.length; i++) {
        this.GrandTotal = this.GrandTotal + data[i].GrossWeight;
      }
      this.spinner = false;
      debugger
      this.DispatchDetailsLength = data.length;
    });

  }


  GetDispatchDetailsWithReceipt() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/getDispatchDetailswithreceipt?PersonId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;

      this.cols = [
        { field: 'CustCode', header: 'Company Name', width: '140px' },
        { field: 'DispNo', header: 'Dispatch No', width: '120px' },
        { field: 'DispDate', header: 'Dispatch Date', width: '120px' },
        { field: 'LRNo', header: 'LR No', width: '120px' },
        { field: 'LRDate', header: 'LR Date', width: '100px' },
        { field: 'GrossWeight', header: 'Gross Wt.', width: '100px' },
        { field: 'TrptCode', header: 'Trpt Code', width: '100px' },
        { field: 'Invoices', header: 'Bill', width: '180px' },
        { field: 'DateofReceipt', header: 'Date Of Receipt', width: '150px' },
        { field: 'Action', header: '', width: '60px' }
      ]
      
     // this.cols.slice(0, 1);

      this.DispatchDetailsWithReceipt = data;
      this.DispatchDetailsWithReceipt1 = data;

      this.DispatchDetailsWithReceiptLength = data.length;

      this.spinner = false;
    });
  }

  GetDispatchDetailsWithOutReceipt() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/getDispatchDetailswithOutreceipt?PersonId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.DispatchDetailsWithOutReceipt = data;
      this.DispatchDetailsWithOutReceipt1 = data;

      this.DispatchDetailsWithOutReceiptLength = data.length;
      this.spinner = false;
    });
  }
  InsertReceiptDate(value) {
    debugger
    this.spinner = true;
    this.DispDetails = value;
    //this.DateOfRct = new Date(this.DateOfRct);
    var GetUrl = '/api/Admin/InsertDispatchReceiptDate?_receiptdate=' + value.DateofReceipt + '&_DispatchNO=' + value.DispNo + '&_CustId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => { 
      debugger;
      if (data == "-99") {

        this.generalService.ShowAlert('ERROR', 'Date of Receipt is not Updated! Please try Again', 'error');

      }
      else {
        this.spinner = false;
        this.generalService.ShowAlert('SUCCESS', 'Date of Receipt  Updated!', 'success');
        this.GetDispatchDetailsWithReceipt();
        this.DispDetails[0].DateofReceipt = value.DateofReceipt;
        value.DateofReceipt = "";
        this.SendDispatchMail();
      }
      this.spinner = false;

    });
  }

  

  SendDispatchMail() {
    debugger
    var Url = '/api/Admin/DispatchMail';
    var UploadFile = new FormData();
    UploadFile.append('ProdDet', JSON.stringify(this.DispDetails));
    UploadFile.append('UserDet', JSON.stringify(this.LoginDet));
    this.generalService.PostData(Url, UploadFile).then((data: any) => {

      debugger

      this.GetDispatchDetailsWithOutReceipt();

    });
  }

  Filter(startdate, enddate) {
    debugger
    this.DispatchDetails = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.DispatchDetails = this.DispatchDetails1.filter(
      m => new Date(m.DispDate) >= new Date(startdate) && new Date(m.DispDate) <= new Date(enddate)
    );

  }
  Filter1(startdate, enddate) {
    debugger
    this.DispatchDetailsWithOutReceipt = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.DispatchDetailsWithOutReceipt = this.DispatchDetailsWithOutReceipt1.filter(
      m => new Date(m.DispDate) >= new Date(startdate) && new Date(m.DispDate) <= new Date(enddate)
    );

  }
  Filter2(startdate, enddate) {
    debugger
    this.DispatchDetailsWithReceipt = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.DispatchDetailsWithReceipt = this.DispatchDetailsWithReceipt1.filter(
      m => new Date(m.DispDate) >= new Date(startdate) && new Date(m.DispDate) <= new Date(enddate)
    );

  }


}
