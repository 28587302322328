import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var google: any;

@Component({
  selector: 'app-dealers-map',
  templateUrl: './dealers-map.component.html',
  styleUrls: ['./dealers-map.component.scss']
})
export class DealersMapComponent implements OnInit {
  htmlFilePath: string = 'assets/appoinment.html';
  safeHtmlFilePath: SafeResourceUrl;
  ///states = statesData;
  constructor(private sanitizer: DomSanitizer) {
    this.safeHtmlFilePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.htmlFilePath);
  }
  
  ngOnInit() {

   

  }
}
