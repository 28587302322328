
<app-page-heade></app-page-heade>
<!--<a class="navbar-brand" routerLink="/Home">
  <img src="../../assets/Avra_Logo_new.png" class="mobile_logo">
</a>-->
<!--<div class="d-flex justify-content-center align-items-center w-100 h-100 register-container">
    <div class="col-xl-4 col-md-6 col-10">
        <div class="card border-0 box-shadow rounded-0">
            <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow">
                <i class="fa fa-registered" aria-hidden="true"></i>
            </div>
            <div class="card-body text-center pb-1">
                <h2>Register member</h2>
                <a routerLink="/login" class="transition">Already have an account? Sign in!</a>

                <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="text-left mt-4">
                    <div class="form-group">
                        <input [formControl]="name" class="form-control validation-field" placeholder="Full Name" type="text">
                        <small class="text-danger" *ngIf="form.get('name').touched">
                            <span *ngIf="form.get('name').hasError('required')">Full Name is required</span>
                            <span *ngIf="form.get('name').hasError('minlength')">Minimum of 3 characters</span>
                        </small>
                    </div>
                    <div class="form-group">
                        <input [formControl]="email" class="form-control validation-field" placeholder="Email" type="text">
                        <small class="text-danger" *ngIf="form.get('email').touched">
                            <span *ngIf="form.get('email').hasError('required')">Email is required</span>
                            <span *ngIf="form.get('email').hasError('invalidEmail')">Invalid email address</span>
                        </small>
                    </div>
                    <div class="form-group">
                        <input [formControl]="password" class="form-control validation-field" placeholder="Password" type="password" minlength="6">
                         <small class="text-danger" *ngIf="form.get('password').touched">
                            <span *ngIf="form.get('password').hasError('required')">Password is required</span>
                            <span *ngIf="form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                        </small>
                    </div>
                    <div class="form-group">
                        <input [formControl]="confirmPassword" class="form-control validation-field" placeholder="Confirm Password" type="password">
                        <small class="text-danger" *ngIf="form.get('confirmPassword').touched">
                            <span *ngIf="form.get('confirmPassword').hasError('required')">Confirm Password is required</span>
                            <span *ngIf="form.get('confirmPassword').hasError('mismatchedPasswords')">Passwords do not match</span>
                        </small>
                    </div>
                    <div class="form-group">
                        <p class="terms">By creating an account, you agree our <a class="transition terms" href="javascript:void(0);">Terms & Privacy Policy</a></p>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!form.valid" class="btn btn-block" type="submit">Sign up</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>-->

<!--<app-page-heade></app-page-heade>-->

<div class="container banner">

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">

    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="register" style="margin-top:8rem;">Sign Up</div>
      <form [formGroup]="accountForm" class="row bg-light pt-2" *ngIf="Grid">


        <div class="row">
          <div class="form-group col-md-6">
            <!--<label class="form-control-label">Comapany Name</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-user-o"></i></span></div>-->
              <input formControlName="comname" class="form-control" placeholder="Company Name" type="text">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('comname').touched">
              <small class="text-danger" *ngIf="accountForm.get('comname').hasError('required')">Company Name is required</small>
            </small>
          </div>

          <div class="form-group col-md-6">
            <!--<label class="form-control-label">User Name</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-user-o"></i></span></div>-->
              <input formControlName="username" class="form-control" placeholder="Name" type="text">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('username').touched">
              <small class="text-danger" *ngIf="accountForm.get('username').hasError('required')">Username is required</small>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <!--<label class="form-control-label">Email</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-envelope"></i></span></div>-->
              <input formControlName="email" class="form-control" (input)="CheckEmail()" placeholder="Email" type="text">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('email').touched">
              <small class="text-danger" *ngIf="accountForm.get('email').hasError('required')">Email is required</small>
              <small class="text-danger" *ngIf="accountForm.get('email').hasError('normalEmailRule')">Invalid email address</small>
              <span *ngIf="checkemail==10">Email address already in use. </span>
            </small>
          </div>
          <div class="form-group col-md-6">
            <!--<label class="form-control-label">Phone Number</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></div>-->
              <input formControlName="phoneno" placeholder="Phone Number" class="form-control" (input)="CheckMobileNo()" (keypress)=keyPress($event) type="text">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('phoneno').touched">
              <small class="text-danger" *ngIf="accountForm.get('phoneno').hasError('required')">PhoneNumber is required</small>
              <span style="color:red" *ngIf="checkNo==10">Mobile Exist</span>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <!--<label class="form-control-label">City</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-user-o"></i></span></div>-->
              <input formControlName="city" class="form-control" placeholder="City" type="text">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('city').touched">
              <small class="text-danger" *ngIf="accountForm.get('city').hasError('required')">City is required</small>
            </small>
          </div>
          <div class="form-group col-md-6">
          
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <!--<label class="form-control-label" >Password</label>-->
            <div class="input-group">
              <!-- <div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-key"></i></span></div>-->
              <input formControlName="password" placeholder="Password" class="form-control" type="password">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('password').touched">
              <small class="text-danger" *ngIf="accountForm.get('password').hasError('required')">Password is required</small>
              <small class="text-danger" *ngIf="accountForm.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</small>
            </small>
          </div>
          <div class="form-group col-md-6">
            <!--<label class="form-control-label">Confirm Password</label>-->
            <div class="input-group">
              <!--<div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-key"></i></span></div>-->
              <input formControlName="confirmPassword" placeholder="Confirm Password" class="form-control" type="password">
            </div>
            <small class="text-danger" *ngIf="accountForm.get('confirmPassword').touched">
              <small class="text-danger" *ngIf="accountForm.get('confirmPassword').hasError('required')">Password is required</small>
              <small class="text-danger" *ngIf="accountForm.get('confirmPassword').hasError('mismatchedPasswords')">Passwords do not match</small>
            </small>
          </div>
        </div>
        <!--<div style="text-align:right">
        &nbsp;
          <button type="submit" [disabled]="accountForm.invalid" (click)="OnSubmit(accountForm.value)" class="btn btn-primary">Submit</button>
        </div>-->

      </form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0px;">
          <button type="button" (click)="Cancel()" class="btn btn-danger">Cancel</button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-right:0px;">
          <button type="submit" [disabled]="accountForm.invalid" (click)="OnSubmit(accountForm.value)" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div>

</div>


<style>

  .app.combined .btn-danger {
    color: #000;
    background-color: rgba(0, 0, 0, 0.00);
    border-color: #F34235;
    width: 100%;
    height: 3.5rem;
    letter-spacing: 0.5px;
    font-size: 16px;
  }

  .btn:not(.btn-link):not(.btn-circle) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
    border: 0;
    font-size: 13px;
    border-radius: 10px;
    width: 100%;
    height: 3.5rem;
    letter-spacing: 0.5px;
    font-size: 16px;
  }

  .btn:not(.btn-link):not(.btn-circle) {
    box-shadow: 0 2px 5px rgb(0 0 0 / 0%), 0 2px 10px rgb(0 0 0 / 0%);
    font-size: 13px;
    border-radius: 10px;
    border: 1px solid #3d3d3d;
  }

  .banner {
    background-image: url(../../assets/ALSBgIMG.jpg);
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app.combined .bg-light {
    background-color: #f5f5f500 !important;
  }

  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;
  }

  .register {
    font-size: 25px;
    color: #000;
    letter-spacing: 0.5px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -0.8rem;
  }

  .app.combined .btn-danger:hover, .app.combined .btn-danger:active, .app.combined .btn-danger.active {
    background-color: #bebebe;
    border-color: #a5a5a5;
  }

  .app.combined .btn-primary {
    color: #3d3d3d;
    background-color: rgba(0, 0, 0, 0.00);
    border-color: #3d3d3d;
  }

    .app.combined .btn-primary:hover, .app.combined .btn-primary:active, .app.combined .btn-primary.active {
      background-color: #bebebe;
      border-color: #a5a5a5;
    }
 
</style>
