import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-aquagrpprods',
  templateUrl: './aquagrpprods.component.html',
  styleUrls: ['./aquagrpprods.component.scss']
})
export class AquagrpprodsComponent implements OnInit {
spinner: boolean;
  Prodslength: any;
  GroupName: any;
  GroupProducts: any;
  HomeUrl: any;

  constructor(public generalService: GeneralService, public router: Router,
    public http: HttpClient, public Location: Location) {
    this.GroupName = localStorage.getItem('GroupName');
    localStorage.removeItem('home');

  }

  ngOnInit(): void {
    this.GetGroupInfoBasedOnGroupID();
  }

  //Getting Special Chemical Group's Products on click of Group//
  GetGroupInfoBasedOnGroupID() {
    debugger
    this.spinner = true;
    var GetUrl = 'api/Values/AVAR2020_GetAquaInfoBasedOnClassID?GroupName=' + this.GroupName + '&SubGroupName=' + '' +  '&igmcode='+'' + '&flag=1';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.GroupProducts = data;
      this.Prodslength = this.GroupProducts.length;
      this.spinner = false;

    });
  }
  ClickOnSplProd(ItemCode) {
    debugger

    localStorage.removeItem('SPl')
    localStorage.setItem('SPl', 'Speciality Products')

    localStorage.removeItem('ItemCode')
    localStorage.setItem('ItemCode', ItemCode)
    this.router.navigateByUrl('/Aquagrpproddetails');
  }
  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnSplProds() {
    debugger
    this.router.navigateByUrl('/Aquaproducts');
  }

}
