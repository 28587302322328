<app-page-heade></app-page-heade>
<br />
<br />
<div class="container" style="height: 0%;">
  <div class="row">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="container"><b>Bulk Enquiry - Order : </b></div>

    <div class="col-lg-12">
      <p-tabView (onChange)="handleChange($event)">
        <p-tabPanel header=" For Bulk Enquiry">
          <div *ngIf="ShowBulkOredrForm">
            <p-table #dt [columns]="cols" [value]="BulkEnquieryOrderDetails" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'100.5%'}">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter123" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                    <div *ngSwitchCase="'Price'">
                      <input *ngIf="editPost" pInputText type="text" [(ngModel)]="rowData.Price">
                    </div>
                    <div *ngSwitchCase="'Comments'">
                      <input *ngIf="editPost" pInputText type="text" [(ngModel)]="rowData.Comments">
                    </div>
                    <div *ngSwitchCase="'PostedDate'">
                      <a *ngIf="!editPost" (click)="PostComments()">Post Comments </a>	&nbsp;
                      <a *ngIf="editPost" (click)="UpdatePost(rowData)">Update</a>	&nbsp;
                      <a *ngIf="editPost">Cancel</a>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">

                <tr style="text-align:right"></tr>

              </ng-template>
            </p-table>
          </div>

          <div *ngIf="!ShowBulkOredrForm" style="margin-bottom:0.5rem;">
            <input class="bulk_input" style="width: 31%;height: 3rem;padding-left: 0.5rem;" placeholder="Search by CAS No/Catalog No/Chemical Name" [(ngModel)]="SearchItem2" />
            <button class="serach_ c_btn" style="margin-left: 1%;" (click)="GetProducts()">
              <img src="https://img.icons8.com/ios-glyphs/35/000000/google-web-search.png" />
            </button>
            <button class="btn btn-primary" style="margin-left: 1%;border-radius: 5px !important;width: 5% !important;margin-top: 0rem !important;" (click)="ShowCartitem()"><i class="fa fa-cart-plus" style="font-size: 21px;"></i></button>

          </div>
          <div *ngIf="!ShowBulkOredrForm">

            <p-table #dt [columns]="cols3" [value]="GVProducts" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'100.5%'}">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                    <div *ngSwitchCase="'Qty'">
                      <input pInputText type="text" [(ngModel)]="rowData.Qty">
                    </div>
                    <div *ngSwitchCase="'Description'">
                      <input pInputText type="text" [(ngModel)]="rowData.Description">
                    </div>
                    <div *ngSwitchCase="'Add'">
                      <span (click)="AddBulkOrder(rowData)"><i class="glyphicon glyphicon-plus"></i> </span>	&nbsp;
                    </div>
                    <div *ngSwitchCase="'Availability'">
                      <span *ngIf="rowData.Qty > 0; else mtoBlock">Yes</span>
                      <ng-template #mtoBlock>MTO</ng-template>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">

                <tr style="text-align:right"></tr>

              </ng-template>
            </p-table>



          </div>
        </p-tabPanel>
        <p-tabPanel header="For Bulk Order">
          <div *ngIf="ShowBulkOredrForm">
            <p-table #dt [columns]="cols" [value]="BulkEnquieryOrderDetails" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'100.5%'}">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                    <!--<div *ngSwitchCase="'Price'" >
                      <input *ngIf="editPost" pInputText type="text" [(ngModel)]="Price">
                    </div>
                    <div *ngSwitchCase="'Comments'" >
                      <input *ngIf="editPost" pInputText type="text" [(ngModel)]="Comments">
                    </div>
                    <div *ngSwitchCase="'PostedDate'" >
                      <a *ngIf="!editPost" (click)="PostComments()">Post Comments </a>	&nbsp;
                      <a *ngIf="editPost" (click)="UpdatePost(Comments,Price,rowData)">Update</a>	&nbsp;
                      <a *ngIf="editPost">Cancel</a>
                    </div>-->
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">

                <tr style="text-align:right"></tr>

              </ng-template>
            </p-table>
          </div>
          <div *ngIf="!ShowBulkOredrForm">

            <p-table #dt [columns]="cols" [value]="BulkEnquieryOrderDetails" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'100.5%'}">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>

                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">

                <tr style="text-align:right"></tr>

              </ng-template>
            </p-table>



          </div>
        </p-tabPanel>


      </p-tabView>

    </div>
  </div>
</div>


<div class="modal" id="myModal">
  <div class="modal-dialog" style="width: 50% !important;" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Products In your Enquiry Cart</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white"></span></button>
      </div>
      <div class="modal-body">
        <p-table #dt [columns]="cols4" [value]="CartArrya" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'100.5%'}">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
              </div>
              <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
              </div>
            </div>

          </ng-template>
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span>{{col.header}}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr style="cursor:pointer">
              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                <div *ngSwitchCase="'Remove'">
                  <span (click)="RemoveCartItems(rowData)"><i class="glyphicon glyphicon-trash"></i> </span>	&nbsp;
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">

            <tr style="text-align:right"></tr>

          </ng-template>
        </p-table>
        <div class="modal-footer">
          <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">

            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="padding-left: 2.5rem;">
              <button class="btn btn-primary" style="width: 111%;" (click)="SubmitEnquiry()">Submit Enquiry</button>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <button class="btn btn-primary" style="margin-left: 10%;width: 111%;" (click)="CancelSubmit()">Cancel</button>
            </div>
          </div>



      
          

        </div>
      </div>
    </div>
  </div>
</div>


<style>
  body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
    background-color: #d6d6d6 !important;
    border: 1px solid #007ad9 !important;
  }
</style>
