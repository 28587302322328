import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { GeneralService } from '../../Services/general.service';
//import { CosserviceService } from '../../Services/cosservice.service';

import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { map, throttle } from 'rxjs/operators';
import Swal from 'sweetalert2';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';  
import domtoimage from 'dom-to-image';
@Component({
  selector: 'app-coa-details',
  templateUrl: './coa-details.component.html',
  styleUrls: ['./coa-details.component.scss']
})
export class CoaDetailsComponent implements OnInit {
  getprint: boolean;
  COADate: any;
  CoaBatchNo: string;

  spinner: boolean;
  PDFNo: any;
  path: any;
  printcontent: HTMLElement;
  RetestDate: any;
  CoaDetails: any;
  CoaDetails1: any;
  CoaDetails2: any;
  CoaDetails3: any;
  CoaDetails5: any;


  CatalogueNo: any;
  productNAme: any;
  Cas: any;
  BatchNo: any;
  cols: any;
  molecular_weight: any;
  FormulaTxt: any;
  Storage: any;
  ShelfLife: any;
  Date1: any;
  MFGDATE: any;
  LoginDet: any;
  constructor(
    //public userice: CosserviceService,
    public generalService: GeneralService, private rout: ActivatedRoute, public router: Router, public http: HttpClient, public http1: Http) {
    debugger

    this.rout.params.subscribe(params => {
      debugger
      this.CoaBatchNo = params['BatchNo']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
    });



    //this.rout.queryParams.subscribe(params => {
    //debugger
    //  this.CoaBatchNo = params['BatchNo'];
    //  if (this.CoaBatchNo == '', this.CoaBatchNo == null, this.CoaBatchNo == undefined) {
    //    this.CoaBatchNo = localStorage.getItem('BatchNo');
    //  }
    //});
    //this.Date1 = new Date();
    this.CoaDetails = JSON.parse(localStorage.getItem('CoaDetails'));
    this.cols = [
      // { field: 'DisplayOrder', header: 'DisplayOrder', width: '180px' },
      { field: 'Parameter', header: 'Description', width: '180px' },
      { field: 'SpecDetail', header: 'Specification', width: '250px' },
      { field: 'SpecResult', header: 'Result', width: '180px' },
    ]


    if (this.CoaBatchNo == '', this.CoaBatchNo == null, this.CoaBatchNo == undefined) {
      this.CoaBatchNo = localStorage.getItem('BatchNo');
    }
    else {

    }

    localStorage.removeItem('home');
    //this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    //if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
    //  this.router.navigate(['/login']);
    //}

  }

  ngOnInit() {
    debugger
    this.GetCoaDetails();
  }

  GetCoaDetails() {
    debugger
      debugger
      var GetUrl = 'api/Values/GetCoaDetails?BatchNo=' + this.CoaBatchNo;
      this.generalService.GetData(GetUrl).then((data: any) => {
        debugger
        this.CoaDetails1 =data.CoaDetails1;
        this.CoaDetails2 =data.CoaDetails2;
        this.CoaDetails3 =data.CoaDetails3;
        this.CoaDetails5 = data.CoaDetails5;



        this.CatalogueNo = this.CoaDetails1[0].Catalog_no;

        //alert(this.CatalogueNo+"--"+ this.CoaDetails1[0].Catalog_no)

        this.Date1 = this.CoaDetails1[0].TodaysDate;
        this.COADate = this.CoaDetails1[0].COADate;



        this.productNAme = this.CoaDetails2[0].ChemicalName;
        this.Cas = this.CoaDetails2[0].Cas;
        this.BatchNo = this.CoaDetails1[0].BatchNo;
        this.RetestDate = this.CoaDetails5[0].RetestDate;
        this.MFGDATE = this.CoaDetails5[0].COADate;
        //this.FormulaTxt = this.CoaDetails2[0].FormulaTxt;
        //this.molecular_weight = this.CoaDetails2[0].molecular_weight;
        this.Storage = this.CoaDetails2[0].Storage;
        //this.ShelfLife = this.CoaDetails2[0].ShelfLife;
      })

  }




  //Method to download PDF File
  DownLoadPdf(pdf, path) {
    debugger

    //window.open();

    return this.http1.get(path, { responseType: ResponseContentType.Blob }).pipe(map(res => {
      return {
        // filename: 'COA' + "-" + pdf + '.pdf',
        filename: pdf + "-" + 'COA' + '.pdf',
        data: res.blob()
      };

    }))
      .subscribe(res => {
        debugger
        console.log('start download:', res);
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        debugger
        console.log('download error:', JSON.stringify(error));
      }, () => {
        debugger
        console.log('Completed file download.')
      });

  }



  CoaPdf() {
    debugger
    var GetUrl = 'api/Values/CoaPdf?BatchNo=' + this.BatchNo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.path = data.filepath;
      this.PDFNo = data.pdfname1;

      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        debugger
        var url = data.Webservice
        debugger

        this.path = url + this.path;
       window.open(this.path);
       // this.DownLoadPdf(this.PDFNo, this.path);
       // this.downloadFile(this.path);

      });

    })
  }


  //downloadFile(data: Response) {
  //  const blob = new Blob([data], { type: 'pdf' });
  //  const url = window.URL.createObjectURL(blob);
  //  window.open(url);
  //}
 


  Print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  public captureScreen() {
    debugger
    this.getprint = true;
   // var dataa = document.getElementById('contentToConvert', 'contentToConvert1', 'contentToConvert1');

    var dataa = document.querySelectorAll('contentToConvert, contentToConvert1, contentToConvert2');
    //var data1 = document.getElementById('contentToConvert1');
    //var data2 = document.getElementById('contentToConvert2');
    //var data = dataa + data1 + data2;
    var data = document.getElementById('contentToConvert');

    html2canvas(data).then(canvas => {
      debugger
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;



      //var imgWidth = 258;
      //var pageHeight = 350;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      
      
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF
      this.CoaPdf()
    });
  }







  captureScreen1() {
    return new Promise((resolve, reject) => {
      debugger
      var img;
      var newImage;
      var data = document.getElementById('contentToConvert');
      domtoimage.toPng(data, { bgcolor: '#fff' }).then(function (dataUrl) {
        debugger
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;
        img.onload = function () {
          debugger
          var pdfWidth = img.width;
          var pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image
          var doc;

          if (pdfWidth > pdfHeight) {
            doc = new jspdf('l', 'px', [pdfWidth, pdfHeight]);
          }
          else {
            doc = new jspdf('p', 'px', [pdfWidth, pdfHeight]);
          }
          //var width = doc.internal.pageSize.getWidth();
          //var height = doc.internal.pageSize.getHeight();
          doc.addImage(newImage, 'PNG', 10, 10, pdfWidth, pdfHeight);
          
          var pdf = doc.output('blob');
          debugger
          resolve(pdf);
        }, err => {
          reject(err)
        };
      }, err => {
        reject(err)
      });
    });
  }


}






