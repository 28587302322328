<app-page-heade></app-page-heade>

<!--<app-page-heade></app-page-heade>-->


<div class="banner">
  <div class="container">
    <div class="row">
      <h1 class="baneer_heading" style="color:#000;font-weight: bold;letter-spacing: 1px;margin-left: 16rem;margin-top:2.5rem;">  Downloads </h1>
      <br />
      <div>
      </div>
      <div class="arrow-circle-down bounce">
        <p>
          <a class="scroll" href="#booya">

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="50" height="50"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;margin-top: 30vh;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#fff"><path d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>


<!--<div class="container">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-left: 7rem;margin-top: 2rem;">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Desktop Gadget</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Product Catalog</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            Equipment Catalogs
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            ASPL Company BankDetails
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            Holidays list 2019
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            UKAS-ISO 9001-2015
          </a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <div class="pdf_card">
                <div class="pdf_heading">Download Gadget</div>
                <div>
                  <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
                </div>
                <div style="padding-top:0.2rem;">
                  <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog Pricing-GST</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div class="pdf_card">
              <div class="pdf_heading">Catalog 2019-2020</div>
              <div>
                <img src="../../../assets/img/PDF.png" style="width:50%;border-radius:15px;" />
              </div>
              <div style="padding-top:0.2rem;">
                <p><a style="color: #565656;letter-spacing: 1px;" id="ContentPlaceHolder1_lnkprice" class="pdf" href="javascript:__doPostBack('ctl00$ContentPlaceHolder1$lnkprice','')">Download PDF </a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->



<div class="container">
  <div class="row tabls_big" style="margin-left:8rem">
    <div class="col-md-12">
      <div class="vertical-tab" role="tabpanel">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist" style="margin-top:2rem;">
          <!--<li role="presentation"><a href="#Section1" aria-controls="home" role="tab" data-toggle="tab">Avra Desktop Gadget</a></li>-->
          <li role="presentation" class="active"><a href="#Section2" aria-controls="profile" role="tab" data-toggle="tab">Speciality Chemicals Catalog</a></li>
          <li role="presentation"><a href="#Section3" aria-controls="messages" role="tab" data-toggle="tab">Biologics Catalog</a></li>
          <li role="presentation"><a href="#Section7" aria-controls="profile" role="tab" data-toggle="tab">Aqua Culture Catalog </a></li>
          <li role="presentation"><a href="#Section4" aria-controls="home" role="tab" data-toggle="tab">ASPL Company BankDetails</a></li>
          <li role="presentation"><a href="#Section5" aria-controls="profile" role="tab" data-toggle="tab">Holidays list </a></li>

          <!--<li role="presentation"><a href="#Section6" aria-controls="messages" role="tab" data-toggle="tab">UKAS-ISO 9001-2015</a></li>-->
        </ul>
        <!-- Tab panes -->
        <div class="tab-content tabs">
          <div role="tabpanel" class="tab-pane  in" id="Section1">
            <h3>Avra Desktop Gadget</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper, magna a ultricies volutpat, mi eros viverra massa, vitae consequat nisi justo in tortor. Proin accumsan felis ac felis dapibus, non iaculis mi varius, mi eros viverra massa.</p>
          </div>
          <div role="tabpanel" class="tab-pane  in active" id="Section2">







            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="fine_name">Speciality Chemicals <br /> Catalog Pricing - GST</div>
                <a href="../../../assets/PDF/AvraPriceList.pdf" target="_blank">  <img src="../../../assets/img/2019-20.png" style="width:80%;" /></a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="fine_name">Speciality Chemicals <br /> Catalog </div>
                <a href="../../../assets/PDF/AvraCatalog.pdf" target="_blank">   <img src="../../../assets/img/list_2019-20.png" style="width:80%;" /></a>
              </div>
              <!--<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <img src="../../../assets/img/2019-20.png" style="width:100%;" />
      </div>-->
            </div>
          </div>
          <div role="tabpanel" class="tab-pane  in" id="Section3">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div class="fine_name">Biochemicals Catalog</div>
                <a href="../../../assets/PDF/Avra_LifeBiologics_Catalog.pdf" target="_blank">  <img src="../../../assets/img/Avra_b.png" style="width:80%;" /></a>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane  in" id="Section4">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div class="fine_name">ASPL Bank Details</div>
                <a href="../../../assets/PDF/ASPL Bank Details.pdf" target="_blank">  <img src="../../../assets/img/ASPL_Bank_Details.png" style="width:70%;" /></a>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane  in" id="Section5">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="fine_name">Holidays List </div>
                <a href="../../../assets/PDF/Holiday list 2019-ASPL.PDF" target="_blank">  <img src="../../../assets/img/Holidays_list_2019.png" style="width:80%;" /></a>
              </div>
              <!--<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <a href="../../../assets/PDF/Catalog-2019-2020.pdf" target="_blank">   <img src="../../../assets/img/list_2019-20.png" style="width:100%;" /></a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <img src="../../../assets/img/2019-20.png" style="width:100%;" />
      </div>-->
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="Section6">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <a href="../../../assets/PDF/f205 use of certificates and certification and accreditation mark.pdf" target="_blank">  <img src="../../../assets/img/Certificates_and_Certification.png" style="width:70%;" /></a>
              </div>
              <!--<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <a href="../../../assets/PDF/Catalog-2019-2020.pdf" target="_blank">   <img src="../../../assets/img/list_2019-20.png" style="width:100%;" /></a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <img src="../../../assets/img/2019-20.png" style="width:100%;" />
      </div>-->
            </div>
          </div>
          <div role="tabpanel" class="tab-pane  in" id="Section7">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div class="fine_name">Aqua Culture Catalog - English</div>
                <a href="../../../assets/PDF/AS_AC_Catalog_Eng.pdf" target="_blank">  <img src="../../../assets/img/avra_catalog.JPG" style="width:70%;" /></a>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div class="fine_name">Aqua Culture Catalog - Telugu</div>
                <a href="../../../assets/PDF/AS_AC_Catalog_Tlg.pdf" target="_blank">  <img src="../../../assets/img/avratelugu.JPG" style="width:70%;" /></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>











<style>



  .pdf_heading {
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1rem;
  }

  .pdf_card {
    border: 1px solid #c1c1c1;
    text-align: center;
    padding: 4%;
    border-radius: 5px;
    height: 15rem;
    margin: 0.3rem;
  }

  .card_heading {
    letter-spacing: 1px;
    text-align: center;
    line-height: 1.5;
    font-weight: 600;
  }

  .blog-content {
    padding: 30px;
  }

    .blog-content h5 a {
      font-size: 18px;
      color: #232d37;
    }

  .single-blog-area {
    border: 1px solid #ebebeb;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
  }


    .single-blog-area:hover {
      background-color: #fff;
      box-shadow: 2px 8px 40px rgba(0,0,0,.1);
    }

  .single-blog-area {
    border: 1px solid #ebebeb;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  .more_btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
    padding: 10px 25px;
    font-size: 18px;
    line-height: 1.42857143;
    border-radius: 0px;
    user-select: none;
    letter-spacing: 2px;
    margin-top: 3%;
    outline: none;
  }


  .btn-success {
    color: #000;
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: #000000;
  }

    .btn-success:hover {
      color: #fff !important;
      background-color: #d7127b !important;
      border-color: #d7127b;
      transition: background 0.8s ease;
    }

  .main_o {
    color: #3d3d3d;
    font-weight: bold;
  }

  .card {
    background-color: #ffffff;
    border: 1px solid #c1c1c1;
    box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.24), 2px 4px 10px 0 rgba(0, 34, 51, 0.14);
    border-radius: 0.15rem;
    border-radius: 5px;
    padding: 22px;
    line-height: 28px;
    font-size: 15px;
    height: 150px;
  }

  .banner {
    height: 42vh;
    background-image: url("https://images.unsplash.com/photo-1489844097929-c8d5b91c456e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1504&q=80");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pad_1 {
    /* background-image: url(../../assets/pad_1.png); */
    padding: 16.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    letter-spacing: 0.5px;
    line-height: 2;
    background-color: #ffcc00;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }

  .pad_2 {
    /* background-image: url(../../assets/pad_2.png); */
    padding: 13.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    letter-spacing: 0.5px;
    height: 30.2vh;
    line-height: 2;
    background-color: #92d050;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }


  .banner1 {
    /* background-image: url("../../assets/slide6.jpg"); */
    /* height: 100vh; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner2 {
    /* background-image: url("../../assets/demo.png"); */
    /* height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    background-position: 12px 60px; */
  }

  .banner3 {
    background-image: url("../../assets/v0TKbz.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner9 .row {
    text-align: center;
    margin-top: 25vh
  }

  .banner2 .row {
    text-align: center;
    margin-top: 29vh;
    margin-bottom: 12%;
  }

  .banner1 .row {
    text-align: center;
  }

  .banner .row {
    text-align: center;
    margin-top: 18vh
  }

  .banner3 .row {
    text-align: center;
    margin-top: 16vh
  }

  .btn-success {
    color: #000;
    background-color: #ffffff5c;
    border-color: #000000;
  }

    .btn-success:hover {
      color: #000;
      background-color: #ffffff5c;
      border-color: #000000;
    }

  .banner h1 {
    color: white;
  }


  #contact {
    background: #fff;
    padding: 15px !important;
    margin: 0px 0 !important;
  }



  .arrow-circle-down {
    display: block;
    height: 40px;
    margin: 16px 0;
    /*fill: #fff;*/
  }

  .bounce {
    -webkit-animation: bounce 2s;
    animation: bounce 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @-webkit-keyframes bounce {
    0%, 25%, 50%, 75%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    40% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }

    60% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
    }
  }

  @keyframes bounce {
    0%, 25%, 50%, 75%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    40% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }

    60% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
    }
  }




  .btn-danger {
    color: #fff;
    background-color: #00000000;
    border-color: #ffffff;
  }

    .btn-danger:hover {
      color: #fff;
      background-color: #00000000;
      border-color: #ffffff;
    }
</style>

<style>
  .list {
    list-style: none;
    line-height: 25px;
  }

  .list2 {
    list-style: none;
    line-height: 25px;
  }

  .material-icons {
    padding-top: 60px;
    font-size: 60px;
    color: #d60976;
  }

  .Registered {
    border: 2px solid#F9F9F9;
    margin-bottom: 10px;
    outline: #e1e8ed 1px solid;
    padding-top: 5px;
    padding-bottom: 10px;
    box-shadow: 2px 2px 8px 1px #ccc;
  }

  .Registered-Office {
    font-size: 20px;
    padding-left: 40px;
    font-weight: bold;
  }

  .Avra-Synthesis {
    padding-left: 18px;
    font-size: 14px;
  }

  .Factory-Address {
    border: 2px solid#F9F9F9;
    margin-bottom: 10px;
    outline: #e1e8ed 1px solid;
    box-shadow: 2px 2px 8px 1px #ccc;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .Factory {
    font-size: 20px;
    padding-left: 40px;
    font-weight: bold;
  }

  .Avra-Synthesis-1 {
    padding-left: 18px;
    font-size: 14px;
  }


  #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] {
    font: 400 12px/16px "Open Sans", Helvetica, Arial, sans-serif;
  }

  #contact {
    background: #fff;
    padding: 25px;
    margin: 50px 0;
  }

    #contact h3 {
      color: #d7127b;
      display: block;
      font-size: 30px;
      font-weight: 400;
    }

    #contact h4 {
      margin: 5px 0 15px;
      display: block;
      font-size: 13px;
    }

  fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
  }

  #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    width: 100%;
    border: 1px solid #CCC;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
  }

    #contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
      -webkit-transition: border-color 0.3s ease-in-out;
      -moz-transition: border-color 0.3s ease-in-out;
      transition: border-color 0.3s ease-in-out;
      border: 1px solid #AAA;
    }

  #contact textarea {
    height: 100px;
    max-width: 100%;
    resize: none;
  }

  #contact button[type="submit"] {
    cursor: pointer;
    width: 100%;
    border: none;
    background: grey;
    color: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    font-size: 15px;
  }

    #contact button[type="submit"]:hover {
      background: grey;
      -webkit-transition: background 0.3s ease-in-out;
      -moz-transition: background 0.3s ease-in-out;
      transition: background-color 0.3s ease-in-out;
    }

    #contact button[type="submit"]:active {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    }

  #contact input:focus, #contact textarea:focus {
    outline: 0;
    border: 1px solid #999;
  }

  ::-webkit-input-placeholder {
    color: #888;
  }

  :-moz-placeholder {
    color: #888;
  }

  ::-moz-placeholder {
    color: #888;
  }

  :-ms-input-placeholder {
    color: #888;
  }
</style>


