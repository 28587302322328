import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url: any;
  urlname: any;

  constructor(public http: HttpClient) { }

  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: 'Ok'
    })
  }
  ShowAlert1(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: 'Print',
       //confirmButtonText1: 'Cancel'
    })
  }
  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        
        this.urlname = data.Webservice;
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData(url, PostParam) {
    
    return new Promise((resolve, reject) => {
      
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
    
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  GetDataWithFlag(url, PostParam) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.get(url, PostParam).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }

}
