import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-aquaproducts',
  templateUrl: './aquaproducts.component.html',
  styleUrls: ['./aquaproducts.component.scss']
})
export class AquaproductsComponent implements OnInit {

 spinner: boolean;
  BatchNo: any;
  WebStock: any;
  HomeUrl: any;

  CatalogNo               :any;
  Cas                     :any;
  ChemicalName            :any;
  molecular_structure_path:any;
  molecular_formula_path  :any;
  molecular_weight        :any;
  PhysicalState           :any;
  boiling_point           :any;
  melting_point           :any;
  Rdata                   :any;
  Sdata                   :any;
  Density                 :any;
  FlashPoint              :any;
  RefractiveIndex         :any;
  Storage: any;
  GroupProductsSpecDetails: any;
  GroupProductsDetails: any;
  ShowProductDetails: boolean = false;
  GroupName: any;
  GroupProducts: any;
  ShowGroupProduct: boolean = false;
  ShowGroups: boolean = false;
  SplGroups: any;
 Color: string;
  Icon: string;
  constructor(public generalService: GeneralService, public router: Router,
    public http: HttpClient, public Location: Location) {
    localStorage.removeItem('home');

    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      //debugger
      this.HomeUrl = data.Webservice;
      //debugger

    })
  }

  ngOnInit(): void {
    this.ShowGroups = true;
    this.GetSplGroups();
  }

  //Getting Speciality Chemicals//
  GetSplGroups() {
  this.spinner = true;
  const GetUrl = 'api/Values/AVAR2020_GetAquaGroups_SubGroups';

  this.generalService.GetData(GetUrl).then((data: any) => {
    this.SplGroups = [];

    // Initialize arrays to store icons and colors for each group
 
    for (let i = 0; i < data.length; i++) {
        debugger
        if (i == 0) {
          this.Icon = '../../../assets/aqua/aquaculture.png';
          this.Color = '#991d62';

        }
        else if (i == 1) {
          this.Icon = '../../assets/aqua/fish.png';
          this.Color = '#e6a025';

        }
        else if (i == 2) {
          this.Icon = '../../assets/aqua/fish (1).png';
          this.Color = '#8eb629';

        }
        else if (i == 3) {
          this.Icon = '../../assets/aqua/fish (2).png';
          this.Color = '#3592c1';

        }
        else if (i == 4) {
          this.Icon = '../../assets/aqua/marine (1).png';
          this.Color = '#672862';

        }
 else if (i == 5) {
          this.Icon = '../../assets/aqua/marine.png';
          this.Color = '#672862';

        }
 else if (i == 6) {
          this.Icon = '../../assets/aqua/pond.png';
          this.Color = '#672862';

        }
 else if (i == 7) {
          this.Icon = '../../assets/aqua/sea-life.png';
          this.Color = '#672862';

        }
 else if (i == 8) {
          this.Icon = '../../assets/aquaculture.png';
          this.Color = '#672862';

        }
 else if (i == 9) {
          this.Icon = '../../assets/aquasupp.png';
          this.Color = '#672862';

        }
this.SplGroups.push({ igmCode: data[i].igmCode, GroupName: data[i].GroupName, Icon: this.Icon, Color: this.Color })
}
    this.spinner = false;
  }).catch(() => {
    this.spinner = false; // Ensure spinner is stopped in case of error
  });
}


  


  ClickOnSplGroupProds(GroupName) {
    debugger
    localStorage.removeItem('GroupName')
    localStorage.setItem('GroupName', GroupName)
    this.router.navigateByUrl('/Aquagrpprods');
  }

  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnSplProds() {
    debugger
    //this.router.navigateByUrl('/SplChem');
    this.router.navigateByUrl('/ProductsTree', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/SplChem']);
    });
  }

}
