import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;

@Component({
  selector: 'app-agroup-data',
  templateUrl: './agroup-data.component.html',
  styleUrls: ['./agroup-data.component.scss']
})
export class AgroupDataComponent implements OnInit {
  spinner: boolean;
  igmDesc: any;
  SubGroupName: any;
  isgmDesc: any;
  AGroupData: any;

  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {
    debugger
   
    this.SubGroupName = localStorage.getItem('SubGroupName')
    this.isgmDesc = localStorage.getItem('isgmDesc')
    this.igmDesc = localStorage.getItem('igmDesc')
    localStorage.removeItem('home');


    
  }

  ngOnInit(): void {
    this.spinner = true;
    this.AGroupData = JSON.parse(localStorage.getItem('AGroupData'));
    this.spinner = false;

  }

  ClickOnAgrpDesc(item) {
    debugger
    localStorage.removeItem('Grps')
    localStorage.setItem('Grps', 'FromAgroups')
    localStorage.removeItem('AGroupCode')
    localStorage.setItem('AGroupCode', item.AGroupCode)
    localStorage.removeItem('AGroupDesc')
    localStorage.setItem('AGroupDesc', item.AGroupDesc)
    this.router.navigateByUrl('/BioGrpProds');
  }


  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnBiologics() {
    debugger
    this.router.navigateByUrl('/Biologics');
  }
  ClickOnHeadGroup() {
    debugger
    this.router.navigateByUrl('/BioSubGrps');
  }
}
