
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;

@Component({
  selector: 'app-bulk-order-enquiry',
  templateUrl: './bulk-order-enquiry.component.html',
  styleUrls: ['./bulk-order-enquiry.component.scss']
})
export class BulkOrderEnquiryComponent implements OnInit {
  HomeUrl: any;
  LoginDetails: any;
  ShowLableEnquriy: any;
  BulkEnquieryOrderDetails: any;
  cols: any = [];
  Comments: any;
  Price: any;
  SearchItem: any = '';
  SearchItem2: any = '';
  GVProducts: any;
  editPost: boolean;
  hideEdits: boolean;
  ShowBulkOredrForm: boolean;
  cols3: any = [];
  showcarttable: boolean;
  Qty: string;
  Description: string;
  cols4: any = [];
  CartArrya: any = [];
  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {
    debugger

    this.SearchItem = "";
    this.SearchItem2 = "";

    localStorage.removeItem('home');

    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols3 = [

   
      { field: 'ItemCode', header: 'Catlog No', width: '120px' },
      { field: 'CAS', header: 'Cas', width: '80px' },
      { field: 'ItemName', header: 'Chemical Name', width: '120px' },
      { field: 'Availability', header: 'Availability', width: '120px' },
      { field: 'Qty', header: 'Quantity', width: '80px' },
      { field: 'Description', header: 'Description', width: '120px' },
      { field: 'Add', header: '', width: '40px' },
    ]

    this.cols4 = [
      { field: 'CatalogNo', header: 'Catlog No', width: '60px' },
      { field: 'Quantity', header: 'Quantity', width: '60px' },
      { field: 'Description', header: 'Description', width: '150px' },
      { field: 'Remove', header: '', width: '20px' },
    ]


    this.cols = [
      //{ field: 'ENQ_ID', header: 'ENQ_ID No', width: '100px' },
      { field: 'ContPerson', header: 'Company Name', width: '120px' },
      { field: 'Catalog_No', header: 'Catalog_No', width: '80px' },
      { field: 'ChemicalName', header: 'Chemical Name', width: '120px' },
      { field: 'Quantity', header: 'Quantity', width: '80px' },
      { field: 'Description', header: 'Description', width: '120px' },

      { field: 'EnquiryDate', header: 'Enquiry Date', width: '100px' },
      { field: 'Price', header: 'Price', width: '80px' },
      { field: 'Comments', header: 'Comments', width: '130px' },
      { field: 'PostedDate', header: 'Posted Date', width: '120px' },

    ]


  }

  ngOnInit(): void {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
   //   this.ShowBulkOredrForm = true;
      this.GetProducts();
      this.ReplyForBulkOrder();
      this.setAvailabilityBasedOnQty();
      if (this.LoginDetails[0].UserTypeId == "5" || this.LoginDetails[0].UserTypeId == "6") {
        this.ShowBulkOredrForm = true;
        this.ReplyForBulkOrder();

      }
      else {
        this.GetProducts();
      }
    });
  }

  handleChange(id) {
    debugger;
    this.SearchItem2 = '';
    if (id.index == 0) {
      this.ReplyForBulkOrder();
    }
    else if (id.index == 1) {
      this.PreviousBulkOrderHistory();
    }
  }

  Cols2() {
    this.cols = [
      { field: 'Catalog_No', header: 'Catalog_No', width: '60px' },
      { field: 'ChemicalName', header: 'Chemical Name', width: '120px' },
      { field: 'Quantity', header: 'Quantity', width: '60px' },
      { field: 'Description', header: 'Description', width: '120px' },

      { field: 'Price', header: 'Price', width: '100px' },
      { field: 'Comments', header: 'Comments', width: '120px' },
      { field: 'PostedDate', header: 'Posted Date', width: '120px' },

    ]
  }

  loadPage() {
    if (this.LoginDetails != "" || this.LoginDetails != null) {
      this.PreviousBulkOrderHistory();
      if (this.LoginDetails[0].UserTypeId == "5" || this.LoginDetails[0].UserTypeId == "6") {
        this.ShowLableEnquriy = true;

      }
    }
  }

  // ReplyForBulkOrder() {
  //   debugger;
  //   if (this.LoginDetails[0].UserTypeId == "5") {
  //     var UploadFile = new FormData();
  //     UploadFile.append("PersonID", this.LoginDetails[0].ID);
  //     UploadFile.append("flag1", "1");
  //     UploadFile.append("flag", "1");
  //     UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

  //     var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
  //     this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
  //       debugger
  //       this.BulkEnquieryOrderDetails = data;
  //     });
  //   }
  //   else if (this.LoginDetails[0].UserTypeId == "6") {
  //     debugger;
  //     var UploadFile = new FormData();
  //     UploadFile.append("PersonID", this.LoginDetails[0].ID);
  //     UploadFile.append("flag1", "1");
  //     UploadFile.append("flag", "2");
  //     UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

  //     var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
  //     this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
  //       debugger
  //       this.BulkEnquieryOrderDetails = data;
  //     });
  //   }
  //else  {
  //     debugger;
  //     var UploadFile = new FormData();
  //     UploadFile.append("PersonID", "4450");
  //     UploadFile.append("flag1", "1");
  //     UploadFile.append("flag", "2");
  //     UploadFile.append("OrderTo", "1");

  //     var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
  //     this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
  //       debugger
  //       this.BulkEnquieryOrderDetails = data;
  //     });
  //   }
  // }
// Assuming BulkEnquieryOrderDetails is an array of objects
  setAvailabilityBasedOnQty() {
    debugger
    // Assuming BulkEnquieryOrderDetails is an array of objects
    this.BulkEnquieryOrderDetails.forEach(item => {
      // Add a new property 'Availability' based on the value of 'Qty'
      item.Availability = item.Qty > 0 ? 'Yes' : 'MTO';
    });
  }


  ReplyForBulkOrder() {
    debugger;

    if (this.LoginDetails && this.LoginDetails.length > 0) {
      if (this.LoginDetails[0].UserTypeId == "5") {
        var UploadFile = new FormData();
        UploadFile.append("PersonID", this.LoginDetails[0].ID);
        UploadFile.append("flag1", "1");
        UploadFile.append("flag", "1");
        UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

        var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
        this.generalService.PostData(GetUrl, UploadFile).then((data) => {
          debugger;
          this.BulkEnquieryOrderDetails = data;
          this.setAvailabilityBasedOnQty();
        });
      } else if (this.LoginDetails[0].UserTypeId == "6") {
        var UploadFile = new FormData();
        UploadFile.append("PersonID", this.LoginDetails[0].ID);
        UploadFile.append("flag1", "1");
        UploadFile.append("flag", "2");
        UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

        var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
        this.generalService.PostData(GetUrl, UploadFile).then((data) => {
          debugger;
          this.BulkEnquieryOrderDetails = data;
          this.setAvailabilityBasedOnQty();
        });
      }
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("PersonID", "4450");
      UploadFile.append("flag1", "1");
      UploadFile.append("flag", "1");
      UploadFile.append("OrderTo", "1");

      var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
      this.generalService.PostData(GetUrl, UploadFile).then((data) => {
        debugger;
        this.BulkEnquieryOrderDetails = data;
        this.setAvailabilityBasedOnQty();
      });
    }
  }
  PreviousBulkOrderHistory() {
    debugger;
    if (this.LoginDetails[0].UserTypeId == "5") {
      var UploadFile = new FormData();
      UploadFile.append("PersonID", this.LoginDetails[0].ID);
      UploadFile.append("flag1", "2");
      UploadFile.append("flag", "1");
      UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

      var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
      this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        debugger
        this.BulkEnquieryOrderDetails = "";
        this.BulkEnquieryOrderDetails = data;
        this.Cols2();
      });
    }
    else if (this.LoginDetails[0].UserTypeId == "6") {
      var UploadFile = new FormData();
      UploadFile.append("PersonID", this.LoginDetails[0].ID);
      UploadFile.append("flag1", "2");
      UploadFile.append("flag", "2");
      UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

      var GetUrl = '/api/Values/getBulkOrderEnquiriesExecutive';
      this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        debugger
        this.BulkEnquieryOrderDetails = "";
        this.BulkEnquieryOrderDetails = data;
        this.Cols2();
      });
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("PersonID", this.LoginDetails[0].ID);
      UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);
      var GetUrl = '/api/Values/getPreviousBulkOrderEnquiries';
      this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        debugger
        this.BulkEnquieryOrderDetails = "";
        this.BulkEnquieryOrderDetails = data;
        this.Cols2();

      });
    }

  }


  GetProducts() {
    this.ShowBulkOredrForm = false;
    debugger
    if (this.LoginDetails && this.LoginDetails.length > 0) {
      if (this.SearchItem2 != "") {
        var UploadFile = new FormData();
        UploadFile.append("SearchProduct", this.SearchItem2);
        if (this.LoginDetails[0].WareHouseID == "1" || this.LoginDetails[0].ID == "") {
          var GetUrl = '/api/Values/GetWebStockItemMstSearch';
          this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
        else if (this.LoginDetails[0].WareHouseID == "2") {
          var GetUrl = '/api/Values/GetWebStockBangItemMstSearch';
          this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }

        else if (this.LoginDetails[0].WareHouseID == "3") {
          var GetUrl = '/api/Values/GetWebStockBangHydItemMstSearch';
          this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
        else if (this.LoginDetails[0].WareHouseID == "4") {
          var GetUrl = '/api/Values/GetWebStockAhmedabadItemMstSearch';
          this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
        var GetUrl = '/api/Values/GetWebStockItemMstSearch';
        this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
          debugger
          this.GVProducts = data;
        });

      }
      else {
        var UploadFile2 = new FormData();
        if (this.LoginDetails[0].WareHouseID == "1" || this.LoginDetails[0].ID == "") {
          var GetUrl = '/api/Values/GetWebStockItemMst';
          this.generalService.PostData(GetUrl, UploadFile2).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
        else if (this.LoginDetails[0].WareHouseID == "2") {
          var GetUrl = '/api/Values/GetWebStockBangItemMst';
          this.generalService.PostData(GetUrl, UploadFile2).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }

        else if (this.LoginDetails[0].WareHouseID == "3") {
          var GetUrl = '/api/Values/GetWebStockBothItemMst';
          this.generalService.PostData(GetUrl, UploadFile2).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
        else if (this.LoginDetails[0].WareHouseID == "4") {
          var GetUrl = '/api/Values/GetWebStockAhmadabadItemMst';
          this.generalService.PostData(GetUrl, UploadFile2).then((data: any) => {
            debugger
            this.GVProducts = data;
          });
        }
      }

    }

    var UploadFile2 = new FormData();
    var GetUrl = '/api/Values/GetWebStockItemMst';
    this.generalService.PostData(GetUrl, UploadFile2).then((data: any) => {
      debugger
      this.GVProducts = data;
    });

  }


  PostComments() {
    if (this.LoginDetails[0].UserTypeId == "4" || this.LoginDetails[0].UserTypeId == "5" || this.LoginDetails[0].UserTypeId == "6") {
      this.editPost = true;
    }
  }

  UpdatePost(rowData) {
    debugger
    if (rowData.Comments != '') {
      var UploadFile = new FormData();
      UploadFile.append("EnquiryID", rowData.ENQ_ID);
      UploadFile.append("PersonID", this.LoginDetails[0].ID);
      UploadFile.append("Price", rowData.Price);
      UploadFile.append("Comments", rowData.Comments);
      UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);
      var GetUrl = '/api/Values/InsertBulkEnquiryComments';
      this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        debugger
        if (data != null) {
          this.generalService.ShowAlert('SUCCESS', 'Comments posted Successfully !', 'success');
          this.SendPostComentMail(JSON.stringify(data), this.LoginDetails[0].EMailID);
          this.PreviousBulkOrderHistory();
          this.ReplyForBulkOrder();
          rowData.Comments = ''
          rowData.Price == ''

          const index: number = this.BulkEnquieryOrderDetails.indexOf(rowData.Sno);
          this.CartArrya.splice(index, 1)
          this.BulkEnquieryOrderDetails.slipce()

        }
      });
    }
    else if (rowData.Comments == '') {
      alert('Please Enter Comment !');
    }
    else if (rowData.Price == '') {
      alert('Please Enter Price');
    }
  }
  SendPostComentMail(data, EMailID) {
    debugger
    var UploadFile = new FormData();
    //UploadFile.append("data", data);
    //UploadFile.append("EMailID", EMailID);
    //UploadFile.append("UserTypeId",this.LoginDetails[0].UserTypeId)
    //UploadFile.append("ContPerson", this.LoginDetails[0].ContPerson);
    //UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);

    if (this.LoginDetails[0].OrderTo == 1) //Commented on 24 oct
    {
      this.LoginDetails[0].FromAddress = "info@avrasynthesis.com";
    }
    else if (this.LoginDetails[0].OrderTo == 2) {
      this.LoginDetails[0].FromAddress = "bangaloreinfo@avrasynthesis.com";

    }
    else {
      this.LoginDetails[0].FromAddress = "info@avrasynthesis.com";
    }
    UploadFile.append('ProdDet', JSON.stringify(data));
    UploadFile.append('UserDet', JSON.stringify(this.LoginDetails));
    UploadFile.append('OrderTo', this.LoginDetails[0].OrderTo);

    var Url = '/api/Admin/SendMailPostComment';


    //var GetUrl = '/api/Mail/SendMailPostComment';
    this.generalService.PostData(Url, UploadFile).then((data: any) => {
      debugger
    });
  }



  /////       INsert Bulk Order Functionality ////////
  AddBulkOrder(rowData) {
    debugger;
  if (!this.LoginDetails || this.LoginDetails.length === 0) {
    // User is not logged in, handle the scenario accordingly
    this.generalService.ShowAlert('ERROR', 'Please login to continue!', 'error');
  this.router.navigate(['/login']);
    return;
  }
    if (rowData.Qty == '' || rowData.Qty == null || rowData.Qty == undefined) {
      this.generalService.ShowAlert('ERROR', 'Please Enter Quantity !', 'error');

    }
    else if (rowData.Description == '' || rowData.Description == null || rowData.Description == undefined) {
      this.generalService.ShowAlert('ERROR', 'Please Enter Description  !', 'error');
    }
    else {
      var length
      if (this.CartArrya != null) {
        if (this.CartArrya.length > 0) {
          length = this.CartArrya.length + 1;
        }
        else {
          length = 1;
        }
      }
      this.CartArrya.push({
        Sno: length,
        Quantity: rowData.Qty, PersonID: this.LoginDetails[0].ID, CatalogNo: rowData.ItemCode,
        ChemicalName: rowData.ItemName, Description: rowData.Description,
        Price: null,
        Comments: "",
      });

      if (this.CartArrya != null) {
        if (this.CartArrya.length > 0) {
          this.showcarttable = true;
          this.generalService.ShowAlert('SUCCESS', 'Product Added in Cart Successfully !', 'success');

        }
      }
      rowData.Qty = '';
      rowData.Description = '';

    }
  }

  RemoveCartItems(rowData) {
    debugger
    const index: number = this.CartArrya.indexOf(rowData.Sno);
    this.CartArrya.splice(index, 1)
  }

  ShowCartitem() {
    debugger
    $('#myModal').modal('show');
  }

  SubmitEnquiry() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("xmlparam", JSON.stringify(this.CartArrya));
    UploadFile.append("OrderTo", this.LoginDetails[0].OrderTo);
    var GetUrl = '/api/Values/InsertBulkEnquiryOrders';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.CartArrya = [];
        this.generalService.ShowAlert('SUCCESS', 'Order placed successfully!', 'success');
        debugger
        $('#myModal').modal('hide');
        this.SendPostComentMail((data), this.LoginDetails[0].EMailID);

      }
    });
  }

  CancelSubmit() {
    debugger
    $('#myModal').modal('hide');
  }
  /////       INsert Bulk Order Functionality ////////
}
