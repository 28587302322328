<!--<app-page-heade></app-page-heade>-->

<app-page-heade></app-page-heade>

<br />
<br />
<br />
<br />
<br />
<br />
<head>
  <meta name="viewport" content="width=device-width,height=device-height,initial-scale=1.0">
</head>
<body class="ux-app">


  <section class="promo">
    <div> Items in your Cart..! </div>
  </section><br /><br />
  <!--<div class="container">

    <div class="cart">
      <div class="row">
        <div class="col-lg-8">
          <div class="basket" *ngFor="let item of CartData">
            <div class="product ux-card">

              <div class="row" style="width:125% !important">
                <div class="col-lg-6">
                  <table cellspacing="0" style="width:100%;border-collapse:collapse;margin-top: 0%;margin-bottom: 3%;line-height:2;letter-spacing:0.5px">
                    <tbody>

                      <tr align="center">
                        <td align="left" class="tds"> <strong> Item Code </strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.ItemCode}}</td>

                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> Item Name </strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.ChemicalName}}</td>
                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> CAS NO </strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.CASNo}}</td>
                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> Packsize</strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.Packsize}}</td>
                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> Packcode </strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.Packcode}}</td>
                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> GST% </strong></td>
                        <td></td>
                        <td align="left" class="tds1">{{item.GSTTAX}}</td>
                      </tr>
                      <tr align="center">
                        <td align="left" class="tds"> <strong> Quantity </strong></td>
                        <td></td>
                        <td align="left">
                          <button class="dec" (click)="IncDecQty(item,-1)">-</button>&nbsp;
                          <input id="qty" disabled value="{{item.Quantity}}" />&nbsp;
                          <button class="inc" (click)="IncDecQty(item,+1)">+</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-6">
                  <table cellspacing="0" style="width:70%;border-collapse:collapse;margin-top: 0%;margin-bottom: 3%;">
                    <tbody>

                      <tr align="center">
                        <td align="right" class="tds"><b>Unit Price</b></td>
                        <td align="right"><b class="fa fa-inr" style="font-weight: bold;">&nbsp;{{item.UnitPrice | number:'1.2-2'}}</b></td>
                      </tr>
                      <tr align="center">
                        <td align="right" class="tds"><b>Sub Total</b></td>
                        <td align="right"><b class="fa fa-inr" style="font-weight: bold;">&nbsp;{{item.Price | number:'1.2-2'}}</b></td>
                      </tr>
                      <tr align="center">
                        <td align="right" class="tds"><b>GST</b></td>
                        <td align="right"><b class="fa fa-inr" style="font-weight: bold;">&nbsp;{{item.IndiProdGst| number:'1.2-2'}}</b></td>
                      </tr>
                  </table>

                </div>

              </div>


              <div class="row">



                <div class="col-lg-12">
                </div>
                <div style="cursor:pointer;float: right;" (click)="Delete(item.ID)">
                  <button class="btn btn-canvas btn-xs remove" style="color:#fff !important">Remove</button>


                </div>

              </div>




            </div>


          </div>
        </div>


        <div class="col-lg-4" *ngIf="CartLength!=0" style="padding-left:0px;">





          <div class="summary product ux-card" style="padding-right: 27px;">
            <dl class="subtotal">
              <dt>Total</dt>
              <dd><b class="fa fa-inr">&nbsp;{{GrandTotal | number:'1.2-2'}}</b></dd>
              <dt>Total GST</dt>
              <dd><b class="fa fa-inr">&nbsp;{{GstTotal | number:'1.2-2'}}</b></dd>
              <dd> <img style="width: 100%;" src="https://www.avrasynthesis.com/images/ImgPDFLine.jpg"></dd>
              <dt><b>Grand Total</b></dt>
              <dd><b class="fa fa-inr">&nbsp;{{GrandTotal1 | number:'1.2-2'}}</b></dd>
              <dd> <img style="width: 100%;" src="https://www.avrasynthesis.com/images/ImgPDFLine.jpg"></dd>
            </dl>
            <div class="form-group">
              <label for="comment">Comment:</label>
              <textarea class="form-control" rows="2" [(ngModel)]="Comment" id="comment"></textarea>
            </div>
            <dl class="total" (click)="ConfirmPlaceOrder()">
              <dt><b>Place Order</b></dt>
              <dd><b class="fa fa-inr">&nbsp;{{GrandTotal1 | number:'1.2-2'}}</b></dd>
            </dl>
            <br />


            <dl>
              <dt><b style="margin-left: 161px;">(OR)</b></dt>
            </dl>
            <dl class="total" (click)="SendMailToCustomer()">
              <dt><b>Send Quote</b></dt>
            </dl>
            <br />
          </div>
        </div>


      </div>

      <p-toast position="top-left"></p-toast>
    </div>
  </div>-->




<div class="container">
  <div class="row" *ngIf="CartLength!=0">
    <p-table #dt [columns]="cols" [scrollable]="true" [value]="CartData" [responsive]="true" [rows]="12" scrollHeight="200px" [style]="{width:'100%'}">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
            <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
            <!--<p class="CountGridRecords" style="margin-left:-5px !important">{{dt.totalRecords}}</p>-->

          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--  <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">-->
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width:40px">
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width:50%;">Delete</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span>{{col.header}}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>





      <ng-template pTemplate="body" let-rowData let-columns="columns">

        <!--<tr [ngClass]="{'aqua-id-0': rowData.AquaID == 0, 'aqua-id-1': rowData.AquaID == 1}" style="cursor:pointer;">
          <td class="col-btn">
            <i pTooltip="Delete" (click)="Delete(rowData.ID)" class="fa fa-trash" style="color: red;" aria-hidden="true"></i>
          </td>
          <td *ngFor="let col of cols" [ngSwitch]="col.field">
            <span *ngSwitchDefault style="width: 150px; overflow: hidden; display: table-row; text-overflow: ellipsis; cursor: pointer;">
              {{rowData[col.field]}}
            </span>
            <div *ngSwitchCase="'UnitPrice'" style="text-align: right; padding-right: 0.5rem;">
              {{rowData[col.field] | number:'1.2-2'}}
            </div>
            <span *ngSwitchCase="'Quantity'" style="text-align: right;">
              <button class="dec" (click)="IncDecQty(rowData, -1)">-</button>&nbsp;
              <input id="qty" disabled value="{{rowData[col.field]}}" />&nbsp;
              <button class="inc" (click)="IncDecQty(rowData, +1)">+</button>
            </span>
            <div *ngSwitchCase="'GSTTAX'" style="text-align: right; padding-right: 0.5rem;">
              {{rowData[col.field]}}
            </div>
            <div *ngSwitchCase="'price'" style="text-align: right; padding-right: 0.5rem;">
              {{rowData[col.field] | number:'1.2-2'}}
            </div>
            <div *ngSwitchCase="'IndiProdGst'" style="text-align: right; padding-right: 0.5rem;">
              {{rowData[col.field] | number:'1.2-2'}}
            </div>
            <div *ngSwitchCase="'IndTotPrice'" style="text-align: right; padding-right: 0.5rem;">
              {{rowData[col.field] | number:'1.2-2'}}
            </div>
          </td>
        </tr>-->





        <tr [ngClass]="{'aqua-id-0': rowData.AquaID == 0, 'aqua-id-1': rowData.AquaID == 1}" style="cursor:pointer; ">
          <td class="col-btn">
            <i pTooltip="Delete" (click)="Delete(rowData.ID)" class="fa fa-trash" style="color: red;" aria-hidden="true"></i>
          </td>
          <td *ngFor="let col of cols" [ngSwitch]="col.field">
            <span *ngSwitchDefault style="width: 150px; overflow: hidden; display: table-row; text-overflow: ellipsis; cursor: pointer;">{{rowData[col.field]}}</span>
            <div *ngSwitchCase="'UnitPrice'" style="text-align: right; padding-right: 0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <span *ngSwitchCase="'Quantity'" style="text-align: right;">
              <button class="dec" (click)="IncDecQty(rowData, -1)">-</button>&nbsp;
              <input id="qty" disabled value="{{rowData[col.field]}}" />&nbsp;
              <button class="inc" (click)="IncDecQty(rowData, +1)">+</button>
            </span>
            <div *ngSwitchCase="'GSTTAX'" style="text-align: right; padding-right: 0.5rem;">{{rowData[col.field]}}</div>
            <div *ngSwitchCase="'price'" style="text-align: right; padding-right: 0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'IndiProdGst'" style="text-align: right; padding-right: 0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
            <div *ngSwitchCase="'IndTotPrice'" style="text-align: right; padding-right: 0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
          </td>
        </tr>


      </ng-template>

      <ng-template pTemplate="footer">
        <!--<tr>
          <td colspan="10" class="p-text-right">GST Total</td>
          <td>{{GstTotal | number:'1.2-2'}}</td>
        </tr>
        <tr>
          <td colspan="10" class="p-text-right">Sub Total</td>
          <td>{{GrandTotal | number:'1.2-2'}}</td>
        </tr>-->
        <tr style="text-align:right">
          <td colspan="9" style="text-align: right;">Grand Total</td>
          <td>{{GrandTotal1 | number:'1.2-2'}}</td>
        </tr>
        <tr>
          <td colspan="8" style="text-align: right;">
            <!--<textarea placeholder="Comment" style="width: 100%;padding:0.5rem;" rows="2" [(ngModel)]="Comment" id="comment"></textarea>-->
            <div class="form-group" style="text-align: left;padding: 0.5rem;">
              <label for="comment">Comment:</label>
              <textarea [(ngModel)]="Comment" class="form-control" rows="5" id="comment"></textarea>
            </div>
          </td>

          <td>

            <div class="summary" style="width:103%;">
              <dl class="total" (click)="ConfirmPlaceOrder()">
                <dt><b>Order Now</b></dt>
              </dl>
            </div>
          </td>
          <td>
            <div class="summary" style="width:103%;">
              <dl class="total" (click)="SendMailToCustomer()">
                <dt><b>Get Quote</b></dt>
              </dl>
            </div>
          </td>
          <!--<td>
            <div class="summary" style="width:103%;">
              <dl class="total" (click)="SendMailToCustomeraqua()">
                <dt><b>Enquiry</b></dt>
              </dl>
            </div>
          </td>-->

        </tr>
      </ng-template>


    </p-table>
    <br />

  </div>
  <div class="modal" id="myModal2">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Choose Your Preferred State</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>By selecting a facility below, you confirm that all products in your cart will be processed and shipped to the chosen location..</p>
          <form>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="state" id="hyderabad" value="1" (click)="setState(1)">
              <label class="form-check-label" for="hyderabad">
                Hyderabad
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="state" id="bangalore" value="2" (click)="setState(2)">
              <label class="form-check-label" for="bangalore">
                Bangalore
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="state" id="ahmedabad" value="3" (click)="setState(3)">
              <label class="form-check-label" for="ahmedabad">
                Ahmedabad
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="confirmState()">Confirm</button>
        </div>
      </div>
    </div>
  </div>


  <!--<div class="form-group">
    <label for="comment">Comment:</label>
    <textarea class="form-control" rows="2" [(ngModel)]="Comment" id="comment"></textarea>
  </div>-->
</div>















  <div *ngIf="CartLength==0">
    <h2 style="text-align:center;color:grey">Your Cart Is Empty.....</h2>
  </div>
  <!--<div *ngIf="spinner" class="loader"></div>-->


  <div class="loading-indicator" *ngIf="spinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>
  <!--<p-toast position="top-left" key="tl"></p-toast>-->
</body>


<style>
  .remove {
    float: right;
    clear: right;
    width: 121px;
    color: #ffffff;
    border-width: 1px;
    box-shadow: none !important;
    font-size: 12px;
    padding-bottom: 5px !important;
    background-color: red;
    letter-spacing: 0.5px;
    padding: 13%;
    margin-bottom: 22%;
  }
</style>
