<!--<div class="row">
  <div class="col-lg-4">

  </div>
  <div class="col-lg-4" style="    margin-left: -111px;">
    <img src="../../../assets/img/projects/Comingsoon_prev_ui.png" />
  </div>
  <div class="col-lg-4">

  </div>
</div>-->
<!--<app-page-heade></app-page-heade>

<br />
<br />
<br />


<fieldset class="scheduler-border" [formGroup]="Form" *ngIf="!gridview">
  <legend class="scheduler-border" style="text-align:center">{{ButtonText}} Occasion </legend>

  <div class="row">
    <div class="col-lg-2 ">
      <button type="button" (click)="Back()" class="btn btn-danger btn-border-radius waves-effect mb-2"> <i class="material-icons"></i> <span class="icon-name">Back</span></button>

    </div>

  </div>

  <div class=" row" >

    <div class="col-lg-4">
      <div class="form-group">


        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="Name" type="text" placeholder="Delears">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>

        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="PhoneNumber" type="text" placeholder="PhoneNumber">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>


        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="Address" type="text" placeholder="Address">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>


        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="Location" type="text" placeholder="Location">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>


        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="EmailId" type="text" placeholder="Email Id">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>

        <label><b>Dealer's Name<span style="color:red">*</span></b></label>
        <input class="form-control" formControlName="StateCode" type="text" placeholder="State Code">
        <small *ngIf="Form.controls.Occasionname.touched && Form.controls.Occasionname.errors?.required" class="p-error">Occasion Name is required.</small>

      </div>
    </div>

  </div>

  <div class="form-group" style="text-align:right">
    <button type="button" style="margin-top:0px;" (click)="Back()" class="btn btn-danger m-t-15 waves-effect">Cancel</button>&nbsp;
    <button *ngIf="flag==1" type="submit" (click)="AddSource(Form.value,1)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Submit</button>
    <button *ngIf="flag==2" type="submit" (click)="AddSource(Form.value,2)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Update</button>

  </div>

</fieldset>

<div class="row" >
  <ng-template pTemplate="caption">
    <div class="row" *ngIf="gridview">
      <div class="col-lg-3" style="text-align: left;margin-bottom: 0;">
        <i class="fa fa-plus addIcon" (click)="openAddForm()" pTooltip="ADD" tooltipPosition="top" style="font-size:20px;"></i>
        <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
           aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:0.8px;"></i>


      </div>
      <div class="col-lg-9" style="text-align: right;margin-bottom: 0;">
        {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
        <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter"
               (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
      </div>
    </div>
  </ng-template>


</div>

<div>



  <div class="container" style="margin-top: 80px;">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Action</th>
          <th>Date</th>
          <th>Name</th>

          <th>PhoneNumber</th>
          <th>Email Id</th>
          <th>Address</th>


          <th>Location</th>
          <th>State Code</th>-->
<!--<th *ngFor="let col of cols" [pSortableColumn]="col.field">
  {{col.field}}
  <p-sortIcon [field]="col.field"></p-sortIcon>
</th>-->
<!--</tr>
</thead>
<tbody>-->
<!-- Template for body -->
<!--<ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr >
    <td class="border-right">
      <i pTooltip="Edit" (click)="Edit(rowData)" style="cursor:pointer;margin-left:12px;" class="fa fa-pencil-square-o" aria-hidden="true"></i>
      &nbsp;
      <i class="fa fa-trash" (click)="Delete(rowData)" style="float:initial;color: red;cursor:pointer;"></i>
    </td>
    <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.Occasionname }}</td>
    <td style="padding-left: 12px; padding-right: 10px;" class="border-right">{{ rowData.CreatedDate | date:'dd/MM/yyyy  HH:mm' }}</td>
  </tr>
</ng-template>-->
<!-- Loop through dataResult to display rows -->
<!--<tr *ngFor="let rowData of dataResult">
          <td class="col-btn">
            <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="edit(rowData)"></i>
            <i class="fa fa-trash" style="color: red;" (click)="Delete(rowData)"></i>
          </td>
          <td>{{ rowData.CreatedDate | date:'dd/MM/yyyy' }}</td>
          <td>{{ rowData.Name }}</td>
          <td>{{ rowData.PhoneNumber }}</td>
          <td>{{ rowData.EmailId }}</td>
          <td>{{ rowData.Address }}</td>
          <td>{{ rowData.Location }}</td>
          <td>{{ rowData.StateCode }}</td>
        </tr>
      </tbody>

    </table>
  </div>


</div>-->
<app-page-heade></app-page-heade>


<div #dt class="container" style="margin-top:5%;">
  <div class="row">
    <!-- Add a page header component -->

    <br />
    <br />
    <br />

    <!-- Form for adding/editing data -->
    <fieldset class="scheduler-border" [formGroup]="Form" *ngIf="!gridview">
      <legend class="scheduler-border" style="text-align:center">{{ ButtonText }} Dealerslist </legend>

      <div class="row">
        <div class="col-lg-2">
          <button type="button" (click)="cancel()" class="btn btn-danger btn-border-radius waves-effect mb-2">
            <i class="material-icons"></i>
            <span class="icon-name">Back</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label><b>Name<span style="color:red">*</span></b></label>
            <input class="form-control" formControlName="Name" type="text" placeholder="Name">
            <small *ngIf="Form.controls.Name.touched && Form.controls.Name.errors?.required" class="p-error">Name is required.</small>
          </div>
          <div class="form-group">
            <label><b>Phone Number<span style="color:red">*</span></b></label>
            <input class="form-control" formControlName="PhoneNumber" type="tel" maxlength="10" placeholder="Phone Number">
            <small *ngIf="Form.controls.PhoneNumber.touched && Form.controls.PhoneNumber.errors?.required" class="p-error">Phone Number is required.</small>
          </div>
          <div class="form-group">
            <label><b>Email Id<span style="color:red">*</span></b></label>
            <input class="form-control" formControlName="EmailId" type="text" placeholder="Email Id">
            <small *ngIf="Form.controls.EmailId.touched && Form.controls.EmailId.errors?.required" class="p-error">Email Id is required.</small>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label><b>Address<span style="color:red">*</span></b></label>
            <input class="form-control" formControlName="Address" type="text" placeholder="Address">
            <small *ngIf="Form.controls.Address.touched && Form.controls.Address.errors?.required" class="p-error">Address is required.</small>
          </div>
          <div class="form-group">
            <label><b>Location<span style="color:red">*</span></b></label>
            <input class="form-control" formControlName="Location" type="text" placeholder="Location">
            <small *ngIf="Form.controls.Location.touched && Form.controls.Location.errors?.required" class="p-error">Location is required.</small>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label><b>Select state<span style="color:red">*</span></b></label>

              <select formControlName="StateCode">
                <!--<option>{{StateCode}}</option>-->
                <option *ngFor="let item of states" [value]="item.code" placeholder="{{StateCode}}">{{item.name}}, {{item.code}}</option>
              </select>
            </div>
          </div>
          <!--<div class="form-group" >
          <label><b>State Code<span style="color:red">*</span></b></label>
          <input class="form-control" formControlName="StateCode" type="text" placeholder="State Code">
          <small *ngIf="Form.controls.StateCode.touched && Form.controls.StateCode.errors?.required" class="p-error">State Code is required.</small>
        </div>-->
        </div>
      </div>

      <div class="form-group" style="text-align:right">
        <button type="button" style="margin-top:0px;" (click)="cancel()" class="btn btn-danger m-t-15 waves-effect">Cancel</button>&nbsp;
        <button *ngIf="flag==1" type="submit" (click)="AddSource(Form.value,1)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Submit</button>
        <button *ngIf="flag==2" type="submit" (click)="update(Form.value,2)" [disabled]="Form.invalid" class="btn btn-primary m-t-15 waves-effect">Update</button>
      </div>
    </fieldset>

    <!-- Table for displaying data -->
    <!--<p-table #dt *ngIf="gridview" [columns]="cols" [value]="dataResult" [scrollable]="true" [responsive]="true" [rows]="12" [paginator]="true" [style]="{width:'98.7%'}">-->

      <div class="container" style="margin-top: 10px;" *ngIf="gridview">
        <div class="row" style="font-size: large; background-color: #dfeff7 !important; margin-left: 0px; margin-right: 0px;">

          <div class="col-lg-3" (click)="openAddForm()">
            <i class="fa fa-plus addIcon" (click)="openAddForm()" pTooltip="ADD" tooltipPosition="top" style="font-size: 20px; margin-top: 24px;"></i>

            <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
               aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>

          </div>

          <div class="col-lg-9" style="text-align: right;margin-bottom: 0;">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>

            <input placeholder=" Global Filter" [debounce]="1000" (ionClear)="ClearSearch()" (input)="SearchHistory($event, 'contains')"  style="margin-top: 18px; font-size: 11px; width: 42%; "/>
              <!--<i class="fa-solid fa-xmark" (ionClear)="ClearSearch()"></i>-->
            <p>
              <img src="../../../assets/Cancel.png" (click)="ClearSearch()" class="cancel" />
            </p>
          </div>

          <!--<div class="col-lg-9" style="text-align: right;margin-bottom: 0;">
            {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter"
                   (input)="dt.dataResult($any($event.target).value, 'contains')" style="width:auto; margin-top:8px">
          </div>-->
        </div>

        <table class="table table-bordered" #dt>


          <thead style="font-size: 11px; background-color: #dfeff7 !important;">
            <tr>

              <th>

                Action

              </th>
              <th>Date</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email Id</th>
              <th>Address</th>
              <th>Location</th>
              <th>State Code</th>
            </tr>
          </thead>
          <tbody>
            <!-- Template for body -->
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr class="trd">
                <td class="border-right">
                  <i pTooltip="Edit" (click)="Edit(rowData)" style="cursor:pointer;margin-left:12px;" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  &nbsp;
                  <i class="fa fa-trash" (click)="Delete(rowData)" style="float:initial;color: red;cursor:pointer;"></i>
                </td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.CreatedDate | date:'dd/MM/yyyy' }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.Name }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.PhoneNumber }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.EmailId }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.Address }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.Location }}</td>
                <td style="padding-left: 12px; padding-right: 10px;width:273px" class="border-right">{{ rowData.StateCode }}</td>
              </tr>
            </ng-template>

            <!-- Loop through dataResult to display rows -->
            <tr *ngFor="let rowData of dataResult">
              <td class="col-btn">
                <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="edit(rowData)"></i>
                <i class="fa fa-trash" style="color: red; margin-left: 9px;" (click)="Delete(rowData)"></i>
              </td>
              <td>{{ rowData.CreatedDate | date:'dd/MM/yyyy' }}</td>
              <td>{{ rowData.Name }}</td>
              <td>{{ rowData.PhoneNumber }}</td>
              <td>{{ rowData.EmailId }}</td>
              <td>{{ rowData.Address }}</td>
              <td>{{ rowData.Location }}</td>
              <td>{{ rowData.StateCode }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    <!--</p-table>-->

  </div>
</div>

