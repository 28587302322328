import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { MessageService } from 'primeng/api';
declare var $: any;
import Swal from 'sweetalert2';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-customer-reg',
  templateUrl: './customer-reg.component.html',
  styleUrls: ['./customer-reg.component.scss'],
  providers: [MessageService],
})
export class CustomerRegComponent implements OnInit {

  WareId: number;
  WarehouseDDL: any;
  LoginDet: any;
  Edit: boolean;
  RegAry: any[];

  showIndex: any;
  RegDataLength: any;
  table: boolean;
  cols: any;
  RegData: any;
  IsActiveData: any = [];
  ZoneHeadData: any;
  SalesExecutiveData: any;
  SalesManagerData: any;
  SalesCoordinatorData: any;
  UserTypeData: any;
  ShowForm: boolean = true;
  ShowForm1: boolean = true;
  /// @ViewChild('frameTop', { static: true }) _modalbody: ModalDirective;
  UserObj: any = [];
  RegData1: any;
  RegData2: any;
  RegDataLength1: any;
  Orders: any;
  constructor(public generalService: GeneralService, public router: Router,
    private messageService: MessageService) {
    
    this.cols = [

      { field: 'Companyname', header: 'Company Name', width: '180px' },
      { field: 'ContactPerson', header: 'Contact Person name', width: '180px' },
      { field: 'Phone1', header: 'Mobile 1', width: '180px' },
      { field: 'Phone2', header: 'Mobile 2', width: '180px' },
      { field: 'Email', header: 'Email', width: '180px' },
      { field: 'warehouse', header: 'Warehouse', width: '180px' },
      { field: 'City', header: 'City Name', width: '180px' },
      { field: 'UserStatus', header: 'Active Status', width: '180px' },
    ]
    localStorage.removeItem('home');
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
      this.router.navigate(['/Home']);
    }
  }

  ngOnInit() {
    this.GetUserType();
    this.table = true;
    this.GetOrders();
    
    this.GetZoneHead();
    this.GetSalesExecutive();
    this.GetSalesManager();
    this.GetSalesCoordinator();
    this.GetRegDetails();
    this.GetWarehouseDDL();

  }
 
  GetWarehouseDDL() {
    
    var GetUrl = 'api/Values/GetWarehouseDDL';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.WarehouseDDL = data;
    })
  }
  GetUserType() {
    
    var GetUrl = 'api/Values/ALS_GetPersons_UserType?Param1=1';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.UserTypeData = data.UserType;
      this.IsActiveData = [];
      this.IsActiveData.push({ ID: 1, Active: "Yes" }, { ID: 2, Active: "No" })
    })

  }
  GetZoneHead() {
    
    var GetUrl = 'api/Values/ALS_GetPersons_UserType?Param1=2';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.ZoneHeadData = data.Person
    });
  }

  GetSalesExecutive() {
    
    var GetUrl = 'api/Values/ALS_GetPersons_UserType?Param1=3';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.SalesExecutiveData = data.Person;
    });
  }
  GetSalesManager() {
    
    var GetUrl = 'api/Values/ALS_GetPersons_UserType?Param1=4';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.SalesManagerData = data.Person;
    });
  }
  GetSalesCoordinator() {             //========================Sr.Sales Execitive
    
    var GetUrl = 'api/Values/ALS_GetPersons_UserType?Param1=5';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.SalesCoordinatorData = data.Person;
    });
  }

  GetRegDetails() {
    
    var GetUrl = 'api/Admin/GetRegDetails';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger
      this.RegData = data;
      //this.RegDataLength = this.RegData.length;
      this.RegData1 = this.RegData.filter(t => t.IsActive == true);
      this.RegDataLength = this.RegData1.length;
      this.RegData2 = this.RegData.filter(t => t.IsActive == false || t.IsActive == 2);
      this.RegDataLength1 = this.RegData2.length;
    })
  }
  GetOrders() {
    
    var GetUrl = 'api/Values/GetOrders';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.Orders = data;
    })
  }
  Back() {
    
    this.table = true;
  }
  ShowUserForm() {
    
    //this.UserObj = [];
    this.UserObj.Warehouse = "Hyderabad";
    this.UserObj.OrderTo = "Hyderabad";
    this.showIndex = undefined;
    this.table = false;
  }
  Validations() {
    
    // this.messageService.add({ summary: 'Please enter fabric Quantity', severity: 'warn', sticky: false });
    //this.messageService.add({ summary: this.appConfig._severErrorMessage, severity: 'danger', sticky: false });
    //this.messageService.add({ summary: 'Quantity added successfully', severity: 'success', sticky: false });

    if (this.UserObj.City == "" || this.UserObj.City == undefined || this.UserObj.City == null) {
      //this.messageService.add({ summary: 'Please enter City', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter City..!', 'error');

    }
    else if (this.UserObj.Warehouse == "" || this.UserObj.Warehouse == undefined || this.UserObj.Warehouse == null) {
      //this.messageService.add({ summary: 'Please enter Warehouse', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter Warehouse..!', 'error');

    }
    else if (this.UserObj.Phone2 == "" || this.UserObj.Phone2 == undefined || this.UserObj.Phone2 == null) {
      //this.messageService.add({ summary: 'Please enter Phone2', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter Phone2..!', 'error');

    }
    else if (this.UserObj.Email == "" || this.UserObj.Email == undefined || this.UserObj.Email == null) {
      this.messageService.add({ summary: 'Please enter Email', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter Email..!', 'error');

    }
    else if (this.UserObj.EmailCC == "" || this.UserObj.EmailCC == undefined || this.UserObj.EmailCC == null) {
      // this.messageService.add({ summary: 'Please enter EmailCC', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter EmailCC..!', 'error');

    }
    else if (this.UserObj.Password == "" || this.UserObj.Password == undefined || this.UserObj.Password == null) {
      // this.messageService.add({ summary: 'Please enter Password', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Something went wrong..!', 'error');

    }
    else if (this.UserObj.UserTypeId == "" || this.UserObj.UserTypeId == undefined || this.UserObj.UserTypeId == null) {
      //this.messageService.add({ summary: 'Please select UserType', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please select UserType..!', 'error');

    }
    else if (this.UserObj.OrderTo == "" || this.UserObj.OrderTo == undefined || this.UserObj.OrderTo == null) {
      //this.messageService.add({ summary: 'Please enter OrderTo', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter OrderTo..!', 'error');

    }
    else {
      return true;
    }

  }




  Register() {
    
    if (this.Validations()) {
      
      if (this.UserObj.ZoneHead == undefined) {
        this.UserObj.ZoneHead = "";
      }
      if (this.UserObj.SalesManager == undefined) {
        this.UserObj.SalesManager = "";
      }
      if (this.UserObj.SalesCoordinator == undefined) {
        this.UserObj.SalesCoordinator = "";
      }
      if (this.UserObj.SalesExecutive == undefined) {
        this.UserObj.SalesExecutive = "";
      }
      if (this.UserObj.CreditLimit == undefined) {
        this.UserObj.CreditLimit = 0;
      }
      let RegDetail = [];
      RegDetail.push({

        PersonID: this.UserObj.PersonID,
        Companycode: this.UserObj.CompanyCode,
        Companyname: this.UserObj.CompanyName,
        ContactPerson: this.UserObj.ContactPerson,
        City: this.UserObj.City,
        warehouse: this.UserObj.Warehouse,
        //warehouse: '1',

        Phone1: this.UserObj.Phone1,
        Phone2: this.UserObj.Phone2,
        Email: this.UserObj.Email,
        EMailID: this.UserObj.Email,
        EmailCC: this.UserObj.EmailCC,
        Password: this.UserObj.Password,
        UserTypeId: this.UserObj.UserTypeId,
        IsActive: this.UserObj.IsActive,
        NoOrder: this.UserObj.NoOrder,

        SalesCoordinator: this.UserObj.SalesCoordinator,
        SalesExcecutive: this.UserObj.SalesExecutive,
        CreaditDays: this.UserObj.CreditDays,
        CreditLimit: this.UserObj.CreditLimit,
        ZoneHead: this.UserObj.ZoneHead,
        SalesManager: this.UserObj.SalesManager,
        OrderTo: this.UserObj.OrderTo,
      })

      this.RegAry = [];
      this.RegAry = RegDetail;
      var UploadFile = new FormData();
      UploadFile.append("RegDetailsXml", JSON.stringify(RegDetail));
      var PostUrl = 'api/Values/Registeration';
      this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
        
        if (data == "REG SUCCESS") {
          
          // this.messageService.add({ summary: 'Registeration Was Successfull', severity: 'success', sticky: false });
          this.generalService.ShowAlert('SUCCESS', 'Registeration Was Successfull..!', 'success');

          this.table = true;
          this.UserObj = [];
          this.GetRegDetails();
          this.SendRegMail();

        }
        else if (data == "UPDATE SUCCESS") {
          
          this.table = true;
          this.GetRegDetails();
          this.SendRegMail1();
          // this.messageService.add({ summary: 'Registeration Details Updated Successfully', severity: 'success', sticky: false });
          this.generalService.ShowAlert('SUCCESS', 'Registeration Details Updated Successfully..!', 'success');

        }
        else if (data == "EMAIL-EXISTED") {
          
          // this.messageService.add({ summary: 'Email Alreaady Exists', severity: 'warn', sticky: false });
          this.generalService.ShowAlert('error', 'Email Alreaady Exists..!', 'error');


        }
        else {
          
          // this.messageService.add({ summary: 'Something Went Wrong', severity: 'warn', sticky: false });
          this.generalService.ShowAlert('error', 'Something Went Wrong..!', 'error');


        }
      })
    }

  }


  SendRegMail() {
    
    var UploadFile = new FormData();
    UploadFile.append("RegDetails", JSON.stringify(this.RegAry));
    var PostUrl = 'api/Values/RegMailTo_Customer';
    this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
      
    })
  }



  SendRegMail1() {
    
    var UploadFile = new FormData();
    UploadFile.append("RegDetails", JSON.stringify(this.RegAry));
    var PostUrl = 'api/Values/UpdatedRegMailTo_Customer';
    this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
      
    })
  }






  handleChange(id) {
    ;
    if (id.index == 0) {
      this.GetRegDetails();
    }
    else if (id.index == 1) {
      this.GetRegDetails();
    }
  }
  OpenEditForm(RowData) {
    debugger
    this.showIndex = "index";
    this.table = false;
    this.Edit = true;

    // Setting default values for undefined properties
    RowData.ZoneHead = RowData.ZoneHead ?? "";
    RowData.SalesManager = RowData.SalesManager ?? "";
    RowData.SalesCoordinator = RowData.SalesCoordinator ?? "";
    RowData.SalesExcecutive = RowData.SalesExcecutive ?? "";

    this.UserObj = {
      PersonID: RowData.PersonID,
      CompanyCode: RowData.Companycode,
      CompanyName: RowData.Companyname,
      ContactPerson: RowData.ContactPerson,
      City: RowData.City,
      Warehouse: RowData.warehouseid,
      Phone1: RowData.Phone1,
      Phone2: RowData.Phone2,
      Email: RowData.Email,
      EmailCC: RowData.EmailCC,
      Password: RowData.Password,
      UserTypeId: RowData.UserTypeId,
      IsActive: RowData.IsActive ? 1 : 0, // Setting IsActive correctly
      NoOrder: RowData.NoOrder,
      SalesCoordinator: RowData.SalesCoordinator,
      SalesExecutive: RowData.SalesExcecutive,
      CreditDays: RowData.CreaditDays,
      CreditLimit: RowData.CreditLimit,
      ZoneHead: RowData.ZoneHead,
      SalesManager: RowData.SalesManager,
      OrderTo: RowData.OrderTo
    };

    // Handling the warehouse selection logic
    if (RowData.warehouseid == 1) {
      this.WareId = 1;
    } else if (RowData.warehouseid == 2) {
      this.WareId = 2;
    } else if (RowData.warehouseid == 3) {
      this.WareId = 3;
    } else if (RowData.warehouseid == 4) {
      this.WareId = 4;
    }
    else if (RowData.warehouseid == 5) {
      this.WareId = 5;
    }
    else if (RowData.warehouseid == 6) {
      this.WareId = 6;
    }

    this.UserObj.Warehouse = RowData.warehouseid;
  }
  //OpenEditForm(RowData) {
  //  debugger
  //  this.showIndex = "index";
  //  this.table = false;
  //  this.Edit = true;
  //  // this.UserObj = [];
  //  if (RowData.ZoneHeadd == undefined) {
  //    RowData.ZoneHead = "";
  //  }
  //  if (RowData.SalesManager == undefined) {
  //    RowData.SalesManager = "";
  //  }
  //  if (RowData.SalesCoordinator == undefined) {
  //    RowData.SalesCoordinator = "";
  //  }
  //  if (RowData.SalesExcecutive == undefined) {
  //    RowData.SalesExcecutive = "";
  //  }
  //  this.UserObj.PersonID = RowData.PersonID;
  //  this.UserObj.CompanyCode = RowData.Companycode;
  //  this.UserObj.CompanyName = RowData.Companyname;
  //  this.UserObj.ContactPerson = RowData.ContactPerson;
  //  this.UserObj.City = RowData.City;

  //  if (RowData.warehouseid == 1) {
  //    this.WareId = 1;
  //  }
  //  else if (RowData.warehouseid == 2) {
  //    this.WareId = 2;
  //  }
  //  else if (RowData.warehouseid == 3) {
  //    this.WareId = 3;
  //  }
  //  else if (RowData.warehouseid == 4) {
  //    this.WareId = 4;
  //  }
 


  //  this.UserObj.Warehouse = this.WareId;
  //  this.UserObj.Phone1 = RowData.Phone1;
  //  this.UserObj.Phone2 = RowData.Phone2;
  //  this.UserObj.Email = RowData.Email;
  //  this.UserObj.EmailCC = RowData.EmailCC;
  //  this.UserObj.Password = RowData.Password;
  //  this.UserObj.UserTypeId = RowData.UserTypeId;
  //  this.UserObj.IsActive = RowData.IsActive;
  //  this.UserObj.NoOrder = RowData.NoOrder;


  //  this.UserObj.SalesCoordinator = RowData.SalesCoordinator;
  //  this.UserObj.SalesExecutive = RowData.SalesExcecutive;
  //  this.UserObj.CreditDays = RowData.CreaditDays;
  //  this.UserObj.CreditLimit = RowData.CreditLimit;
  //  this.UserObj.ZoneHead = RowData.ZoneHead;
  //  this.UserObj.SalesManager = RowData.SalesManager;
  //  this.UserObj.OrderTo = RowData.OrderTo;
  //}


  confirmDelete(RowData) {
    
    Swal.fire({
      title: 'Are you sure to delete ?',
      text: "You won't be able to revert this and respective data will be deleted",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.DeleteUser(RowData.PersonID);
      }
    })
  }
  validatepri(e: any) {
    
    let input;
    input = String.fromCharCode(e.which);
    return !!/[0-9]/.test(input);
  }


  DeleteUser(RowData) {
    

    var PersonId = RowData;
    var Url = 'api/Values/DeleteRegDetails?PersonId=' + PersonId;
    this.generalService.GetData(Url).then((data: any) => {
      
      if (data == "SUCCESS") {
        this.GetRegDetails();
        //  this.messageService.add({ summary: 'Customer Deleted Successfully', severity: 'success', sticky: false });
        this.generalService.ShowAlert('SUCCESS', 'Customer Deleted Successfully..!', 'success');
      }
      else {
        //this.messageService.add({ summary: 'Something Went Wrong', severity: 'warn', sticky: false });
        this.generalService.ShowAlert('error', 'Something Went Wrong..!', 'error');
      }

    })
  }
}
