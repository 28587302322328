import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [DatePipe]

})
export class InvoiceComponent implements OnInit {
  InvoiceByCoordinator1: any;
  InvoiceByCoordinatorlength: any;
  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';
  NetAmt: number;
  Quantity: number;
  GrandTotal: number;
  ShowDropDown: boolean;
  cols: { field: string; header: string; width: string; }[];
  SalesPerson: any;
  spinner: boolean;
  DropDownData: any;
  InvoiceBySalesManager: any;
  LoginDet: any;
  ZoneHeadDDL: any;
    SalesManagerDDL: any;
    SalesCoordinatorDDL: any;
    SalesExecutiveDDL: any;
    InvoiceByExecutive: any;
    InvoiceByCoordinator: any;
    InvoiceByCmpHead: any;
    InvoiceCustomer: any;
  year: any;
  month: any;
  day: any;
  constructor(public datePipe: DatePipe,public generalService: GeneralService, public router: Router) {
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    
    this.cols = [
      { field: 'INVNo', header: 'InvNo', width: '130px' },
      { field: 'InvDate', header: 'InvDate', width: '130px' },
      { field: 'CompanyName', header: 'Party Name', width: '130px' },
      { field: 'ItemCode', header: 'Item Code', width: '130px' },
      { field: 'ChemicalName', header: 'Item Name', width: '200px' },
      { field: 'PackDesc', header: 'PackDesc', width: '130px' },
      { field: 'Quantity', header: 'Quantity', width: '130px' },
      { field: 'NetAmt', header: 'NetAmt in Rs.', width: '130px' },
      { field: 'BatchNo', header: 'BatchNo', width: '130px' },
      { field: 'PONo', header: 'PONo', width: '130px' },
      { field: 'PODate', header: 'PODate', width: '130px' },
    ]
  }

  ngOnInit(): void {

    var date = new Date();

    var nextyear = date;
    this.year = nextyear.getFullYear();
    this.month = nextyear.getMonth();
    this.day = nextyear.getDate();

    this.month = this.month + 1;
    nextyear = new Date(this.year, this.month, this.day);
    if (this.day < 10) {
      this.day = "0" + this.day;
    }
    if (this.month < 10) {
      this.month = "0" + this.month;
    }

    //this.FromDate = this.year + "-" + this.month + "-" + this.day;
    this.startdate = this.year + "-" + this.month + "-" + this.day;

    this.enddate = this.year + "-" + this.month + "-" + this.day;

    this.mindate = this.day + "-" + this.month + "-" + this.year;
    this.maxdate = this.day + "-" + this.month + "-" + this.year;




    //this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.mindate = this.enddate;
    //this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');

    this.GetSalesCoordinatorDDLBasedOnId();

    this.ShowDropDown = false;
    this.GetInvoices();
    if (this.LoginDet[0].UserTypeId == '5' || this.LoginDet[0].UserTypeId == '7' || this.LoginDet[0].UserTypeId == '8' || this.LoginDet[0].UserTypeId == '9') {
      this.ShowDropDown = true;
      this.DropDown();
    } else {
      this.ShowDropDown = false;
      this.GetInvoicesByCustomer();
    }
  }


  GetZoneHeadDDLBasedOnId() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("",'');
    var GetUrl = '/api/Mail/GetZoneHeadDDLBasedOnId';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.ZoneHeadDDL = data;
    });
  }

  GetSalesManagerDDLBasedOnId() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("PersonId", this.LoginDet[0].ID);
    var GetUrl = '/api/Mail/GetSalesManagerDDLBasedOnId';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.SalesManagerDDL = data;
    });
  }

  GetSalesCoordinatorDDLBasedOnId() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("PersonId", this.LoginDet[0].ID);
    var GetUrl = '/api/Mail/GetSalesCoordinatorDDLBasedOnId';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.SalesCoordinatorDDL = data;
    });
  }


  GetSalesExecutiveDDLBasedOnId() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("PersonId", this.LoginDet[0].ID);
    var GetUrl = '/api/Mail/GetSalesExecutiveDDLBasedOnId';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.SalesExecutiveDDL = data;
    });
  }




  GetInvoiceByExecutive() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("PERSONID", this.LoginDet[0].ID);
    UploadFile.append("ORDERTO", this.LoginDet[0].OrderTo);
    var GetUrl = '/api/Mail/GetInvoiceByExecutive';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.InvoiceByExecutive = data;
    });
  }


  GetInvoiceByCmpHead() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("ORDERTO", this.LoginDet[0].OrderTo);
    var GetUrl = '/api/Mail/GetInvoiceByCmpHead';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.InvoiceByCmpHead = data;
    });
  }


  GetInvoiceBySalesManager() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("ORDERTO", this.LoginDet[0].OrderTo);
    var GetUrl = '/api/Mail/GetInvoiceBySalesManager';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.InvoiceBySalesManager = data;
    });
  }


  getInvoiceCustomer() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("CustomerID", this.LoginDet[0].ID);
    UploadFile.append("ORDERTO", this.LoginDet[0].OrderTo);
    var GetUrl = '/api/Mail/getInvoiceCustomer';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger;
      this.InvoiceCustomer = data;
    });
  }



 //============================new ====================//
  DropDown() {
    debugger
    let personid = this.SalesPerson;
    this.spinner = true;
    var GetUrl = '/api/Admin/GetSalesExecutiveDDLBasedonId?PersonId=' + this.LoginDet[0].ID + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.DropDownData = data;
      this.spinner = false;
    });

  }





  GetInvoices() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Values/GetInvoices?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.InvoiceByCoordinator = data;
      this.InvoiceByCoordinator1 = data;

      this.Quantity = 0;
      this.NetAmt = 0;
      this.InvoiceByCoordinatorlength = data.length;

      for (var i = 0; i < data.length; i++) {
        this.Quantity = this.Quantity + data[i].Quantity;
        this.NetAmt = this.NetAmt + data[i].NetAmt;
      }
    });
    this.spinner = false;

  }

  GetInvoicesByCustomer() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Values/GetInvoicesByCustomer?CustomerID=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.InvoiceByCoordinator = data;
      this.InvoiceByCoordinator1 = data;

      this.Quantity = 0;
      this.NetAmt = 0;
      this.InvoiceByCoordinatorlength = data.length;

      for (var i = 0; i < data.length; i++) {
        this.Quantity = this.Quantity + data[i].Quantity;
        this.NetAmt = this.NetAmt + data[i].NetAmt;
      }
    });
    this.spinner = false;

  }


  AvraSyn_GetInvoiceDetailsOnSelect(Value) {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Values/GetInvoices?PersonId=' + Value + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.InvoiceByCoordinator = data;
    });
    this.spinner = false;

  }

  Filter(startdate, enddate) {
    debugger

    this.InvoiceByCoordinator = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.InvoiceByCoordinator = this.InvoiceByCoordinator1.filter(
      m => new Date(m.InvDate) >= new Date(startdate) && new Date(m.InvDate) <= new Date(enddate)
    );
    this.InvoiceByCoordinatorlength = this.InvoiceByCoordinator.length;


  }
  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
}
