import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';

@Component({
  selector: 'app-cust-complaints',
  templateUrl: './cust-complaints.component.html',
  styleUrls: ['./cust-complaints.component.scss']
})
export class CustComplaintsComponent implements OnInit {
  ComplaintDataLength: any;
  Status1: string;
  Status: string;
  Remarks: string;
  StatusAry: any = [];
  StatusAry1: any = [];

  StatusType: any;
  EditText: boolean=false;
  CustomerComplaints: any;
  flag: string;
  ComplaintData: any;
  TicketNo: any;
  Msg: boolean=false;
  Catalogs: any;
  InvoiceNumber1: any;
  InvoiceNumber: any;
  ComplaintType: any;
  LoginUserTypeId: any;

  spinner: boolean;
  LoginDet: any;

  cols: any;
  UserObj: any = [];


  constructor(public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'TicketNo', header: 'Ticket No', width: '30px' },
      { field: 'ComplaintText', header: 'Complaint Type', width: '40px' },
      { field: 'InvoiceNumber', header: 'Invoice No.', width: '40px' },
      { field: 'CatalogNumber', header: 'Catalog No.', width: '30px' },
      { field: 'ComplaintMessage', header: 'Complaint Message', width: '40px' },
      { field: 'ComplaintRemarks', header: 'Remarks', width: '30px' },
      { field: 'StatusName', header: 'Status', width: '40px' },



    ]
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "0";
    }
    else {
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;

    }
  }


  ngOnInit(): void {
    debugger
    if (this.LoginUserTypeId == '4') {
      this.Msg = false;
      //this.Msg = true;

      this.GetComplaintType();
      this.UserObj.LabName = this.LoginDet[0].CompanyName; 
      this.UserObj.CustName = this.LoginDet[0].ContPerson;  
      this.GetInvoiceNumberForComplaints();
      this.GetComplaintData();
    }
    else {
      if (this.LoginUserTypeId == '5' || this.LoginUserTypeId == '6') {
        this.Msg = true;
      //this.Msg = false;

        if (this.LoginUserTypeId == '5') {
          this.flag='1'
        }
        else if(this.LoginUserTypeId == '6') {
          this.flag = '2'
        }
        this.GetComplaintsExecutive();

      }
      else {
        this.router.navigate(['/login']);
      }
    }
  }
  //handleChange(id) {
  //  debugger;
  //  if (id.index == 0) {
  //    this.Msg = false
  //  }
  //  else if (id.index == 1) {
  //    this.GetDispatchDetailsWithReceipt();
  //  }
  //}
  StatusChange(value) {
    debugger
    
    if (value == 'Open') {
      this.Status1='1'
    }
    else if (value == 'Fixed') {
      this.Status1 = '2'

    }
    else if (value == 'Re-Opened') {
      this.Status1 = '3'

    }
    else if (value == 'Completed') {
      this.Status1 = '4'

    }
  }

  GetComplaintData() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetComplaintData?CustCode=' + this.LoginDet[0].CompanyCode;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.ComplaintData = data;
      this.ComplaintDataLength = data.length;
      this.spinner = false;
    });
  }
  GetComplaintsExecutive() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetComplaintsExecutive?PersonID=' + this.LoginDet[0].ID +'&flag='+this.flag;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.CustomerComplaints = data;
      this.spinner = false;
    });
  }
  GetComplaintType() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetComplaintType';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.ComplaintType = data;
      this.spinner = false;
    });
  }
  GetInvoiceNumberForComplaints() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/Avra2020_GetInvoiceNumberForComplaints?PersonId=' + this.LoginDet[0].ID + '&WareHouseId=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.InvoiceNumber = data.data1;
      this.InvoiceNumber1 = data.data2;

      this.spinner = false;
    });
  }



  GetCatalogNum() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Values/Avra2020_GetCatalogNumber?PersonId=' + this.UserObj.InvoiceNo + '&WareHouseId=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.Catalogs = data;
      this.spinner = false;
    });
  }


  GetCatalogNum1() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetChemicalName?Invoice=' + this.UserObj.InvoiceNo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.Catalogs = data;
      this.spinner = false;
    });
  }
  BindData() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetCatalogNumber?Invoice=' + this.UserObj.InvoiceNo + '&CatalogNo=' + this.UserObj.CatalogNo;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.UserObj.ChemicalName = data[0].ChemicalName;
      this.UserObj.BatchNo = data[0].BatchNo;
      this.UserObj.DateofPurchase = data[0].INVDate;
      this.UserObj.POref = data[0].PONo;
      this.spinner = false;
    });
  }
  Cancel() {
    debugger
    this.UserObj = [];
  }


  SubmitCompliant() {
      debugger
      var UploadFile = new FormData();
      UploadFile.append("CustomerID", this.LoginDet[0].CompanyCode);
      UploadFile.append("ComplaintType", this.UserObj.ComplaintType);
      UploadFile.append("ComplaintMessage", this.UserObj.Message);
      UploadFile.append("TicketNo", this.LoginDet[0].TicketNo);
      UploadFile.append("Status", '1');
      UploadFile.append("CatalogNumber", this.UserObj.CatalogNo);
      UploadFile.append("InvoiceNumber", this.UserObj.InvoiceNo);
      var PostUrl = 'api/Admin/SaveComplaints';
      this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
        debugger
        if (data == '' || data == undefined || data == null) {
          this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');

        }
        else {
          this.UserObj = [];
          this.TicketNo = data[0].TicketNo;
          this.generalService.ShowAlert('SUCCESS', 'Your complaint number is : ' + data[0].TicketNo + ". Please save the complaint number for further assistance.", 'success');
          this.ComplaintsMail();
        }

     
      })
  }






  ComplaintsMail() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("ContPerson", this.LoginDet[0].ContPerson);
    UploadFile.append("TicketNo", this.TicketNo);
    UploadFile.append("EMailID", this.LoginDet[0].EMailID);
    var PostUrl = 'api/Admin/ComplaintsMail';
    this.generalService.PostData(PostUrl, UploadFile).then((data: any) => {
      debugger
    })
  }

  GetStatusType() {
    debugger
    this.spinner = true;
    var GetUrl = '/api/Admin/GetStatusType';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.StatusType = data;
      this.StatusAry = [];
      for (var i = 0; i < this.StatusType.length; i++) {
        this.StatusAry.push({ label: this.StatusType[i].StatusName, value: this.StatusType[i].StatusID })
      }

      this.spinner = false;
    });
  }

  Edit(rowData) {
    debugger
    this.GetStatusType();
    this.EditText = true;
    this.Remarks = rowData.ComplaintRemarks;

  }
  Update(value) {
    debugger
    var GetUrl = '/api/Admin/UpdateComplaints?ComplaintID=' + value.Complaint_ID + '&Remarks=' + this.Remarks + '&Status=' + this.Status1;
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.GetComplaintsExecutive();
      this.EditText = false;
          this.generalService.ShowAlert('SUCCESS', 'Updated successfully', 'success');
      this.spinner = false;

    });
    

  }
  Canceled() {
    debugger
    this.EditText = false;

  }
}
