import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-special-chemicals',
  templateUrl: './special-chemicals.component.html',
  styleUrls: ['./special-chemicals.component.scss']
})
export class SpecialChemicalsComponent implements OnInit {
  spinner: boolean;
  BatchNo: any;
  WebStock: any;
  HomeUrl: any;

  CatalogNo               :any;
  Cas                     :any;
  ChemicalName            :any;
  molecular_structure_path:any;
  molecular_formula_path  :any;
  molecular_weight        :any;
  PhysicalState           :any;
  boiling_point           :any;
  melting_point           :any;
  Rdata                   :any;
  Sdata                   :any;
  Density                 :any;
  FlashPoint              :any;
  RefractiveIndex         :any;
  Storage: any;
  GroupProductsSpecDetails: any;
  GroupProductsDetails: any;
  ShowProductDetails: boolean = false;
  GroupName: any;
  GroupProducts: any;
  ShowGroupProduct: boolean = false;
  ShowGroups: boolean = false;
  SplGroups: any;

  constructor(public generalService: GeneralService, public router: Router,
    public http: HttpClient, public Location: Location) {
    localStorage.removeItem('home');

    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      //debugger
      this.HomeUrl = data.Webservice;
      //debugger

    })
  }

  ngOnInit(): void {
    this.ShowGroups = true;
    this.GetSplGroups();
  }

  //Getting Speciality Chemicals//
  GetSplGroups() {
    debugger
    this.spinner = true;
    //var GetUrl = 'api/Values/GetGroupSPL';
    //this.generalService.GetData(GetUrl).then((data: any) => {
    //  debugger;
    //    this.SplGroups = data;
    //});
    var GetUrl = 'api/Values/GetGroupSPL';
    var UploadFile = new FormData();
    UploadFile.append('Flag', "1");
    UploadFile.append('ItmCode', "");
    UploadFile.append('ItmDesc', "");
    this.generalService.PostData(GetUrl, UploadFile).then((data) => {
      this.SplGroups = data;
      this.spinner = false;

    });
  }

  


  ClickOnSplGroupProds(GroupName) {
    debugger
    localStorage.removeItem('GroupName')
    localStorage.setItem('GroupName', GroupName)
    this.router.navigateByUrl('/SplGrpProds');
  }

  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }
  ClickOnSplProds() {
    debugger
    //this.router.navigateByUrl('/SplChem');
    this.router.navigateByUrl('/ProductsTree', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/SplChem']);
    });
  }
  //SplGrpProds
}
