import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { GeneralService } from '../../Services/general.service';
//import { CosserviceService } from '../../Services/cosservice.service';

import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { map, throttle } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-feed-back',
  templateUrl: './feed-back.component.html',
  styleUrls: ['./feed-back.component.scss']
})
export class FeedBackComponent implements OnInit {
  FY3: string;
  FY2: string;
  FY1: string;
  spinner: boolean;
  Year: number;
  ForPeriod: string;
  FinYear: string;
  ddMMyyyy: any;
  ContPerson: any;
  CompanyName: any;
  LoginDet: any;

  Suggestion: any;
  feedbackData: any = [];
  Obj: any = [];
  Quarters: any = [];
  FY: any = [];


  
  
  constructor(public generalService: GeneralService, private rout: ActivatedRoute,
    public router: Router, public http: HttpClient, public http1: Http, private datePipe: DatePipe) {
    debugger

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    localStorage.removeItem('home');
    this.CompanyName = this.LoginDet[0].CompanyName
    this.ContPerson = this.LoginDet[0].ContPerson


  }

  ngOnInit(): void {
    this.CheckBox();
    debugger
    this.ddMMyyyy = this.datePipe.transform(new Date(), "dd/MM/yyyy");
    this.Year = (new Date()).getFullYear();
    var a = this.Year + 1;
    var b = a + 1
    var c = this.Year - 1;
    this.FY1 = a + "-" + b;
    this.FY2 = this.Year + "-" + a;
    this.FY3 = c + "-" + this.Year

   // this.FinYear = this.Year + "-" + a;


    this.Quarters = [];
    this.Quarters.push({ ID: "Jan-March (Q1)", Quart: "Jan-March (Q1)" }, { ID: "Apr-Jun (Q2)", Quart: "Apr-Jun (Q2)" },
      { ID: "Jly-Sept (Q3)", Quart: "Jly-Sept (Q3)" }, { ID: "Oct-Dec (Q4)", Quart: "Oct-Dec (Q4)" })

    this.FY = [];
    this.FY.push({ ID: this.FY1, Quart: this.FY1 }, { ID: this.FY2, Quart: this.FY2 },
      { ID: this.FY3, Quart: this.FY3 })


    this.Obj.Comm1  ="";
    this.Obj.Comm2  ="";
    this.Obj.Comm3  ="";
    this.Obj.Comm4  ="";
    this.Obj.Comm5  ="";
    this.Obj.Comm6  ="";
    this.Obj.Comm7  ="";
    this.Obj.Comm8  ="";
    this.Obj.Deli1  ="";
    this.Obj.Deli2  ="";
    this.Obj.Deli3  ="";
    this.Obj.Deli4  ="";
    this.Obj.Doc1   ="";
    this.Obj.Doc2   ="";
    this.Obj.Doc3   ="";
    this.Obj.Doc4   ="";
    this.Obj.Exper1 ="";
    this.Obj.Exper2 ="";
    this.Obj.Exper3 ="";
    this.Obj.Exper4 ="";
    this.Obj.Prof1  ="";
    this.Obj.Prof2  ="";
    this.Obj.Prof3  ="";
    this.Obj.Prof4  ="";
    this.Obj.Qual1  ="";
    this.Obj.Qual2  ="";
    this.Obj.Qual3  ="";
    this.Obj.Qual8  ="";
    this.Obj.Qual4  ="";
    this.Obj.Qual5  ="";
    this.Obj.Qual6  ="";
    this.Obj.Qual7  ="";
    this.Obj.Resp1  ="";
    this.Obj.Resp2  ="";
    this.Obj.Resp3  ="";
    this.Obj.Resp4  ="";
    this.Obj.Resp5  ="";
    this.Obj.Resp6  ="";
    this.Obj.Resp7  ="";
    this.Obj.Resp8  ="";
    this.Obj.Web1   ="";
    this.Obj.Web2   ="";
    this.Obj.Web3   ="";
    this.Obj.Web4 = "";

    this.Suggestion = "";
    //this.FinYear = "";
    this.ForPeriod = "";


  }

  CheckBox() {
    debugger
    $(document).ready(function () {
      $('input[type="checkbox"]').on('change', function () {
        var checkedValue = $(this).prop('checked');
        // uncheck sibling checkboxes (checkboxes on the same row)
        $(this).closest('tr').find('input[type="checkbox"]').each(function () {
          $(this).prop('checked', false);
        });
        $(this).prop("checked", checkedValue);

      });
    });

  }


  onChange(val,bool,Area) {
    debugger

    if (Area == '1') {
      if (val == '1') {
        this.Obj.Comm1 = val
      }
      else if (val == '2') {
        this.Obj.Comm2 = val
      }
      else if (val == '3') {
        this.Obj.Comm3 = val
      } else if (val == '4') {
        this.Obj.Comm4 = val
      }

    }



    else  if (Area == '2') {
      if (val == '1') {
        this.Obj.Comm5 = val
      }
      else if (val == '2') {
        this.Obj.Comm6 = val
      }
      else if (val == '3') {
        this.Obj.Comm7 = val
      } else if (val == '4') {
        this.Obj.Comm8 = val
      }

    }
    else  if (Area == '3') {
      if (val == '1') {
        this.Obj.Prof1 = val
      }
      else if (val == '2') {
        this.Obj.Prof2 = val
      }
      else if (val == '3') {
        this.Obj.Prof3 = val
      } else if (val == '4') {
        this.Obj.Prof4 = val
      }

    }
    else  if (Area == '4') {
      if (val == '1') {
        this.Obj.Resp1 = val
      }
      else if (val == '2') {
        this.Obj.Resp2 = val
      }
      else if (val == '3') {
        this.Obj.Resp3 = val
      } else if (val == '4') {
        this.Obj.Resp4 = val
      }

    }

    else   if (Area == '5') {
      if (val == '1') {
        this.Obj.Resp5 = val
      }
      else if (val == '2') {
        this.Obj.Resp6 = val
      }
      else if (val == '3') {
        this.Obj.Resp7 = val
      } else if (val == '4') {
        this.Obj.Resp8 = val
      }

    }

    else  if (Area == '6') {
      if (val == '1') {
        this.Obj.Exper1 = val
      }
      else if (val == '2') {
        this.Obj.Exper2 = val
      }
      else if (val == '3') {
        this.Obj.Exper3 = val
      } else if (val == '4') {
        this.Obj.Exper4 = val
      }

    }
    else if (Area == '7') {
      if (val == '1') {
        this.Obj.Deli1 = val
      }
      else if (val == '2') {
        this.Obj.Deli2 = val
      }
      else if (val == '3') {
        this.Obj.Deli3 = val
      } else if (val == '4') {
        this.Obj.Deli4 = val
      }

    }

    else  if (Area == '8') {
      if (val == '1') {
        this.Obj.Qual1 = val
      }
      else if (val == '2') {
        this.Obj.Qual2 = val
      }
      else if (val == '3') {
        this.Obj.Qual3 = val
      } else if (val == '4') {
        this.Obj.Qual4 = val
      }

    }
    else  if (Area == '9') {
      if (val == '1') {
        this.Obj.Qual5 = val
      }
      else if (val == '2') {
        this.Obj.Qual6 = val
      }
      else if (val == '3') {
        this.Obj.Qual7 = val
      } else if (val == '4') {
        this.Obj.Qual8 = val
      }

    }


    else  if (Area == '10') {
      if (val == '1') {
        this.Obj.Doc1 = val
      }
      else if (val == '2') {
        this.Obj.Doc2 = val
      }
      else if (val == '3') {
        this.Obj.Doc3 = val
      } else if (val == '4') {
        this.Obj.Doc4 = val
      }

    }
    else  if (Area == '11') {
      if (val == '1') {
        this.Obj.Web1 = val
      }
      else if (val == '2') {
        this.Obj.Web2 = val
      }
      else if (val == '3') {
        this.Obj.Web3 = val
      } else if (val == '4') {
        this.Obj.Web4 = val
      }

    }

   

    
  }

  Validations() {
    debugger
    if (this.FinYear == "" || this.FinYear == undefined || this.FinYear == null) {
      this.generalService.ShowAlert('error', 'Please enter Financial Year..!', 'error');

    }
    else if (this.ForPeriod == "" || this.ForPeriod == undefined || this.ForPeriod == null) {
      this.generalService.ShowAlert('error', 'Please enter For Period..!', 'error');

    }
    else {
      return true;
    }
  }



  /*FeedBackPdf*/
  FeedBackPdf() {
    debugger;

    this.feedbackData = [];
    this.feedbackData.push({

      Comm1: this.Obj.Comm1
      , Comm2: this.Obj.Comm2
      , Comm3: this.Obj.Comm3
      , Comm4: this.Obj.Comm4
      , Comm5: this.Obj.Comm5
      , Comm6: this.Obj.Comm6
      , Comm7: this.Obj.Comm7
      , Comm8: this.Obj.Comm8
      , Deli1: this.Obj.Deli1
      , Deli2: this.Obj.Deli2
      , Deli3: this.Obj.Deli3
      , Deli4: this.Obj.Deli4
      , Doc1: this.Obj.Doc1
      , Doc2: this.Obj.Doc2
      , Doc3: this.Obj.Doc3
      , Doc4: this.Obj.Doc4
      , Exper1: this.Obj.Exper1
      , Exper2: this.Obj.Exper2
      , Exper3: this.Obj.Exper3
      , Exper4: this.Obj.Exper4
      , Prof1: this.Obj.Prof1
      , Prof2: this.Obj.Prof2
      , Prof3: this.Obj.Prof3
      , Prof4: this.Obj.Prof4
      , Qual1: this.Obj.Qual1
      , Qual2: this.Obj.Qual2
      , Qual3: this.Obj.Qual3
      , Qual8: this.Obj.Qual8
      , Qual4: this.Obj.Qual4
      , Qual5: this.Obj.Qual5
      , Qual6: this.Obj.Qual6
      , Qual7: this.Obj.Qual7
      , Resp1: this.Obj.Resp1
      , Resp2: this.Obj.Resp2
      , Resp3: this.Obj.Resp3
      , Resp4: this.Obj.Resp4
      , Resp5: this.Obj.Resp5
      , Resp6: this.Obj.Resp6
      , Resp7: this.Obj.Resp7
      , Resp8: this.Obj.Resp8
      , Web1: this.Obj.Web1
      , Web2: this.Obj.Web2
      , Web3: this.Obj.Web3
      , Web4: this.Obj.Web4
      , ContactPerson: this.LoginDet[0].ContPerson
      , CompanyName: this.LoginDet[0].CompanyName
      , Suggestion: this.Suggestion
      , FinYear: encodeURIComponent(this.FinYear)
      , ForPeriod: encodeURIComponent(this.ForPeriod)
      , EMailID: this.LoginDet[0].EMailID



      


    })

    if (this.Validations()) {
      this.spinner = true;

      var UploadFile = new FormData();
      UploadFile.append('Feedback', JSON.stringify(this.feedbackData))
      var url = "/api/Values/FeedbackPdf"
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data == "SUCCESS") {
          debugger
          this.spinner = false;

          this.generalService.ShowAlert('SUCCESS', 'Feedback submitted  Successfully..!', 'success');
        }
      }, err => {
        this.spinner = false;
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    }
    }


    
  /*end code*/
}
