<app-page-heade></app-page-heade>





  

  <div class="loading-indicator" *ngIf="spinner">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  </div>

  <div class="container" style="height: 0%;">
    <div class="row">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style="font-size: 17px;letter-spacing: 0.5px;font-weight: bold;padding-left:1rem;padding-top: 1rem;"> Dispatch details : </div>
      <form *ngIf="ShowDropDown">
        <fieldset>
          <div class="row">
            <div class="col-lg-4">
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="col-md-4 control-label">Sales-Exec.</label>
                <div class="col-md-4">
                  <div class="input-group">
                    <select name="SalesExecutive" class="form-control selectpicker" [(ngModel)]="SalesPerson" (change)="AvraSyn_GetDispatchDetailsOnSelect(SalesPerson)">
                      <option [ngValue]="undefined" disabled selected> Please select Sales Exective </option>
                      <option *ngFor="let item of DropDownData" [ngValue]="item.PersonID"> {{ item.ContPerson }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </fieldset>
      </form>

      <div class="row" *ngIf="LoginUserTypeId!='4'">


        



        <form class="row form-inline" style="padding-left:1rem;">
          <div class="row" style="padding-top:2rem;padding-bottom:1rem;">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <label for="input-12">Order Start Date</label>
              <input type="date" class="form-control" [max]="maxdate" style="height: calc(2.5em + 0.75rem + -10px);" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="    padding-left: 7px;">
              <label for="input-13">Order End Date</label>
              <input type="date" style="height: calc(2.5em + 0.75rem + -10px);" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
            </div>
            <div class="col-lg-2 col-md-1 col-sm-12 col-xs-12" style="padding-left: 77px;">
              <button type="submit" class="btn btn-primary" style="    border-radius: 0px !important;
    margin-top: 26px;width: 64px;" (click)="Filter(startdate,enddate)">
                Get
              </button>
            </div>
          </div>







          <!--<div class="form-group col-md-4 col-sm-6 col-12">
      <label for="input-12" style="padding: 8px;">Order Start Date</label>
      <input type="date" class="form-control" [max]="maxdate" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
    </div>
    <div class="form-group col-md-4 col-sm-6 col-12">
      <label for="input-13" style="padding: 8px;">Order End Date</label>
      <input type="date" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
    </div>
    <div class="form-group col-md-1 col-lg-1 col-12" style="float:right;">
      <button type="submit" class="btn btn-primary" (click)="Filter(startdate,enddate)">Get</button>
    </div>
    <div class="form-group col-md-3 col-lg-3 col-12">

    </div>-->
        </form>

        <div style="padding-left: 2rem;padding-right: 2rem;">
          <p-table #dt [columns]="cols" [value]="DispatchDetails" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">


            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                  <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                     aria-label="" (click)="dt.exportCSV()"
                     style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;">Export to Excel</i>
                  <p class="CountGridRecords" style="margin-left:30%">{{dt.totalRecords}} out of {{DispatchDetailsLength}}</p>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
                </div>
              </div>

            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>

                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr style="cursor:pointer">
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                  <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                  <div *ngSwitchCase="'GrossWeight'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>

                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">

              <tr style="text-align:right" *ngIf="DispatchDetailsLength!=0">
                <td colspan="5" style="text-align: right;">Grand Total</td>
                <td><b>{{GrandTotal | number:'1.2-2'}}</b></td>
              </tr>
              <tr style="text-align:center" *ngIf="DispatchDetailsLength==0">
                <td colspan="8" style="text-align: center;">There are no records available in this duration please change START DATE END DATE from another search.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>


      <div class="col-lg-12" *ngIf="LoginUserTypeId=='4'">
        <p-tabView (onChange)="handleChange($event)">
          <p-tabPanel header="Dispatch Details">

            

            <form class="row form-inline" style="padding-left:1rem;">
              <div class="row" style="padding-top:2rem;padding-bottom:1rem;">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <label for="input-12">Disp Start Date</label>
                  <input type="date" class="form-control" [max]="maxdate" style="height: calc(2.5em + 0.75rem + -10px);" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="    padding-left: 7px;">
                  <label for="input-13">Disp End Date</label>
                  <input type="date" style="height: calc(2.5em + 0.75rem + -10px);" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
                </div>
                <div class="col-lg-2 col-md-1 col-sm-12 col-xs-12" style="padding-left: 77px;">
                  <button type="submit" class="btn btn-primary" style="    border-radius: 0px !important;
    margin-top: 26px;width: 64px;" (click)="Filter(startdate,enddate)">
                    Get
                  </button>
                </div>
              </div>







              
            </form>
            <p-table #dt [columns]="cols" [value]="DispatchDetailsWithOutReceipt" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">

              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                    <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                       aria-label="" (click)="dt.exportCSV()"
                       style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;">Export to Excel</i>
                    <p class="CountGridRecords" style="margin-left:30%">{{dt.totalRecords}} out of {{DispatchDetailsWithOutReceiptLength}}</p>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:75%">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                    <div *ngSwitchCase="'DateofReceipt'" style="text-align: right !important;padding-right:0.5rem;">
                      <input [(ngModel)]="rowData.DateofReceipt" type="date">
                    </div>

                    <div *ngSwitchCase="'Action'" style="padding-right:0.5rem;">
                      <input class="form-input" type="submit" (click)="InsertReceiptDate(rowData)">
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr style="text-align:center" *ngIf="DispatchDetailsWithOutReceiptLength==0">
                  <td colspan="9" style="text-align: center;">No dispatch details available</td>
                </tr>
              </ng-template>
            </p-table>











          </p-tabPanel>
          <p-tabPanel header="Dispatch Details With Receipt Date">
            





            <form class="row form-inline" style="padding-left:1rem;">
              <div class="row" style="padding-top:2rem;padding-bottom:1rem;">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <label for="input-12">Disp Start Date</label>
                  <input type="date" class="form-control" [max]="maxdate" style="height: calc(2.5em + 0.75rem + -10px);" [(ngModel)]="startdate" name="startdate" id="input-12" (input)="Checkdate(startdate)">
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="    padding-left: 7px;">
                  <label for="input-13">Disp End Date</label>
                  <input type="date" style="height: calc(2.5em + 0.75rem + -10px);" class="form-control" [min]="mindate" [(ngModel)]="enddate" name="enddate" id="input-13">
                </div>
                <div class="col-lg-2 col-md-1 col-sm-12 col-xs-12" style="padding-left: 77px;">
                  <button type="submit" class="btn btn-primary" style="    border-radius: 0px !important;
    margin-top: 26px;width: 64px;" (click)="Filter(startdate,enddate)">
                    Get
                  </button>
                </div>
              </div>








            </form>

            <p-table #dt [columns]="cols" [value]="DispatchDetailsWithReceipt" [responsive]="true" [paginator]="true" [rows]="10" [style]="{width:'100%'}" [scrollable]="true" scrollHeight="285px">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                    <i style="font-size: 20px; color:#3e50b4;margin-left:-1px;"></i>
                    <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                       aria-label="" (click)="dt.exportCSV()"
                       style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"> Export to Excel</i>
                  </div>
                  <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                    <input type="text" class="global_search" pInputText size="50" placeholder="Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </div>

              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>

                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr style="cursor:pointer">
                  <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <span *ngSwitchDefault style="width : 150px;overflow:hidden;display:inline-block;text-overflow: ellipsis;white-space: nowrap;cursor:pointer">{{rowData[col.field]}}</span>
                    <div *ngSwitchCase="'GrossWeight'" style="text-align: right !important;padding-right:0.5rem;">{{rowData[col.field] | number:'1.2-2'}}</div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr style="text-align:center" *ngIf="DispatchDetailsWithReceiptLength==0">
                  <td colspan="9" style="text-align: center;">No dispatch details availabale</td>
                </tr>
              </ng-template>
            </p-table>


          </p-tabPanel>


        </p-tabView>

      </div>
    </div>
  </div>

