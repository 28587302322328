<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>










<div class="banner">
  <div class="container">
    <div class="row test">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1 style="color:#000;font-weight: bold;letter-spacing: 1px;"> Hyma Synthesis Private Limited </h1>
          <h1 style="color: #000; letter-spacing: 1px; margin-top: 0px; "> A one-stop destination for all your research needs</h1>
          <br />
        </div>

      </div>


      <div class="radio" *ngIf="LoginDetails != '' && LoginDetails != null && LoginDetails != undefined">
        <input id="radio-1" name="radio" type="radio" checked (click)="Radio(1)">
        <label for="radio-1" class="radio-label">Speciality/Fine Chemicals</label>
        <input id="radio-2" name="radio" (click)="Radio(2)" type="radio">
        <label for="radio-2" class="radio-label">Biologics</label>
        <input id="radio-3" name="radio" (click)="Radio(3)" type="radio">
        <label for="radio-3" class="radio-label">Aqua Culture</label>
      </div>


      <div class="row search_row">
        <div class="col-lg-3">

        </div>
        <div class="col-lg-4">

          <form class="search-container search-wrapper search_f" style="font-size:13px !important">
            <p-autoComplete name="Value" [(ngModel)]="SearchKeyWord" [suggestions]="FlteredProductsList" (completeMethod)="FilterProducts($event)" field="ChemicalName" [size]="30"
                            (onSelect)="GoToProductsGrid(SearchKeyWord)" scrollHeight="50px" placeholder="CAS No/Catalog No/Chemical Name" [minLength]="1"></p-autoComplete>
          </form>


        </div>

        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-1 search-container search-wrapper home_search_b">
          <button type="submit" (click)="GoToProductsGridWithSearchClick(SearchKeyWord)">Search</button>

        </div>



      </div>

      <!--<div class="row">
          <div class="col-lg-12">
            <br />

           <div #productContainer class="marquee-container1">
    <div class="marquee">
      <div *ngFor="let item of ProductsLists" class="product-item" (click)="navigateToProduct(item)">
        <span style="margin-bottom: 5px;">{{item.CAS}}</span>
        <span>{{item.ItemName}}</span>
        <span class="badge badge-danger badge-pill text_left custom-badge">New</span>
      </div>
    </div>
  </div>



          </div>
        </div>-->

      <div class="row">
        <div class="col-lg-12">
          <br />
          <div #productContainer class="marquee-container1">
            <div class="marquee">
              <div *ngFor="let item of ProductsLists"
                   class="product-item"
                   (click)="navigateToProduct(item)"
                   (mouseenter)="stopScroll()"
                   (mouseleave)="startScroll()">
                <span style="margin-bottom: 5px;">{{item.CAS}}</span>&nbsp; | 
                <span>{{item.ItemName}}</span>
                <span class="badge badge-danger badge-pill text_left custom-badge">New</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container " style="margin-top: -4%">
        <div class="row b_c_w" style="margin-left: 89px;">

          <div class="col-lg-4 cd_w baneer_card" style="margin-left: 17%;" routerLink="/ProductsTree">
            <div class="cds1">
              <span>
                Product Tree
              </span>

            </div>
          </div>
          <div class="col-lg-4 cd_w">
            <div class="cds1" routerLink="/coamsds">
              <span>
                COA & MSDS
              </span>

            </div>
          </div>
          <div class="col-lg-4 cd_w">

            <div class="cds1" routerLink="/Downloads">
              <span>
                Download Catalogue
              </span>
            </div>

          </div>


        </div>
        <br />
        <br />
        <!-- Include the Owl Carousel CSS and JS files in your Angular project as described earlier -->
        <!-- Your Angular component template -->
        <!--<div class="marquee-container">
      <div class="row row1 b_c_w" style="margin-left: 89px;">
        <div class="marginn" *ngFor="let item of Gettest">
          <div class="col-lg-12 baneer_card">
            <div class="cds2 border">
              <div class="profile-circle">{{item.Contactperson[0].toUpperCase()}}</div>
              <div class="text-left1">
                <b>{{item.Contactperson}}</b><br />
                <span>{{item.CompanyName}}</span><br />
                <p class="comment"><span style="font-size: 25px;">“</span>{{item.Comment}}<span style="font-size: 25px;">”</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->







      </div>
    </div>

  <!--<div class="container">
    <div class="row b_c_w" style="margin-left: 89px; overflow-x: auto; white-space: nowrap;">-->
  <!--   Loop through the first 20 products and display them in sets of 3-->
  <!--<div *ngFor="let item of ProductsList | slice:0:20" class="col-lg-4 cd_w" style="display: inline-block; min-width: 33.33%;">-->
  <!--    Replace the content below with the product information-->
  <!--<div class="cds1">
    <span>
      {{item.ItemName}}-->
  <!--    Display other product information-->
  <!--</span>
        </div>
      </div>
    </div>
  </div>-->






  <a class="scroll" href="#booya"></a>
  <div style="text-align: center;padding-top: 5%;" _ngcontent-pex-c3="" class="arrow-circle-down bounce"><p _ngcontent-pex-c3=""><a _ngcontent-pex-c3="" class="scroll" href="#booya"><svg _ngcontent-pex-c3="" height="40" style=" fill:#000000;" viewBox="0 0 172 172" width="40" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-pex-c3="" fill="none" fill-rule="nonzero" font-family="none" font-size="none" font-weight="none" stroke="none" stroke-dasharray="" stroke-dashoffset="0" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-width="1" style="mix-blend-mode: normal" text-anchor="none"><path _ngcontent-pex-c3="" d="M0,172v-172h172v172z" fill="none"></path><g _ngcontent-pex-c3="" fill="#000000"><path _ngcontent-pex-c3="" d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg></a></p></div>
</div>
  </div>




<div class="banner2">
  <div class="container" id="booya">
    <div class="row test">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1 style="text-align: center;padding-top: 12%;color:#000;font-weight: bold;letter-spacing: 1px;margin-top:0px;">
            About Us
          </h1>

        </div>
      </div>
      <br />
      <br />

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 " style="border-right: 1px solid #c1c1c1;">
          <div><img src="..//..//assets/Md-photo1.JPG" alt="md photo" style="width: 25%;margin-left: 38%;"></div>
          <h4 style="text-align: center;">Founded by</h4>
          <div>
            <h3 style="margin-top: 0;font-weight: bold;text-align: center;">  Dr A V Rama Rao</h3>
          </div>
          <div>
            <ul class="ul_text">
              <li> <img src="https://img.icons8.com/ios-filled/24/000000/right.png" alt="md photo"> Former Director of the Indian Institute of Chemical Technology</li>

              <li> <img src="https://img.icons8.com/ios-filled/24/000000/right.png" alt="md photo"> Trained 109 PhD students and published 262 papers.</li>

              <li>
                <img src="https://img.icons8.com/ios-filled/24/000000/right.png" alt="md photo">
                Developed over 50 drug technologies that have been
                commercialized by the pharmaceutical industry.
              </li>

              <div style="text-align: center;padding-top: 5%;" class="md_text">
                The President of India bestowed Rama Rao with the <strong> ‘Padma Shri’</strong>
                in 1991 and the <strong> ‘Padma Bhushan’ </strong> in 2016 for Extraordinary Contributions to
                science and industry.
              </div>
            </ul>
          </div>
        </div>


        <div class="col-lg-6 col-md-6 ol-sm-12 col-xs-12 avra_f">
          <div>
            <img src="..//..//assets/Avra_Logo_new.png" alt="hyma" style="margin-top: 8%; margin-left: 38%; width: 15%;"> 
          </div>
          <div style="text-align:center;">
            <sub class="formaerl_text" style="text-align:center;">(Formerly known as Avra Synthesis Private Limited)</sub>

          </div>
          <div style="padding: 5%;letter-spacing: 0.5px;line-height: 2;">
            <p>Hyma Synthesis Private Limited offers a comprehensive catalogue, curated by expert chemists and microbiologists, comprising specialty chemicals and biologics across various grades and quantities.</p>
            Quality of our products is of paramount importance, given their application in R&D. We are ISO certified and have state-of-the-art quality control instruments including GC, HPLC, NMR, GC-MS to ensure that all our products meet stringent quality parameters.
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 ol-sm-12 col-xs-12" style="text-align: center;">
          <img src="../../../assets/Md-photo1.JPG" alt="hyma" style="width: 58%;padding-top: 4%;" />
          <h3><b>Dr A V Rama Rao</b></h3>
          <p style="margin-top: -14px;">Chairman & MD</p>
        </div>
        <div class="col-lg-4 col-md-6 ol-sm-12 col-xs-12" style="text-align: center;">
          <img src="../../../assets/Ramakrishna-Image.jpg" alt="Ramakrishna" />
          <h3><b>Dr Ramakrishna Rao</b></h3>
          <p style="margin-top: -14px;">Joint Managing Director</p>
        </div>
        <div class="col-lg-4 col-md-6 ol-sm-12 col-xs-12" style="text-align: center;">
          <img src="../../../assets/ChandraRamarao-Image.jpg"alt="handraRamarao" />
          <h3><b>Dr Chandra Ramarao</b></h3>
          <p style="margin-top: -14px;">Joint Managing Director</p>
        </div>
      </div>

    </div>
  </div>


  <a class="scroll" href="#ProCats"></a>

  <div style="text-align: center;padding-top: 5%;" _ngcontent-pex-c3="" class="arrow-circle-down bounce">
    <p _ngcontent-pex-c3="">
      <a _ngcontent-pex-c3="" class="scroll" href="#ProCats">
        <svg _ngcontent-pex-c3="" height="40" style=" fill:#000000;" viewBox="0 0 172 172" width="40" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-pex-c3="" fill="none" fill-rule="nonzero" font-family="none" font-size="none" font-weight="none" stroke="none" stroke-dasharray="" stroke-dashoffset="0" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-width="1" style="mix-blend-mode: normal" text-anchor="none"><path _ngcontent-pex-c3="" d="M0,172v-172h172v172z" fill="none"></path><g _ngcontent-pex-c3="" fill="#000000"><path _ngcontent-pex-c3="" d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg>
      </a>
    </p>
  </div>

</div>
<div class="banner2" id="ProCats">
  <div class="container">
    <div class="row test">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1 style="text-align: center;padding-top: 12%;color:#000;font-weight: bold;letter-spacing: 1px;margin-top:0px;">
            Product Categories
          </h1>

        </div>
      </div>
      <br />
      <br />

      <div class="row" style="padding-bottom:5rem;">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div>
            <h3 style="margin-top: 0;font-weight: bold;text-align: center;">  Speciality Chemicals</h3>
          </div>
          <br />
          <div class="row" style="cursor:pointer">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnSplChrm()">
              <img src="../../../assets/007.png" alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 img_text" (click)="ClickOnSplChrm()">
              <div>
                Speciality Chemicals
              </div>
            </div>


          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="border-left: 1px solid #c1c1c1;">
          <div>
            <h3 style="margin-top: 0;font-weight: bold;text-align: center;"> Biologics </h3>
          </div>
          <br />



          <div class="row" style="cursor:pointer">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnBioGrp(BioGroups[0].igmDesc)">
              <img src="../../assets/001.png"alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 img_text" (click)="ClickOnBioGrp(BioGroups[0].igmDesc)">
              <div>
                Microbiology
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnBioGrp(BioGroups[1].igmDesc)">
              <!--<img src="{{BioGroups[1].Icon}}" style="width:100%;" />-->
              <img src="../../assets/002.png" alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 img_text" (click)="ClickOnBioGrp(BioGroups[1].igmDesc)">
              <div>

                <!--{{BioGroups[1].igmDesc}}-->
                Immunology
              </div>
            </div>
          </div>
          <div class="row" style="cursor:pointer">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnBioGrp(BioGroups[2].igmDesc)">
              <!--<img src="{{BioGroups[2].Icon}}" style="width:100%;" />-->
              <img src="../../assets/003.png" alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 img_text" (click)="ClickOnBioGrp(BioGroups[2].igmDesc)">
              <div>

                <!--{{BioGroups[2].igmDesc}}-->
                Proteomics
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnBioGrp(BioGroups[3].igmDesc)">
              <!--<img src="{{BioGroups[3].Icon}}" style="width:100%;" />-->
              <img src="../../assets/004.png" alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 img_text" (click)="ClickOnBioGrp(BioGroups[3].igmDesc)">
              <div>

                <!--{{BioGroups[3].igmDesc}}-->
                Molecular Biology
              </div>
            </div>
          </div>

          <div class="row" style="cursor:pointer">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align_c" (click)="ClickOnBioGrp(BioGroups[4].igmDesc)">
              <!--<img src="{{BioGroups[4].Icon}}" style="width:100%;" />-->
              <img src="../../assets/005.png" alt="hyma" style="width:100%;" class="icons_width" />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 img_text" (click)="ClickOnBioGrp(BioGroups[4].igmDesc)">
              <div>

                <!--{{BioGroups[4].igmDesc}}-->
                Biochemicals
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>





  <a class="scroll" href="#CSR"></a>

  <div style="text-align: center;" _ngcontent-pex-c3="" class="arrow-circle-down bounce">
    <p _ngcontent-pex-c3="">
      <a _ngcontent-pex-c3="" class="scroll" href="#CSR">
        <svg _ngcontent-pex-c3="" height="40" style=" fill:#000000;" viewBox="0 0 172 172" width="40" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-pex-c3="" fill="none" fill-rule="nonzero" font-family="none" font-size="none" font-weight="none" stroke="none" stroke-dasharray="" stroke-dashoffset="0" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-width="1" style="mix-blend-mode: normal" text-anchor="none"><path _ngcontent-pex-c3="" d="M0,172v-172h172v172z" fill="none"></path><g _ngcontent-pex-c3="" fill="#000000"><path _ngcontent-pex-c3="" d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg>
      </a>
    </p>
  </div>


</div>












<div class="banner2" id="CSR">
  <div class="container">
    <div class="row test">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1 style="text-align: center;padding-top: 12%;color:#000;font-weight: bold;letter-spacing: 1px;margin-top:0px;">
            Corporate Social Responsibility
          </h1>

        </div>
      </div>
      <br />
      <br />


    </div>
  </div>


  <div class="container">
    <div class="row ts">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 gggggg">
        <br /> <br />
               <div class="text_font">
                 Hyma strives towards achieving both scientific and business excellence whilst ensuring job creation and social welfare through its Corporate Social Responsibility

                 program (CSR). Although India is a fast growing emerging nation, much needs to be done to bring parity and ensure opportunities to many unprivileged sections and

                 communities. In this regard, Hyma is committed to build its business and engage with society to assist to the extent possible in areas covered under Schedule VII of

                 Section 135 of the Companies Act, 2013 which include, but not limited to the points mentioned below:
               </div>
      </div>

    </div>
    <br />



    <div>

      <div>
        <ol>
          <li>Healthcare</li>
          <li>Education</li>
          <li>Sports</li>
          <li>Disaster Management</li>
          <li>Contribution to Government Funds</li>
          <li>Environmental Sustainability</li>
          <li>Promoting Gender Equality</li>
          <li>Empowering Women</li>
          <li>Protecting National Heritage</li>
          <li>Eradicating Hunger</li>
          <li>Rural Development Projects</li>
          <li>Slum Area Development</li>
        </ol>
      </div>

    </div>

  </div>

  <br />
  <br />
  <br />


  <a class="scroll" href="#Awards"></a>

  <div style="text-align: center;" _ngcontent-pex-c3="" class="arrow-circle-down bounce">
    <p _ngcontent-pex-c3="">
      <a _ngcontent-pex-c3="" class="scroll" href="#Awards">
        <svg _ngcontent-pex-c3="" height="40" style=" fill:#000000;" viewBox="0 0 172 172" width="40" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-pex-c3="" fill="none" fill-rule="nonzero" font-family="none" font-size="none" font-weight="none" stroke="none" stroke-dasharray="" stroke-dashoffset="0" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-width="1" style="mix-blend-mode: normal" text-anchor="none"><path _ngcontent-pex-c3="" d="M0,172v-172h172v172z" fill="none"></path><g _ngcontent-pex-c3="" fill="#000000"><path _ngcontent-pex-c3="" d="M154.75969,27.46625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.03469c-1.41094,0 -2.66063,0.84656 -3.19813,2.13656c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313zM154.75969,72.18625c-0.90031,0.02688 -1.76031,0.40313 -2.39187,1.06156l-66.36781,66.36781l-66.36781,-66.36781c-0.645,-0.67188 -1.53187,-1.03469 -2.45906,-1.04813c-1.41094,0.01344 -2.66063,0.86 -3.19813,2.15c-0.52406,1.30344 -0.215,2.78156 0.79281,3.7625l71.23219,71.23219l71.23219,-71.23219c1.02125,-0.98094 1.33031,-2.49937 0.79281,-3.80281c-0.55094,-1.30344 -1.84094,-2.15 -3.26531,-2.12313z"></path></g></g></svg>
      </a>
    </p>
  </div>

</div>


<!--h===========================================================bhh-->






<div class="banner2" id="Awards">
  <div class="container">
    <div class="row test">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h1 style="text-align: center;padding-top: 12%;color:#000;font-weight: bold;letter-spacing: 1px;margin-top:0px;">
            Awards & Recognition
          </h1>

        </div>
      </div>
      <br />
      <br />


    </div>
  </div>


  <div class="container">
    <div class="row ts">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 gggggg">
        <br /> <br />
        <div class="tab-pane active" id="tab_default_0">
          <div class="container">
            <div class="year"> 2020 </div>
            <div style="font-size: 20px;letter-spacing: 1px;line-height: 1.8;margin-bottom: 2%;" class="st_heading">Avra developed an alternative, low-cost and highly efficient manufacturing process for COVID-19 drug Favipiravir, and was the exclusive supplier to Cipla </div>
            <br />

            <p style="border-left: 2px solid #838383; text-align: left; padding-left: 1rem;">
              <span style="color:#838383">27 Jul 2020<br></span>
              <a href="https://www.business-standard.com/article/companies/avra-laboratories-gets-regulatory-approval-for-covid-19-drug-favipiravir-120072700636_1.html" target="_blank" style=" color:black; font-size:20px;">Business Standard: Avra Laboratories gets regulatory approval for Covid-19 drug Favipiravir</a>
            </p>

            <br />
            <p style="border-left: 2px solid #838383; text-align: left; padding-left: 1rem;">
              <span style="color:#838383">27 Jul 2020<br></span>
              <a href="https://www.moneycontrol.com/news/business/avra-laboratories-gets-cdsco-nod-to-make-favipiravir-api-5606291.html" target="_blank" style=" color:black;font-size:20px;">Moneycontrol: Avra Laboratories gets CDSCO nod to make Favipiravir API</a>
            </p>

            <br />
            <p style="border-left: 2px solid #838383; text-align: left; padding-left: 1rem;">
              <span style="color:#838383">27 Jul 2020<br></span>
              <a style=" color:black;font-size:20px;text-decoration:none;">Police commissioner of Rachakonda visited Avra Unit-2 and felicitated Dr. A. V. Rama Rao for Avra â€™s contribution towards COVID-19 relief efforts.</a>
            </p>



          </div>
          <div>





            <div class="tz-gallery">

              <div class="row" style="padding-top:2rem;    margin-left: 7px;">
                <div class="col-sm-12 col-md-4">
                  <a class="lightbox" href="../../assets/Gallery/01.jpg">
                    <img src="../../../assets/Awd1.jpg" alt="Bridge">
                  </a>

                </div>
                <div class="col-sm-6 col-md-4">
                  <a class="lightbox" href="../../assets/Gallery/02.jpg">
                    <img src="../../../assets/Awd2.jpg" alt="Park">
                  </a>


                </div>
                <div class="col-sm-6 col-md-4">
                  <a class="lightbox" href="../../assets/Gallery/03.jpg">
                    <img src="../../../assets/Awd3.jpg" alt="Tunnel">
                  </a>
                </div>
                <div class="col-sm-12 col-md-4">
                  <a class="lightbox" href="../../assets/Gallery/04.jpg">
                    <img src="../../../assets/Awd4.jpg" alt="Traffic">
                  </a>
                </div>
                <div class="col-sm-6 col-md-4">
                  <a class="lightbox" href="../../assets/Gallery/05.jpg">
                    <img src="../../../assets/Awd5.jpg" alt="Coast">
                  </a>
                </div>



              </div>

            </div>

          </div>







          <div class="tab-pane" id="tab_default_1_1">


            <div class="container">
              <br /><br /><br />
              <div class="year"> 2018 </div>
              <p style="border-left: 2px solid #838383; text-align: left; padding-left: 1rem;margin-bottom:3rem;margin-top:2rem;">
                <span style="color:#838383">10 AUG 2018<br></span>
                <a href="https://www.alumni.iisc.ac.in/news/16567" target="_blank" style=" color:black; font-size:20px;">Avra Labs makes philanthropic contribution to IISc</a>
              </p>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <a href="https://www.alumni.iisc.ac.in/news/16567" target="_blank">   <img src="../../assets/IISC_Image.png" alt="iisc image"style="width:100%;border-radius:5px;" /></a>
                </div>
              </div>


            </div>
          </div>

          <div class="tab-pane " id="tab_default_1">
            <div class="container">
              <br /><br /><br />
              <div class="year"> 2016 </div>
              <div style="font-size: 20px;letter-spacing: 1px;line-height: 1.8;margin-bottom: 2%;" class="st_heading">The Padma Bhushan was bestowed upon Dr A V Rama Rao by the President of India for contributions to <strong> Science and Engineering.</strong></div>

              <div>  <img src="../../../assets/PadmaBhushan-Image.jpg"alt="padmabhushan" class="awrd_img" /> </div>

            </div>
          </div>


          <div class="tab-pane" id="tab_default_2">
            <div class="container">
              <br /><br /><br />
              <div class="year"> 2016 </div>
              <div style="font-size: 20px;letter-spacing: 1px;line-height: 1.8;margin-bottom: 2%;" class="st_heading">
                <strong> CSIR Diamond Jubilee Technology Award </strong> for the Development of
                Innovative Technology for Irinotecan, an anticancer drug.
              </div>

              <div>  <img src="../../../assets/2014_Award.png" alt="award image" class="awrd_img" /> </div>

            </div>
          </div>







          <div class="tab-pane" id="tab_default_3">
            <div class="container">
              <br /><br /><br />
              <div class="year"> 2013 </div>
              <div style="font-size: 15px;letter-spacing: 0.5px;text-align: left;font-weight: bold;color: #0068bf;" class="blue_heading">
                ICC ACHARYA P. C. RAY AWARD FOR DEVELOPMENT OF INDIGENOUS TECHNOLOGY - 2013
                Conferred on Avra LABORATORIES PVT. LTD.
              </div>
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <div style="font-size: 14px;letter-spacing: 0.5px;text-align: left;line-height: 2;margin-top: 5%;">Dr. A. V. Rama Rao founded Avra LABORATORIES 1995 after retiring as the Director of the Indian Institute of Chemical Technology (IICT), Hyderabad. With four sites based in Hyderabad and Vizag, it is amongst the first companies in India to focus on high-end contract research, custom synthesis of complex chemicals and difficult to manufacture intermediates and APIs.</div>
                  <div style="font-size: 14px;letter-spacing: 0.5px;text-align: left;line-height: 2;margin-top: 5%;">
                    One example of the innovative technologies developed by Avra is the total synthesis of Irinotecan, a compound with high molecular complexity. This drug is a semi-synthetic derivative of the natural product Camptothecin and used to treat colorectal cancer. Using a 20 step
                  </div>


                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <img src="../../../assets/icc_awards_2013_12.jpg" alt="awards"style="width: 105%;margin-top: 10%;" />
                </div>
              </div>

              <div style="font-size: 14px;letter-spacing: 0.5px;text-align: left;line-height: 2;margin-top: 1%;">

                synthesis, Avra has developed a commercially viable process to produce Irinotecan and its intermediates with a higher purity at a lower cost.
              </div>

              <div style="font-size: 14px;letter-spacing: 0.5px;text-align: left;line-height: 2;margin-top: 1%;">
                Conventionally, Irinotecan and its intermediates such as SN-38 is made from Camptothecin, a compound derived through extraction from a plant source - Camptotheca acuminata. This process requires extensive chromatographic separations and relies on some low yielding synthetic steps to yield SN-38 with a purity not exceeding 98.5%. Avra's technology does not rely on the natural source and the product is made directly from commercially available and inexpensive raw materials.
              </div>

              <div style="font-size: 14px;letter-spacing: 0.5px;text-align: left;line-height: 2;margin-top: 1%;">
                The process allows for the preparation of SN-38, the key intermediate for the synthesis of Irinotecan with a purity of 99.8%. The process is scalable and all concerns related to dependence on plant sources such as seasonal yield, land and time for growth and pesticide residues from soil do not arise. Avra's process allows for recovery and re-use of certain reagents and solvents making the process green by minimizing waste.
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
    <br />





  </div>

</div>


<style>
  @keyframes marqueeAnimation {
    0% {
      transform: translateX(0%);
    }
    /* Start from the left */
    100% {
      transform: translateX(-100%);
    }
    /* End at the left after scrolling */
  }

  .marquee-container1 {
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #f5f6f7;
    margin-bottom: 1%;
    letter-spacing: 1px;
  }

  .marquee {
    display: flex;
    /*animation: marqueeAnimation 50s linear infinite;*/
  }

  .product-item {
    margin-right: 20px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 5px;
    transition: all 0.3s ease-in-out;
  }

    .product-item:hover {
      border: none;
      color: white;
      background-color: grey;
    }
  .marquee:hover {
    animation-play-state: paused;
    cursor: pointer;
  }

  /* Form submit button */
  .search-wrapper button {
    overflow: visible;
    position: relative;
    float: right;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 2.4rem;
    width: 72px;
    color: white;
    text-transform: uppercase;
    background: #8b8b8b;
    border-radius: 0 3px 3px 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
    margin-left: -10%;
    margin-right: -27%;
  }

    .search-wrapper button:hover {
      background: #8b8b8b;
    }

    .search-wrapper button:active,
    .search-wrapper button:focus {
      background: #8b8b8b;
      outline: 0;
    }

    .search-wrapper button:before { /* left arrow */
      content: '';
      position: absolute;
      border-width: 8px 8px 8px 0;
      border-style: solid solid solid none;
      border-color: transparent #8b8b8b transparent;
      top: 12px;
      left: -6px;
    }

    .search-wrapper button:hover:before {
      border-right-color: #8b8b8b;
    }

    .search-wrapper button:focus:before,
    .search-wrapper button:active:before {
      border-right-color: #8b8b8b;
    }

    .search-wrapper button::-moz-focus-inner { /* remove extra button spacing for Mozilla Firefox */
      border: 0;
      padding: 0;
    }


  .ui-inputtext:enabled:focus {
    border-color: none !important;
    outline: 0 none;
    box-shadow: none;
    outline: none !important;
  }

  .search-wrapper button:before {
    content: '';
    position: absolute;
    border-width: 8px 8px 8px 0;
    border-style: solid solid solid none;
    border-color: transparent #8b8b8b transparent;
    top: 9px !important;
    left: -6px;
  }

  .ui-inputtext:enabled:hover: {
    border-color: none !important;
  }


  /* Form wrapper styling */




  .maring_t_3 {
    margin-top: 3%;
  }


  .img_text {
    font-size: 17px;
    letter-spacing: 1px;
    padding-top: 4%;
    font-weight: bold;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: #3d3d3d !important;
  }

  .search-box {
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 60px;
    border-radius: 120px;
    margin: 0 auto;
  }

  .search-icon, .go-icon {
    position: absolute;
    top: 0;
    height: 60px;
    width: 86px;
    line-height: 61px;
    text-align: center;
  }

  .search-icon {
    left: 0;
    pointer-events: none;
    font-size: 1.22em;
    will-change: transform;
    transform: rotate(0deg) !important;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -webkit-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  }

  .si-rotate {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .go-icon {
    right: 0;
    pointer-events: none;
    font-size: 1.38em;
    will-change: opacity;
    cursor: default;
    opacity: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -webkit-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  }

  .go-in {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -webkit-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  }

  .search-border {
    display: block;
    width: 100%;
    max-width: 500px;
    height: 60px;
  }

  .border {
    fill: none;
    stroke: #c1c1c1;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .border {
    stroke-dasharray: 740;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
    -webkit-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
    -moz-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
    -o-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
  }

  .border-searching .border {
    stroke-dasharray: 740;
    stroke-dashoffset: 459;
    transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
    -webkit-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
    -moz-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
    -o-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
  }

  #search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 120px;
    border: none;
    background: rgba(255,255,255,0);
    padding: 0 68px 0 68px;
    color: #000;
    font-size: 1.32em;
    letter-spacing: -0.015em;
    outline: none !important;
  }

    #search::-webkit-input-placeholder {
      color: #c1c1c1;
    }

    #search::-moz-placeholder {
      color: #000;
    }

    #search:-ms-input-placeholder {
      color: #000;
    }

    #search:-moz-placeholder {
      color: #000;
    }

    #search::-moz-selection {
      color: #000;
      background: rgba(0,0,0,0.25);
    }

    #search::selection {
      color: #FFFFFF;
      background: rgba(0,0,0,0.25);
    }

  .cd_w {
    width: 18%;
    outline: none;
  }

  .cds {
    border: 1px solid #c1c1c1;
    padding: 10% 2% 10% 2%;
    border-radius: 6px;
    text-align: center;
    box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
    background: #00000000;
    color: #000;
    font-size: 13px;
    height: 65px;
    letter-spacing: 0.5px;
    cursor: pointer;
    outline: none !important;
  }

    .cds:hover {
      border: 1px solid #d7127b;
      padding: 10% 2% 10% 2%;
      border-radius: 6px;
      text-align: center;
      box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
      background: #d7127b;
      color: #fff;
      font-size: 13px;
      height: 65px;
      letter-spacing: 0.5px;
      cursor: pointer;
      outline: none;
    }

      .cds:hover:before {
        top: 0;
      }

  .cds1 {
    border: 1px solid #c1c1c1;
    padding: 7%;
    border-radius: 6px;
    text-align: center;
    box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
    background: #ffffff;
    color: #000000;
    font-size: 13px;
    padding-top: 8%;
    letter-spacing: 0.5px;
    cursor: pointer;
    outline: none !important;
    width: 180px !important;
  }
  .cds2 {
    border: 1px solid #c1c1c1;
    padding: 7%;
    border-radius: 5px;
    text-align: left;
    box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
    background: #ffffff;
    color: #000000;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 2px;
    letter-spacing: 0.5px;
    cursor: pointer;
    outline: none !important;
    width: 450px !important;
    /* margin-top:-3%*/
  }
    .cds1:hover {
      /*border: 1px solid #d7127b;*/
      padding: 7%;
      border-radius: 6px;
      text-align: center;
      box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
      background: rgba(144, 144, 144, 0.39);
      /*color: #fff;*/
      font-size: 13px;
      padding-top: 8%;
      letter-spacing: 0.5px;
      cursor: pointer;
      outline: none !important;
      width: 180px !important;
      transition: background-color 1s;
    }
  .cds2:hover {
    /*border: 1px solid #d7127b;*/
    padding: 7%;
    /* border-radius: 20px;*/
    text-align: left;
    box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
    background: rgba(144, 144, 144, 0.39);
    /*color: #fff;*/
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 2px;
    letter-spacing: 0.5px;
    cursor: pointer;
    outline: none !important;
    width: 450px !important;
    transition: background-color 1s;
  }
  .banner { /*background-image: url("../../assets/bg.png");*/
    /*--background-image: url("https://ak2.picdn.net/shutterstock/videos/929752/thumb/1.jpg");*/
    background-image: url(../../assets/ALSBgIMG.jpg);
    /*background-image: url('../../assets/generics_bg.jpg');*/ /* Full height */
    height: 105vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

    .banner .test {
      text-align: center;
      margin-top: 35vh
    }

  .row-banner {
    text-align: left;
    margin-top: 30vh
  }

  .banner h1 {
    color: white;
  }













  ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 18px;
    line-height: 2.5;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #000;
    font-family: poppins;
  }

  .div_text {
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-left: -4rem;
    margin-top: 1.1rem;
  }

  .text_font {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 33px;
    color: #000;
    text-align: justify;
  }
</style>
