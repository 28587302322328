import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-pending-payments',
  templateUrl: './pending-payments.component.html',
  styleUrls: ['./pending-payments.component.scss'],
  providers: [DatePipe]

})
export class PendingPaymentsComponent implements OnInit {
  PendingPayments1: any;
  Paymentslength: any;
  BillAmt: any;
  RecAmt: any;
  PendingAmt: any;

  maxdate: string
  mindate: string;
  startdate: string = '';
  enddate: string = '';

  PendingPayments: any;
  spinner: boolean;
  cols: any;
  LoginDet: any;

  constructor(public datePipe: DatePipe,public generalService: GeneralService, public router: Router) {
    debugger
    localStorage.removeItem('home');

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cols = [
      { field: 'BillNo', header: 'Bill No', width: '120px' },
      { field: 'BillDate', header: 'Bill Date', width: '120px' },
      { field: 'BillAmt', header: 'Bill Amount in Rs.', width: '120px' },
      { field: 'RecAmt', header: 'Rec Amt in Rs.', width: '120px' },
      { field: 'PendingAmt', header: 'Pending Amt in Rs.', width: '120px' },
      { field: 'DueDays', header: 'Due Days', width: '120px' },

      { field: 'voucherType', header: 'Voucher Type', width: '120px' },
      //{ field: 'ContPerson', header: 'Sales Order No', width: '120px' },
      { field: 'CustCode', header: 'Company - Representative', width: '120px' },

    
    ]


  }



  ngOnInit(): void {
    var date = new Date();
    this.startdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.enddate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.mindate = this.enddate;
    this.maxdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.AvraSyn_GetPendingPayments();
  }

  AvraSyn_GetPendingPayments() {
    debugger
    this.spinner = true;

    if (this.LoginDet[0].UserTypeId != '5' && this.LoginDet[0].UserTypeId != '6' &&
      this.LoginDet[0].UserTypeId != '7' && this.LoginDet[0].UserTypeId != '8' &&
      this.LoginDet[0].UserTypeId != '9'
    ) {
      var GetUrl = '/api/Admin/AvraSyn_GetPendingPayments?PersonId=' + this.LoginDet[0].CompanyCode + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    }
    else {
      var GetUrl = '/api/Admin/AvraSyn_GetPendingPayments?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo + '&UserType=' + this.LoginDet[0].UserTypeId;
    }
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.PendingPayments = data;
      this.PendingPayments1 = data;

      this.Paymentslength = this.PendingPayments.length;
      this.BillAmt = 0;
      this.RecAmt = 0;
      this.PendingAmt = 0;

      for (var i = 0; i < data.length; i++) {
        this.BillAmt = this.BillAmt + data[i].BillAmt;
        this.RecAmt = this.RecAmt + data[i].RecAmt;
        this.PendingAmt = this.PendingAmt + data[i].PendingAmt;

      }

      this.spinner = false;
    });

  }
  Filter(startdate, enddate) {
    debugger
    this.PendingPayments = '';
    startdate = new Date((new Date(startdate)).getTime() - (60 * 60 * 24 * 1000));
    this.PendingPayments = this.PendingPayments1.filter(
      m => new Date(m.BillDate) >= new Date(startdate) && new Date(m.BillDate) <= new Date(enddate)
    );
    this.Paymentslength = this.PendingPayments.length;

  }
  /*check the startdate and enddate */
  Checkdate(startdate) {
    debugger;
    //this.enddate = startdate;
    this.mindate = startdate;

  }
  /*end code*/
}
