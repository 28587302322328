import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import { map, throttle } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
  
})
export class HomeComponent implements AfterViewInit  {
  @ViewChild('productContainer') productContainer!: ElementRef<HTMLDivElement>;
  CatalogNo: string;
    query1: any;
  IpAddress: any;
  LocDet: any;
  Location1: Response;
  Location: Position;
  geolocationPosition: Position;
  ReportData: any[];
  Special: any;
  item1: any;
  item: any;
  Icon: any;
  Color: any;
  BioGroups: any=[];

  cols: any;
  ShowGrid: boolean = false;
  spinner: any;
  ProductsListNew: any = [];
  ProductsList: any;
  SearchValue: any;
  ProdDetails: any;
Gettest:any;
  count: any;
  LoginDetails: any;
  Value: any;
  LoginUserTypeId: any;
  GroupAry: any;
  ContPerson: any;
  GroupsList: any;
  FlteredProductsList: any = [];
  country: any;
  HomePage: any;

  MI: any;
  IM: any;
  PR: any;
  MB: any;
  BC: any;
  LAAI: any;



  SearchKeyWord: any;
  location: any;
  ProductsLists: any;
  isScrolling = true;
  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient, location: Location, private renderer: Renderer2) {
    
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    localStorage.removeItem('PrdGridClickkey');
    this.HomePage = 1;
    localStorage.removeItem('home');
    localStorage.setItem('home', this.HomePage);
    
    //this.location = location;
    //alert(JSON.stringify(this.location))
   

    this.getIPAddress().subscribe((res: any) => {
      
      this.IpAddress = res.ip;
      //alert(this.IpAddress)
    }); 
  }

  ngAfterViewInit(): void {

    if (this.LoginDetails == null || this.LoginDetails == "" || this.LoginDetails == undefined) {
      this.Special = null
      localStorage.removeItem('SplBio');
      localStorage.setItem('SplBio', this.Special)
    }
    else {
      this.Special = '1'
      this.LoginUserTypeId = this.LoginDetails[0].UserTypeId;
      localStorage.removeItem('SplBio');
      localStorage.setItem('SplBio', this.Special)
    }
   
    this.startAutoScroll();
    this.GetAllProducts1();
    this.top();
    this.search();
    this.GetAllProducts();
    this.Avra2020_ALS_GetGroups();
    this.Gettestimonial();
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
  startAutoScroll(): void {
    const container = this.productContainer.nativeElement;
    const scrollDelay = 20; // Adjust the scroll delay as needed (in milliseconds)
    const scrollSpeed = 1; // Adjust the scroll speed as needed

    container.scrollLeft = 0; // Set initial scroll position to the start

    setInterval(() => {
      if (this.isScrolling) {
        container.scrollLeft += scrollSpeed;
        if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
          container.scrollLeft = 0; // Reset to start when reaching the end
        }
      }
    }, scrollDelay);
  }
  stopScroll() {
    debugger
    this.isScrolling = false;
  }

  startScroll() {
    this.isScrolling = true;
  }

  search() {
    
    $(document).ready(function () {
      
      $("#search").focus(function () {
        $(".search-box").addClass("border-searching");
        $(".search-icon").addClass("si-rotate");
      });
      $("#search").blur(function () {
        $(".search-box").removeClass("border-searching");
        $(".search-icon").removeClass("si-rotate");
      });
      $("#search").keyup(function () {
        if ($(this).val().length > 0) {
          
          this.SearchValue = $(this).val();
          localStorage.removeItem('Value');
          localStorage.setItem('Value', this.SearchValue)
          localStorage.removeItem('key');
          localStorage.setItem('Key', '1');
          $(".go-icon").addClass("go-in");
        }
        else {
          $(".go-icon").removeClass("go-in");
        }
      });
      $(".go-icon").click(function () {
        $(".search-form").submit();
      });
    });

  }
   

  test() {
    
  }


  top() {
    (function () {
      'use strict';
      if ('querySelector' in document && 'addEventListener' in window && Array.prototype.forEach) {
        var smoothScroll = function (anchor, duration) {
          var startLocation = window.pageYOffset;
          var endLocation = anchor.offsetTop;
          var distance = endLocation - startLocation;
          var increments = distance / (duration / 16);
          var stopAnimation;
          var animateScroll = function () {
            window.scrollBy(0, increments);
            stopAnimation();
          };

          if (increments >= 0) {
            stopAnimation = function () {
              var travelled = window.pageYOffset;
              if ((travelled >= (endLocation - increments)) || ((window.innerHeight + travelled) >= document.body.offsetHeight)) {
                clearInterval(runAnimation);
              }
            };
          }
          // If scrolling up
          else {
            // Stop animation when you reach the anchor OR the top of the page
            stopAnimation = function () {
              var travelled = window.pageYOffset;
              if (travelled <= (endLocation || 0)) {
                clearInterval(runAnimation);
              }
            };
          }

          // Loop the animation function
          var runAnimation = setInterval(animateScroll, 16);

        };

        // Define smooth scroll links
        var scrollToggle = document.querySelectorAll('.scroll');

        // For each smooth scroll link
        [].forEach.call(scrollToggle, function (toggle) {

          // When the smooth scroll link is clicked
          toggle.addEventListener('click', function (e) {

            // Prevent the default link behavior
            e.preventDefault();

            // Get anchor link and calculate distance from the top
            var dataID = toggle.getAttribute('href');
            var dataTarget = document.querySelector(dataID);
            var dataSpeed = toggle.getAttribute('data-speed');

            // If the anchor exists
            if (dataTarget) {
              // Scroll to the anchor
              smoothScroll(dataTarget, dataSpeed || 1000);
            }

          }, false);

        });

      }

    })();



  }

  OnSerach() {
    
    this.router.navigateByUrl('/Products');
  }



 
 

  GetProducts() {
   
    var Query = "";
    var GetUrl = 'api/Values/GetProducts?SerachProduct=' + Query;
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      this.ProductsList = data;
      for (let i = 0; i < this.ProductsList.length; i++) {
        this.ProductsListNew.push(this.ProductsList[i].chemical_name);
      }
    });
  }




  loc1() {
    ;
    var GetUrl = 'http://api.ipstack.com/check?access_key=83ef7a5415d0c9522944fa09208ac349';

    //this.http.get(GetUrl).pipe(map(res => res.json())).subscribe((data) => {

    this.http.get(GetUrl).subscribe((response: any) => {
      

    
      this.LocDet = response;
     // let user = response.json();
      //this.Location = response;
      
   
    });
 
  }



  /*open pop for delete offer*/
  PopUp(Value) {
    //this.loc();
    //this.loc1();

    Swal.fire({
      title: 'We have not found your search item,please click ok to submit your search item',
      text: "",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      
      if (result.value) {
        
        this.ReportData = [];
        if (this.LoginDetails != '' && this.LoginDetails != null && this.LoginDetails != undefined) {
          this.ReportData.push({
            UserID: this.LoginDetails[0].ID
            , SearchItemName: Value
            , ClientName: this.LoginDetails[0].ContPerson
            , CompanyName: this.LoginDetails[0].CompanyName
            , Mobile: this.LoginDetails[0].Phone1
            , EmailId: this.LoginDetails[0].EMailID
            , SearchDate: new Date()
            , IPAddress: this.LocDet.ip
            , City: this.LocDet.city
            , Latitude: this.LocDet.latitude
            , Longitute: this.LocDet.longitude
            //, CatalogNo: this.query1[1]


          })
        }
        else (
          this.ReportData.push({
            UserID: 0
            , SearchItemName: Value
            , ClientName: 'anonymous'
            , CompanyName: 'anonymous'
            , SearchDate: new Date()
            , IPAddress: this.LocDet.ip
            , City: this.LocDet.city
            , Latitude: this.LocDet.latitude
            , Longitute: this.LocDet.longitude
            //, CatalogNo: this.query1[1]

          })
        )


        var UploadFile = new FormData();
        UploadFile.append('Report', JSON.stringify(this.ReportData));
        var url = "/api/Admin/InsertAuditData"
        this.generalService.PostData(url, UploadFile).then(data => {
          ;
          if (data == 'SUCCESS') {
            this.generalService.ShowAlert('SUCCESS', "Search item submitted successfully", 'success');
          }

        }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
      }
    })
  }
  /*end code*/







 
  loc(Value) {
    //var ip = this.http.get("http://api.ipify.org/?format=json");

    debugger
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          debugger
          this.geolocationPosition = position,
            console.log(position)
          this.Location = position;
        this.InsertAudit(Value)

        },
        error => {
          debugger
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    };
  }



  InsertAudit(Value) {
    debugger
    if (this.query1 == '' || this.query1 == null || this.query1 == undefined) {
      this.CatalogNo = '';
    }
    else {
      this.CatalogNo = this.query1[1];
    }

    debugger
    this.ReportData = [];
    if (this.LoginDetails != '' && this.LoginDetails != null && this.LoginDetails != undefined) {
      this.ReportData.push({
        UserID: this.LoginDetails[0].ID
        , SearchItemName: Value
        , ClientName: this.LoginDetails[0].ContPerson
        , CompanyName: this.LoginDetails[0].CompanyName
        , Mobile: this.LoginDetails[0].Phone1
        , EmailId: this.LoginDetails[0].EMailID
        , SearchDate: new Date()
        //, IPAddress: this.LocDet.ip

        //, City: this.LocDet.city
        //, Latitude: this.LocDet.latitude
        //, Longitute: this.LocDet.longitude

        , IPAddress: this.IpAddress
        , Latitude: this.Location.coords.latitude
        , Longitude: this.Location.coords.longitude
        , CatalogNo: this.CatalogNo
        , OrderNumber: ''



        

      })
    }
    else (
      this.ReportData.push({
        UserID: 0
        , SearchItemName: Value
        , ClientName: 'anonymous'
        , CompanyName: 'anonymous'
        , SearchDate: new Date()
        //, IPAddress: this.LocDet.ip
        //, City: this.LocDet.city
        //, Latitude: this.LocDet.latitude
        //, Longitute: this.LocDet.longitude

        , IPAddress: this.IpAddress
        , Latitude: this.Location.coords.latitude
        , Longitude: this.Location.coords.longitude
        , CatalogNo: this.CatalogNo
        , OrderNumber: ''
      })
    )
    var UploadFile = new FormData();
    UploadFile.append('Report', JSON.stringify(this.ReportData));
    var url = "/api/Admin/InsertAuditData"
    this.generalService.PostData(url, UploadFile).then(data => {
      ;
      if (data == 'SUCCESS') {
        //this.generalService.ShowAlert('SUCCESS', "Search item submitted successfully", 'success');
      }

    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  

  }


 
  GoToProductsGrid(Value) {
    
    //if (this.ProductsList.length == '0' || this.ProductsList == '' || this.ProductsList == null || this.ProductsList == undefined) {
    //  this.PopUp(Value);
    //}
    //else
    {
      if (Value == undefined) {
        localStorage.removeItem('Value');
        this.router.navigateByUrl('/Products');
      }
      else {
        debugger
        let query = Value.ChemicalName.substring(0, Value.ChemicalName.indexOf(" |"));
        this.query1 = Value.ChemicalName.split(' | ');
        this.loc(query);
        localStorage.removeItem('Value');
        localStorage.setItem('Value', query)
        this.router.navigateByUrl('/Products');
      }

    }
    
  }

  GoToProductsGridWithSearchClick(Value) {
    debugger
    //if (this.ProductsList.length == '0' || this.ProductsList == '' || this.ProductsList == null || this.ProductsList == undefined) {
    //  //this.PopUp(Value);
    //}
    //else
    {
      if (Value == undefined) {
        localStorage.removeItem('Value');
        this.router.navigateByUrl('/Products');
      }
      else {
       // this.loc(Value);
        this.loc(Value);
        //this.InsertAudit(Value)
        localStorage.removeItem('Value');
        localStorage.setItem('Value', Value)
        this.router.navigateByUrl('/Products');
      }

    }

  }


  GetAllProducts() {
    
    var GetUrl = 'api/Admin/GetChemicalNames';
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      debugger
      this.ProductsList = data;
      
    });
  }
  //chunkedProducts(): any[][] {
  //  const chunkSize = 3;
  //  const chunks = [];

  //  for (let i = 0; i < this.ProductsList.length; i += chunkSize) {
  //    chunks.push(this.ProductsList.slice(i, i + chunkSize));
  //  }

  //  return chunks;
  //}

  FilterProducts(event) {
    
    let query = event.query;
    var GetUrl = 'api/Values/GetChemicalNames?Query=' + query;
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      this.ProductsList = data;
      let arr=[]
     // this.FlteredProductsList = data;

      if (this.LoginDetails != '' && this.LoginDetails != null && this.LoginDetails != undefined) {
        
        arr = data
        if (this.Special == '1') {
          arr = data.filter(a => a.Chemical == 'Special')
        }
        else if (this.Special == '2') {
          arr = data.filter(a => a.Chemical == 'Biologics')
        }
        else {
          arr = data;
        }
        this.getItems(arr);
      }

      else {
        this.FlteredProductsList = data;
        this.FlteredProductsList = data.slice(0, 6)
      }


      
    });
 
  }

  getItems(arr) {  ///Venkatesh
    this.FlteredProductsList = [];

    

    this.FlteredProductsList = arr.slice(0, 6)

  }

 Radio(value) {
  if (value == '1') {
    this.Special = '1';
  } else if (value == '2') {
    this.Special = '2';
  } else {
    this.Special = '3';
  }
  localStorage.removeItem('SplBio');
  localStorage.setItem('SplBio', value);
}








  //Getting Biologics Groups//
  Avra2020_ALS_GetGroups() {
    
    var GetUrl = 'api/Values/Avra2020_ALS_GetGroups';
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      this.BioGroups = [];

      for (var i = 0; i < data.length; i++) {
        
        if (i == 0) {
          this.Icon = '../../assets/001.png';
          this.Color = '#991d62';

        }
        else if (i == 1) {
          this.Icon = '../../assets/002.png';
          this.Color = '#e6a025';

        }
        else if (i == 2) {
          this.Icon = '../../assets/003.png';
          this.Color = '#8eb629';

        }
        else if (i == 3) {
          this.Icon = '../../assets/004.png';
          this.Color = '#3592c1';

        }
        else if (i == 4) {
          this.Icon = '../../assets/005.png';
          this.Color = '#672862';

        }
        this.BioGroups.push({ igmCode: data[i].igmCode, igmDesc: data[i].igmDesc, Icon: this.Icon, Color: this.Color })
      }

    });
  }

  ClickOnBioGrp(igmDesc) {
    
    //Color: "#991d62"
    //Icon: "../../assets/001.png"
    //igmCode: "MI"
    //igmDesc: "Microbiology"


    let object = this.BioGroups.filter(a => a.igmDesc == igmDesc);
    this.item = Object.assign({}, ...object);  
    localStorage.removeItem('Item');
    localStorage.setItem('Item', JSON.stringify(this.item))
    this.router.navigateByUrl('/BioSubGrps');
  }
  ClickOnSplChrm() {
    this.router.navigateByUrl('/SplChem');

  }
 ClickOnAqua() {
    this.router.navigateByUrl('/Aquaproducts');

  }


   Gettestimonial() {
    debugger
    var GetUrl = 'api/Admin/Gettestimonial';
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      debugger
      this.Gettest = data;
      
    });
  }

  GetAllProducts1() {

    var GetUrl = 'api/Values/GetAllProductsNew2023';
    this.generalService.GetData(GetUrl).then((data: any) => {
      ;
      debugger
      this.ProductsLists = data;

    });
  }
  navigateToProduct(ItemCode: any) {
    debugger
    // Store the selected item's ItemCode in local storage
   
      


      this.router.navigateByUrl('/Newprod');


    
   

}
}
