import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
declare var $

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  arr: any = [];
  arr1: any[];
  loginDet: any;
  StateName: any;
  cols: any;

  Age: any;
  Qualification: any;
  dataResult: any = [];
  CompanyName: any;
  PersonName: any;
  HomeUrl: any;
  public ckeditorContent: any;
  gridview: boolean = true;
  Form: FormGroup;
  flag: any;
 
  Status: any;
  ButtonText: any;
  StateId: any;
  public config: any;
  public config1: any;
  dataResult1: any; 
  TTMID: any;
  Companydata: any;
  persondata: any;
  custlist: any=[];
  lawyerlist: any=[];
    deeptList: any=[];
    CompanyName123: any;
    selectedCompanyName: any;
    name: any;
  Contact: any;
    Sequence: any;
  constructor(public generalService: GeneralService, public fb: FormBuilder, public http: HttpClient, private datePipe: DatePipe) {
    this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    //this.GetTestimonial();
   
    this.Form = this.fb.group({
      CompanyName: ['', Validators.required],
      Contactperson: ['', Validators.required],
      Comment: ['', [Validators.required, Validators.maxLength(225)]],
      Sequence: ['', [Validators.required, this.onlyNumericValidator]],
    });



    this.cols = [

      { field: 'CreatedDate', header: 'Created Date', width: '10%' },
      { field: 'CompanyName', header: 'Company Name', width: '90%' },
      { field: 'Contactperson', header: 'Contact Person', width: '90%' },
      { field: 'Comment', header: 'Comment', width: '90%' },
      { field: 'Sequence', header: 'Sequence', width: '90%' },
     

    ]
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {

    this.GetTestimonial();
    this.GetCompany();

  }

  onlyNumericValidator(control: AbstractControl): { [key: string]: any } | null {
    const numericRegex = /^[0-9]*$/; // Regular expression to match only numeric values

    if (!numericRegex.test(control.value)) {
      return { 'numericOnly': true }; // Validation failed
    }

    return null; // Validation passed
  }


  GetTestimonial() {
  
    this.arr = []
    this.arr.push({
      //ID: this.loginDet.ID,
      /*  TokenID: this.loginDet.TokenID,*/

    })
    var UploadFile = new FormData()
    UploadFile.append("xmlParam", JSON.stringify(this.arr));
    UploadFile.append("flag", '4');
    var url = "api/Admin/Web_Testimonials_CRUD";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.dataResult = data;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

    });

   

  }



  AddSource(value, flag) {
    debugger
    var arr = [];
    arr.push({
      ID: this.loginDet.ID,
      TTMID: this.TTMID,
      CompanyName: value.CompanyName,
      Contactperson: value.Contactperson,
      Comment: value.Comment,
      Sequence: value.Sequence,
    });

    var UploadFile = new FormData();
    UploadFile.append("xmlParam", JSON.stringify(arr));
    UploadFile.append("flag", flag);
    var url = "api/Admin/Web_Testimonials_CRUD";

    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "SUCCESS") {
        if (this.flag == 1) {
          this.generalService.ShowAlert('SUCCESS', 'Testimonial Added successfully ', 'success');
        }
        else {
          this.generalService.ShowAlert('SUCCESS', 'Testimonial Updated successfully', 'success');

        }
        this.Form.reset();
        this.gridview = true;
        this.GetTestimonial();
      }
      if (data == "Exist") {
        this.generalService.ShowAlert('Exist', 'Data Already Exist ', 'success');
      }
    }, error => ({
      title: 'ERROR',
      text: 'Something went wrong. Please try again later.',
      type: 'error',
      confirmButtonText: 'Ok'

    })
    );
  }

  
  edit(val) {
    debugger;
    this.gridview = false;
    this.flag = 2;
    this.ButtonText = "Update";
    this.TTMID = val.TTMID;  
    this.Form.controls.CompanyName.setValue(val.CompanyName);

    this.name = val.Contactperson;
    this.Form.controls.Contactperson.setValue(val.Contactperson);
    this.Form.controls.Comment.setValue(val.Comment);
    this.Contact = val.Contactperson;
    this.Form.controls.Sequence.setValue(val.Sequence);
    this.Sequence = val.Sequence;

  }


  ChangeStatus(e, val) {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("TTMID", val.TTMID);
    UploadFile.append("Status", e.checked);
    UploadFile.append("Flag", "1");
    var url = "api/Admin/Web_Testimonials_CRUD"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'SUCCESS') {
        this.generalService.ShowAlert('SUCCESS', 'Status Updated successfully.', 'success');
        //this.getSource();

      }
      else {
        this.generalService.ShowAlert('ERROR', data, 'error')
      }
      
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });

  }


  cancel() {
    debugger
   // this.Form.reset();
    this.GetTestimonial();
    this.gridview = true;

    
  }

  //**This is used to back from the form.**//
  //Back() {
  //  debugger
  //  this.gridview = true;
  //  window.location.reload()
  //  this.getSource()
  //}


  onChange(event: any) {
    setTimeout(() => {
      this.ckeditorContent = event;
    });
  }
  onReady(event: any) {
    event.editor.focus();

  }
  onFocus(event: any) {
    console.log("editor is focused");

  }
  onBlur(event: any) {

  }

  openAddForm() {
    debugger
    this.Status = true;
    this.Form.reset();
    this.gridview = false;
    this.flag = 1;
    this.ButtonText = "Add";
  }

  Delete(val) {
    debugger
    Swal.fire({
      title: 'Alert',
      text: 'Are you sure to delete this Testimonial ?',
      showCancelButton: true,
      //icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        debugger
        this.arr1 = []
        this.arr1.push({

          TTMID: val.TTMID

        });

        var UploadFile = new FormData();
        UploadFile.append("xmlParam", JSON.stringify(this.arr1));
        UploadFile.append("flag", '3');
        var url = "api/Admin/Web_Testimonials_CRUD";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          debugger
          if (data == "SUCCESS") {
            this.generalService.ShowAlert('SUCCESS', 'Testimonial deleted successfully', 'success');
            this.GetTestimonial();
          }
        }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        })
      }
    })
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
  
  GetCompany() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("Param1", '');
    UploadFile.append("flag", '1');
    var url = "/api/Admin/GetCompanyperson";
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Companydata = data;
      this.CompanyName123 = this.Companydata.CompanyName
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
    });
  }


  searchCont(event) {
    debugger
    let filtered: any = [];
    let query = event.query;
    {
      this.deeptList = [];
      for (var i = 0; i < this.Companydata.length; i++) {
        let country = this.Companydata[i];
        if (country.CompanyName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
        }

      }

      this.deeptList = filtered;
    }
  }

  Getperson(val) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("Param1", val);
    UploadFile.append("flag", '2');
    var url = "/api/Admin/GetCompanyperson";
    this.generalService.PostData(url, UploadFile).then(data => {
      this.persondata = data;

      this.GetCompany();
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
    });
  }

  onCompanySelect(event: any) {
    debugger
    // You can perform additional actions here if needed
    console.log('Selected company:', this.selectedCompanyName);
  }



  formatCreatedDate(date: any): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

   

}

