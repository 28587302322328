import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import Swal from 'sweetalert2';
//import { Location } from '@angular/common';
import { UserserviceService } from '../../Services/userservice.service';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-cartitems',
  templateUrl: './cartitems.component.html',
  styleUrls: ['./cartitems.component.scss'],
  styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .custom-toast .ui-toast-message {
            background: #FC466B;
            background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);
            background: linear-gradient(to right, #3F5EFB, #FC466B);
        }

        :host ::ng-deep .custom-toast .ui-toast-message div {
            color: #ffffff;
        }

        :host ::ng-deep .custom-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
            color: #ffffff;
        }
    `],
  //providers: [MessageService]
   providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }, MessageService]
})
export class CartitemsComponent implements OnInit {
  ReportData: any[];
  geolocationPosition: Position;
  IpAddress: any;
  Currency: any;
  cols: { field: string; header: string; width: string; }[];
  options: GlobalConfig;
  Comment: string;
  GrandTotal1: any;
  spinner: boolean = false;
  OrderId: any;
  GstTotal: any;
  Dltflg: string;
  flag: any;
  Quantity: any;
  num: number;
  GrandTotal: any;
  CartLength: any;
  CartData: any;
  LoginDet: any;
  Location: Position;
  selectedState: number;
  showStateSelection: boolean = false;
  constructor(public generalService: GeneralService, public router: Router,
    public http: HttpClient, location: Location, private messageService: MessageService,
    public userice: UserserviceService, public toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;

    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    localStorage.removeItem('home');
    localStorage.removeItem('CartPage');
    localStorage.setItem('CartPage', 'CartPage');

    this.Currency = (localStorage.getItem('Currency'));





    this.cols = [

      { field: 'ItemCode', header: 'Item Code', width: '100px' },
      { field: 'ChemicalName', header: 'Item Name', width: '250px' },
      { field: 'Packsize', header: 'Pack size', width: '100px' },
      { field: 'UnitPrice', header: 'Unit Price (Rs)', width: '100px' },
      { field: 'Quantity', header: 'Qty', width: '100px' },
      { field: 'GSTTAX', header: 'GST %', width: '70px' },
      { field: 'price', header: 'Price (Rs)', width: '100px' },
      { field: 'IndiProdGst', header: 'GST Price (Rs)', width: '100px' },
      { field: 'IndTotPrice', header: 'Tot Price (Rs)', width: '100px' },

    ]
    



  }

 

  ngOnInit(): void {
    this.showSuccess()
    this.GetCartItems();
    if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
      debugger
      if (this.LoginDet[0].OrderTo == 1 || this.LoginDet[0].OrderTo == 4 || this.LoginDet[0].OrderTo == 7) {
        this.flag = '1';
        this.Dltflg = '4';       //==deleting flag
      }
      else if (this.LoginDet[0].OrderTo == 2 || this.LoginDet[0].OrderTo == 6) {
        this.flag = '2';
        this.Dltflg = '5';

      }
      else if (this.LoginDet[0].OrderTo == 3 || this.LoginDet[0].OrderTo == 5) {
        this.flag = '3';
        this.Dltflg = '6';

      }
    }
   
    
    
  }





  GetCartItems() {
    debugger
    this.spinner=true;
    if (this.LoginDet[0].OrderTo == 1 || this.LoginDet[0].OrderTo == 4 || this.LoginDet[0].OrderTo == 7) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=1' + '&Param2=' + this.LoginDet[0].ID;
    }
    if (this.LoginDet[0].OrderTo == 2 || this.LoginDet[0].OrderTo == 6) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=3' + '&Param2=' + this.LoginDet[0].ID;

    }
    if (this.LoginDet[0].OrderTo == 3||this.LoginDet[0].OrderTo == 5) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=2' + '&Param2=' + this.LoginDet[0].ID;

    }
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.spinner = false;
      this.CartData = data;
      this.CartLength = this.CartData.length;
      if (this.CartLength != 0) {
        debugger
        this.GrandTotal = this.CartData[0].GrandTotal;
        this.GstTotal = this.CartData[0].TotalGst;
        this.GrandTotal1 = this.GrandTotal + this.GstTotal;

        localStorage.removeItem('Currency');
        localStorage.setItem('Currency', this.CartData[0].Currency)


        if (this.CartData[0].Currency=='Rupees') {
          this.cols = [

            { field: 'ItemCode', header: 'Item Code', width: '100px' },
            { field: 'ChemicalName', header: 'Item Name', width: '250px' },
            { field: 'Packsize', header: 'Pack size', width: '100px' },
            { field: 'UnitPrice', header: 'Unit Price (Rs)', width: '100px' },
            { field: 'Quantity', header: 'Qty', width: '100px' },
            { field: 'GSTTAX', header: 'GST %', width: '70px' },
            { field: 'price', header: 'Price (Rs)', width: '100px' },
            { field: 'IndiProdGst', header: 'GST Price (Rs)', width: '100px' },
            { field: 'IndTotPrice', header: 'Tot Price (Rs)', width: '100px' },

          ]
        }
        else {
          this.cols = [

            { field: 'ItemCode', header: 'Item Code', width: '100px' },
            { field: 'ChemicalName', header: 'Item Name', width: '250px' },
            { field: 'Packsize', header: 'Pack size', width: '100px' },
            { field: 'UnitPrice', header: 'Unit Price ($)', width: '100px' },
            { field: 'Quantity', header: 'Qty', width: '100px' },
            //{ field: 'GSTTAX', header: 'GST %', width: '70px' },
            { field: 'price', header: 'Price ($)', width: '100px' },
            //{ field: 'IndiProdGst', header: 'GST Price ($)', width: '100px' },
            { field: 'IndTotPrice', header: 'Tot Price ($)', width: '100px' },

          ]
        }
        
      }
      this.userice.edituser(this.CartLength);
    });

  }


  IncDecQty(value, num) {
    debugger

    if (parseInt(num) == 1) {
      debugger
      this.num = 0;
    }
    if (parseInt(num) == -1) {
      debugger
      this.num = 1;
    }

    if (value.Quantity > this.num) {
      this.Quantity = value.Quantity + parseInt(num);
      var TotalPrice = this.Quantity * value.UnitPrice
      var Gstprice = value.GSTTAX * TotalPrice / 100;
      let CartData = [];
      CartData.push({
        ID: value.ID
        , Quantity: this.Quantity
        , Price: TotalPrice
        , Gstprice: Gstprice
        , flag: this.flag

      })
      var Url = '/api/Values/UpdateCart';         //
      var UploadFile = new FormData();
      UploadFile.append('CartData', JSON.stringify(CartData));
      this.generalService.PostData(Url, UploadFile).then((data: any) => {
        debugger
        this.GetCartItems();
      });
    }


  }
  Delete(CartId) {
    debugger
    let CartData = [];
    CartData.push({
      ID: CartId,
      flag: this.Dltflg,
      Quantity: '0',
      Price: '0',
      GstPrice:'0'
    })
    var Url = '/api/Values/UpdateCart';         //
    var UploadFile = new FormData();
    UploadFile.append('CartData', JSON.stringify(CartData));
    this.generalService.PostData(Url, UploadFile).then((data: any) => {
      debugger
      if (data == 'SUCCESS') {
        debugger
        // this.messageService.add({ summary: 'item deleted from the cart successfully....!', severity: 'success', sticky: false });
        this.generalService.ShowAlert('SUCCESS', 'Item deleted from the cart successfully...!', 'success');

        this.GetCartItems();
      }
      else {
        debugger
        // this.messageService.add({ summary: 'Something went wrong', severity: 'warn', sticky: false });
        this.generalService.ShowAlert('error', 'Something went wrong..!', 'error');

      }
    });
  }
  ConfirmPlaceOrder() {
    debugger
    if (this.LoginDet[0].OrderTo == 4
      || this.LoginDet[0].OrderTo == 5 || this.LoginDet[0].OrderTo == 6 || this.LoginDet[0].OrderTo == 7


    ) {
      this.openModal(); // Show the modal to select a state if OrderTo is 4, 5, 6, or 7
    } else {
      this.showPlaceOrderConfirmation(); // If state selection is not required, proceed to place order confirmation
    }
  }

  openModal() {
    debugger
    $('#myModal2').modal('show');
  }

  confirmState() {
    debugger
    if (this.selectedState) {
      this.LoginDet[0].OrderTo = this.selectedState; // Update OrderTo with selected state
      localStorage.setItem('LoginDetails', JSON.stringify(this.LoginDet));
      $('#myModal2').modal('hide'); // Hide the modal
      this.showPlaceOrderConfirmation(); // Continue the cart process after state selection
    } else {
      this.generalService.ShowAlert('ERROR', 'Please select a state', 'error');
    }
  }


  showPlaceOrderConfirmation() {
    debugger
    Swal.fire({
      title: 'Are you sure to place order ?',
      text: "",
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes..!'
    }).then((result) => {
      if (result.value) {
        this.PlaceOrder();
      }
    })
  }
  // Method to set the selected state
  setState(stateId: number) {
    this.selectedState = stateId;
  }


  PlaceOrder() {
    this.spinner = true;
    var Url = '/api/Values/PlaceOrder?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
      this.spinner = false;
      this.OrderId = data[0].OrderId;
      if (data[0].OrderId != null) {
        this.UpdateOrderInfo();
        this.GetCartItems();
        this.SendOrderMail();
        this.loc(data[0].OrderId);
        //this.Success()
        this.generalService.ShowAlert('SUCCESS', 'Order placed successfully..!', 'success');
      }
      else {
        this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
      }
      this.spinner = false;

    });
  }


  UpdateOrderInfo() {
    var Url = '/api/Values/UpdateOrderInfo?PersonId=' + this.LoginDet[0].ID + '&OrderTo=' + this.LoginDet[0].OrderTo;
    this.generalService.GetData(Url).then((data: any) => {
      debugger;
    });
  }
  SendOrderMail() {
    debugger
    var Url = '/api/Admin/SendOrderMail';         
    var UploadFile = new FormData();
    if (this.LoginDet[0].OrderTo == 1) //Commented on 24 oct
    {
      this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
    }
    else if (this.LoginDet[0].OrderTo  == 2) {
      this.LoginDet[0].FromAddress = "bangaloreinfo@avrasynthesis.com";

    }
    else {
      this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
    }

    this.LoginDet[0].FromAddress
    //this.LoginDet[0].Comment = encodeURIComponent(this.Comment);
    this.LoginDet[0].Comment = this.Comment;
    UploadFile.append('ProdDet', JSON.stringify(this.CartData));
    UploadFile.append('UserDet', JSON.stringify(this.LoginDet));
    UploadFile.append('OrderId', this.OrderId);
    this.generalService.PostData(Url, UploadFile).then((data: any) => {
      debugger
      this.InsertComments();
    });
  }
  SendMailToCustomer() {
    debugger
    this.spinner = true;
    var Url = '/api/Admin/MailPlaceOrder';         
    var UploadFile = new FormData();
    if (this.LoginDet[0].OrderTo == 1) //Commented on 24 oct
    {
      this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
    }
    else if (this.LoginDet[0].OrderTo == 2) {
      this.LoginDet[0].FromAddress = "bangaloreinfo@avrasynthesis.com";

    }
    else {
      this.LoginDet[0].FromAddress = "info@avrasynthesis.com";
    }
    debugger
    if (this.Comment == undefined) {
      this.Comment = '';
    }
    this.LoginDet[0].Comment = encodeURIComponent(this.Comment);
    UploadFile.append('CartData', JSON.stringify(this.CartData));
    UploadFile.append('RegDet', JSON.stringify(this.LoginDet));
    this.generalService.PostData(Url, UploadFile).then((data: any) => {
      debugger
      if (data == 'Sucess') {
        this.spinner = false;

        this.generalService.ShowAlert('SUCCESS', 'Quotation sent to your mail, Please check mail .....', 'success');
      }
      else {
        this.spinner = false;

        this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');

      }
    }, err => {
      this.spinner = false;

      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


  }




 SendMailToCustomeraqua() {
  // Set spinner to true to indicate processing
  this.spinner = true;
debugger
  // API URL
  const url = '/api/Admin/MailPlaceOrder';
debugger
  // Create FormData object for sending the request
  const uploadFile = new FormData();

  // Filter CartData to include only items with AquaID === 1
  const filteredCartData = this.CartData.filter(item => item.AquaID === 1);
debugger
  // Proceed only if there are items with AquaID === 1
  if (filteredCartData.length > 0) {
    // Set FromAddress based on OrderTo value in LoginDet
    const loginDet = this.LoginDet[0];
    switch (loginDet.OrderTo) {
      case 1:
        loginDet.FromAddress = "info@avrasynthesis.com";
        break;
      case 2:
        loginDet.FromAddress = "bangaloreinfo@avrasynthesis.com";
        break;
      default:
        loginDet.FromAddress = "info@avrasynthesis.com";
    }

    // Set Comment to an empty string if undefined
    loginDet.Comment = encodeURIComponent(this.Comment ?? '');

    // Append filtered data to FormData
    uploadFile.append('CartData', JSON.stringify(filteredCartData));
    uploadFile.append('RegDet', JSON.stringify(this.LoginDet));

    // Make the POST request
    this.generalService.PostData(url, uploadFile).then((data: any) => {
      // Handle successful response
      if (data === 'Success') {
        this.spinner = false;
        this.generalService.ShowAlert('SUCCESS', 'Quotation sent to your mail. Please check your email.', 'success');
      } else {
        // Handle unexpected response
        this.spinner = false;
        this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
      }
    }).catch(err => {
      // Handle request error
      this.spinner = false;
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
    });
  } else {
    // Handle the case where there are no items with AquaID === 1
    this.spinner = false;
    this.generalService.ShowAlert('INFO', 'No items with AquaID 1 to process.', 'info');
  }
}



  InsertComments() {
    debugger
    var url = 'api/Values/InsertCommentsBasedonOrder?OrderID=' + this.OrderId + '&PersonID=' + this.LoginDet[0].ID + '&Comment=' + encodeURIComponent(this.Comment);
    this.generalService.GetData(url).then(data => {
      debugger
    })
  }
  showSuccess() {
    this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'Order submitted' });
  }






  loc(Val) {
    //var ip = this.http.get("http://api.ipify.org/?format=json");

    debugger
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          debugger
          this.geolocationPosition = position,
            console.log(position)
          this.Location = position;
          this.InsertAudit(Val)

        },
        error => {
          debugger
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    };
  }



  InsertAudit(Val) {
    debugger
   
    debugger
    this.ReportData = [];
    if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
      for (var i = 0; i < this.CartData.length; i++) {
        this.ReportData.push({
          UserID: this.LoginDet[0].ID
          , SearchItemName: this.CartData[i].ChemicalName
          , ClientName: this.LoginDet[0].ContPerson
          , CompanyName: this.LoginDet[0].CompanyName
          , Mobile: this.LoginDet[0].Phone1
          , EmailId: this.LoginDet[0].EMailID
          , SearchDate: new Date()
          , IPAddress: this.IpAddress
          , Latitude: this.Location.coords.latitude
          , Longitude: this.Location.coords.longitude
          , CatalogNo: this.CartData[i].ItemCode
          , OrderNumber: Val
        })
      }
      
    }
    
    var UploadFile = new FormData();
    UploadFile.append('Report', JSON.stringify(this.ReportData));
    var url = "/api/Admin/InsertAuditData"
    this.generalService.PostData(url, UploadFile).then(data => {
      ;
      if (data == 'SUCCESS') {
        //this.generalService.ShowAlert('SUCCESS', "Search item submitted successfully", 'success');
      }

    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


  }



}
