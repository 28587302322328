<!--<div class="app" [ngClass]="{'combined': settings.theme.skin == 'combined',
                            'light': settings.theme.skin == 'light',
                            'dark': settings.theme.skin == 'dark',
                            'blue': settings.theme.skin == 'blue',
                            'green': settings.theme.skin == 'green',
                            'navbar-fixed': settings.theme.navbarIsFixed,
                            'sidebar-fixed': settings.theme.sidebarIsFixed, 
                            'horizontal-menu': settings.theme.menu == 'horizontal',                                      
                            'compact': settings.theme.menuType == 'compact', 
                            'mini': settings.theme.menuType == 'mini',
                            'menu-hide': !settings.theme.showMenu }">
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div> 
</div>-->
<div class="app"  [ngClass]="settings.theme.skin" 
                  [class.navbar-fixed]="settings.theme.navbarIsFixed"  
                  [class.sidebar-fixed]="settings.theme.sidebarIsFixed"  
                  [class.horizontal-menu]="settings.theme.menu == 'horizontal'" 
                  [class.compact]="settings.theme.menuType == 'compact'" 
                  [class.mini]="settings.theme.menuType == 'mini'" 
                  [class.menu-hide]="!settings.theme.showMenu">
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div> 
</div>
