import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var $next: any;
declare var $this: any;

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  Section2: any;
  Section3: any;
  Section4: any;
  Section5: any;
  Section6: any;

  constructor() {
    localStorage.removeItem('home');

  }

  ngOnInit(): void {
    this.Section2 = true;
  }

  Click(id) {
    debugger
    if (id == '2') {
      this.Section2 = true;
    }
    if (id == '3') {
      this.Section2 = false;
      this.Section3 = true;

    }
    if (id == '4') {
      this.Section4 = true;
    }
    if (id == '5') {
      this.Section5 = true;
    }
    if (id == '6') {
      this.Section6 = true;
    }
  }
}
//$(function () {
//  var Accordion = function (el, multiple) {
//    this.el = el || {};
//    this.multiple = multiple || false;

//    // Variables privadas
//    var links = this.el.find('.link');
//    // Evento
//    links.on('click', { el: this.el, multiple: this.multiple }, this.dropdown)
//  }

//  Accordion.prototype.dropdown = function (e) {
//    var $el = e.data.el;
//    $this = $(this),
//      $next = $this.next();

//    $next.slideToggle();
//    $this.parent().toggleClass('open');

//    if (!e.data.multiple) {
//      $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
//    };
//  }

//  var accordion = new Accordion($('#accordion'), false);
//});


