import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { GeneralService } from '../../Services/general.service';
import { CosserviceService } from '../../Services/cosservice.service';

import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { map, throttle } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-coa-msds',
  templateUrl: './coa-msds.component.html',
  styleUrls: ['./coa-msds.component.scss']
})
export class CoaMsdsComponent implements OnInit {
  LoginDet: any;
  HomeUrl: any;

  spinner: boolean;
  BatchNo: string;
  MSDNparam: any;

  constructor(public generalService: GeneralService, public router: Router, public user: CosserviceService, public http: HttpClient) {
    debugger
    localStorage.removeItem('home');
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));

    this.http.get('../../../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeUrl = data.Webservice;
    })



  }

  ngOnInit() {
  }
  //BatchNo

  Validations() {
    debugger
    if (this.BatchNo == "" || this.BatchNo == undefined || this.BatchNo == null) {
      //this.messageService.add({ summary: 'Please enter Batch Number', severity: 'warn', sticky: false });
      this.generalService.ShowAlert('error', 'Please enter Batch Number..!', 'error');

    }
    else {
      return true;
    }
  }
  PopUp() {
    debugger
    Swal.fire({
      title: 'Alert',
      text: 'Please login to continue',
      type: 'warning',
      //showCancelButton: true,
      //icon: 'warning',
      confirmButtonText: 'OK',
      // cancelButtonText: 'No, keep it'
    }).then((data1) => {
      if (data1.value == true) {
        debugger
        this.router.navigate(['/login']);
      }
    })
  }

  GetCoaDetails() {
    debugger
    if (this.Validations()) {
      debugger
      if (this.LoginDet == '' || this.LoginDet == null || this.LoginDet == undefined) {
        this.PopUp();
      }
      else {
        debugger
        this.spinner = true;
        var GetUrl = 'api/Values/GetCoaDetails?BatchNo=' + this.BatchNo;
        this.generalService.GetData(GetUrl).then((data: any) => {
          debugger
          var CoaDetails = data;

          //this.user.savebatch(this.BatchNo)
          localStorage.removeItem('BatchNo');
          localStorage.setItem('BatchNo', this.BatchNo)
          localStorage.removeItem('CoaDetails');
          localStorage.setItem('CoaDetails', JSON.stringify(data));
          //this.BatchNo = '';
           //window.open('http://localhost:4200//#/CoaDetails/' + this.BatchNo, '_blank');
          

          if (CoaDetails.CoaDetails5[0].DaysLeft < 60) {
            this.generalService.ShowAlert('Info', 'COA hasnot found..!', 'info');
          }
          else {
            window.open('https://avrasynthesis.com/CoaDetails/' + this.BatchNo, '_blank');
          }

        })
        this.spinner = false;
      }
    }
   

  }


  view() {
    this.MSDNparam;
    window.open('https://www.avrasynthesis.com/webservices/Content/MSDS/'+this.MSDNparam+'.pdf', '_blank');
  }

  OpenPdf() {
    debugger


  }
}
