import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators} from '@angular/forms';
import { PasswordValidators, EmailValidators, CreditCardValidators, UniversalValidators } from 'ngx-validators'
import { GeneralService } from '../../Services/general.service'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent {
  public accountForm: FormGroup;
    Emailid: any;
    checkemail: number;
    checkNo: number;
    MobileNo: any;
  arr: any[];
  Grid: boolean = true;
    
  constructor(public generalService: GeneralService, private formBuilder: FormBuilder, public router: Router){
    let password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    let confirmPassword = new FormControl('', Validators.required);
    this.accountForm = this.formBuilder.group({
      'comname': ['', Validators.required],
      'username': ['', Validators.required],
      'phoneno': ['', Validators.compose([Validators.pattern(/^[6-9]\d{9}$/), Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      'city': ['', Validators.required],
      'password': password,
      'confirmPassword': confirmPassword,
      'email': ['', Validators.compose([Validators.required, EmailValidators.normal])]
    });
    this.accountForm.validator = PasswordValidators.mismatchedPasswords('password', 'confirmPassword'); 
    }

  OnSubmit(value) {
    debugger
    this.arr = [];
    if (this.accountForm.valid) {
      this.arr.push({
        CompanyName: value.comname,
        ContPerson: value.username,
        EMailID: value.email,
        Phone1: value.phoneno,
        City: value.city,
        PWD: value.password
      });
    }
        var UploadFile = new FormData();      
        UploadFile.append("CustDetails", JSON.stringify(this.arr));
        var url = "/api/Values/InsertCustomerDetails";
        this.generalService.PostData(url, UploadFile).then(data => {
          debugger
          if (data != null) {
            debugger
            if (data == "SUCCESS") {
              this.generalService.ShowAlert('SUCCESS', 'Registration Successfull,We will intimate your Activate ASAP', 'success');

              this.accountForm.reset();
              var UploadFile = new FormData();
              UploadFile.append("RegDetails", JSON.stringify(this.arr));
              var url = "api/Values/RegMailTo_Customer";
              this.generalService.PostData(url, UploadFile).then(data => {
                var msg = data;
              });
            }
            else {
              this.generalService.ShowAlert('INFO', 'Please fill out all the required fields', 'info');
            }
          }
        });
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  CheckMobileNo() {
    debugger
    this.MobileNo = this.accountForm.controls['phoneno'];
    if (this.MobileNo.value.length == 10) {
      var UploadFile = new FormData();
      UploadFile.append("Mobile", this.MobileNo.value);
      UploadFile.append("flag", "1");
      var url = "/api/Values/CheckMobileandEmail"
      debugger;
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data == 'EXIST') {
          this.checkNo = 10;
          this.accountForm.controls['phoneno'].setErrors({ 'incorrect': true });
          //this.generalService.ShowAlert('ERROR', 'Mobile number is already used.', 'error')
        }
        else {
          this.checkNo = 11;
        }
      }, err => {

      });
    }
  }

  CheckEmail() {
    debugger
    if (this.accountForm.controls['email'].hasError('invalidEmail') != true) {
      this.Emailid = this.accountForm.controls['email'];
      var UploadFile = new FormData();
      UploadFile.append("Mobile", this.Emailid.value);
      UploadFile.append("flag", "2");
      var url = "/api/Values/CheckMobileandEmail"
      debugger;
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data == 'EXIST') {
          this.checkemail = 10;
          this.accountForm.controls['Email'].setErrors({ 'incorrect': true });
          // this.generalService.ShowAlert('ERROR', 'Email is already used.', 'error')
        }
        else {
          this.checkemail = 11;
        }
      }, err => {

      });
    }
  }
  Cancel() {
    debugger;
    this.router.navigateByUrl("/login");
  }
    ngAfterViewInit(){
        document.getElementById('preloader').classList.add('hide');
    }
}

export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true})
        }
    }
}
