
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;
import { Location } from '@angular/common';
import { UserserviceService } from '../../Services/userservice.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-page-heade',
  templateUrl: './page-heade.component.html',
  styleUrls: ['./page-heade.component.scss']
})
export class PageHeadeComponent implements OnInit {
  CatalogNo: string;
  query1: any;
  USDPrice: any;
  LoginDetails: any;
  IpAddress: any;
  LocDet: any;
 SplChecked: boolean = false;
  BioChecked: boolean = false;
  SplBio: string;
  ProductsListNew: any;
  Special: any;
  count: any;
  CartLength: any;
  CartData: any;
  HomePage: any;
  ContPerson: any;
  LoginUserTypeId: string;
  LoginDet: any;
  ProductsList: any;
  FlteredProductsList: any;
  SearchKeyWord: any;
  SplGroups: any;
AquaChecked: boolean = false;
AllProducts:any;
spinner: boolean=false;

  Location: Position;
  geolocationPosition: Position;
  ReportData: any[];


  constructor(public generalService: GeneralService, public router: Router,
    public location: Location, public userice: UserserviceService, public http: HttpClient) {
    
   // this.count = 0;
    this.getIPAddress().subscribe((res: any) => {
      
      this.IpAddress = res.ip;
      //alert(this.IpAddress)
    }); 


    
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));

    if (this.LoginDetails != null && this.LoginDetails != "" && this.LoginDetails != undefined) {
      this.CheckLoginUser(this.LoginDetails[0].EMailID, this.LoginDetails[0].PWD)
    }

    
   

    this.userice.cast.subscribe(count => this.count = count);
    this.HomePage = localStorage.getItem('home')
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.LoginUserTypeId = "";
    }
    else {
      
      this.LoginUserTypeId = this.LoginDet[0].UserTypeId;
      this.ContPerson = this.LoginDet[0].ContPerson;
      if (this.LoginUserTypeId == '1') {
        this.Avra2020_GetFormula();
      }
      this.GetCartItems();
     

    }
    this.SplBio = localStorage.getItem('SplBio');
    
  if (this.SplBio === '2') {
    this.BioChecked = true;
    this.SplChecked = false;
    this.AquaChecked = false;
    this.Special = '2';
  } else if (this.SplBio === '1') {
    this.SplChecked = true;
    this.BioChecked = false;
    this.AquaChecked = false;
    this.Special = '1';
  } else if (this.SplBio === '3') {
    this.SplChecked = false;
    this.BioChecked = false;
    this.AquaChecked = true;
    this.Special = '3';
  } else {
    this.SplChecked = true;
    this.BioChecked = false;
    this.AquaChecked = false;
    this.Special = '1';
  }
}
   


  ngOnInit(): void {
    if (this.LoginDet == null || this.LoginDet == "" || this.LoginDet == undefined) {
      this.Special = null
      localStorage.removeItem('SplBio');
      localStorage.setItem('SplBio', this.Special)
    }
    else {
      this.Special = '1'
      localStorage.removeItem('SplBio');
      localStorage.setItem('SplBio', this.Special)

    }



    this.GetAllProducts();
     
    this.GetSplGroups();
    this.TopBar();
    this.Avra2020_GetFormula();
    //if () {
    //}
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  } 
  TopBar() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50)  {
        $('.opaque-navbar').addClass('opaque');
      } else {
        $('.opaque-navbar').removeClass('opaque');
      }
    });
  }


    
 

  CheckLoginUser(EMailID, PWD) {
    
    var UploadFile = new FormData();
    UploadFile.append("Email", EMailID);
    UploadFile.append("Password", PWD);
    var GetUrl = '/api/Values/Login';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      
      if (data.length != 0) {
        
        localStorage.removeItem('LoginDetails');
        localStorage.setItem('LoginDetails', JSON.stringify(data))
        this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
        this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
      }
     
      else {
       
        
        if (this.LoginDetails[0].ContPerson == 'Test') {
          {
            
            let EnquiryAry = []; EnquiryAry.push({ ContPerson: 'please not respond its testing from gagri', Phone1: this.LoginDetails[0].Phone1, EMailID: 'rk661012@gmail.com', Description: 'please not respond its testing from gagri' })
            var UploadFile = new FormData();
            UploadFile.append("Details", JSON.stringify(EnquiryAry));
            var url = "/api/Values/EnquiryMailToAdmin"
            this.generalService.PostData(url, UploadFile).then((data: any) => {
            });
          }
        }
        localStorage.removeItem('LoginDetails');
       // this.generalService.ShowAlert('error', 'Invalid UserName or Password..!', 'error');
      }
    });
   }

  //FilterProducts(Alpha) {
  //  
  //  Alpha = Alpha.query;
  //  this.FlteredProductsList = '';
  //  if (Alpha != '') {
  //    this.FlteredProductsList = this.ProductsList.filter((item) => {
  //      return (item.ItemName.toLowerCase().indexOf(Alpha.toLowerCase()) > -1);
  //    })
  //  }
  //  else {

  //  }
  //}


  loc1() {
    ;
    var GetUrl = 'http://api.ipstack.com/check?access_key=83ef7a5415d0c9522944fa09208ac349';

    //this.http.get(GetUrl).pipe(map(res => res.json())).subscribe((data) => {

    this.http.get(GetUrl).subscribe((response: any) => {
      


      this.LocDet = response;
      // let user = response.json();
      //this.Location = response;


    });

  }


  loc(Value) {
    //var ip = this.http.get("http://api.ipify.org/?format=json");


    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          
          this.geolocationPosition = position,
            console.log(position)
          this.Location = position;
          this.InsertAudit(Value)

        },
        error => {
          
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              console.log('Position Unavailable');
              break;
            case 3:
              console.log('Timeout');
              break;
          }
        }
      );
    };
  }



  InsertAudit(Value) {
    if (this.query1 == '' || this.query1 == null || this.query1 == undefined) {
      this.CatalogNo = '';
    }
    else {
      this.CatalogNo = this.query1[1];
    }



    this.ReportData = [];
    if (this.LoginDetails != '' && this.LoginDetails != null && this.LoginDetails != undefined) {
      this.ReportData.push({
        UserID: this.LoginDetails[0].ID
        , SearchItemName: Value
        , ClientName: this.LoginDetails[0].ContPerson
        , CompanyName: this.LoginDetails[0].CompanyName
        , Mobile: this.LoginDetails[0].Phone1
        , EmailId: this.LoginDetails[0].EMailID
        , SearchDate: new Date()
        //, IPAddress: this.LocDet.ip

        //, City: this.LocDet.city
        //, Latitude: this.LocDet.latitude
        //, Longitute: this.LocDet.longitude

        , IPAddress: this.IpAddress
        , Latitude: this.Location.coords.latitude
        , Longitude: this.Location.coords.longitude

        , CatalogNo: this.CatalogNo
        , OrderNumber: ''


      })
    }
    else (
      this.ReportData.push({
        UserID: 0
        , SearchItemName: Value
        , ClientName: 'anonymous'
        , CompanyName: 'anonymous'
        , SearchDate: new Date()
        //, IPAddress: this.LocDet.ip
        //, City: this.LocDet.city
        //, Latitude: this.LocDet.latitude
        //, Longitute: this.LocDet.longitude

        , IPAddress: this.IpAddress
        , Latitude: this.Location.coords.latitude
        , Longitude: this.Location.coords.longitude
        , CatalogNo: this.CatalogNo
        , OrderNumber: ''
      })
    )
    var UploadFile = new FormData();
    UploadFile.append('Report', JSON.stringify(this.ReportData));
    var url = "/api/Admin/InsertAuditData"
    this.generalService.PostData(url, UploadFile).then(data => {
      ;
      if (data == 'SUCCESS') {
        //this.generalService.ShowAlert('SUCCESS', "Search item submitted successfully", 'success');
      }

    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


  }





  /*open pop for delete offer*/
  PopUp(Value) {
    this.loc1();
    Swal.fire({
      title: 'We have not found your search item,please click ok to submit your search item',
      text: "",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      
      if (result.value) {
        
        this.ReportData = [];
        if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
          this.ReportData.push({
            UserID: this.LoginDet[0].ID
            , SearchItemName: Value
            , ClientName: this.LoginDet[0].ContPerson
            , CompanyName: this.LoginDet[0].CompanyName
            , Mobile: this.LoginDet[0].Phone1
            , EmailId: this.LoginDet[0].EMailID
            , SearchDate: new Date()
            , IPAddress: this.LocDet.ip
            , City: this.LocDet.city
            , Latitude: this.LocDet.latitude
            , Longitute: this.LocDet.longitude

          })
        }
        else (
          this.ReportData.push({
            UserID: 0
            , SearchItemName: Value
            , ClientName: 'anonymous'
            , CompanyName: 'anonymous'
            , SearchDate: new Date()
            , IPAddress: this.LocDet.ip
            , City: this.LocDet.city
            , Latitude: this.LocDet.latitude
            , Longitute: this.LocDet.longitude
          })
        )


        var UploadFile = new FormData();
        UploadFile.append('Report', JSON.stringify(this.ReportData));
        var url = "/api/Admin/InsertAuditData"
        this.generalService.PostData(url, UploadFile).then(data => {
          ;
          if (data == 'SUCCESS') {
            this.generalService.ShowAlert('SUCCESS', "Search item submitted successfully", 'success');
          }

        }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
      }
    })
  }
  /*end code*/



 // GetAllProducts() {
    
 //   var GetUrl = 'api/Admin/GetChemicalNames';
  //  this.generalService.GetData(GetUrl).then((data: any) => {
  //    ;
 //     this.ProductsList = data;
 //   });
//  }

GetAllProducts() {
    debugger
    this.spinner = true;
    var GetUrl = 'api/Values/GetAllProducts';
    this.generalService.GetData(GetUrl).then((data: any) => {
      debugger;
      this.spinner = false
      this.AllProducts = [];

      if (this.SplBio == '' || this.SplBio == null || this.SplBio == 'undefined') {
        this.AllProducts = data;
      }
      else {
        if (this.SplBio == '1') {
          this.AllProducts = data.filter(a => a.MainGroupName == 'Speciality Chemicals')
          
        }
        else if (this.SplBio == '2') {
          this.AllProducts = data.filter(a => a.MainGroupName == 'Biologics')
         
        }
else if (this.SplBio == '3') {
          this.AllProducts = data.filter(a => a.MainGroupName == 'Aqua Culture')
          
        }
        else {
          this.AllProducts = data;

        }
 localStorage.removeItem('Value');



      }
    });
    //this.spinner = false
  }

 handleSelect(event) {
    // Assuming event.data contains the selected item object
    this.SearchKeyWord = event.data;
    console.log('Selected item in handleSelect:', this.SearchKeyWord);
  }

  GoToProductsGrid(Value) {

    //if (this.ProductsList.length == '0' || this.ProductsList == '' || this.ProductsList == null || this.ProductsList == undefined) {
    //  this.PopUp(Value);
    //}
    //else
    {

      if (Value == undefined) {
        localStorage.removeItem('Value');
        this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/Products');
        });
      }
      else {

        let query = Value.ChemicalName.substring(0, Value.ChemicalName.indexOf(" |"));
        this.query1 = Value.ChemicalName.split(' | ');
        this.loc(query);
        localStorage.removeItem('Value');
        localStorage.setItem('Value', query)
        this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/Products');
        });
debugger
 if (Value.ChemicalName.includes('Aqua Culture')) {
        this.SplBio = '3';
      } else if (Value.ChemicalName.includes('Speciality Chemicals')) {
        this.SplBio = '1';
      }
else {
this.SplBio='2';
}
localStorage.setItem('SplBio', this.SplBio);
      }
    }

  }


 GoToProductsGridWithSearchClick(Value) {
    debugger;

    if (Value == undefined) {
      localStorage.removeItem('Value');
      this.router.navigateByUrl('/Products');
      this.reloadIfOnProductsPage();
    } else {
      this.loc(Value);
      localStorage.removeItem('Value');
      localStorage.setItem('Value', Value);
      this.router.navigateByUrl('/Products');
      this.reloadIfOnProductsPage();
    }
  }

  reloadIfOnProductsPage() {
    if (this.router.url === '/Products') {
      window.location.reload();
    }
  }


resetSearch() {
  localStorage.removeItem('Value');
  this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
    this.router.navigateByUrl('/Products');
  });
}





  //USDPrice//
  Avra2020_GetFormula() {
    var GetUrl = 'api/Values/Avra2020_GetFormula';
    this.generalService.GetData(GetUrl).then((data: any) => {
      
      this.USDPrice = data[0].Formula;
      localStorage.removeItem('USDPrice')
      localStorage.setItem('USDPrice', this.USDPrice)
      //alert(data)
    });
  }


  //Getting Speciality Chemicals//
  GetSplGroups() {
    var GetUrl = 'api/Values/GetSplGroups';
    this.generalService.GetData(GetUrl).then((data: any) => {
      this.SplGroups = data;
    });
  }




//Getting Speciality Chemicals//
  Avra2020_InsertFormula() {
    var UploadFile = new FormData();
    UploadFile.append("Password", '');
    var GetUrl = 'api/Values/Avra2020_InsertFormula';
  this.generalService.GetData(GetUrl).then((data: any) => {
    
 
  });
  }

  //Getting Speciality Chemicals//
  Avra2020_UpdateFormula() {
    var UploadFile = new FormData();
    UploadFile.append("Id", '1');
    UploadFile.append("Formula", this.USDPrice);
    var GetUrl = 'api/Values/Avra2020_UpdateFormula';
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      
      if (data == 'SUCCESS') {
        this.generalService.ShowAlert('SUCCESS', 'USD Conversion Updated SUccessfully..!', 'success');
        this.Avra2020_GetFormula();
      }
      else {
        this.generalService.ShowAlert('error', 'Something Went Wrong..!', 'error');

      }

    });
  }

  Back() {

  }




 FilterProducts(event) {
    debugger
    let query = event.query;
    var GetUrl = 'api/Values/GetChemicalNames?Query=' + encodeURIComponent(query); // Encode the query to handle special characters
    this.generalService.GetData(GetUrl).then((data: any) => {
        this.ProductsList = data;
        let arr = [];
        
         if (this.LoginDet != '' && this.LoginDet != null && this.LoginDet != undefined) {
            arr = data;

          
            this.getItems(arr);
        } else {
            this.FlteredProductsList = data;
            this.FlteredProductsList = data.slice(0, 6); // Limit to first 6 items
        }
    });
}

  getItems(arr) {  ///Venkatesh
    this.FlteredProductsList = [];

    

    this.FlteredProductsList = arr.slice(0, 6)

  }


Radio(value: string) {
  debugger;
  
  // Set the localStorage item before navigating
  

  // Update the special value
  if (value == '1' && this.router.url !== '/Products') {
    this.Special = '1';
    this.router.navigateByUrl('/SplChem');
  } else if (value == '2' && this.router.url !== '/Products') {
    this.Special = '2';
    this.router.navigateByUrl('/Biologics');
  } else if (value !== '1' && value !== '2' && this.router.url !== '/Products') {
    this.Special = '3';
    this.router.navigateByUrl('/Aquaproducts');
  }
if (this.router.url === '/Products') {
    window.location.reload();
  }
localStorage.removeItem('SplBio');
  localStorage.setItem('SplBio', value);
  // Call GetAllProducts to refresh the product list after navigation
  this.GetAllProducts();
}




  GoToCart() {
    this.LoginDet = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.LoginDet != '' && this.LoginDet != undefined && this.LoginDet != null) {
      if (this.LoginDet[0].NoOrder == '1') {
        this.router.navigateByUrl('/Cart');
      }
      else {
        this.generalService.ShowAlert('ERROR', 'You are not authorised to order, Please contact admin', 'error');

      }

    }
    else {
      this.generalService.ShowAlert('error', 'Please login to continue.!', 'error');
    }
  }
  LogOut() {
    
    localStorage.removeItem('totalcartitems');
    this.LoginDet[0].UserTypeId

   // this.LoginUserTypeId
    localStorage.removeItem('LoginDetails');
    let cart = localStorage.getItem('CartPage');

    if (cart == 'CartPage') {
      localStorage.removeItem('CartPage');
      this.router.navigate(['/Home']);
    }
    else if (this.LoginUserTypeId == '1' ||this.LoginUserTypeId == '4' || this.LoginUserTypeId == '5' || this.LoginUserTypeId == '6' || this.LoginUserTypeId == '7' || this.LoginUserTypeId == '8' || this.LoginUserTypeId == '9' || this.LoginUserTypeId == '11' ) {
      //this.router.navigate(['/Home']);
      this.router.navigateByUrl('/PageHead', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl('/Home');
      });
    }
    else {
      window.location.reload();
    }
   

  }


  GetCartItems() {
    //if (this.LoginDet[0].OrderTo == 1) {
    //  var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=1' + '&Param2=' + this.LoginDet[0].ID;
    //}
    //if (this.LoginDet[0].OrderTo == 2) {
    //  var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=3' + '&Param2=' + this.LoginDet[0].ID;

    //}
    //if (this.LoginDet[0].OrderTo == 3) {
    //  var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=2' + '&Param2=' + this.LoginDet[0].ID;

    //}
    if (this.LoginDet[0].OrderTo == 1 || this.LoginDet[0].OrderTo == 4 || this.LoginDet[0].OrderTo == 7) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=1' + '&Param2=' + this.LoginDet[0].ID;
    }
    if (this.LoginDet[0].OrderTo == 2 || this.LoginDet[0].OrderTo == 6 || this.LoginDet[0].OrderTo == 7) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=3' + '&Param2=' + this.LoginDet[0].ID;

    }
    if (this.LoginDet[0].OrderTo == 3 || this.LoginDet[0].OrderTo == 5 || this.LoginDet[0].OrderTo == 7) {
      var Url = '/api/Values/GetClientorders_Ahemdabad_Bangalore?flag=2' + '&Param2=' + this.LoginDet[0].ID;

    }
    this.generalService.GetData(Url).then((data: any) => {
      this.CartData = data;
      this.CartLength = this.CartData.length;
      this.count = data.length;
      localStorage.removeItem("totalcartitems");
      localStorage.setItem("totalcartitems", data.length);
      debugger
      if (this.CartData.length != 0) {
        localStorage.removeItem('Currency');
        localStorage.setItem('Currency', this.CartData[0].Currency)
      }
      else {
        localStorage.removeItem('Currency');
        localStorage.setItem('Currency', 'Rupees')
      }

    });

  }

  Currency(val) {
    debugger
    localStorage.removeItem('Currency');
    localStorage.setItem('Currency', val)
  }
}
