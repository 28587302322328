<app-page-heade></app-page-heade>

<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>




<div class="container" style="margin-top:8rem;">


  <h3 class="ClaHead">

    Solvents & MSDS

  </h3>
  <br />
  <!--*ngIf="LoginUserTypeId=='1'"-->
  <div class="row" *ngIf="LoginUserTypeId=='1'">

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        Upload Solvents Excel sheet :
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="file">
          <input type="file" (change)="detectFiles($event)" id="file" aria-label="File browser example">
          <span class="file-custom"></span>
        </label>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
        <input type="submit" (click)="UploadSolventsExcel()" style="padding: 0px 4px 0px 4px;" value="Upload" />
      </div>


    </div>

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

  </div>


  <br />
  <div class="row">

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding: 0px 23px 0px 0px;">
        Here we can download the Solvents Excel sheet :
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="margin: 0px 0px 6px -35px;">
        <input type="button" (click)="DownLoadSolvent()" style="padding: 0px 4px 0px 4px;" value="Download" />
      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
      </div>


    </div>

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

  </div>


  <br />
  <div class="row">

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border">
      <div style="padding: 0px 314px 8px 0px;">
        Note : MSDS Name must be like this (AST1918 MSDS)(Catalogno MSDS)
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="margin-left: 21px;">
        Upload MSDS Documents Here  :
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="file">
          <input (change)="detectFilesMSDS($event)" type="file" id="file" aria-label="File browser example">
          <span class="file-custom"></span>
        </label>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
        <input (click)="UploadMSDS()" type="submit" style="padding: 0px 4px 0px 4px;" value="Upload" />
      </div>


    </div>

    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">

    </div>

  </div>
</div>






<style>
  .border {
    border: 1px solid rgba(112, 112, 108, 0.42);
    padding: 6px 0px 0px 0px;
    text-align:right
  }
</style>

