import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
declare var $: any;

@Component({
  selector: 'app-biologics',
  templateUrl: './biologics.component.html',
  styleUrls: ['./biologics.component.scss']
})
export class BiologicsComponent implements OnInit {
  spinner: boolean;
  SubGroupProducts: any;
  ShowSubGroupProduct: boolean = false;
  igmDesc: any;
  ShowSubGroups: boolean = false;
  ShowGroups: boolean = true;;
  Color: string;
  Icon: string;
  SubGroups: any;
  IgmCode: any;
  BioGroups: any=[];
  HomeUrl: any;


  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    })
    localStorage.removeItem('home');

  }

  ngOnInit(): void {
    this.Avra2020_ALS_GetGroups();
  }



  //Getting Biologics Groups//
  Avra2020_ALS_GetGroups() {
    debugger
    this.spinner = true;
    
      var GetUrl = 'api/Values/GetGroupSPL';
      var UploadFile = new FormData();
      UploadFile.append('Flag', "2");
      UploadFile.append('ItmCode', "");
    UploadFile.append('ItmDesc', "");
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
        this.BioGroups = [];
      for (var i = 0; i < data.length; i++) {
        debugger
        if (i == 0) {
          this.Icon = '../../assets/001.png';
          this.Color = '#991d62';

        }
        else if (i == 1) {
          this.Icon = '../../assets/002.png';
          this.Color = '#e6a025';

        }
        else if (i == 2) {
          this.Icon = '../../assets/003.png';
          this.Color = '#8eb629';

        }
        else if (i == 3) {
          this.Icon = '../../assets/004.png';
          this.Color = '#3592c1';

        }
        else if (i == 4) {
          this.Icon = '../../assets/005.png';
          this.Color = '#672862';

        }
        
        this.BioGroups.push({ igmCode: data[i].igmCode, igmDesc: data[i].GroupName, Icon: this.Icon, Color: this.Color })

      }
        this.spinner = false;

    });
  }
  









  //===============//  
  CickOnAvraCaralogue() {
    debugger
    this.router.navigateByUrl('/ProductsTree');
  }

  ClickOnBioGrp(Item) {
    debugger
    var GetUrl = 'api/Values/GetGroupSPL';
    var UploadFile = new FormData();
    UploadFile.append('Flag', "3");
    UploadFile.append('ItmCode', Item.igmCode);
    UploadFile.append('ItmDesc', "");
    this.generalService.PostData(GetUrl, UploadFile).then((data: any) => {
      debugger
      localStorage.removeItem('Items');
      localStorage.setItem('Items', JSON.stringify(data))
    });
    localStorage.removeItem('Item');
    localStorage.setItem('Item', JSON.stringify(Item))
    this.router.navigateByUrl('/BioSubGrps');
  }





  
}
